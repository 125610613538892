<template>
  <div :class="['AppAutoUpload', { [`size-x${size}`]: true }]">
    <ImageUpload
      @change="uploadLayout"
      v-loading="loading"
      :src="value | uploadUrl"
      :preview="preview"
      :accept="accept"
      :size="size"
    />
    <ElProgress
      v-if="loading"
      :percentage="this.progress"
      :stroke-width="3"
      type="circle"
      :width="60 * size + 3"
    />
  </div>
</template>

<script>
import ImageUpload from 'components/ImageUpload';
import { commonAlerts } from 'util/commonAlerts';
export default {
  name: 'AppAutoUpload',
  components: { ImageUpload },
  props: {
    value: {},
    accept: { default: '*/*' },
    preview: { default: true },
    size: { default: 1 },
  },
  data() {
    return {
      loading: false,
      progress: 0,
    };
  },
  methods: {
    async uploadLayout(file) {
      if (!file) {
        this.$emit('input', file);
        return;
      }
      this.loading = true;
      try {
        const [{ id }] = await this.$api.Upload.uploadFiles(file, progress => {
          this.progress = progress;
        });
        this.$emit('input', id);
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
        this.progress = 0;
      }
    },
  },
};
</script>

<style lang="scss">
.AppAutoUpload {
  display: inline-block;
  position: relative;
  .el-progress {
    position: absolute;
    top: 6px;
    left: 13px;
    .el-progress__text {
      font-size: 0.8rem !important;
      font-weight: bold;
    }
  }
}
</style>
