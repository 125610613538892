<template>
  <Tooltip :content="$t('duplicate')">
    <BaseButton
      size="sm"
      icon
      class="btn-link btn-github btn-simple"
      @click="clone"
    >
      <i class="far fa-copy" />
    </BaseButton>
  </Tooltip>
</template>

<script>
import OrderEditDialog from 'pages/order/OrderEditDialog';

export default {
  name: 'OrderCloneButton',
  props: {
    orderId: { required: true },
  },
  methods: {
    clone(e) {
      this.$emit('click', e);
      this.$openModal(OrderEditDialog, {
        id: this.orderId,
        forCloning: true,
      });
    },
  },
};
</script>

<style scoped></style>
