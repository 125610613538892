var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ProductPricingTable overflow-auto"},[_c('table',{staticClass:"w-100 PriceListTable"},[_c('colgroup',[(!_vm.editMode)?_c('col',{staticStyle:{"width":"20px"}}):_vm._e(),_c('col',{staticStyle:{"width":"100px"}})]),_c('thead',[_c('tr',[(!_vm.editMode)?_c('th'):_vm._e(),_c('th',{staticClass:"text-center"},[_c('span',{staticClass:"m-1"},[_vm._v(" "+_vm._s(_vm.$t('quantity'))+" ")])]),_c('th',{staticClass:"text-center"},[_c('span',{staticClass:"m-1"},[_vm._v(" "+_vm._s(_vm.$t('basePrice'))+" ")])]),_vm._l((_vm.productPricing.attributeValues),function(attributeValueId,idx){return (_vm.attributeValues.hasOwnProperty(attributeValueId))?_c('th',{key:attributeValueId,class:{
            'text-center': true,
            AttributeMatch: _vm.selectedAttributeValues.some(
              (a) => a.id === attributeValueId
            ),
          },attrs:{"set":(_vm.attrData = _vm.attributeValues[attributeValueId])}},[_c('Badge',{staticClass:"m-1",attrs:{"type":"info"}},[_vm._v(" "+_vm._s(_vm.attrData.attribute.caption)+": "+_vm._s(_vm.attrData.value.caption)+" "),(_vm.editMode)?_c('BaseButton',{staticClass:"m-0 ml-1",attrs:{"icon":"","size":"xs","type":"danger"},on:{"click":function($event){return _vm.productPricing.attributeValues.removeIdx(idx)}}},[_c('i',{staticClass:"fas fa-times p-0"})]):_vm._e()],1)],1):_vm._e()}),(_vm.editMode)?_c('th'):_vm._e()],2)]),_c('tbody',_vm._l((_vm.productPricing.list),function(item,idx){return _c('tr',{key:idx,class:{ QuantityMatch: _vm.currentQuantity >= item.quantity }},[(!_vm.editMode)?_c('td',{staticClass:"ApplyCol"},[_c('Tooltip',{attrs:{"content":_vm.$t('applyPriceListTooltip')}},[_c('BaseButton',{attrs:{"type":_vm.currentQuantity >= item.quantity ? 'info' : 'github',"icon":"","link":"","simple":""},on:{"click":function($event){return _vm.applyValue(item)}}},[_c('i',{staticClass:"fas fa-check"})])],1)],1):_vm._e(),_c('td',[_c('InputMoney',{attrs:{"name":"quantity","prefix":'',"precision":0,"readonly":!_vm.editMode},model:{value:(item.quantity),callback:function ($$v) {_vm.$set(item, "quantity", _vm._n($$v))},expression:"item.quantity"}})],1),_c('td',[_c('InputMoney',{attrs:{"readonly":!_vm.editMode},model:{value:(item.price),callback:function ($$v) {_vm.$set(item, "price", _vm._n($$v))},expression:"item.price"}})],1),_vm._l((_vm.productPricing.attributeValues),function(attributeValueId){return (_vm.attributeValues.hasOwnProperty(attributeValueId))?_c('td',{key:attributeValueId,class:{
            AttributeMatch: _vm.selectedAttributeValues.some(
              (a) => a.id === attributeValueId
            ),
          }},[_c('InputMoney',{attrs:{"readonly":!_vm.editMode},model:{value:(item.getPriceFor(attributeValueId).value),callback:function ($$v) {_vm.$set(item.getPriceFor(attributeValueId), "value", _vm._n($$v))},expression:"item.getPriceFor(attributeValueId).value"}})],1):_vm._e()}),(_vm.editMode)?_c('td',{staticClass:"ActionCol"},[_c('TableRemoveButton',{on:{"click":function($event){return _vm.productPricing.list.removeIdx(idx)}}})],1):_vm._e()],2)}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }