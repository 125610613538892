<template>
  <CrudDialog
    :title="$t('contact')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col-6">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
        />
      </div>
      <div class="col-6">
        <BaseInput
          name="type"
          :label="$t('type')"
          :value="$t(model.type)"
          :readonly="true"
        />
      </div>
    </div>

    <BaseInput
      name="content"
      :label="$t('content')"
      v-model="model.content"
      v-validate="`required|min:15|max:15|uniquePhoneNumber:${phoneList}`"
      v-mask="['(##) ####-####', '(##) #####-####']"
      placeholder="(##) #####-####"
      required
    />
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import { mergeObjects } from 'util/utils';

export default {
  name: 'ContactEditDialog',
  mixins: [FormMixin],
  props: {
    contacts: {},
    entity: {},
    onSave: {},
  },
  data() {
    return {
      loading: false,
      model: mergeObjects(
        {
          id: undefined,
          type: 'PHONE',
          content: '',
        },
        this.entity
      ),
    };
  },
  computed: {
    phoneList() {
      return [
        this.entity?.content || '',
        ...(this.contacts || []).map((c) => c.content),
      ].join(',');
    },
  },
};
</script>

<style scoped></style>
