import { render, staticRenderFns } from "./AccountingEntryCategories.vue?vue&type=template&id=48c695cd&scoped=true"
import script from "./AccountingEntryCategories.vue?vue&type=script&lang=js"
export * from "./AccountingEntryCategories.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "48c695cd",
  null
  
)

export default component.exports