<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  >
    <template v-slot:headerButtons>
      <base-button @click="openTypeDialog()" class="mr-2">
        {{ $t('configureTypes') }}
      </base-button>
    </template>
  </CrudPage>
</template>
<script>
import OrderStatusEditDialog from 'pages/orderStatus/OrderStatusEditDialog';
import { EventType } from 'plugins/eventBusPlugin';
import TagsCol from 'components/app/table/TagsCol';
import OrderStatusTypeConfigDialog from 'pages/orderStatus/OrderStatusTypeConfigDialog';

export default {
  name: 'OrderStatus',
  data() {
    return {
      dialog: OrderStatusEditDialog,
      eventsToListen: [EventType.OrderStatusChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 80 } },
        {
          prop: 'funnel.name',
          label: this.$t('funnel'),
          attrs: { width: 150 },
        },
        { prop: 'description' },

        {
          prop: 'props.sectors',
          label: this.$t('sectors'),
          component: TagsCol,
          toLabel: (sectorId) => {
            const sector = this.sectors[sectorId];

            return sector ? sector[0].name : '';
          },
        },
        { prop: 'order', label: this.$t('position'), attrs: { width: 100 } },
        {
          prop: 'visible',
          attrs: { width: 100 },
          render: ({ row }) => this.$t(row.visible.toString()),
        },
      ],
      sectors: [],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|description|funnel.name:ilike'] = `%${query}%`;
      }
      this.sectors = _.groupBy(
        await this.$api.Sector.findAll({ orderBy: 'name' }),
        'id'
      );
      const data = await this.$api.OrderStatus.findAll({
        eager: ['funnel'],
        ...filters,
        ...pagination,
      });
      data.results.forEach((status) => {
        let sectors = _.get(status, 'props.sectors') || [];
        sectors = sectors.filter((sectorId) =>
          this.sectors.hasOwnProperty(sectorId)
        );
        _.set(
          status,
          'props.sectors',
          sectors.filter((sectorId) => this.sectors.hasOwnProperty(sectorId))
        );
      });
      return data;
    },
    async remove(row) {
      return await this.$api.OrderStatus.remove(row.id);
    },
    openTypeDialog() {
      this.$openModal(OrderStatusTypeConfigDialog);
    },
  },
};
</script>
<style scoped lang="scss"></style>
