<template>
  <AppDateTimePicker
    type="daterange"
    :value="value"
    @input="valueChanged"
    :picker-options="pickerOptions"
    unlink-panels
  />
</template>

<script>
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
export default {
  name: 'AppFilterDatePicker',
  components: { AppDateTimePicker },
  props: {
    value: {},
  },
  formatAsFilter(name, dateRange) {
    return {
      [`${name}:dateGte`]: dateRange ? dateRange[0] : undefined,
      [`${name}:dateLte`]: dateRange ? dateRange[1] : undefined,
    };
  },
  data() {
    return {
      pickerOptions: {
        shortcuts: [
          {
            text: this.$t('lastWeek'),
            onClick(picker) {
              picker.$emit('pick', [
                moment().subtract(1, 'w').startOf('week'),
                moment().subtract(1, 'w').endOf('week'),
              ]);
            },
          },
          {
            text: this.$t('currentWeek'),
            onClick(picker) {
              picker.$emit('pick', [
                moment().startOf('week'),
                moment().endOf('week'),
              ]);
            },
          },
          {
            text: this.$t('lastMonth'),
            onClick(picker) {
              picker.$emit('pick', [
                moment().subtract(1, 'M').startOf('month'),
                moment().subtract(1, 'M').endOf('month'),
              ]);
            },
          },
          {
            text: this.$t('currentMonth'),
            onClick(picker) {
              picker.$emit('pick', [
                moment().startOf('month'),
                moment().endOf('month'),
              ]);
            },
          },
          {
            text: this.$t('currentYear'),
            onClick(picker) {
              picker.$emit('pick', [
                moment().startOf('year'),
                moment().endOf('year'),
              ]);
            },
          },
        ],
      },
    };
  },
  methods: {
    valueChanged(value) {
      this.$emit('input', value);
    },
  },
};
</script>

<style></style>
