import { axiosInstance } from 'api/AxiosInstance';
import { BrainApi } from 'api/BrainApi';

const $http = axiosInstance();

const resource = 'quizzes';

export class QuizApi extends BrainApi {
  constructor() {
    super({ resource });
  }

  async findByIdentifier(identifier) {
    const response = await $http.get(`/api/public/${resource}/${identifier}`);
    return response.data;
  }

  async saveResponse(quizResponse, params = {}) {
    const response = await $http.post(
      `/api/public/${resource}/${quizResponse.identifier}`,
      { props: quizResponse.props },
      { params }
    );
    return response.data;
  }

  async resendNotifications(quizId) {
    const response = await $http.post(
      `/api/${resource}/${quizId}/resendNotifications`
    );
    return response.data;
  }
}
