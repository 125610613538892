<template>
  <Card class="LayoutRequestCard">
    <template v-slot:header>
      <div class="row">
        <div v-if="request.order" class="col-3 position-relative">
          <ManageOrderButton :order-id="request.order.id" />
          <div class="ml-4">
            {{ request.order.orderCode }}
          </div>
        </div>
        <div class="col text-right">
          <div class="d-flex align-items-center justify-content-end">
            <div class="mr-3">
              <i class="far fa-clock" />
              {{ request.createdAt | formatDateTime }}
            </div>
            <PriorityBadge class="m-0" :priority="request.priority" />
          </div>
        </div>
      </div>
    </template>

    <div class="Body">
      <div class="row">
        <div class="col">
          <p>
            <b class="mr-2">{{ $t('seller') }}:</b>
            <span>{{ request.user ? request.user.name : '-' }}</span>
          </p>
        </div>
        <div class="col-4" v-if="!isRequestView">
          <BaseInput name="alert" class="m-0">
            <BaseCheckbox
              class="m-0"
              name="alert"
              :checked="request.alert"
              :disabled="true"
            >
              {{ $t('alertTheDesigners') }}
            </BaseCheckbox>
          </BaseInput>
        </div>
      </div>

      <template v-if="!isRequestView || !request.response">
        <div class="Request">
          <BaseInput :label="$t('notes')">
            <AppTextArea :value="request.notes" read-only="true" />
          </BaseInput>

          <DropzoneUpload :value="request.uploads" :read-only="true" />
        </div>

        <div class="Response mt-4 p-2" v-if="request.response">
          <div class="d-flex align-items-center mb-3">
            <h4 class="m-0">{{ $t('response') }}</h4>
            <BaseInput
              v-if="request.response && isRequestView"
              name="alert"
              class="m-0 ml-4"
            >
              <BaseCheckbox
                class="m-0"
                name="alert"
                :checked="request.response.alert"
                :disabled="true"
              >
                {{ $t('alertTheSeller') }}
              </BaseCheckbox>
            </BaseInput>
          </div>

          <BaseInput :label="$t('notes')">
            <AppTextArea :value="request.response.notes" read-only="true" />
          </BaseInput>

          <DropzoneUpload :value="request.response.uploads" :read-only="true" />
        </div>
      </template>
    </div>
    <div class="Footer mt-3">
      <div class="row">
        <div class="col-6">
          <p>
            <b class="mr-2">{{ $t('designer') }}:</b>
            <span>{{
              request.response ? request.response.user.name : '-'
            }}</span>
          </p>
        </div>
        <div class="col text-right" v-if="isRequestView">
          <BaseButton
            :type="request.response ? 'warning' : 'info'"
            @click="$emit('editResponse')"
          >
            {{ $t(request.response ? 'editDesign' : 'addDesign') }}
          </BaseButton>
        </div>
        <div class="col text-right" v-if="!isRequestView">
          <BaseButton type="warning" @click="$emit('editRequest')">
            {{ $t('editRequest') }}
          </BaseButton>
        </div>
      </div>
      <div class="text-right"></div>
    </div>
  </Card>
</template>
<script>
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import Badge from 'components/Badge';
import PriorityBadge from 'pages/order/design/PriorityBadge';
import DropzoneUpload from 'components/app/input/DropzoneUpload';
import AppTextArea from 'components/app/AppTextArea';
import ManageOrderButton from 'pages/order/ManageOrderButton';

export default {
  name: 'LayoutRequestCard',
  components: {
    ManageOrderButton,
    AppTextArea,
    DropzoneUpload,
    PriorityBadge,
    Badge,
    AppSelect,
    AppFilterDatePicker,
  },
  props: {
    request: { required: true },
    isRequestView: { default: true },
  },
  data() {
    return {};
  },
  methods: {},
  async mounted() {},
};
</script>
<style lang="scss">
.LayoutRequestCard {
  .ManageOrderButton {
    position: absolute !important;
    top: -10px;
    left: 8px;
  }

  .Response {
    background-color: lighten(#27293d, 5);
    border-radius: 4px;
  }
}
</style>
