import VueRouter from 'vue-router';
import routes from './routes';
import { appStore } from 'state/Store';
import { $perm } from 'plugins/permissionsPlugin';

const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history',
});

router.beforeEach(async (to, from, next) => {
  const isPublic = to.matched.some(record => record.meta.public);
  const loggedIn = !_.isEmpty(appStore.state.token);

  if (!isPublic && !loggedIn) {
    return next({
      path: '/auth/login',
      query: { redirect: to.fullPath }, // Store the full path to redirect the user to after login
    });
  }

  const onlyWhenLoggedOut = to.matched.some(
    record => record.meta.onlyWhenLoggedOut
  );

  if (loggedIn && onlyWhenLoggedOut) {
    return next('/');
  }

  const requiredPermissions = _.flatMap(to.matched, record =>
    _.get(record, 'meta.perm', [])
  );

  if (!$perm.hasAccess(requiredPermissions)) {
    return next('/errors/403');
  }

  next();
});
export default router;
