<template>
  <Tooltip :content="tooltip">
    <BaseButton
      class="edit btn-link"
      :type="type"
      :size="size"
      icon
      @click="click"
      v-bind="$attrs"
    >
      <i :class="icon"></i>
    </BaseButton>
  </Tooltip>
</template>

<script>
export default {
  name: 'TableButton',
  inheritAttrs: false,
  props: {
    tooltip: String,
    icon: String,
    type: {
      type: String,
      default: 'warning',
    },
    size: {
      default: 'sm',
    },
  },
  methods: {
    click(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped></style>
