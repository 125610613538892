import { axiosInstance } from 'api/AxiosInstance';
import { mergeObjects } from 'util/utils';

const $http = axiosInstance();

const castToClass = (props, data) => {
  if (props.transform) {
    if (data.hasOwnProperty('results')) {
      return {
        ...data,
        results: data.results.map(props.transform),
      };
    } else if (_.isArray(data)) {
      return data.map(props.transform);
    } else {
      return props.transform(data);
    }
  }
  return data;
};

export class BrainApi {
  constructor(props = {}) {
    this.props = props;
  }

  async findAll(params = {}) {
    params = mergeObjects({}, this.props.defaultFindAllParams || {}, params);
    const { data } = await $http.get(`/api/brain/${this.props.resource}`, {
      params,
    });
    return castToClass(this.props, data);
  }

  async findById(id, params = {}) {
    const { data } = await $http.get(`/api/brain/${this.props.resource}`, {
      params: {
        id,
        ...params,
      },
    });
    return castToClass(this.props, data[0]);
  }

  async save(entity, params = {}) {
    let idPart = '';
    if (entity.id) {
      idPart = `/${entity.id}`;
    }
    const response = await $http.post(
      `/api/brain/${this.props.resource}${idPart}`,
      entity,
      {
        params,
      }
    );
    return castToClass(this.props, response.data);
  }

  async remove(id) {
    const { data } = await $http.delete(`/api/brain/${this.props.resource}/${id}`);
    return data;
  }

  async bulkRemove(ids = []) {
    const { data } = await $http.post(`/api/brain/${this.props.resource}/bulkRemove`, { ids });
    return data;
  }

  async fn({ name, method = 'post', params }) {
    const { data } = await $http[method](
      `/api/brain/${this.props.resource}/fns/${name}`,
      params
    );
    return data;
  }
}
