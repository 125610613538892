<template>
  <div
    :class="[
      'container-fluid OrderExportPageNewLayout',
      { IdzLayout: idzLayout, PdfExport: isPdfExport },
    ]"
    ref="container"
    v-if="!_.isEmpty(order)"
    :style="`min-height: ${Math.max(100, itemsPageGroups.length * 100)}vh;`"
  >
    <div class="Content">
      <h1 class="OrderCode">
        {{ order | orderCode }}
      </h1>
      <div class="row mt-2 pl-4 pr-4">
        <div class="col lpad">
          <p v-if="order.seller">
            <b>{{ $t('seller').toUpperCase() }}</b
            >: {{ order.seller.name }}
          </p>
          <p>
            <b>{{ $t('customer').toUpperCase() }}</b>
          </p>
          <table class="InfoTable">
            <colgroup>
              <col style="width: 12%" />
              <col style="width: auto" />
            </colgroup>
            <tr v-for="([key, value], idx) in customerProps" :key="idx">
              <td>{{ $t(key) }}:</td>
              <td class="pl-2">{{ value }}</td>
            </tr>
          </table>
        </div>
        <div class="col lpad">
          <p>
            <b>{{ $t('address').toUpperCase() }}</b>
          </p>
          <table class="InfoTable">
            <colgroup>
              <col style="width: 10%" />
              <col style="width: auto" />
            </colgroup>
            <tr v-for="([key, value], idx) in deliveryProps" :key="idx">
              <td>{{ $t(key) }}:</td>
              <td>{{ value }}</td>
            </tr>
          </table>
        </div>
      </div>

      <div
        :class="['ItemsContainer mt-5 pl-4 pr-4', { OnNewPage: idx > 0 }]"
        v-for="(items, idx) in itemsPageGroups"
        :key="`items-${idx}`"
      >
        <div class="Row" v-for="item in items" :key="item.id">
          <div class="DarkBg align-items-center justify-content-center">
            <img :src="item.containImage() ? $filters.uploadUrl(item.findImage()) : canecaImage" />
          </div>
          <div class="DarkBg">
            <div>
              <div class="mb-2">
                {{ item.product.name }}
              </div>
              <div v-for="attrVal in item.attributes" :key="attrVal.id">
                <b class="mr-2">{{ attrVal.attribute.caption }}:</b>
                <span>{{ attrVal.caption }}</span>
              </div>
            </div>
          </div>
          <div class="DarkBg align-items-center justify-content-center HugeFont">
            x{{ item.quantity | number }}
          </div>
          <div class="DarkBg align-items-center justify-content-center HugeFont">
            {{ item.price | money }}
          </div>
          <div class="DarkBg align-items-center justify-content-center HugeFont">
            {{ (item.price * item.quantity) | money }}
          </div>
        </div>
      </div>

      <div class="Footer mt-3 pl-4 pr-4">
        <div class="row">
          <div class="col lpad d-flex align-items-center" style="font-size: 1rem">
            <div :class="`w-100 ${idzLayout ? 'DarkBg' : 'ShippingInfo'}`">
              <table class="w-100">
                <tr>
                  <td class="text-nowrap">
                    <b>{{ $t('shippingMethod') }}</b>
                  </td>
                  <td colspan="2" class="text-right">
                    {{ _.get(order, 'shipments[0].method.description', '-') }}
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <b>{{ $t('deliveryPrice') }}</b>
                  </td>
                  <td colspan="2" class="text-right">
                    {{ _.get(order, 'shipments[0].total', 0) | money }}
                  </td>
                </tr>
                <tr>
                  <td class="text-nowrap">
                    <b>{{ $t('productionDeadlineDelivery') }}</b>
                  </td>
                  <td colspan="2" class="text-right">
                    {{ _.get(order, 'productionDays', 15) + _.get(order, 'shippingDays', 10) }}
                    {{ $t('workingDays').toLowerCase() }} ({{ $t('afterPayment').toLowerCase() }})
                  </td>
                </tr>
              </table>
            </div>
          </div>

          <div class="col-3 FreteGratis d-flex align-items-center justify-content-center">
            <img
              src="~assets/images/OrderExportPage/freteGratis.svg"
              style="width: 85%; margin-right: 10%; visibility: hidden"
            />
          </div>
          <div class="col-3 TotalContainer d-flex justify-content-center">
            <div
              class="text-center d-flex flex-column justify-content-center"
              style="padding-bottom: 32px !important; padding-right: 5px !important"
            >
              <h2 style="font-weight: bold; text-transform: uppercase; margin: 0" class="">
                {{ $t('total') }}
              </h2>
              <h3 class="m-0">
                <b style="line-height: 3rem; vertical-align: super" class="mr-1"> R$ </b>
                <b style="font-size: 3rem; font-weight: 600">{{
                  $filters.money(order.totalValue()).substring(3)
                }}</b>
              </h3>
            </div>
          </div>
        </div>
      </div>
    </div>
    <footer>
      {{ $t('bugdetValidMsg') }}. {{ $t('bugdetGeneratedDate') }}: {{ currentDate() }}
    </footer>
  </div>
</template>

<script>
import Badge from 'components/Badge';
import { formatDateTime } from 'util/utils';
import OrderMailTag from 'pages/order/OrderMailTag';
import OrderMailTagExportPageContent from 'pages/order/OrderMailTagExportPageContent';
import canecaImage from 'assets/images/OrderExportPage/caneca.png';

export default {
  name: 'OrderExportPageNewLayout',
  components: { OrderMailTagExportPageContent, OrderMailTag, Badge },
  props: {
    orderId: {},
  },
  data() {
    return {
      canecaImage,
      order: null,
      idzLayout: false,
    };
  },
  methods: {
    async fetchOrder() {
      this.order = {};
      if (this.orderId) {
        this.loading = true;
        try {
          this.order = await this.$api.Order.findById(this.orderId, {
            eager: `[
            shipments.[method],
            items.[product.[attributes], attributes.[attribute], design.[designer], provider],
            payments,
            customer.[addresses, contacts, discountCoupons],
            status.[types],
            designChangeUser,
            seller,
            appliedCoupons,
            recommendedByQuiz.[order.[customer]]
          ]`,
          });
        } finally {
          this.loading = false;
        }
      }
    },
    calculateSubtotal(order) {
      if (!order) {
        return 0;
      }
      return order.totalItemsValue();
    },
    currentDate() {
      return moment().format('DD/MM/YYYY HH:mm');
    },
  },
  computed: {
    orderSubtotal() {
      return this.calculateSubtotal(this.order);
    },
    customerProps() {
      const customer = this.order.customer;
      const isLegal = customer.isLegal();
      const contact = customer.mainContact();
      const props = {
        name: customer.name,
        cpf: isLegal ? undefined : customer.cpf || '-',
        cnpj: isLegal ? customer.cnpj || '-' : undefined,
        stateRegistration: isLegal ? customer.stateRegistration() || '-' : undefined,
        phone: contact ? contact.content : '-',
        email: customer.email || '-',
      };
      return Object.entries(props).filter((kv) => !_.isUndefined(kv[1]));
    },
    deliveryProps() {
      const address = this.order.customer.mainAddress();
      const props = {
        street: address?.address || '-',
        neighborhood: address?.neighborhood || '-',
        postalCode: address?.postalCode || '-',
        city: address?.cityAndState() || '-',
      };

      return Object.entries(props).filter((kv) => !_.isUndefined(kv[1]));
    },
    otherProps() {
      const order = this.order;
      return Object.entries({
        productionDeadline: formatDateTime(order.productionDeadline, 'date') || '-',
        eventDate: formatDateTime(order.eventDate, 'date') || '-',
        providers:
          order.items
            .filter((item) => !!item.provider)
            .map((item) => item.provider.name)
            .join(', ') || '-',
      });
    },
    itemsPageGroups() {
      if (!this.isPdfExport) {
        return [this.order.items];
      }

      const pages = [];
      let page = [];
      this.order.items.forEach((item) => {
        const maxChunkLength = pages.length === 0 ? 4 : 6;
        if (page.length === maxChunkLength) {
          pages.push(page);
          page = [];
        }
        page.push(item);
      });

      if (page.length > 0) {
        pages.push(page);
      }
      return pages;
    },
    isPdfExport() {
      return this.$route.query.exportType === 'pdf';
    },
  },
  async mounted() {
    const body = document.querySelector('body');
    body.classList.add('forPrint');
    body.classList.add('OrderExportPage');
    const { company } = await this.$api.Env.findEnv();
    if (company !== this.$api.Env.DEFAULT_COMPANY_NAME) {
      this.idzLayout = true;
    }
    await this.fetchOrder();
    setTimeout(function () {
      window._PAGE_LOAD_FINISHED = true;
    }, 1000);
  },
  updated() {
    if (this.isPdfExport) {
      const prop = this.$refs.container.offsetHeight / window.innerHeight;
      if (prop > 1) {
        this.$refs.container.style.minHeight = `${100 * Math.ceil(prop)}vh`;
      }
    }
  },
};
</script>

<style scoped lang="scss">
@import '~assets/sass/dashboard/custom/variables';
@import url('https://fonts.googleapis.com/css?family=Barlow|Barlow+Condensed:400,500,600,700&display=swap');

$pageTopMargin: 325px;

.Content {
  overflow: hidden;
  margin-top: $pageTopMargin;
}

.container {
  min-height: 100vh;
  position: relative;
}

.OrderExportPageNewLayout.PdfExport {
  background-size: contain;
}

.OrderExportPageNewLayout {
  background-image: url('~assets/images/OrderExportPage/arteOrcamentoCatapulta.svg');
  background-repeat: repeat-y;
  background-size: cover;
  overflow: hidden;
}

.OrderExportPageNewLayout.IdzLayout {
  background-image: url('~assets/images/OrderExportPage/arteOrcamentoIdz.png');
}

.Footer {
  page-break-inside: avoid;
}

.Total {
  background-color: #f04f53;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.Total * {
  color: white;
}

.TableFooter {
  border-top: 3px solid;
}

.container-fluid {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
  position: relative;
}

.alignOnCenter {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.alignOnCenterLeft {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.el-table td {
  border-top-width: 3px;
}

.Content,
.el-table,
.el-table th {
  font-size: $font-size-xl;
}

.TableFooter tr:first-child td {
  border-top-width: 0;
}

.el-table td,
.el-table th {
  border-color: #d3d4d9 !important;
  page-break-inside: avoid;
}

.OrderCode {
  text-align: center;
  font-weight: bold;
  font-size: 3rem;
}

.InfoTable td {
  font-size: $font-paragraph;
}

.ItemsContainer.OnNewPage {
  margin-top: $pageTopMargin;
  page-break-before: always;
}

.ItemsContainer {
  .Row {
    page-break-inside: avoid;

    &:last-child {
      margin-bottom: 0;
    }

    display: flex;
    margin-bottom: 20px;

    .DarkBg {
      page-break-inside: avoid;

      &:first-child {
        margin-left: 0;
      }
      &:nth-child(2) {
        flex-grow: 1;
      }
      margin-left: 20px;
    }
  }
}

@mixin BgOnlyDarkBg {
  background-color: #d3373a;
  border-radius: 8px;
  box-shadow: 0 0 3px darken(#d3373a, 10%);
  padding: 10px 10px;
}

.ShippingInfo {
  @include BgOnlyDarkBg;
}

.DarkBg {
  @include BgOnlyDarkBg;
  font-weight: bold;
  font-size: $font-paragraph;
  display: flex;
  width: 140px;
  min-height: 120px;
  img {
    object-fit: scale-down;
    height: 100px;
  }
}

.IdzLayout .DarkBg {
  background-color: rgba(0, 0, 0, 0.5);
  box-shadow: 0 0 3px rgba(0, 0, 0, 0.8);
}

.IdzLayout .TotalContainer {
  background-image: url('~assets/images/OrderExportPage/retanguloInclinadoIdz.svg');
}

.TotalContainer {
  background-image: url('~assets/images/OrderExportPage/retanguloInclinado.svg');
  background-repeat: no-repeat;
  background-size: contain;
  background-position: top 0 right 5px;
  font-family: 'Barlow Condensed';
}

.Footer > .row {
  min-height: 200px;
}

.lpad {
  padding-left: 80px !important;
}

.HugeFont {
  font-size: 1.3rem;
}

footer {
  position: fixed;
  bottom: 10px;
  right: 35px;
  text-align: right;
}
</style>
