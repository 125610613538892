import { BrainApi } from 'api/BrainApi';
import { OrderPaymentUi } from 'api/Models';

export class OrderPaymentApi extends BrainApi {
  constructor() {
    super({
      resource: 'orderPayments',
      transform: data => new OrderPaymentUi(data),
    });
  }

  findMethods() {
    return this.findAll({ distinct: 'method', orderBy: 'method' });
  }
}
