<template>
  <Tooltip :content="$t('researches')">
    <BaseButton
      size="sm"
      icon
      :class="[
        'OrderQuizButton btn-link btn-simple btn-github',
        { Disabled: _.isEmpty(order.afterSaleQuizId) },
      ]"
      @click="openQuiz"
    >
      <i class="fas fa-question" />
    </BaseButton>
  </Tooltip>
</template>

<script>
import OrderQuizDialog from 'pages/order/quiz/OrderQuizDialog';

export default {
  name: 'OrderQuizButton',
  props: {
    order: {},
  },
  methods: {
    openQuiz(e) {
      this.$emit('click', e);
      this.$openModal(OrderQuizDialog, {
        orderId: this.order.id,
      });
    },
  },
};
</script>

<style lang="scss">
.OrderWhatsButton {
  &.Disabled {
    opacity: 0.5;
  }
}
</style>
