<template>
  <img
    :src="avatarUrl || defaultUserSvg"
    alt="Avatar"
    rel="preload"
    :class="['WhatsAvatar img-fluid rounded-circle', { WithError: error }]"
    @error="loadError"
    v-loading="loading"
    v-observe-visibility="{
      callback: visibilityChanged,
      once: true,
    }"
  />
</template>

<script>
import defaultUserSvg from 'assets/images/defaultUser.svg';

const viewCache = {};

export default {
  name: 'WhatsAvatar',
  props: {
    customer: {},
    contact: {},
  },
  data() {
    return {
      loading: false,
      defaultUserSvg,
      avatarUrl: '',
      error: false,
    };
  },
  watch: {
    contact(val) {
      this.fetchAvatar();
    },
  },
  methods: {
    visibilityChanged(isVisible, entry) {
      if (isVisible) {
        this.fetchAvatar();
      }
    },
    async fetchAvatar() {
      this.avatarUrl = this.contact?.props?.avatarUrl || viewCache[this.contact?.id] || '';

      if (this.avatarUrl) return;

      this.loading = true;
      try {
        const contactId = this.contact?.id;
        if (viewCache[contactId]) {
          this.avatarUrl = viewCache[contactId];
        } else {
          this.avatarUrl = await this.searchForAvatar(false);
          viewCache[contactId] = this.avatarUrl;
        }
      } finally {
        this.loading = false;
      }
    },
    async searchForAvatar(clearCache) {
      const contactId = this.contact?.id;
      const contact = await this.$api.Whats.findContact(this.customer?.id, contactId, clearCache);
      return contact?.avatarUrl || defaultUserSvg;
    },
    loadError() {
      const oldError = this.error;
      this.error = true;
      this.avatarUrl = defaultUserSvg;
      if (!oldError) {
        this.searchForAvatar(true);
      }
    },
  },
};
</script>

<style scoped>
.WhatsAvatar {
  box-shadow: 0 0 2px #eee;
}

.WhatsAvatar.WithError {
  box-shadow: 0 0 5px red;
}

img {
  width: 128px;
}

.xs {
  width: 32px;
}

.sm {
  width: 48px;
}

.md {
  width: 64px;
}

.lg {
  width: 80px;
}
</style>
