<template>
  <span>
    {{ translatedValue }}
  </span>
</template>

<script>
import { formatDateTime } from 'util/utils';

export default {
  name: 'DateTimeCol',
  props: {
    tableProps: {},
    colInfo: {},
  },
  computed: {
    translatedValue() {
      return formatDateTime(
        _.get(this.tableProps.row, this.colInfo.prop),
        _.get(this.colInfo, 'attrs.style', 'datetime')
      );
    },
  },
};
</script>

<style scoped></style>
