import { axiosInstance } from 'api/AxiosInstance';
import { BrainApi } from 'api/BrainApi';

const $http = axiosInstance();

export class UserApi extends BrainApi {
  constructor() {
    super({ resource: 'users' });
    this.brain = this;
  }

  async saveUser(user) {
    let idPart = '';
    if (user.id) {
      idPart = `/${user.id}`;
    }
    const response = await $http.post(`/api/users${idPart}`, user);
    return response.data;
  }

  async updatePassword(id, password) {
    const response = await $http.post(`/api/users/${id}`, { password });
    return response.data;
  }
}
