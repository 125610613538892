<template>
  <div class="Financial" v-loading="loading">
    <div class="FilterButtonContainer">
      <OrderFilterButton @filter="runQuery" ref="filterButton" />
    </div>

    <Tabs>
      <Tab key="sales">
        <div slot="label">
          {{ $t('sales') }}
        </div>

        <div class="text-right">
          <BaseButton @click="openSalesByState()" class="btn-link text-light">
            <i class="fas fa-globe-americas mr-1" />
            {{ $t('salesByState') }}
          </BaseButton>
          <BaseButton
            @click="openCustomersByOrigin()"
            class="btn-link text-light"
          >
            <i class="fas fa-phone mr-1" />
            {{ $t('customersByOrigin') }}
          </BaseButton>
          <BaseButton
            @click="openTopSellingProducts()"
            class="btn-link text-light"
          >
            <i class="fas fa-shopping-cart mr-1" />
            {{ $t('topSellingProducts') }}
          </BaseButton>
        </div>
        <div class="Metrics">
          <MetricRow :metric="metrics.sold" />
        </div>
      </Tab>

      <Tab key="costs">
        <div slot="label">
          {{ $t('costs') }}
        </div>

        <div class="mt-2">
          <StatsCard
            :title="$filters.money(costs.total)"
            :sub-title="$t('totalCosts')"
            type="success"
            :icon="'fas fa-dollar-sign'"
          />

          <div class="row" v-for="chunk in _.chunk(costs.data, 2)">
            <div class="col-6" v-for="category in chunk">
              <StatsCard
                :title="$filters.money(category.value)"
                :sub-title="category.name"
                type="danger"
                :icon="'fas fa-dollar-sign'"
                @click="costs.details = category"
                class="DetailCost mb-3"
              />
            </div>
          </div>

          <Dialog
            v-if="costs.details"
            :title="costs.details.name"
            @close="costs.details = null"
          >
            <AppTable
              :data="costs.details.children"
              :columns="costs.tableColumns"
              row-key="id"
              :default-expand-all="true"
            />
          </Dialog>
        </div>
      </Tab>
    </Tabs>
  </div>
</template>
<script>
import StatsCard from 'components/Cards/StatsCard';
import MetricRow from 'pages/financial/MetricRow';
import MoneyCol from 'components/app/table/MoneyCol';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import OrderFilterButton from 'pages/funnel/OrderFilterButton';
import { StatusTypeUi } from 'api/Models';
import SalesByState from 'pages/reports/SalesByState';
import CustomersByOrigin from 'pages/reports/CustomersByOrigin';
import TopSellingProducts from 'pages/reports/TopSellingProducts';
import Dialog from 'components/app/Dialog';

const calculateOrders = (orders) => {
  const result = {
    orders: orders.length,
    totalValueWithoutDiscounts: orders.reduce(
      (acc, order) => acc + order.totalValueWithoutDiscounts(),
      0
    ),
    itemsValue: orders.reduce((acc, order) => acc + order.totalItemsValue(), 0),
    discountValue: orders.reduce(
      (acc, order) => acc + order.discountValue(),
      0
    ),
    shippingValue: orders.reduce(
      (acc, order) => acc + order.shipmentValue(),
      0
    ),
    shippingCost: orders.reduce((acc, order) => acc + order.shipmentCost(), 0),
    productCost: orders.reduce((acc, order) => acc + order.totalCost(), 0),
    firstPayment: {
      orders: _.orderBy(
        orders
          .filter((order) => !_.isEmpty(order.payments))
          .map((order) => {
            const $date = moment(order.date).startOf('day');
            const $createdAt = moment(order.createdAt).startOf('day');
            const days = Math.abs($createdAt.diff($date, 'days'));

            return {
              order,
              days,
            };
          }),
        ['days'],
        ['desc']
      ),
      avgTime: 0,
    },
  };
  result.firstPayment.avgTime =
    Math.ceil(
      result.firstPayment.orders.reduce((acc, { days }) => acc + days, 0) /
        result.firstPayment.orders.length
    ) || 0;
  return result;
};

export default {
  name: 'FinancialReport',
  components: {
    Dialog,
    OrderFilterButton,
    AppSelect,
    AppFilterDatePicker,
    MetricRow,
    StatsCard,
  },
  data() {
    return {
      loading: false,
      filter: null,
      orders: [],
      metrics: {
        sold: calculateOrders([]),
      },
      costs: {
        tableColumns: [
          { prop: 'name' },
          { prop: 'value', component: MoneyCol },
        ],
        data: [],
        total: 0,
        details: null,
      },
      paymentOptions: _.sortBy(
        ['true', 'false', 'all'].map((value) => ({
          value,
          label: this.$t(value),
        })),
        ['label']
      ),
    };
  },
  methods: {
    runQuery(filter) {
      this.filter = filter;
      this.processQuery();
    },
    async processQuery() {
      this.loading = true;

      try {
        const filter = AppFilterDatePicker.formatAsFilter(
          this.filter.date.field,
          this.filter.date.range
        );

        let orders = await this.$api.Order.findAll({
          ...filter,
          eager: `[
            items.[product, attributes.[attribute]],
            shipments,
            payments,
            customer.[addresses, contacts],
            appliedCoupons
            ]`,
        });

        orders = this.filter.filterOrders(orders);

        this.orders = orders;

        this.metrics.sold = calculateOrders(orders);

        const accountingEntries = _.groupBy(
          await this.$api.AccountingEntry.findAll({
            eager: '[subcategory.[category]]',
            ...AppFilterDatePicker.formatAsFilter(
              'date',
              this.filter.date.range
            ),
          }),
          'subcategory.category.name'
        );

        this.costs.data = _.sortBy(
          Object.entries(accountingEntries).map(([category, entries]) => {
            const subcats = _.groupBy(entries, 'subcategory.name');
            const children = Object.entries(subcats).map(
              ([subcategory, innerEntries]) => {
                const descs = innerEntries.map((e) => {
                  let name = this.$filters.formatDateTime(e.date, 'date');
                  if (e.description) {
                    name += ': ' + e.description;
                  }
                  return {
                    id: `${category}-${subcategory}-${e.id}`,
                    name,
                    date: e.date,
                    value: e.value,
                  };
                });

                return {
                  id: `${category}-${subcategory}`,
                  name: subcategory,
                  value: innerEntries.reduce((acc, e) => acc + e.value, 0),
                  children: _.sortBy(descs, ['date', 'name'], ['desc', 'asc']),
                };
              }
            );

            return {
              id: category,
              name: category,
              value: children.reduce((acc, e) => acc + e.value, 0),
              children: _.sortBy(children, ['name']),
            };
          }),
          ['name']
        );
        this.costs.total = this.costs.data.reduce((acc, e) => acc + e.value, 0);
      } finally {
        this.loading = false;
      }
    },
    openSalesByState() {
      this.$openModal(SalesByState, {
        orders: this.orders,
      });
    },
    openCustomersByOrigin() {
      this.$openModal(CustomersByOrigin, {
        orders: this.orders,
      });
    },
    openTopSellingProducts() {
      this.$openModal(TopSellingProducts, {
        orders: this.orders,
      });
    },
  },
  async mounted() {
    const [statusType] = await this.$api.StatusType.findAll({
      name: StatusTypeUi.Type.SALE,
      eager: '[orderStatus]',
    });
    this.filter = new OrderFilterButton.OrderFilterButtonModel({
      date: {
        range: [moment().startOf('month'), moment().endOf('month')],
      },
      statusTypes: [statusType],
    });
    this.$refs.filterButton.model = this.filter;
    this.processQuery();
  },
};
</script>
<style lang="scss">
.Financial {
  position: relative;

  .FilterButtonContainer {
    position: absolute;
    top: 0;
    right: 0;
  }

  .tab-space {
    padding: 0;
  }

  .DetailCost {
    cursor: pointer;
    transition: filter 200ms ease-in-out;
  }

  .DetailCost:hover {
    filter: brightness(130%);
  }
}
</style>
