<template>
  <div class="MetricRow">
    <div class="row">
      <div class="col">
        <StatsCard
          :title="`${$filters.money(metric.itemsValue)}`"
          :sub-title="$t('productsValue')"
          type="info"
          :icon="'fas fa-industry'"
        />
        <StatsCard
          :title="`${$filters.money(metric.shippingValue)}`"
          :sub-title="$t('chargedShipment')"
          type="info"
          :icon="'fas fa-truck'"
          class="m-0"
        />
      </div>
      <div class="col">
        <StatsCard
          :title="`${$filters.money(metric.productCost)}`"
          :sub-title="$t('costOfProducts')"
          type="danger"
          :icon="'fas fa-industry'"
        />
        <StatsCard
          :title="`${$filters.money(metric.shippingCost)}`"
          :sub-title="$t('shipmentCost')"
          type="danger"
          :icon="'fas fa-truck'"
        />
        <StatsCard
          :title="`${$filters.money(metric.discountValue)}`"
          :sub-title="$t('discounts')"
          type="danger"
          :icon="'fas fa-smile-wink'"
          class="m-0"
        />
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col">
        <StatsCard
          :title="`${$filters.money(metric.totalValueWithoutDiscounts)}`"
          :sub-title="$t('totalGrossValue')"
          type="info"
          :icon="'fas fa-equals'"
          class="m-0"
        />
      </div>
      <div class="col">
        <StatsCard
          :title="`${$filters.money(totalCosts)}`"
          :sub-title="$t('totalCosts')"
          type="danger"
          :icon="'fas fa-equals'"
          class="m-0"
        />
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col">
        <StatsCard
          :title="`${metric.orders}`"
          :sub-title="$t('numberOfOrders')"
          type="warning"
          :icon="'fas fa-shopping-cart'"
        />
      </div>
      <div class="col">
        <StatsCard
          :title="`${$filters.money(
            metric.totalValueWithoutDiscounts - totalCosts
          )}`"
          :sub-title="$t('totalNetValue')"
          type="success"
          :icon="'fas fa-hand-holding-usd'"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <Tooltip :content="$t('firstPaymentAvgTooltip')">
          <StatsCard
            :title="`${metric.firstPayment.avgTime}`"
            :sub-title="$t('firstPaymentAvgTitle')"
            type="warning"
            :icon="'fas fa-stopwatch'"
            @click="showFirstPaymentDetails = true"
            class="DetailCost"
          />
        </Tooltip>
        <Dialog
          v-if="showFirstPaymentDetails"
          @close="showFirstPaymentDetails = false"
          :title="`${$t('firstPaymentAvgTitle')} - ${$t('details')}`"
        >
          <AppTable :data="metric.firstPayment.orders">
            <ElTableColumn :label="$t('order')" min-width="100">
              <template slot-scope="props">
                {{ props.row.order.orderCode }}
              </template>
            </ElTableColumn>

            <ElTableColumn :label="$t('createdAt')" min-width="150">
              <template slot-scope="props">
                {{ props.row.order.createdAt | formatDateTime('date') }}
              </template>
            </ElTableColumn>

            <ElTableColumn :label="$t('date')" min-width="125">
              <template slot-scope="props">
                {{ props.row.order.date | formatDateTime('date') }}
              </template>
            </ElTableColumn>

            <ElTableColumn :label="$t('days')">
              <template slot-scope="props">
                {{ props.row.days }}
              </template>
            </ElTableColumn>
          </AppTable>
        </Dialog>
      </div>
      <div class="col">
        <StatsCard
          :title="`${$filters.money(metric.itemsValue / metric.orders || 0)}`"
          :sub-title="$t('averageTicket')"
          type="success"
          :icon="'fas fa-ticket-alt'"
        />
      </div>
    </div>
  </div>
</template>

<script>
import StatsCard from 'components/Cards/StatsCard';
import Dialog from 'components/app/Dialog';

export default {
  name: 'MetricRow',
  components: { Dialog, StatsCard },
  props: {
    metric: {},
  },
  data() {
    return {
      showFirstPaymentDetails: false,
    };
  },
  computed: {
    totalCosts() {
      return (
        this.metric.productCost +
        this.metric.shippingCost +
        this.metric.discountValue
      );
    },
  },
};
</script>

<style scoped>
.MetricRow hr {
  border-color: white;
}
</style>
