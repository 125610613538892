<template>
  <Dialog
    :title="$t('salesByState')"
    class="SalesByState"
    @close="$emit('close')"
  >
    <div v-loading="loading">
      <AppTable :data="ordersByState">
        <ElTableColumn :label="$t('state')">
          <template slot-scope="props">
            <span class="mr-2">
              {{ props.row.state }}
            </span>
          </template>
        </ElTableColumn>
        <ElTableColumn :label="$t('quantity')" width="150">
          <template slot-scope="props">
            {{ props.row.data.count }}
          </template>
        </ElTableColumn>
      </AppTable>
    </div>
  </Dialog>
</template>

<script>
import OrderFilterButton from 'pages/funnel/OrderFilterButton';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import Badge from 'components/Badge';
import Dialog from 'components/app/Dialog';

export default {
  name: 'SalesByState',
  components: { Dialog, Badge, OrderFilterButton },
  props: {
    orders: {},
  },
  data() {
    return {
      loading: false,
      ordersByState: [],
    };
  },
  methods: {
    async fetchData() {
      const result = this.orders.reduce((acc, order) => {
        const address = order.customer.addresses.find(
          (a) => !_.isEmpty(a.state)
        );
        const state = (
          (address ? address.state : '') || 'NAO INFORMADO'
        ).toUpperCase();
        if (!acc[state]) {
          acc[state] = { count: 0 };
        }
        acc[state].count++;
        return acc;
      }, {});

      this.ordersByState = _.orderBy(
        Object.entries(result).map(([key, value]) => ({
          state: key,
          data: value,
        })),
        ['data.count'],
        ['desc']
      );
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchData();
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
