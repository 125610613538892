<template>
  <ElDropdown
    :trigger="directExportType ? 'manual' : 'click'"
    @command="itemClickHandler"
    class="ExportBtn"
  >
    <Tooltip :content="tooltip ? tooltip : $t('export')">
      <BaseButton class="" v-bind="btnAttrs" @click="buttonClickHandler">
        <i :class="`${btnIcon} mr-1`" />
        <template v-if="!btnAttrs.hasOwnProperty('noBtnText')">
          {{ $t('export') }}
        </template>
      </BaseButton>
    </Tooltip>
    <ElDropdownMenu slot="dropdown">
      <ElDropdownItem :command="{ type: 'pdf' }" icon="fas fa-file-pdf">
        {{ $t('customer') }} (PDF)
      </ElDropdownItem>
      <ElDropdownItem :command="{ type: 'png' }" icon="far fa-image">
        {{ $t('customer') }} (PNG)
      </ElDropdownItem>
      <ElDropdownItem divided />
      <ElDropdownItem
        :command="{ type: 'pdf', uri: '/export/old', providerView: true, hideCustomerInfo: true }"
        icon="fas fa-file-pdf"
      >
        {{ $t('provider') }} (PDF)
      </ElDropdownItem>
      <ElDropdownItem
        :command="{ type: 'png', uri: '/export/old', providerView: true, hideCustomerInfo: true }"
        icon="far fa-image"
      >
        {{ $t('provider') }} (PNG)
      </ElDropdownItem>
      <ElDropdownItem divided />
      <ElDropdownItem
        :command="{ type: 'pdf', uri: '/export/old', providerView: true, hideCustomerInfo: false }"
        icon="fas fa-file-pdf"
      >
        {{ $t('provider') }} ({{ $t('internalUse') }}) (PDF)
      </ElDropdownItem>
      <ElDropdownItem
        :command="{ type: 'png', uri: '/export/old', providerView: true, hideCustomerInfo: false }"
        icon="far fa-image"
      >
        {{ $t('provider') }} ({{ $t('internalUse').toLowerCase() }}) (PNG)
      </ElDropdownItem>
      <ElDropdownItem divided />
      <ElDropdownItem :command="{ type: 'pdf', uri: '/export/mail' }" icon="fas fa-tag">
        {{ $t('mailTag') }} (PDF)
      </ElDropdownItem>
    </ElDropdownMenu>
  </ElDropdown>
</template>

<script>
import { makeApiUrl } from 'util/utils';

export default {
  name: 'OrderExportButton',
  props: {
    orderId: { required: true },
    btnIcon: {
      default: 'fas fa-file-download',
    },
    btnAttrs: {
      default() {
        return {};
      },
    },
    directExportType: {},
    tooltip: {},
  },
  data() {
    return {};
  },
  methods: {
    itemClickHandler(item) {
      this.exportToPdf(item, false);
    },
    exportToPdf({ type, providerView, uri, hideCustomerInfo }, emitEvent = false) {
      if (!uri) {
        uri = '/export';
      }
      const params = new URLSearchParams(window.location.search);
      if (providerView) {
        params.append('providerView', providerView);
      }
      if (hideCustomerInfo) {
        params.append('hideCustomerInfo', hideCustomerInfo);
      }

      const link = `${location.origin}/orders/${this.orderId}${uri}?${params}`;
      const apiUrl = `/api/export/${type}`;
      const exportUrl = `${apiUrl}?${new URLSearchParams({
        link,
        uKey: this.$store.state.token,
      })}`;

      if (emitEvent) {
        this.$emit('export', { exportUrl });
      } else {
        window.open(exportUrl, '_blank');
      }
    },
    async buttonClickHandler() {
      if (this.directExportType) {
        this.exportToPdf(this.directExportType, true);
      }
    },
  },
};
</script>

<style scoped></style>
