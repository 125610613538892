import { formatNumber, formatOrderCode, generateUniqueId, maxDate } from 'util/utils';

class BaseBrainModel {
  constructor(data) {
    Object.assign(this, data);
  }

  transformProp(name, Clazz) {
    const prop = _.get(this, name);
    if (prop) {
      const value = _.isArray(prop) ? prop.map((p) => new Clazz(p)) : new Clazz(prop);
      _.set(this, name, value);
    }
  }
}

export class AddressUi extends BaseBrainModel {
  cityAndState() {
    let result = '';
    if (this.city) {
      result += this.city;
    }
    if (this.state) {
      result += _.isEmpty(result) ? this.state : ` - ${this.state}`;
    }
    return result;
  }
}

export class CustomerUi extends BaseBrainModel {
  constructor(data) {
    super(data);
    this.transformProp('addresses', AddressUi);
    this.transformProp('discountCoupons', DiscountCouponUi);
  }

  mainAddress() {
    return _.isEmpty(this.addresses) ? null : this.addresses[0];
  }

  mainContact() {
    const contact = (this.contacts || []).find((c) => c.props?.isMain);
    if (contact) return contact;
    return this.sortedContacts[0];
  }

  get sortedContacts() {
    const contacts = this.contacts || [];
    return _.orderBy(contacts, (c) => new Date(c.updatedAt), 'desc');
  }

  isLegal() {
    return this.type === 'LEGAL';
  }

  stateRegistration() {
    return _.get(this, 'props.stateRegistration');
  }

  isOwnProduction() {
    return _.get(this, 'props.ownProduction', false);
  }

  get notRedeemedCoupons() {
    return (this.discountCoupons || []).filter((c) => !c.redeemed);
  }
}

export class OrderUi extends BaseBrainModel {
  constructor(data) {
    super(data);

    this.transformProp('customer', CustomerUi);
    this.transformProp('items', OrderItemUi);
    this.transformProp('provider', CustomerUi);
    this.transformProp('status', OrderStatusUi);
    this.transformProp('activities', OrderActivityUi);
    this.transformProp('layoutRequests', OrderLayoutRequestUi);
    this.transformProp('appliedCoupons', DiscountCouponUi);

    //TODO triggering infinity loop
    /* if (data.shipments) {
      this.shipments = data.shipments.map(s => {
        if (s.method) {
          s.method = new ShippingMethodUi(s.method);
        }
        return s;
      });
    }*/
  }

  get orderCode() {
    return formatOrderCode(this);
  }

  totalItemsValue() {
    return (this.items || []).reduce((acc, item) => acc + item.quantity * item.price, 0);
  }

  totalValueWithoutDiscounts() {
    let val = 0;
    val += this.totalItemsValue();
    val += this.shipmentValue();
    return val;
  }

  totalValueDiscount() {
    return (this.appliedCoupons || [])
      .filter((c) => c.discountType === 'Value')
      .reduce((acc, coupon) => acc + (coupon.discount || 0), 0);
  }

  totalPercentDiscount() {
    let discount = this.generalDiscount || 0;
    (this.appliedCoupons || [])
      .filter((c) => c.discountType === 'Percent')
      .forEach((coupon) => (discount += coupon.discount || 0));
    return discount;
  }

  discountValue() {
    let discountValue = this.totalValueDiscount();

    let percentDiscount = this.totalPercentDiscount();

    const totalValue = this.totalItemsValue();

    if (percentDiscount > 0) {
      const percValue = (totalValue - discountValue) * Math.min(1, percentDiscount / 100);
      discountValue += percValue;
    }

    return discountValue > totalValue ? totalValue : discountValue;
  }

  totalValue() {
    let val = this.totalValueWithoutDiscounts();
    const discount = this.discountValue();
    val -= discount;
    return val;
  }

  shipmentValue() {
    return (this.shipments || []).reduce((acc, shipment) => acc + shipment.total, 0);
  }

  shipmentCost() {
    return (this.shipments || []).reduce((acc, shipment) => acc + shipment.cost, 0);
  }

  totalPaid() {
    return this.payments.reduce((acc, p) => acc + p.value, 0);
  }

  percentPaid() {
    const paid = this.totalPaid();
    const total = this.totalValue();
    return (paid / total) * 100;
  }

  classifiedPayments() {
    const total = this.totalValue();
    const result = {
      validated: {
        value: 0,
        percent: total === 0 ? 100 : 0,
        class: 'bg-info',
        validated: true,
      },
      notValidated: {
        value: 0,
        percent: 0,
        class: 'bg-warning',
        validated: false,
      },
    };

    for (const payment of this.payments) {
      const r = result[payment.validated ? 'validated' : 'notValidated'];
      r.value += payment.value;
      r.percent = (r.value / total) * 100;
    }
    return [result.validated, result.notValidated];
  }

  searchString() {
    const { customer } = this;
    return [
      this.id,
      this.orderCode,
      customer.email,
      customer.name,
      customer.cnpj,
      customer.cpf,
      customer.contacts.map((c) => c.content).join('-'),
    ]
      .join('-')
      .toLowerCase();
  }

  duplicate() {
    const props = _.pick(this, ['customerId', 'items', 'shipments']);
    props.shipments.forEach((s) => delete s.id);
    props.items.forEach((item) => delete item.id);
    return new OrderUi(props);
  }

  totalQuantity() {
    return this.items.reduce((acc, item) => acc + (item.quantity || 0), 0);
  }

  shippingQuantity() {
    return this.items.reduce(
      (acc, item) => (item.product.countOnShipping ? acc + (item.quantity || 0) : acc),
      0
    );
  }

  totalCost() {
    return (this.items || []).reduce(
      (acc, item) => acc + (item.quantity || 0) * (item.cost || 0),
      0
    );
  }

  static buildWhatsMsgStatus(item) {
    const {
      lastWhatsMessageAt,
      lastWhatsResponseAt,
      whatsMarkedAsReadAt,
      whatsMarkedAsNotReadAt,
    } = item.customer;

    const min = moment(0);

    const msgAt = lastWhatsMessageAt ? moment(lastWhatsMessageAt) : min;
    const responseAt = lastWhatsResponseAt ? moment(lastWhatsResponseAt) : min;

    const markedReadAt = whatsMarkedAsReadAt ? moment(whatsMarkedAsReadAt) : min;
    const markedNotReadAt = whatsMarkedAsNotReadAt ? moment(whatsMarkedAsNotReadAt) : min;

    const updatedAt = moment(item.updatedAt);

    const needResponse = moment
      .max(responseAt, markedReadAt)
      .isBefore(moment.max(msgAt, markedNotReadAt));

    return {
      needResponse,
      type: needResponse ? 'danger' : 'success',
      msgAt: lastWhatsMessageAt ? msgAt : null,
      responseAt: lastWhatsResponseAt ? responseAt : null,
      markedReadAt: whatsMarkedAsReadAt ? markedReadAt : null,
      markedNotReadAt: whatsMarkedAsNotReadAt ? markedNotReadAt : null,
      updatedAt,
      arrivedInStatusAt: item.activities
        ? moment(InitialContactUi.SortOpts.ArrivalInStatus.findDate(item))
        : null,
      date:
        lastWhatsMessageAt || lastWhatsResponseAt || whatsMarkedAsReadAt || whatsMarkedAsNotReadAt
          ? moment.max(msgAt, responseAt, markedReadAt, markedNotReadAt)
          : updatedAt,
    };
  }
}

export class OrderStatusUi extends BaseBrainModel {
  static Sort = {
    Date: {
      field: 'date',
      sortOrders(orders = []) {
        return _.orderBy(
          orders,
          [
            (o) => {
              if (!o._date) {
                o._date = new Date(o.date);
              }
              return o._date;
            },
          ],
          ['desc']
        );
      },
    },
    EventDate: {
      field: 'eventDate',
      sortOrders(orders = []) {
        return _.sortBy(orders, [
          (o) => {
            if (!o._eventDate) {
              o._eventDate = o.eventDate ? new Date(o.eventDate) : maxDate();
            }
            return o._eventDate;
          },
        ]);
      },
    },
    ProductionDeadline: {
      field: 'productionDeadline',
      sortOrders(orders = []) {
        return _.sortBy(orders, [
          (o) => {
            if (!o._productionDeadline) {
              o._productionDeadline = o.productionDeadline
                ? new Date(o.productionDeadline)
                : maxDate();
            }
            return o._productionDeadline;
          },
        ]);
      },
    },
    ArrivalInStatus: {
      field: 'date',
      sortOrders(orders = []) {
        orders = OrderStatusUi.Sort.Date.sortOrders(orders);
        orders = _.sortBy(orders, (order) => {
          if (!order.__lastActivityChange) {
            const activities = order.activities || [];
            const filteredActivities = activities.filter((activity) => activity.isStatusChange());
            const max = _.maxBy(filteredActivities, (a) => {
              a.createdAt = new Date(a.createdAt);
              return a.createdAt;
            });
            order.__lastActivityChange = max ? max.createdAt : new Date(order.date);
          }
          return order.__lastActivityChange;
        });

        return orders;
      },
    },
    LastInteraction: {
      field: ['lastInteractionOn', 'updatedAt'],
      sortOrders(orders = []) {
        return _.sortBy(orders, [
          (o) => {
            if (!o._lastInteractionOn) {
              if (o.lastInteractionOn) {
                o._lastInteractionOn = new Date(o.lastInteractionOn);
              } else if (o.updatedAt) {
                o._lastInteractionOn = new Date(o.updatedAt);
              }
            }
            return o._lastInteractionOn;
          },
        ]);
      },
    },
    LastWhatsMessage: {
      field: ['customer.lastWhatsMessageAt', 'updatedAt'],
      sortOrders(orders = []) {
        return _.sortBy(orders, [
          (o) => {
            if (!o._lastWhatsMessage) {
              if (o.customer?.lastWhatsMessageAt) {
                o._lastWhatsMessage = new Date(o.customer.lastWhatsMessageAt);
              } else if (o.updatedAt) {
                o._lastWhatsMessage = new Date(o.updatedAt);
              }
            }
            return o._lastWhatsMessage;
          },
        ]);
      },
    },
  };

  static sortArray(statuses) {
    return _.orderBy(statuses, ['order', 'description', 'visible'], ['asc', 'asc', 'desc']);
  }

  constructor(data) {
    super(data);
    if (data.types) {
      this.types = data.types.map((type) => new StatusTypeUi(type));
    }
  }

  isCancelled() {
    return this.types.some((t) => t.isCancelled());
  }

  isFinished() {
    return this.types.some((t) => t.isFinished());
  }

  isProduction() {
    return this.types.some((t) => t.isProduction());
  }

  isSale() {
    return this.types.some((t) => t.isSale());
  }
}

Object.entries(OrderStatusUi.Sort).forEach(([key, val]) => (val.name = key));

export class StatusTypeUi extends BaseBrainModel {
  static Type = {
    CANCELLED: 'CANCELLED',
    SALE: 'SALE',
    PRODUCTION: 'PRODUCTION',
    FINISHED: 'FINISHED',
  };

  isCancelled() {
    return this.name === StatusTypeUi.Type.CANCELLED;
  }

  isFinished() {
    return this.name === StatusTypeUi.Type.FINISHED;
  }

  isProduction() {
    return this.name === StatusTypeUi.Type.PRODUCTION;
  }

  isSale() {
    return this.name === StatusTypeUi.Type.SALE;
  }
}

export class AccountingEntryUi extends BaseBrainModel {
  static Type = {
    CREDIT: 'CREDIT',
    DEBIT: 'DEBIT',
  };
}

export class OrderPaymentUi extends BaseBrainModel {
  constructor(data) {
    super(data);
    if (data.order) {
      this.order = new OrderUi(data.order);
    }
  }
}

export class AttributeUi extends BaseBrainModel {
  constructor(data) {
    super(data);
    if (data.values) {
      this.values = data.values.map((v) => new AttributeValueUi(v));
    }
  }

  getCaption() {
    if (this.caption) return this.caption;
    return this.name;
  }
}

export class AttributeValueUi extends BaseBrainModel {
  constructor(data) {
    super(data);

    if (data.attribute) {
      this.attribute = new AttributeUi(data.attribute);
    }
  }

  getCaption() {
    if (this.caption) return this.caption;
    return this.value;
  }
}

export class OrderItemUi extends BaseBrainModel {
  constructor(data) {
    super(data);

    if (data.attributes) {
      this.attributes = _.sortBy(
        data.attributes.map((a) => new AttributeValueUi(a)),
        ['attribute.caption']
      );
    }

    if (data.product) {
      this.product = new ProductUi(data.product);
    }
  }

  containImage() {
    return !!this.findImage();
  }

  findImage() {
    const { product } = this;

    const { defaultImgId, attrImgs = [] } = product.props || {};
    const match = attrImgs.find((attrImg) => {
      let matches = 0;
      for (const itemAttributeValue of this.attributes) {
        if (attrImg.attributeValues.includes(itemAttributeValue.id)) {
          matches++;
        }
      }
      return this.attributes.length === matches;
    });

    if (match) {
      return match.imgId;
    }

    return defaultImgId;
  }
}

export class OrderActivityUi extends BaseBrainModel {
  static Types = {
    MESSAGE: 'MESSAGE',
    CANCELLATION: 'CANCELLATION',
    STATUS_CHANGE: 'STATUS_CHANGE',
  };

  constructor(data) {
    super(data);
  }

  isMessage() {
    return this.type === OrderActivityUi.Types.MESSAGE;
  }

  isCancellation() {
    return this.type === OrderActivityUi.Types.CANCELLATION;
  }

  isStatusChange() {
    return this.type === OrderActivityUi.Types.STATUS_CHANGE;
  }

  getMessage() {
    return this.props.message || '';
  }

  getReasons() {
    return this.props.reasons || [];
  }
}

export class ProductUi extends BaseBrainModel {
  constructor(data) {
    super(data);
    if (data.provider) {
      this.provider = new CustomerUi(data.provider);
    }
    if (data?.props?.pricing?.list) {
      this.props.pricing.list = data.props.pricing.list.map((p) => new PriceListEntryUi(p));
    }
  }
}

export class PriceListEntryUi {
  quantity = 0;
  prices = {};

  constructor(params) {
    Object.assign(this, params);
  }

  getPriceFor(attributeValueId) {
    let price = this.prices[attributeValueId];
    if (price) return price;

    price = {
      value: 0,
    };

    this.prices[attributeValueId] = price;

    return price;
  }
}

export class ConfUi extends BaseBrainModel {
  constructor(data) {
    super(data);
  }

  get defaultProductionDays() {
    return _.get(this, 'props.defaultProductionDays') || 12;
  }

  get useNewOrderExportLayout() {
    return _.get(this, 'props.useNewOrderExportLayout') || false;
  }
}

export class DesignWorkflowUi extends BaseBrainModel {
  static Priority = {
    LOW: 'LOW',
    MID: 'MID',
    HIGH: 'HIGH',
  };

  static Status = {
    NOT_STARTED: 'NOT_STARTED',
    IN_PROGRESS: 'IN_PROGRESS',
    FINISHED: 'FINISHED',
  };

  constructor(data) {
    super(data);
    if (data.entries) {
      this.entries = data.entries.map((e) => new WorkflowEntryUi(e));
    }
  }
}

export class WorkflowEntryUi extends BaseBrainModel {
  static Type = {
    CUSTOMER_REQUEST: 'CUSTOMER_REQUEST',
    DESIGN_RESPONSE: 'DESIGN_RESPONSE',
  };

  constructor(data) {
    super(data);
  }

  get frontFileId() {
    return _.get(this, 'props.frontFileId');
  }

  get backFileId() {
    return _.get(this, 'props.frontFileId');
  }
}

export class ShippingMethodUi extends BaseBrainModel {
  constructor(data) {
    super(data);
  }

  get isManual() {
    return _.isEmpty(this.props.postalCodeRanges);
  }

  postalCodeToInt(postalCode) {
    return _.parseInt(postalCode.replace('-', ''));
  }

  shippingDaysForPostalCode(postalCode) {
    const range = this.findPostalCodeRange(postalCode);
    return (range ? range.days : this.props.defaultDays) || 10;
  }

  shippingValueForPostalCode(postalCode, quantity) {
    let value = this.props.defaultValue || 0;
    const range = this.findPostalCodeRange(postalCode);
    if (range) {
      const quantityRange = this.findQuantityRange(range, quantity);
      if (quantityRange) {
        value = quantityRange.value;
      }
    }
    return value;
  }

  findQuantityRange(postalCodeRange, quantity) {
    if (_.isEmpty(postalCodeRange.quantityRanges)) {
      return null;
    }
    let match = null;
    for (const quantityRange of postalCodeRange.quantityRanges) {
      if (quantity <= quantityRange.min) {
        match = quantityRange;
        break;
      } else if (quantity <= quantityRange.max) {
        match = quantityRange;
        break;
      }
    }
    return match;
  }

  findPostalCodeRange(postalCode) {
    postalCode = this.postalCodeToInt(postalCode);
    const ranges = this.props.postalCodeRanges || [];
    for (const range of ranges) {
      const initialCode = this.postalCodeToInt(range.initialCode);
      const finalCode = this.postalCodeToInt(range.finalCode);
      if (postalCode >= initialCode && postalCode <= finalCode) {
        return range;
      }
    }
    return null;
  }

  get containLink() {
    return !_.isEmpty(_.get(this, 'props.trackingLink'));
  }

  makeLink(code) {
    const link = _.get(this, 'props.trackingLink') || '';
    return link.replace('${code}', code);
  }
}

export class StockUi extends BaseBrainModel {
  static Operations = {
    IN: {},
    OUT: {},
  };

  constructor(data) {
    super(data);
  }
}

export class FunnelUi extends BaseBrainModel {
  constructor(data) {
    super(data);
    if (data.statuses) {
      this.statuses = OrderStatusUi.sortArray(
        data.statuses.map((status) => new OrderStatusUi(status))
      );
    }
  }

  get showInitialContacts() {
    return this.props?.showInitialContacts || false;
  }
}

export class OrderLayoutRequestUi extends BaseBrainModel {
  static Priority = {
    LOW: { value: 1 },
    MID: { value: 2 },
    HIGH: { value: 3 },
    URGENT: { value: 4 },
  };

  constructor(data) {
    super(data);
    if (this.order) {
      this.order = new OrderUi(this.order);
    }
  }
}

export class DiscountCouponUi extends BaseBrainModel {
  constructor(data) {
    super(data);
  }

  get redeemed() {
    return !!this.targetOrderId;
  }

  discountString() {
    switch (this.discountType) {
      case 'Value': {
        return formatNumber(this.discount);
      }
      case 'Percent': {
        return `${this.discount}%`;
      }
    }
  }
}

export class QuizUi extends BaseBrainModel {
  static Type = {
    AfterSaleQuiz: {
      orderSourceOptions: ['Pesquisa Google', 'Instagram', 'Facebook', 'Indicação', 'Outro'],
      attendanceSpeedOptions: ['Muito lento', 'Lento', 'Normal', 'Rápido', 'Muito rápido'],
      freightCostOptions: ['Barato', 'Dentro do esperado', 'Caro', 'Muito caro'],
      customizationQualityOptions: ['Esperava mais', 'Sim', 'Acima do esperado'],
      shoppingExperienceOptions: ['Ruim', 'Normal', 'Boa', 'Muito boa'],
      productPriceOpts: ['Muito Caro', 'Caro', 'Dentro do esperado', 'Barato', 'Muito barato'],
    },
  };

  constructor(data) {
    super(data);
  }
}

export class InitialContactUi extends BaseBrainModel {
  static SortOpts = {
    Default: {
      type: 'github',
      sort(entries) {
        return _.orderBy(
          entries,
          [
            (i) => {
              const {
                lastWhatsMessageAt,
                lastWhatsResponseAt,
                whatsMarkedAsReadAt,
                whatsMarkedAsNotReadAt,
              } = i.customer;
              return Math.max(
                new Date(lastWhatsMessageAt).getTime() || 0,
                new Date(lastWhatsResponseAt).getTime() || 0,
                new Date(whatsMarkedAsReadAt).getTime() || 0,
                new Date(whatsMarkedAsNotReadAt).getTime() || 0
              );
            },
          ],
          ['desc']
        );
      },
    },
    ByGreatestWaitingTime: {
      type: 'youtube',
      sort(entries) {
        const currentDate = new Date();
        return _.orderBy(
          entries,
          [
            (i) => {
              const msgAt = new Date(i.customer.lastWhatsMessageAt);
              const responseAt = new Date(i.customer.lastWhatsResponseAt);
              const markedAsNotReadAt = new Date(i.customer.whatsMarkedAsNotReadAt);
              const markedAsReadAt = new Date(i.customer.whatsMarkedAsReadAt);
              const containResponse =
                Math.max(responseAt.getTime() || 0, markedAsReadAt.getTime() || 0) <
                Math.max(msgAt.getTime() || 0, markedAsNotReadAt.getTime() || 0);
              return containResponse ? 10 : 0;
            },
            (i) => {
              const msgAt = new Date(i.customer.lastWhatsMessageAt);
              const responseAt = new Date(i.customer.lastWhatsResponseAt);
              const markedAsNotReadAt = new Date(i.customer.whatsMarkedAsNotReadAt);
              const markedAsReadAt = new Date(i.customer.whatsMarkedAsReadAt);
              return (
                currentDate.getTime() -
                Math.max(
                  responseAt.getTime() || 0,
                  msgAt.getTime() || 0,
                  markedAsNotReadAt.getTime() || 0,
                  markedAsReadAt.getTime() || 0
                )
              );
            },
          ],
          ['desc', 'desc']
        );
      },
    },
    ArrivalInStatus: {
      type: 'primary',
      findDate(order) {
        const activities = order.activities || [];
        const filteredActivities = activities.filter((activity) => activity.isStatusChange());
        const max = _.maxBy(filteredActivities, (a) => {
          return new Date(a.createdAt);
        });
        return max ? new Date(max.createdAt) : new Date(order.date);
      },
      sort(entries) {
        const minDate = new Date(0);
        return _.orderBy(
          entries,
          [
            (possibleOrder) => {
              return possibleOrder.hasOwnProperty('activities') ? 0 : 1;
            },
            (possibleOrder) => {
              if (possibleOrder.hasOwnProperty('activities')) {
                return this.findDate(possibleOrder);
              }
              return minDate;
            },
          ],
          ['asc', 'desc']
        );
      },
    },
    ByLastResponse: {
      type: 'youtube',
      sort(entries) {
        return _.orderBy(entries, [
          (i) => {
            const responseAt = new Date(i.customer.lastWhatsResponseAt);
            return responseAt?.getTime() ?? 0;
          },
          (i) => {
            const msgAt = new Date(i.customer.lastWhatsMessageAt);
            return msgAt?.getTime() ?? 0;
          },
        ]);
      },
    },
  };

  constructor(data) {
    super(data);
    this.transformProp('customer', CustomerUi);
  }
}

export class CustomerEventUi extends BaseBrainModel {
  constructor(data) {
    super(data);
    this.transformProp('customer', CustomerUi);
  }
}

export class LogEntryUi extends BaseBrainModel {
  constructor(data) {
    super(data);
    this.transformProp('customer', CustomerUi);
  }
}

export class TagUi extends BaseBrainModel {
  constructor(data) {
    super(data);
  }

  buildStyle() {
    let style = '';
    if (this.props?.bgColor) {
      style += `background-color: ${this.props.bgColor};`;
    }
    if (this.props?.textColor) {
      style += `color: ${this.props.textColor};`;
    }
    return style;
  }
}

export class UserWhatsQueueConfigUi extends BaseBrainModel {
  constructor(data) {
    super(_.merge({ props: { queues: [] } }, data));
    this.transformProp('props.queues', UserWhatsQueueUi);
  }

  addNewQueue() {
    this.props.queues.push(new UserWhatsQueueUi());
  }
}

class UserWhatsQueueUi extends BaseBrainModel {
  id;

  constructor(data) {
    super(_.merge({ id: null, title: 'Título', persistedState: null }, data));
    if (!this.id) {
      this.id = generateUniqueId();
    }
  }
}
