import { BrainApi } from 'api/BrainApi';
import { ConfUi } from 'api/Models';

export class ConfApi extends BrainApi {
  constructor(props) {
    super({
      resource: 'confs',
      transform: data => new ConfUi(data),
      ...props,
    });
  }

  async appConf() {
    const conf = await this.fn({
      name: 'appConf',
    });
    return new ConfUi(conf);
  }
}
