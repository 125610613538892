<template>
  <CrudPage
    :fetch-data="fetchData"
    :columns="tableColumns"
    :table-attrs="tableAttrs"
    :listenToEvents="eventsToListen"
    date-field="date"
    :initial-date-range="filters.initialDateRange"
    ref="crudPage"
  >
    <template v-slot:headerFilters>
      <div class="col-2">
        <div class="form-group">
          <AppSelect
            :options="filters.type.options"
            v-model="filters.type.value"
            @input="filterChanged()"
            :placeholder="$t('type')"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <AppSelect
            :options="filters.prospected.options"
            v-model="filters.prospected.value"
            @input="filterChanged()"
            :placeholder="$t('prospection')"
          />
        </div>
      </div>
      <div class="col-2">
        <div class="form-group">
          <AppSelect
            :options="filters.eventType.options"
            v-model="filters.eventType.value"
            @input="filterChanged()"
            :placeholder="$t('eventType')"
          />
        </div>
      </div>
    </template>

    <template v-slot:headerButtons>
      <BaseButton @click="editEventToProspect()">
        <i class="fas fa-plus mr-1" />
        {{ $t('event') }}
      </BaseButton>
    </template>

    <template v-slot:beforeColumns>
      <ElTableColumn type="expand">
        <template slot-scope="{ row }">
          <AppTable
            v-if="!_.isEmpty(row.prospections)"
            :data="row.prospections"
            :columns="childTableCols"
          >
            <ElTableColumn :label="$t('actions')" width="100">
              <template slot-scope="{ row: prospection }">
                <TableEditButton @click="editProspection(prospection.id)" />
                <TableRemoveButton @click="removeProspection(row, prospection.id)" />
              </template>
            </ElTableColumn>
          </AppTable>

          <BaseAlert v-else type="danger">
            {{ $t('withoutProspections') }}
          </BaseAlert>
        </template>
      </ElTableColumn>
    </template>

    <template v-slot:additionalColumns>
      <ElTableColumn :label="$t('information')">
        <template slot-scope="{ row }">
          <div v-if="row.customer || row.name" class="d-flex align-items-center">
            <b class="mr-2">{{ $t('customer') }}:</b>
            <span class="mr-2">
              {{ row.customer ? row.customer.name : row.name }}
            </span>
            <TableEditButton v-if="row.customer" @click="editCustomer(row.customer.id)" />
          </div>

          <ul v-if="row.customer || row.phone" class="list-unstyled">
            <li v-for="(contact, idx) in findContacts(row)" :key="idx">
              <OrderWhatsButton>
                <template v-slot="{ openWhats }">
                  <div
                    @click="openWhatsHandler($event, contact, openWhats, row)"
                    class="Whats d-inline-block"
                  >
                    <i class="fab fa-whatsapp" />
                    {{ contact.content }}
                    <span v-if="row.customer && row.customer.contacts.length !== 1">
                      {{ row.customer.mainContact().id === contact.id ? '*' : '' }}
                    </span>
                  </div>
                </template>
              </OrderWhatsButton>
            </li>
          </ul>

          <pre v-if="row.notes" v-html="row.notes" />
        </template>
      </ElTableColumn>

      <ElTableColumn :label="$t('actions')" width="130">
        <template slot-scope="{ row }">
          <Tooltip :content="$t('addProspection')">
            <BaseButton class="btn-link" type="success" size="sm" icon @click="newProspection(row)">
              <i class="fas fa-plus"></i>
            </BaseButton>
          </Tooltip>

          <template v-if="!row.customer">
            <TableEditButton @click="editEventToProspect(row.id)" />

            <TableRemoveButton @click="removeEventToProspect(row.id)" />
          </template>
        </template>
      </ElTableColumn>
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import EventProspectionEditDialog from 'pages/customer/EventProspectionEditDialog';
import DateTimeCol from 'components/app/table/DateTimeCol';
import BaseAlert from 'components/BaseAlert';
import { commonAlerts } from 'util/commonAlerts';
import CustomerEditDialog from 'pages/customer/CustomerEditDialog';
import EventToProspectEditDialog from 'pages/customer/EventToProspectEditDialog';
import OrderWhatsButton from 'pages/order/whats/OrderWhatsButton';

export default {
  name: 'EventProspections',
  components: { OrderWhatsButton, BaseAlert, DateTimeCol, AppSelect, AppFilterDatePicker },
  data() {
    return {
      eventsToListen: [
        EventType.EventProspectionChanged,
        EventType.CustomerChanged,
        EventType.EventsToProspectChanged,
      ],
      tableColumns: [
        {
          label: this.$t('date'),
          render: ({ row }) => {
            return this.$filters.formatDateTime(row.$date, 'date');
          },
          attrs: { width: 120 },
        },
        {
          label: this.$t('eventType'),
          render({ row }) {
            return row.eventType.name;
          },
          attrs: { width: 180 },
        },
      ],
      tableAttrs: {
        defaultExpandAll: true,
        rowKey: 'id',
      },
      childTableCols: [
        {
          prop: 'id',
          attrs: { width: 75 },
        },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { width: 175 },
        },
        {
          prop: 'user.name',
          label: this.$t('user'),
          attrs: { width: 200 },
        },
        {
          prop: 'notes',
        },
      ],
      filters: {
        initialDateRange: [moment().startOf('month'), moment().add(2, 'month').endOf('month')],
        type: {
          value: '',
          options: ['customer', 'event'].map((value) => ({
            value,
            label: this.$t(value),
          })),
        },
        prospected: {
          value: 'notProspected',
          options: ['prospected', 'notProspected'].map((value) => ({
            value,
            label: this.$t(value),
          })),
        },
        eventType: {
          value: '',
          options: [],
        },
      },
    };
  },
  methods: {
    filterChanged() {
      this.$refs.crudPage.runSearch();
    },
    editEventToProspect(id) {
      this.$openModal(EventToProspectEditDialog, {
        id,
      });
    },
    async removeEventToProspect(id) {
      if (!(await commonAlerts.confirmOperation())) {
        return;
      }

      try {
        await this.$api.EventsToProspect.remove(id);
        this.$bus.$emit(EventType.EventsToProspectChanged);
      } catch (e) {
        console.error(e);
        await commonAlerts.defaultErrorMessage(e);
      }
    },
    newProspection(row) {
      this.$openModal(EventProspectionEditDialog, {
        customerEventId: row.customer ? row.id : undefined,
        eventToProspectId: row.customer ? undefined : row.id,
      });
    },
    editProspection(id) {
      this.$openModal(EventProspectionEditDialog, {
        id,
      });
    },
    editCustomer(id) {
      this.$openModal(CustomerEditDialog, {
        id,
      });
    },
    async removeProspection(row, id) {
      try {
        if (!(await commonAlerts.confirmOperation())) {
          return;
        }
        await this.$api.EventProspection.remove(id);
        row.prospections = row.prospections.filter((p) => p.id !== id);
      } catch (e) {
        console.error(e);
        await commonAlerts.defaultErrorMessage(e);
      }
    },
    async fetchData({ query, pagination, sort, filters: queryFilters }) {
      const filters = {};
      const eventsToProspectFilters = {};

      if (query) {
        filters['customer.name|customer.contacts.content|eventType.name:ilike'] = `%${query}%`;
        eventsToProspectFilters['name|phone|notes|eventType.name:ilike'] = `%${query}%`;
      }

      const date = moment().startOf('day');

      let events = [];

      if (this.filters.eventType.value) {
        filters['eventType.id'] = this.filters.eventType.value;
        eventsToProspectFilters['eventType.id'] = this.filters.eventType.value;
      }

      {
        const loadCustomerEvents =
          _.isEmpty(this.filters.type.value) || this.filters.type.value === 'customer';
        const loadEventsToProspect =
          _.isEmpty(this.filters.type.value) || this.filters.type.value === 'event';

        const [customerEvents, eventsToProspect] = await Promise.all([
          loadCustomerEvents
            ? this.$api.CustomerEvent.findAll({
                eager: '[customer.[contacts], eventType, prospections.[user]]',
                ...filters,
              })
            : [],
          loadEventsToProspect
            ? this.$api.EventsToProspect.findAll({
                eager: `[eventType, prospections.[user]]`,
                ...eventsToProspectFilters,
                ...AppFilterDatePicker.formatAsFilter('date', queryFilters.dateRange),
              })
            : [],
        ]);

        events = [...customerEvents, ...eventsToProspect];
      }

      let start = queryFilters?.dateRange?.[0].clone().year(2000);
      let end = queryFilters?.dateRange?.[1]?.clone().year(2000);

      if (start && end) {
        if (end.isBefore(start)) {
          end.add(1, 'y');
        }
      }

      events = events.filter((e) => {
        e.$date = moment(e.date).startOf('day');

        if (start || end) {
          const date = e.$date.clone().year(2000);
          if (start && date.isBefore(start)) {
            return false;
          }
          if (end && date.isAfter(end)) {
            return false;
          }
        }

        if (e.customer && e.$date.isBefore(date)) {
          e.$date.year(date.year());
          if (e.$date.isBefore(date)) {
            events.push({
              ...e,
              $date: moment(e.$date).add(1, 'year'),
            });
          }
        }
        e.prospections.forEach((p) => (p.$date = moment(p.date)));
        e.prospections = _.orderBy(e.prospections, ['$date'], ['desc']);
        return true;
      });

      if (this.filters.prospected.value) {
        const prospected = this.filters.prospected.value === 'prospected';
        events = events.filter((e) => {
          const containProspections = e.prospections.length > 0;
          return (prospected && containProspections) || (!prospected && !containProspections);
        });
      }

      return _.sortBy(events, ['$date']);
    },
    findContacts(row) {
      if (row.customer) {
        return row.customer.contacts || [];
      }
      return [{ content: row.phone }];
    },
    async openWhatsHandler(event, contact, openWhats, row) {
      let customer = row.customer;
      if (!customer) {
        const customers = await this.$api.Customer.findAll({
          'contacts.content': contact.content,
        });
        customer = customers[0];
        if (!customer) {
          customer = await this.$api.Customer.save({
            name: row.name,
            contacts: [
              {
                type: 'PHONE',
                content: row.phone,
              },
            ],
          });
        }
      }
      const modalProps = {};
      const [initialContact] = await this.$api.InitialContact.findAll({
        customerId: customer.id,
      });
      modalProps.initialEntity = initialContact;
      openWhats(event, contact, modalProps);
    },
  },
  async mounted() {
    this.loading = true;
    try {
      const eventTypes = await this.$api.EventType.findAll({ orderBy: 'name' });
      this.filters.eventType.options = eventTypes.map((et) => ({
        value: et.id,
        label: et.name,
      }));
    } catch (e) {
      console.error(e);
    } finally {
      this.loading = false;
    }
  },
};
</script>
<style scoped lang="scss"></style>
