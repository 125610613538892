<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
    date-field="date"
    ref="crudPage"
  >
    <template v-slot:headerFilters>
      <div class="col">
        <div class="form-group">
          <AppSelect
            value-key="id"
            :options="categoryOpts"
            v-model="filter.category"
            @input="filterChanged($event)"
            :placeholder="$t('categories')"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <AppSelect
            value-key="id"
            :options="subcategoryOpts"
            v-model="filter.subcategory"
            @input="filterChanged($event)"
            :placeholder="$t('subcategories')"
            :disabled="!filter.category"
          />
        </div>
      </div>
    </template>

    <template v-slot:afterTable="{ visibleEntities }">
      <div class="text-right mr-4">
        <b class="mr-2">{{ $t('total') }} {{ $t('CREDIT') }}:</b>
        {{
          visibleEntities
            .filter((e) => e.type === 'CREDIT')
            .reduce((acc, e) => acc + e.value, 0) | money
        }}
      </div>
      <div class="text-right mr-4">
        <b class="mr-2">{{ $t('total') }} {{ $t('DEBIT') }}:</b>
        {{
          visibleEntities
            .filter((e) => e.type === 'DEBIT')
            .reduce((acc, e) => acc + e.value, 0) | money
        }}
      </div>
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import DateTimeCol from 'components/app/table/DateTimeCol';
import MoneyCol from 'components/app/table/MoneyCol';
import AccountingEntryEditDialog from 'pages/accountingEntry/AccountingEntryEditDialog';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';

export default {
  name: 'AccountingEntries',
  components: { AppSelect },
  data() {
    return {
      dialog: AccountingEntryEditDialog,
      eventsToListen: [EventType.AccountingEntryChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { style: 'date' },
        },
        { prop: 'subcategory.category.name', label: this.$t('category') },
        { prop: 'subcategory.name', label: this.$t('subcategory') },
        { prop: 'description' },
        { prop: 'value', component: MoneyCol },
      ],
      filter: {
        category: null,
        subcategory: null,
      },
      categoryOpts: [],
    };
  },
  computed: {
    subcategoryOpts() {
      const { category } = this.filter;
      if (category) {
        return category.subcategories.map((s) => ({
          value: s,
          label: s.name,
        }));
      }
      return [];
    },
  },
  watch: {
    'filter.category'() {
      this.filter.subcategory = null;
    },
  },
  methods: {
    async fetchData({ query, pagination, filters: queryFilters }) {
      const filters = {};

      if (queryFilters.dateRange) {
        Object.assign(
          filters,
          AppFilterDatePicker.formatAsFilter('date', queryFilters.dateRange)
        );
      }

      if (query) {
        filters['id|subcategory.name|description|date:ilike'] = `%${query}%`;
      }

      if (this.filter.category) {
        filters['subcategory.parentId'] = this.filter.category.id;
        if (this.filter.subcategory) {
          filters['subcategory.id'] = this.filter.subcategory.id;
        }
      }

      const result = await this.$api.AccountingEntry.findAll({
        eager: '[subcategory.[category]]',
        orderByDesc: 'date',
        ...filters,
        ...pagination,
      });
      return result;
    },
    async remove(row) {
      return await this.$api.AccountingEntry.remove(row.id);
    },
    filterChanged($event) {
      this.$refs.crudPage.fetchEntities();
    },
  },
  async mounted() {
    this.categoryOpts = (
      await this.$api.AccountingEntryCategory.findAll({
        eager: '[subcategories]',
        'parentId:isNull': '',
      })
    ).map((category) => {
      return {
        value: category,
        label: category.name,
      };
    });
  },
};
</script>
<style scoped lang="scss"></style>
