<template>
  <Tooltip :content="$t('remove')">
    <BaseButton
      class="remove btn-link"
      type="danger"
      :size="size"
      icon
      @click="click"
    >
      <i class="far fa-trash-alt"></i>
    </BaseButton>
  </Tooltip>
</template>

<script>
export default {
  name: 'TableRemoveButton',
  props: {
    size: {
      default: 'sm',
    },
  },
  methods: {
    click(event) {
      this.$emit('click', event);
    },
  },
};
</script>

<style scoped></style>
