<template>
  <AppPopoverButton
    placement="right"
    width="275"
    btn-icon="fas fa-tag"
    :btn-type="availableCoupons.length > 0 ? 'warning' : 'github'"
    :btn-attrs="{
      badgeValue: `${availableCoupons.length}`,
    }"
    :disabled="_.isEmpty(availableCoupons)"
  >
    <template v-slot:default="slotProps">
      <div class="pl-2 pr-2 Discounts">
        <div
          v-for="(coupon, idx) in availableCoupons"
          :key="coupon.id"
          class="row"
        >
          <div class="col d-flex justify-content-between align-items-center">
            <small class="text-white"> ID: {{ coupon.id }} </small>
            <div>
              <Badge type="success" class="m-0">
                {{ $t('discount') }}: {{ coupon.discountString() }}
              </Badge>
            </div>
            <div>
              <BaseButton
                @click="applyCoupon(coupon, slotProps.close)"
                type="github"
                simple
                link
              >
                {{ $t('apply') }}
              </BaseButton>
            </div>
          </div>
        </div>
      </div>
    </template>
  </AppPopoverButton>
</template>

<script>
import AppPopoverButton from 'components/app/AppPopoverButton';
import Badge from 'components/Badge';

export default {
  name: 'CustomerDiscountButton',
  components: { Badge, AppPopoverButton },
  props: {
    order: {},
  },
  data() {
    return {};
  },
  computed: {
    availableCoupons() {
      if (!this.order.customer) return [];
      let coupons = this.order.customer.discountCoupons.slice();
      coupons = coupons.filter((c) => {
        if (c.redeemed) {
          if (c.targetOrderId === this.order.id) {
            return !this.order.appliedCoupons.some((ac) => ac.id === c.id);
          }
          return false;
        }

        return !this.order.appliedCoupons.some((ac) => ac.id === c.id);
      });
      return _.orderBy(coupons, ['discount', 'id'], ['desc']);
    },
  },
  methods: {
    async applyCoupon(coupon, closePopover) {
      this.$emit('couponSelected', coupon);
      closePopover();
    },
  },
};
</script>

<style scoped>
.Discounts {
  overflow: auto;
  overflow-x: hidden;
  max-height: 300px;
}
</style>
