import { appStore } from 'state/Store';
import { Permission, Permissions } from 'security/Permission';

export const $perm = new Permissions({
  roleSupplier: () => {
    return _.get(appStore, 'state.user.role', 'USER');
  },
});

export const $permType = Permission;

export const PermissionPlugin = {
  install(Vue) {
    Vue.prototype.$perm = $perm;
    Vue.prototype.$permType = Permission;
  },
};
