<template>
  <CrudDialog
    :title="$t('sellersReport')"
    @save="generateReport"
    :loading="loading"
    @close="$emit('close')"
    :button-label="$t('generate')"
  >
    <div class="row">
      <div class="col-5">
        <BaseInput :label="$t('dateRangeLabel')">
          <AppFilterDatePicker v-model="model.dateRange" :clearable="false" />
        </BaseInput>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <BaseCheckbox v-model="model.dayLevel">
          {{ $t('dayLevel') }}
        </BaseCheckbox>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import AppSelect from 'components/app/input/AppSelect';
import AppAutoUpload from 'components/app/input/AppAutoUpload';
import AppTextArea from 'components/app/AppTextArea';
import Dialog from 'components/app/Dialog';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import { makeApiUrl } from 'util/utils';

export default {
  name: 'SellersReportDialog',
  components: { Dialog, AppTextArea, AppAutoUpload, AppSelect, AppFilterDatePicker },
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        dateRange: [moment().startOf('month'), moment().endOf('month').startOf('day')],
        dayLevel:false,
      },
    };
  },
  methods: {
    generateReport() {
      const apiUrl = makeApiUrl(
        `/api/brain/orders/fns/sellersReport?${new URLSearchParams({
          initialDate: this.model.dateRange[0]?.toISOString() ?? '',
          finalDate: this.model.dateRange[1]?.toISOString() ?? '',
          dayLevel: this.model.dayLevel ?? false,
          uKey: this.$store.state.token,
        })}`
      );
      window.open(apiUrl, '_blank');
    },
  },
  async mounted() {},
};
</script>

<style scoped></style>
