<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
    action-col-width="125"
  >
    <template v-slot:additionalColumns>
      <ElTableColumn :label="$t('subcategories')">
        <template slot-scope="{ row }">
          <Badge
            v-for="sub in row.subcategories"
            :key="sub.id"
            class="mr-2 noUpperCase"
          >
            {{ sub.name }}
            <TableEditButton
              @click="editSubcategory(sub)"
              size="xs"
              class="ml-2 mr-2"
            />
            <TableRemoveButton @click="removeSubcategory(sub)" size="xs" />
          </Badge>
        </template>
      </ElTableColumn>
    </template>

    <template v-slot:tableButtons="{ rowProps }">
      <Tooltip :content="$t('addSubcategory')">
        <BaseButton
          class="btn-link"
          type="success"
          size="sm"
          icon
          @click="addSubcategory(rowProps.row)"
        >
          <i class="fas fa-plus"></i>
        </BaseButton>
      </Tooltip>
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import Badge from 'components/Badge';
import AccountingEntryCategoryEditDialog from 'pages/accountingEntryCategory/AccountingEntryCategoryEditDialog';
import { commonAlerts } from 'util/commonAlerts';

export default {
  name: 'AccountingEntryCategories',
  components: { Badge },
  data() {
    return {
      dialog: AccountingEntryCategoryEditDialog,
      eventsToListen: [EventType.AccountingEntryCategoryChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'name', label: this.$t('category') },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination, filters: queryFilters }) {
      const filters = {};

      if (query) {
        filters['id|name|subcategories.name:ilike'] = `%${query}%`;
      }
      const result = await this.$api.AccountingEntryCategory.findAll({
        eager: '[subcategories]',
        orderBy: 'name',
        ...filters,
        ...pagination,
        'parentId:isNull': '',
      });
      return result;
    },
    async remove(row) {
      return await this.$api.AccountingEntryCategory.remove(row.id);
    },
    editSubcategory(row) {
      this.$openModal(this.dialog, {
        id: row.id,
      });
    },
    addSubcategory(row) {
      this.$openModal(this.dialog, {
        parentId: row.id,
      });
    },
    async removeSubcategory(sub) {
      try {
        if (await commonAlerts.confirmOperation()) {
          this.remove(sub);
          this.$bus.$emit(EventType.AccountingEntryCategoryChanged);
        }
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      }
    },
  },
};
</script>
<style scoped lang="scss"></style>
