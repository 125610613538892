import VeeValidate, { Validator } from 'vee-validate';
import validationMessages from 'vee-validate/dist/locale/pt_BR';
import i18n from 'i18n';
import { appApi } from 'api/AppApi';

Object.assign(validationMessages.attributes, i18n.messages.pt);

installUniquePhoneNumber();

export default {
  install(Vue) {
    Vue.use(VeeValidate, {
      i18n,
      dictionary: {
        pt: validationMessages,
      },
    });
  },
};

function installUniquePhoneNumber() {
  validationMessages.messages.uniquePhoneNumber =
    i18n.messages.pt.uniquePhoneNumberValidation;
  Validator.extend('uniquePhoneNumber', async (value, args) => {
    if (args) {
      if (!Array.isArray(args)) args = [args];

      const current = args[0];
      if (value === current) return true;
      if (args.includes(value)) {
        return false;
      }
    }
    const customers = await appApi.Customer.findAll({
      'contacts.content': value,
    });
    return _.isEmpty(customers);
  });
}
