<template>
  <div
    :class="['TagInput', { editing: editing, withValue: !_.isEmpty(value) }]"
    @click="editTag"
    @keydown.esc="cancelEdit"
  >
    <Badge type="transparent" :style="!editing && buildTagStyle(value)" pill>
      <template v-if="editing">
        <div class="d-flex">
          <ElPopover placement="top" trigger="click" :popper-class="`${whiteMode && 'white-bg'}`">
            <input
              slot="reference"
              class="flex-grow-1"
              type="text"
              ref="nameInput"
              maxlength="32"
              :placeholder="$t('tagName')"
              :value="model.name"
              @input="model.name = $event.target.value.toUpperCase()"
              @keypress.enter="save"
            />

            <div class="TagCloud">
              <Card v-if="_.isEmpty(tagOpts)">
                <h1 class="text-center m-0 mt-2">
                  <i class="far fa-frown"></i>
                </h1>
                <h4 class="text-center mt-2">
                  {{ $t('emptyAroundHere') }}
                </h4>
              </Card>

              <div
                v-else
                v-for="tag in tagOpts"
                :key="tag.id"
                :class="[
                  'Tag d-inline-flex',
                  { match: !model.name || tag.name.includes(model.name) },
                ]"
                @click="selectTag(tag)"
              >
                <Tooltip :content="$t('clickToApplyTag')">
                  <Badge type="transparent" :style="buildTagStyle(tag)" pill>
                    {{ tag.name }}
                    <Tooltip :content="$t('removeTag')">
                      <BaseButton
                        @click.stop="deleteTag(tag)"
                        size="xs"
                        class="m-0 ml-1 p-0"
                        type="warning"
                        simple
                        icon
                        link
                      >
                        <i class="fas fa-times" />
                      </BaseButton>
                    </Tooltip>
                  </Badge>
                </Tooltip>
              </div>
            </div>
          </ElPopover>

          <Tooltip :content="$t('bgColor')">
            <span class="d-inline-flex align-items-center ml-2">
              B:
              <AppColorPicker v-model="model.props.bgColor" class="xs bgColor ml-1" show-alpha />
            </span>
          </Tooltip>

          <Tooltip :content="$t('cardTextColor')">
            <span class="d-inline-flex align-items-center ml-2">
              T: <AppColorPicker v-model="model.props.textColor" class="xs ml-1" show-alpha />
            </span>
          </Tooltip>

          <Tooltip :content="$t('save')">
            <BaseButton
              @click="save"
              size="xs"
              class="m-0 ml-2 p-0"
              type="success"
              simple
              icon
              link
              :disabled="_.isEmpty(model.name)"
            >
              <i class="fas fa-check" />
            </BaseButton>
          </Tooltip>

          <Tooltip :content="$t('unlinkTag')">
            <BaseButton
              @click="unlinkTag"
              size="xs"
              class="m-0 ml-1 p-0"
              type="warning"
              simple
              icon
              link
            >
              <i class="fas fa-times" />
            </BaseButton>
          </Tooltip>
        </div>
      </template>
      <template v-else>
        {{ (value && value.name) || '+ Tag' }}
      </template>
    </Badge>
  </div>
</template>

<script>
import Badge from 'components/Badge';
import { mergeObjects } from 'util/utils';
import AppColorPicker from 'components/app/input/AppColorPicker';
import { commonAlerts } from 'util/commonAlerts';
import { TagUi } from 'api/Models';

let instanceBeingEdited;

export default {
  name: 'TagInput',
  components: { AppColorPicker, Badge },
  inject: {
    layoutConfig: {
      default: null,
    },
  },
  props: {
    value: {},
    excludedTags: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    return {
      editing: false,
      model: {},
      tagOpts: [],
    };
  },
  computed: {
    whiteMode() {
      return this.layoutConfig ? !this.layoutConfig.darkMode : false;
    },
  },
  methods: {
    buildTagStyle(tag) {
      return new TagUi(tag).buildStyle();
    },
    async editTag() {
      if (this.editing) return;

      if (instanceBeingEdited) {
        instanceBeingEdited.editing = false;
        instanceBeingEdited = null;
      }

      instanceBeingEdited = this;
      this.model = mergeObjects(
        {},
        {
          id: undefined,
          name: '',
          props: {
            bgColor: '#f5ca02',
            textColor: '#4c2b00',
          },
        },
        this.value || {}
      );
      this.model.id = undefined;
      this.tagOpts = (
        await this.$api.Tag.findAll({
          orderBy: 'name',
        })
      ).filter((t) => !this.excludedTags.some((et) => et.id === t.id));
      this.editing = true;
      await this.$nextTick();
      this.$refs.nameInput.focus();
      this.$refs.nameInput.click();
      this.$refs.nameInput.select();
    },
    async fetchTagOpts() {
      this.tagOpts = await this.$api.Tag.findAll({
        orderBy: 'name',
      });
    },
    cancelEdit(event) {
      if (event) event.stopPropagation();
      this.editing = false;
      if (instanceBeingEdited === this) {
        instanceBeingEdited = null;
      }
    },
    unlinkTag(event) {
      this.$emit('input', null);
      this.cancelEdit(event);
    },
    async deleteTag(tag) {
      try {
        if (!(await commonAlerts.confirmOperation())) return;

        await this.$api.Tag.remove(tag.id);
        await this.fetchTagOpts();
        this.$notify({
          type: 'info',
          message: this.$t('objectRemovedWithSuccess'),
        });
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    async save() {
      if (!this.model.name) return;

      this.model.name = this.model.name.toUpperCase();

      let [tag] = await this.$api.Tag.findAll({
        name: this.model.name,
      });

      if (!tag || !_.isEqual(tag, this.model)) {
        tag = mergeObjects(tag || {}, this.model);
        tag = await this.$api.Tag.save(tag);
      }

      this.$emit('input', tag);
      this.cancelEdit();
    },
    selectTag(tag) {
      this.$emit('input', tag);
      this.cancelEdit();
    },
  },
};
</script>

<style scoped lang="scss">
.TagInput {
  position: relative;
  display: inline-block;

  &.editing {
    .badge {
      opacity: 1;
    }
  }

  &.withValue {
    .badge {
      opacity: 1;
    }
  }

  .badge {
    cursor: pointer;

    opacity: 0.8;
    width: 100%;

    &:hover {
      opacity: 1;
    }

    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px solid rgba(255, 255, 255, 0.5);
      color: white;
      outline: none;
      width: 72px;
    }
  }
}

.TagCloud {
  display: flex;
  gap: 5px;
  flex-wrap: wrap;
  width: 425px;

  .Tag {
    cursor: pointer;

    opacity: 0.4;

    &.match {
      opacity: 0.8;
    }

    transition: opacity 200ms ease-in-out;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
