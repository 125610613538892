export class UploadApi {
  constructor($http) {
    this.$http = $http;
  }

  async uploadFiles(files = [], progressListener = _.noop) {
    if (files.toString().includes('FileList')) {
      files = Array.from(files);
    }

    if (!_.isArray(files)) {
      files = [files];
    }

    if (_.isEmpty(files)) {
      return;
    }

    const formData = new FormData();

    for (const file of files) {
      formData.append('uploads', file);
    }

    const { data } = await this.$http.post('/api/uploads', formData, {
      onUploadProgress(event) {
        const percentCompleted = Math.round((event.loaded * 100) / event.total);
        progressListener(percentCompleted, event);
      },
    });

    return data;
  }
}
