<template>
  <span>
    {{ translatedValue }}
  </span>
</template>

<script>
import { formatNumber } from 'util/utils';

export default {
  name: 'MoneyCol',
  props: {
    tableProps: {},
    colInfo: {},
  },
  computed: {
    translatedValue() {
      let val = _.get(this.tableProps.row, this.colInfo.prop);
      if (_.isFunction(val)) {
        val = val.bind(this.tableProps.row)();
      }
      return formatNumber(val);
    },
  },
};
</script>

<style scoped></style>
