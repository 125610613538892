import {
  Input,
  InputNumber,
  Tooltip as ElTooltip,
  Popover,
  Table,
  TableColumn,
  TimeSelect,
  DatePicker,
  Select,
  Option,
  OptionGroup,
  Loading,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  Progress,
} from 'element-ui';
import VueObserveVisibility from 'vue-observe-visibility';

import BaseInput from 'components/Inputs/BaseInput.vue';
import BaseDropdown from 'components/BaseDropdown.vue';
import Card from 'components/Cards/Card.vue';
import BaseButton from 'components/BaseButton.vue';
import BaseCheckbox from 'components/Inputs/BaseCheckbox.vue';
import Modal from 'components/Modal';
import { BasePagination } from 'components';
import Tooltip from 'components/app/Tooltip';
import Tabs from 'components/Tabs/Tabs';
import Tab from 'components/Tabs/Tab';
import CrudPage from 'components/app/CrudPage';
import AppTable from 'components/app/AppTable';
import TableEditButton from 'components/app/btn/TableEditButton';
import TableRemoveButton from 'components/app/btn/TableRemoveButton';
import CrudDialog from 'components/app/CrudDialog';

const GlobalComponents = {
  install(Vue) {
    Vue.component(BaseInput.name, BaseInput);
    Vue.component(BaseDropdown.name, BaseDropdown);
    Vue.component(Card.name, Card);
    Vue.component(BaseCheckbox.name, BaseCheckbox);
    Vue.component(Modal.name, Modal);
    Vue.component(BaseButton.name, BaseButton);
    Vue.component(Tooltip.name, Tooltip);
    Vue.component(Tabs.name, Tabs);
    Vue.component(Tab.name, Tab);
    Vue.component(TableEditButton.name, TableEditButton);
    Vue.component(TableRemoveButton.name, TableRemoveButton);
    Vue.component(AppTable.name, AppTable);
    Vue.component(CrudPage.name, CrudPage);
    Vue.component(CrudDialog.name, CrudDialog);

    Vue.component(Input.name, Input);
    Vue.component(InputNumber.name, InputNumber);
    Vue.component(Table.name, Table);
    Vue.component(TableColumn.name, TableColumn);
    Vue.component(BasePagination.name, BasePagination);
    Vue.component(TimeSelect.name, TimeSelect);
    Vue.component(DatePicker.name, DatePicker);
    Vue.component(Select.name, Select);
    Vue.component(Option.name, Option);
    Vue.component(OptionGroup.name, OptionGroup);
    Vue.component(Dropdown.name, Dropdown);
    Vue.component(DropdownMenu.name, DropdownMenu);
    Vue.component(DropdownItem.name, DropdownItem);
    Vue.component(Progress.name, Progress);

    Vue.use(Loading.directive);
    Vue.use(ElTooltip);
    Vue.use(Popover);
    Vue.use(VueObserveVisibility);
  },
};

export default GlobalComponents;
