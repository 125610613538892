<template>
  <Dialog
    :title="$t('customersByOrigin')"
    class="CustomersByOrigin"
    @close="$emit('close')"
  >
    <div class="" v-loading="loading">
      <AppTable
        :data="customersByOrigin"
        :columns="tableColumns"
        row-key="id"
      />
    </div>
  </Dialog>
</template>

<script>
import OrderFilterButton from 'pages/funnel/OrderFilterButton';
import Badge from 'components/Badge';
import Dialog from 'components/app/Dialog';

export default {
  name: 'CustomersByOrigin',
  components: { Dialog, Badge, OrderFilterButton },
  props: {
    orders: {},
  },
  data() {
    return {
      loading: false,
      customersByOrigin: [],
      tableColumns: [],
    };
  },
  methods: {
    async fetchData() {
      let customerMap = {};
      this.orders.forEach((o) => (customerMap[o.customer.id] = o.customer));

      const customers = _.orderBy(
        Object.values(customerMap),
        [(c) => new Date(c.createdAt)],
        ['desc']
      );

      const data = {};

      const dataFn = ($createdAt, state, sourceName) => {
        const key = $createdAt.toISOString();
        if (!data[key]) {
          data[key] = {};
        }

        if (!data[key][state]) {
          data[key][state] = {};
        }

        if (!data[key][state][sourceName]) {
          data[key][state][sourceName] = [];
        }

        return data[key][state][sourceName];
      };

      const tableCols = [];

      for (const customer of customers) {
        const $createdAt = moment(customer.createdAt).startOf('month');
        const address = customer.mainAddress();
        const state = address?.state || 'NÃO INF';
        const sourceName = customer.source || 'NÃO INF';
        if (!tableCols.includes(sourceName)) {
          tableCols.push(sourceName);
        }
        dataFn($createdAt, state, sourceName).push(customer);
      }

      const processedData = Object.entries(data).map(([key, states]) => {
        const $date = moment(key);

        let totalValueProps = {};

        const dateChildren = _.orderBy(Object.entries(states), (s) => s[0]).map(
          ([stateKey, sources]) => {
            let stateValue = 0;

            const additionalProps = {};

            _.orderBy(Object.entries(sources), (s) => s[0]).map(
              ([sourceKey, customers]) => {
                stateValue += customers.length;

                additionalProps[sourceKey] = customers.length;

                if (!totalValueProps[sourceKey]) {
                  totalValueProps[sourceKey] = 0;
                }

                totalValueProps[sourceKey] += customers.length;

                return {
                  id: `${key}-${stateKey}-${sourceKey}`,
                  name: sourceKey,
                  value: customers.length,
                };
              }
            );

            return {
              id: `${key}-${stateKey}`,
              name: stateKey,
              ...additionalProps,
            };
          }
        );
        return {
          id: key,
          name: this.$filters.formatDateTime($date, 'monthYear'),
          children: dateChildren,
          ...totalValueProps,
        };
      });

      tableCols.sort();
      this.tableColumns = [
        {
          prop: 'name',
          label: `${this.$t('date')}/${this.$t('state')}`,
          attrs: { width: 150 },
        },
        ...tableCols.map((sourceName) => ({
          prop: sourceName,
          label: sourceName,
          attrs: { className: 'text-center' },
        })),
      ];

      this.customersByOrigin = processedData;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchData();
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
