<template>
  <ElTooltip
    :content="content"
    placement="top"
    :open-delay="openDelay"
    v-bind="$attrs"
  >
    <slot />
  </ElTooltip>
</template>

<script>
export default {
  name: 'Tooltip',
  inheritAttrs: false,
  props: {
    content: {},
    openDelay: {
      default: 400,
    },
  },
};
</script>

<style scoped></style>
