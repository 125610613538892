import { Mutation } from 'state/Store';

export default {
  install(Vue) {
    Vue.prototype.$openModal = function (component, props = {}) {
      this.$store.commit(Mutation.openModal, {
        component,
        props,
      });
    };
  },
};
