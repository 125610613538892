<template>
  <div
    class="CenterIconMessage d-flex align-items-center justify-content-center flex-column p-4"
  >
    <i :class="[icon, 'mb-3']" />
    <h1 class="m-0">{{ message }}</h1>
  </div>
</template>

<script>
export default {
  name: 'CenterIconMessage',
  props: {
    icon: {},
    message: {},
  },
};
</script>

<style scoped lang="scss">
.CenterIconMessage {
  width: 100%;
  height: 100%;
  color: rgba(255, 255, 255, 0.8);
  font-size: 4rem;
}
</style>
