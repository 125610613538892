import _ from 'lodash';
import moment from 'moment-business-days';
import 'moment/locale/pt-br';
import Swal from 'sweetalert2';

window._ = _;
moment.locale('pt-br');
window.moment = moment;
window.Swal = Swal;

if (!Array.prototype.removeIdx) {
  Array.prototype.removeIdx = function(idx) {
    this.splice(idx, 1);
  };
}

if (!Array.prototype.replaceIdx) {
  Array.prototype.replaceIdx = function(idx, replacement) {
    this.splice(idx, 1, replacement);
  };
}

moment.prototype.toJSON = function() {
  return this.toISOString(true);
};

moment.prototype.toString = function() {
  return this.toISOString(true);
};

Date.prototype.toJSON = function() {
  return moment(this).toISOString(true);
};
