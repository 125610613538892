<template>
  <Dialog
    :loading="loading"
    @close="$emit('close')"
    :class="['OrderWhatsDialog', { WhiteMode: !this.layoutConfig.darkMode }]"
  >
    <div class="OrderWhatsDialogContentContainer">
      <div class="TitleBg" />
      <div class="Title d-flex">
        <div class="d-flex flex-column justify-content-center">
          <WhatsAvatar :customer="computedCustomer" :contact="contact" class="md" />
        </div>
        <div class="ml-2 d-flex InfoContainer flex-grow-1">
          <div>
            <table>
              <tr>
                <td class="mr-2">{{ $t('customer') }}:</td>
                <td class="d-flex align-items-center">
                  <span class="mr-1">
                    {{ computedCustomer && computedCustomer.name }}
                  </span>
                  <Tooltip :content="`${$t('edit')} ${$t('customer')}`">
                    <TableButton
                      class="m-0"
                      icon="fas fa-edit"
                      @click="editCustomer({ customer: computedCustomer })"
                      size="sm"
                    />
                  </Tooltip>
                </td>
              </tr>
              <tr>
                <td class="mr-2">{{ $t('contact') }}:</td>
                <td>
                  <ElDropdown
                    v-if="contactOpts.length > 1"
                    trigger="click"
                    @command="changeContact"
                  >
                    <span class="text-success text-nowrap">
                      {{ contact && contact.content }}
                      <i class="ml-1 fas fa-chevron-down" />
                    </span>
                    <ElDropdownMenu slot="dropdown">
                      <ElDropdownItem
                        v-for="{ value, label } in contactOpts"
                        :key="label"
                        :command="{ contact: value }"
                        :disabled="value.id === contact.id"
                      >
                        {{ label }}
                      </ElDropdownItem>
                    </ElDropdownMenu>
                  </ElDropdown>
                  <span v-else class="text-nowrap">
                    {{ contact && contact.content }}
                  </span>
                </td>
              </tr>
              <tr v-if="entity && isOrder">
                <td class="mr-2">{{ $t('order') }}:</td>
                <td>{{ entity.orderCode }}</td>
              </tr>
              <tr v-if="entity">
                <td class="mr-2">{{ $t('responsible') }}:</td>
                <td>
                  <ElDropdown trigger="click" @command="changeResponsible">
                    <span class="text-white">
                      {{ _.get(entity.responsibleUser, 'name', $t('selectOne')) }}
                      <i class="ml-1 fas fa-chevron-down" />
                    </span>
                    <ElDropdownMenu slot="dropdown">
                      <ElDropdownItem
                        :command="{ user: null }"
                        :disabled="_.isEmpty(entity.responsibleUser)"
                      >
                        {{ $t('noOne') }}
                      </ElDropdownItem>
                      <ElDropdownItem
                        v-for="{ value, label } in userOpts"
                        :key="value.id"
                        :command="{ user: value }"
                        :disabled="value.id === _.get(entity.responsibleUser, 'id', 0)"
                      >
                        {{ label }}
                      </ElDropdownItem>
                    </ElDropdownMenu>
                  </ElDropdown>
                </td>
              </tr>
            </table>
          </div>
          <div class="ml-3 flex-grow-1" v-if="entity">
            <div class="TagsInput">
              <TagArrayInput :value="entity.tags" @input="saveTags" />
            </div>
          </div>
        </div>
      </div>

      <Tooltip :content="$t('reloadMessages')">
        <BaseButton
          class="ReloadMessagesButton"
          @click="reloadMessages"
          icon
          simple
          link
          type="github"
        >
          <i class="fas fa-sync-alt"></i>
        </BaseButton>
      </Tooltip>

      <div class="ChatWrapper position-relative">
        <div class="Chat mb-2" ref="Chat" v-show="!showFilePreview">
          <div class="text-center">
            <BaseButton
              v-if="!showAll && messages.length !== visibleMessages.length"
              @click="showAllMessages()"
            >
              {{ $t('viewAll') }}
            </BaseButton>
          </div>

          <div v-for="{ date, messages } in groupedVisibleMessages" :key="date">
            <div class="text-center mt-2 mb-2 DateDivisor">
              <small class="p-2">{{ date }}</small>
            </div>
            <div
              v-for="message in messages"
              :key="message.id"
              :class="['MsgWrapper', { FromMe: !!message.origin }]"
            >
              <WhatsMsg
                :customer="computedCustomer"
                :message="message"
                :quoted="model.quotedMessage && model.quotedMessage.id === message.id"
                @quote="quoteMessage"
              />
            </div>
          </div>

          <div class="SendQueue mt-2" v-if="!_.isEmpty(sendQueue)">
            <div v-for="(queuedMessage, idx) in sendQueue" class="MsgWrapper FromMe" :key="idx">
              <div class="Msg MsgType-chat">
                <div class="d-flex align-items-center">
                  <h3 class="m-0 mr-3 ml-2">
                    <i class="fas fa-paper-plane"></i>
                  </h3>
                  <div>
                    <div>{{ $t('sendingMessage') }}...</div>
                    <div class="text-right">
                      <small>{{ queuedMessage.date | formatDateTime }}</small>
                    </div>
                  </div>
                  <div class="LoadingMessages ml-auto" v-loading="true" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="LoadingMessages ForChatWrapper" v-loading="loadingMessages" />
      </div>

      <div class="Preview" v-if="showFilePreview">
        <div class="PreviewTitle d-flex align-items-center">
          <Tooltip :content="$t('cancel')">
            <BaseButton simple icon link @click="clearFileInput" type="github">
              <i class="fas fa-times" />
            </BaseButton>
          </Tooltip>
          <h3 class="ml-2 m-0">Preview</h3>
        </div>
        <div class="PreviewContainer">
          <img :src="filePreview" alt="File Preview" v-if="filePreview" />
          <div v-else class="GenericFile">
            <div class="mb-2">
              <i class="fas fa-file"></i>
            </div>
            <div v-if="model.file">
              {{ model.file.name }}
            </div>
          </div>
        </div>
      </div>

      <hr />

      <div class="FooterBg" />
      <div class="InputMessageContainer">
        <div class="row">
          <div v-if="model.quotedMessage" class="col-4">
            <WhatsMsg
              :customer="computedCustomer"
              :message="model.quotedMessage"
              @quote="quoteMessage"
              quoted
            />
          </div>
          <div class="col d-flex flex-column justify-content-end">
            <textarea
              class="form-control MessageInput pt-1 pb-1 pl-2 pr-2"
              v-model="model.message"
              @keypress.enter="sendMessage"
              :placeholder="$t('whatsInputPlaceholder')"
              rows="3"
            />
            <div class="row mt-2">
              <div class="col-4">
                <div v-show="!model.file">
                  <input
                    id="WhatsUploadInput"
                    type="file"
                    @change="fileUpload"
                    ref="fileUpload"
                    hidden
                  />
                  <label for="WhatsUploadInput" class="btn btn-sm btn-round btn-behance">
                    <i class="fas fa-upload" />
                    Upload
                  </label>
                </div>
              </div>
              <div class="col text-right">
                <Tooltip :content="$t('toggleDarkMode')">
                  <BaseButton @click="toggleDarkMode" type="github" link simple size="sm">
                    <i :class="`${this.layoutConfig.darkMode ? 'fas' : 'far'} fa-moon`"></i>
                  </BaseButton>
                </Tooltip>

                <Tooltip :content="$t('markAsNotRead')">
                  <BaseButton @click="markAsRead(false)" type="danger" link size="sm" class="ml-2">
                    <i class="fas fa-envelope mr-1"></i>
                    {{ $t('notRead') }}
                  </BaseButton>
                </Tooltip>
                <Tooltip :content="$t('markAsRead')">
                  <BaseButton @click="markAsRead(true)" type="success" link size="sm" class="ml-2">
                    <i class="fas fa-envelope-open mr-1"></i>
                    {{ $t('read') }}
                  </BaseButton>
                </Tooltip>

                <ElPopover v-model="messageTemplatePopoverVisible" placement="top">
                  <div>
                    <div style="width: 500px; max-height: 450px; overflow: auto">
                      <h4 v-if="messageTemplates.length === 0" class="text-center mb-0">
                        {{ $t('messageTemplateEmpty') }}
                      </h4>

                      <div
                        v-for="messageTemplate in messageTemplates"
                        :key="messageTemplate.id"
                        class="d-flex align-items-center mb-2"
                        style="gap: 20px"
                      >
                        <BaseButton
                          size="sm"
                          class="flex-grow-1 justify-content-start"
                          @click="selectMessageTemplate(messageTemplate)"
                        >
                          <Tooltip :content="$t('containText')">
                            <i
                              class="fa-solid fa-file-lines mr-1"
                              v-if="!_.isEmpty(messageTemplate.content)"
                            />
                          </Tooltip>
                          <Tooltip :content="$t('containImage')">
                            <i
                              class="fas fa-image mr-1"
                              v-if="!_.isEmpty(messageTemplate.imageFileId)"
                            />
                          </Tooltip>
                          {{ messageTemplate.name }}
                        </BaseButton>
                        <div class="align-self-end">
                          <TableEditButton @click="openMessageTemplateForm(messageTemplate.id)" />
                          <TableRemoveButton @click="removeMessageTemplate(messageTemplate.id)" />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                      <BaseButton
                        class=""
                        size="sm"
                        type="info"
                        block
                        @click="openMessageTemplateForm()"
                      >
                        <i class="fas fa-plus mr-1"></i>
                        {{ $t('createNewMessageTemplate') }}
                      </BaseButton>
                    </div>
                  </div>
                  <BaseButton class="ml-2" size="sm" link type="github" simple slot="reference">
                    <i class="fas fa-comment mr-1"></i>
                    {{ $t('template') }}
                  </BaseButton>
                </ElPopover>

                <BaseButton @click="sendMessage" class="ml-2" size="sm" type="info">
                  <i class="fas fa-paper-plane mr-1"></i>
                  {{ $t('send') }}
                </BaseButton>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="FloatingActions" v-loading="loading" v-if="entity">
        <div v-if="isOrder">
          <ManageOrderButton :order-id="entity.id" size="lg" />
          <EditOrderButton :id="entity.id" size="lg" />
          <MoveOrderToStatusButton
            :order="entity"
            :funnels="funnels"
            :status="entity.status"
            size="lg"
          />
          <OrderLayoutButton :order="entity" size="lg" />
          <OrderWhatsSendBudgedButton
            :order-id="entity.id"
            @exporting="loading = $event"
            @export="handleExport"
          />
        </div>
        <OrderWhatsCommentPopup
          :current-message="entity.props.whatsDialogMessage || ''"
          @save="handleCommentSave"
        />
        <div v-if="!isOrder">
          <Tooltip :content="$t('generateOrder')">
            <TableButton
              icon="fas fa-archive"
              @click="generateOrderFromInitialContact(entity)"
              size="lg"
            />
          </Tooltip>

          <TableRemoveButton @click="deleteInitContact" size="lg" />
        </div>
      </div>
    </div>
  </Dialog>
</template>

<script>
import { commonAlerts } from 'util/commonAlerts';
import { EventType } from 'plugins/eventBusPlugin';
import Badge from 'components/Badge';
import ExpandableImage from 'components/app/ExpandableImage';
import Dialog from 'components/app/Dialog';
import InitialContactMixin from 'pages/initialContact/InitialContactMixin';
import TableButton from 'components/app/btn/TableButton';
import OrderWhatsCommentPopup from 'pages/order/whats/OrderWhatsCommentPopup';
import OrderExportButton from 'pages/order/OrderExportButton';
import OrderWhatsSendBudgedButton from 'pages/order/whats/OrderWhatsSendBudgedButton';
import WhatsAvatar from 'components/app/WhatsAvatar';
import WhatsMsg from 'pages/order/whats/WhatsMsg';
import TagArrayInput from 'components/app/input/TagArrayInput';
import { appCache } from 'state/Cache';
import { formatDateTime, uploadUrl } from 'util/utils';
import MessageTemplateFormDialog from 'pages/messageTemplates/MessageTemplateFormDialog';

let SEND_QUEUE = Promise.resolve();

const OrderWhatsDialogDarkModeKey = 'OrderWhatsDialogDarkMode';

export default {
  name: 'OrderWhatsDialog',
  components: {
    TagArrayInput,
    WhatsMsg,
    WhatsAvatar,
    OrderWhatsSendBudgedButton,
    OrderExportButton,
    OrderWhatsCommentPopup,
    TableButton,
    Dialog,
    ExpandableImage,
    Badge,
    MoveOrderToStatusButton: () => import('pages/funnel/MoveOrderToStatusButton'),
    EditOrderButton: () => import('pages/order/EditOrderButton'),
    ManageOrderButton: () => import('pages/order/ManageOrderButton'),
    OrderLayoutButton: () => import('pages/order/OrderLayoutButton'),
  },
  provide() {
    return {
      layoutConfig: this.layoutConfig,
    };
  },
  props: {
    initialEntity: {},
    selectedContact: {},
    customer: {},
    registerViewEvent: { type: Boolean, default: true },
  },
  mixins: [InitialContactMixin],
  data() {
    return {
      loading: false,
      loadingMessages: false,
      messages: [],
      visibleMessages: [],
      showAll: false,
      model: {
        message: '',
        file: null,
        inlineFile: null,
        quotedMessage: null,
      },
      contact: this.selectedContact,
      userOpts: [],
      entity: null,
      isOrder: this.initialEntity?.hasOwnProperty('statusId') || false,
      filePreview: null,
      funnels: [],
      sendQueue: [],
      layoutConfig: {
        darkMode: appCache.exists(OrderWhatsDialogDarkModeKey)
          ? appCache.get(OrderWhatsDialogDarkModeKey)
          : true,
      },
      messageTemplatePopoverVisible: false,
      messageTemplates: [],
    };
  },
  watch: {
    'model.file'(val) {
      this.filePreview = null;
      if (val && val.type.includes('image')) {
        const reader = new FileReader();
        reader.onload = () => {
          this.filePreview = reader.result;
        };
        reader.readAsDataURL(val);
      }
    },
  },
  computed: {
    contactOpts() {
      if (_.isEmpty(this.computedCustomer?.contacts)) return [];

      return _.orderBy(
        this.computedCustomer.contacts,
        [(c) => new Date(c.updatedAt)],
        ['desc']
      ).map((c) => ({
        value: c,
        label: c.content,
      }));
    },
    apiService() {
      return this.isOrder ? this.$api.Order : this.$api.InitialContact;
    },
    computedCustomer() {
      return this.entity?.customer || this.customer;
    },
    groupedVisibleMessages() {
      const grouped = _.groupBy(this.visibleMessages || [], (m) =>
        formatDateTime(m.createdAt, 'date')
      );
      return _.orderBy(
        Object.keys(grouped).map((date) => ({ date, time: new Date(date).getTime() || 0 })),
        ['asc']
      ).map(({ date }) => ({ date, messages: grouped[date] }));
    },
    showFilePreview() {
      return !!this.model.file || !!this.filePreview;
    },
  },
  methods: {
    toggleDarkMode() {
      this.layoutConfig.darkMode = !this.layoutConfig.darkMode;
      appCache.put(OrderWhatsDialogDarkModeKey, this.layoutConfig.darkMode);
    },
    async markAsRead(read = true) {
      const customer = this.computedCustomer;
      const field = read ? 'whatsMarkedAsReadAt' : 'whatsMarkedAsNotReadAt';
      customer[field] = new Date();
      await this.$api.Customer.save({
        id: customer.id,
        [field]: customer[field],
      });
      this.$bus.$emit(EventType.CustomerChanged, {
        model: 'Customer',
        type: 'UPDATED',
        data: customer,
      });
    },
    async quoteMessage(message) {
      if (this.model.quotedMessage && this.model.quotedMessage.id === message.id) {
        this.model.quotedMessage = null;
        return;
      }
      this.model.quotedMessage = message;
    },
    resetModel() {
      this.model = {
        message: '',
        file: null,
        inlineFile: null,
        quotedMessage: null,
      };
      this.filePreview = null;
    },
    async handleExport({ data }) {
      this.loading = true;
      try {
        this.resetModel();
        this.model.inlineFile = data;
        await this.$nextTick();
        await this.sendMessage();
      } finally {
        this.loading = false;
      }
    },
    async handleCommentSave({ message }) {
      this.loading = true;
      try {
        const patch = _.pick(this.entity, ['id', 'props']);
        patch.props.whatsDialogMessage = message;
        await this.apiService.save(patch);
        this.entity.props.whatsDialogMessage = message;
        this.$notify({
          message: this.$t('messageSaveSuccess'),
          type: 'info',
        });
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async deleteInitContact() {
      if (await this.confirmRemoveInitialContact(this.entity)) {
        this.$emit('close');
      }
    },
    async showAllMessages() {
      await this.reloadMessages(false);
      this.showAll = true;
      await this.preloadImages(this.messages);
      this.visibleMessages = this.messages;
    },
    registerEvent(type, props = {}) {
      this.$api.LogEntry.save({
        event: 'Whats',
        customerId: this.computedCustomer?.id,
        userId: this.$store.state.user.id,
        props: {
          type,
          ...props,
        },
      });
    },
    async sendMessage(event) {
      if (event && event.key === 'Enter' && event.shiftKey) {
        return;
      }

      event?.preventDefault();

      this.sendQueue.push({ date: moment() });

      const msgData = {
        customerId: this.computedCustomer.id,
        contactId: this.contact.id,
        text: this.model.message,
        inlineFile: this.model.inlineFile ? JSON.stringify(this.model.inlineFile) : undefined,
        upload: this.model.file,
        quotedMessageId: this.model.quotedMessage?.id,
      };

      this.resetModel();

      await this.$nextTick();
      this.scrollChatToBottom();

      SEND_QUEUE = SEND_QUEUE.then(async () => {
        try {
          await this.$api.Whats.sendMessage(msgData);
          this.registerEvent('MessageSent');

          if (this.entity && !this.entity.responsibleUserId) {
            await this.apiService.save({
              id: this.entity.id,
              responsibleUserId: this.$store.state.user.id,
            });
            await this.fetchEntity();
          } else if (!this.entity) {
            const initialEntity = await this.$api.InitialContact.save({
              customerId: this.computedCustomer.id,
              responsibleUserId: this.$store.state.user.id,
            });
            this.$emit('reopenModal', {
              initialEntity,
              registerViewEvent: false,
            });
          }
          await this.fetchMessages();
        } catch (e) {
          console.error(e);
          commonAlerts.defaultErrorMessage(e);
        } finally {
          this.sendQueue.shift();
        }
      });

      this.resetModel();
      this.$refs.fileUpload.value = '';
    },
    async fileUpload(event) {
      this.model.file = event.target.files[0];
    },
    clearFileInput() {
      this.model.file = null;
      this.model.inlineFile = null;
      this.filePreview = null;
      this.$refs.fileUpload.value = '';
    },
    async fetchMessages(clearCache = false, scrollToBottom = true) {
      this.loadingMessages = true;
      try {
        if (!this.contact) {
          this.contact = this.computedCustomer.mainContact();
        }

        const { messages } = await this.$api.Whats.findMessages(
          this.computedCustomer.id,
          this.contact.id,
          {
            clearCache,
          }
        );

        const visibleMessages = this.showAll ? messages : messages.slice(-50);

        await this.preloadImages(visibleMessages);

        this.messages = messages;
        this.visibleMessages = visibleMessages;

        await this.$nextTick();

        if (scrollToBottom) {
          this.scrollChatToBottom();
        }
      } catch (e) {
        console.error(e);

        window.__OrderWhatsDialogLastException = `${e.stack}`;
        this.$notify({
          type: 'warning',
          message: `<span onclick="navigator.clipboard.writeText(window.__OrderWhatsDialogLastException);" style="cursor: pointer" title="Clique para copiar o erro para a área de transferência">${this.$t(
            'whatsAppContactNotFoundMessage'
          )}</span>`,
          timeout: 0,
          closeOnClick: false,
        });

        this.$emit('close');
      } finally {
        this.loadingMessages = false;
      }
    },
    async fetchMessageTemplates() {
      try {
        this.messageTemplates = await this.$api.MessageTemplate.findAll({
          orderBy: ['name'],
        });
      } catch (e) {
        console.error('Error on fetchMessageTemplates()', e);
      }
    },
    scrollChatToBottom() {
      if (this.$refs.Chat) {
        this.$refs.Chat.scrollTop = this.$refs.Chat.scrollHeight;
      }
    },
    async preloadImages(messages) {
      // TODO desabilitado devido a lentidão no download das imagens estar impedindo a visualização das mensagens
      return;

      const imagePreload = [];
      for (const message of messages) {
        const mime = message?.file?.mimetype || '';
        if (!mime.includes('image')) continue;

        const url = message?.file?.url;
        if (url) {
          imagePreload.push(
            new Promise((res) => {
              const img = new Image();
              img.onload = res;
              img.onerror = res;
              img.src = url;
            })
          );
        }
      }

      await Promise.all(imagePreload);
    },
    async onWhatsEventReceived(event) {
      if (event.customerId === this.computedCustomer.id) {
        await this.fetchMessages();
      }
    },
    async customerChanged(customer) {
      if (this.computedCustomer.id !== customer.id) return;
      await this.fetchEntity();
    },
    async orderChanged(event) {
      const changedOrder = event.data;
      if (!this.isOrder) {
        if (changedOrder.customerId === this.computedCustomer?.id) {
          this.$emit('reopenModal', {
            initialEntity: changedOrder,
            registerViewEvent: false,
          });
        }
        return;
      }

      if (changedOrder.id !== this.entity.id) return;

      await this.fetchEntity();
    },
    async changeContact({ contact }) {
      if (this.contact && contact.id === this.contact.id) return;
      this.contact = contact;
      await this.fetchMessages();
    },
    async changeResponsible({ user }) {
      if (this.entity.responsibleUser && user?.id === this.entity.responsibleUser.id) return;

      await this.apiService.save({ id: this.entity.id, responsibleUserId: user?.id || null });
      this.entity.responsibleUserId = user?.id;
      this.entity.responsibleUser = user;
    },
    reloadMessages(scrollToBottom = true) {
      this.fetchMessages(true, scrollToBottom);
    },
    async fetchEntity() {
      this.loading = true;
      try {
        const id = this.entity?.id || this.initialEntity?.id;
        if (id) {
          this.entity = await this.apiService.findById(id, {
            eager: `[
            customer.[contacts],
            responsibleUser,
            tags,
            ${this.isOrder ? 'status' : ''}
          ]`,
          });
        }
        if (!this.contact) {
          this.contact = this.computedCustomer.mainContact();
        }
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    findLastCustomerMessage() {
      for (let i = this.messages.length - 1; i >= 0; i--) {
        const msg = this.messages[i];
        if (msg.type === 'chat' && !msg.origin) {
          return msg;
        }
      }
    },
    findLastSystemMessage() {
      for (let i = this.messages.length - 1; i >= 0; i--) {
        const msg = this.messages[i];
        if (msg.origin && msg.origin !== 'ticket') {
          return msg;
        }
      }
    },
    onClipboardPaste(pasteEvent) {
      const item = pasteEvent?.clipboardData?.files?.[0];
      if (item) {
        this.model.file = item;
      }
    },
    async saveTags(tags) {
      try {
        this.entity.tags = tags;
        const service = this.apiService;
        await service.save(_.pick(this.entity, ['id', 'tags']));
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    openMessageTemplateForm(id) {
      this.messageTemplatePopoverVisible = false;
      this.$openModal(MessageTemplateFormDialog, {
        id,
      });
    },
    async removeMessageTemplate(id) {
      this.messageTemplatePopoverVisible = false;
      try {
        if (!(await commonAlerts.confirmOperation())) return;

        await this.$api.MessageTemplate.remove(id);
        this.fetchMessageTemplates();
      } catch (e) {
        console.error('Error on removeMessageTemplate()', e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    async selectMessageTemplate(messageTemplate) {
      this.loading = true;
      try {
        this.messageTemplatePopoverVisible = false;
        this.model.message = messageTemplate.content;

        if (messageTemplate.imageFileId) {
          const url = uploadUrl(messageTemplate.imageFileId);
          try {
            const response = await this.$api.$http.get(url, {
              responseType: 'blob',
            });
            this.model.file = response.data;
            // const fileReader = new FileReader();
            // fileReader.onload = () => {
            //   this.filePreview = fileReader.result;
            //   this.model.inlineFile = {
            //     name: `image`,
            //     mimetype: response.headers['content-type'].split(';')[0],
            //     base64: fileReader.result.substring(fileReader.result.indexOf(',') + 1),
            //   };
            // };
            // fileReader.readAsDataURL(response.data);
          } catch (e) {
            console.error('Error while downloading image to attach to message', { url }, e);
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    await this.fetchEntity();
    await this.fetchMessages();
    this.fetchMessageTemplates();
    this.funnels = await this.$api.Funnel.findAll({
      eager: '[statuses.[types]]',
    });

    if (this.entity) {
      const lastCustomerMessage = this.findLastCustomerMessage();
      const lastSystemMessage = this.findLastSystemMessage();

      if (lastCustomerMessage) {
        this.computedCustomer.lastWhatsMessageAt = moment(lastCustomerMessage.createdAt);
      }

      if (lastSystemMessage) {
        this.computedCustomer.lastWhatsResponseAt = moment(lastSystemMessage.createdAt);
      }

      await this.$api.Customer.save(
        _.pick(this.computedCustomer, ['id', 'lastWhatsMessageAt', 'lastWhatsResponseAt']),
        {
          skipEventPub: true,
        }
      );
    }

    this.userOpts = (await this.$api.User.brain.findAll({ orderBy: 'name' })).map((value) => {
      return {
        value,
        label: value.name,
      };
    });

    this.$bus.$on(EventType.Whats, this.onWhatsEventReceived);
    this.$bus.$on(EventType.CustomerChanged, this.customerChanged);
    this.$bus.$on(EventType.OrderChanged, this.orderChanged);
    this.$bus.$on(EventType.MessageTemplateChanged, this.fetchMessageTemplates);
    window.addEventListener('paste', this.onClipboardPaste);

    if (this.registerViewEvent) {
      this.registerEvent('ChatView');
    }
  },
  beforeDestroy() {
    this.$bus.$off(EventType.Whats, this.onWhatsEventReceived);
    this.$bus.$off(EventType.CustomerChanged, this.customerChanged);
    this.$bus.$off(EventType.OrderChanged, this.orderChanged);
    this.$bus.$on(EventType.MessageTemplateChanged, this.fetchMessageTemplates);
    window.removeEventListener('paste', this.onClipboardPaste);
  },
};
</script>

<style lang="scss">
.OrderWhatsDialog {
  $ff: Segoe UI, Helvetica Neue, Helvetica, Lucida Grande, Arial, Ubuntu, Cantarell, Fira Sans,
    sans-serif;

  font-family: $ff !important;

  pre {
    font-family: $ff !important;
  }

  position: relative;

  .ReloadMessagesButton {
    position: absolute !important;
    right: 30px;
    top: 34px;
  }

  .Chat {
    padding: 0 4px;
    overflow-x: hidden;
    overflow-y: auto;
    min-height: 50vh;
    max-height: 50vh;
    /*box-shadow: 0 0 4px rgba(255, 255, 255, 0.1);*/
    display: flex;
    flex-direction: column;
    margin-top: 75px;

    .MsgWrapper {
      display: flex;

      margin-bottom: 8px;

      &:last-child {
        margin-bottom: 0;
      }

      &.FromMe {
        justify-content: flex-end;

        .Msg.Quoted {
          border-right: 5px solid white;
          border-left: none;
        }
      }
    }
  }

  .LoadingMessages {
    width: 48px;
    height: 48px;

    .el-loading-mask {
      background-color: transparent;
    }
  }

  .LoadingMessages.ForChatWrapper {
    position: absolute;
    bottom: 0;
    right: 15px;
  }

  .Preview {
    overflow-x: hidden;
    width: 100%;
    min-height: 50vh;
    max-height: 50vh;
    margin-top: 75px;

    .PreviewTitle {
      background-color: rgba(255, 255, 255, 0.1);
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
    }

    .PreviewContainer {
      background-color: rgba(255, 255, 255, 0.05);

      padding: 20px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;

      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;

      min-height: calc(50vh - 50px);
      max-height: calc(50vh - 50px);
      overflow: hidden;

      img {
        max-height: calc(50vh - 100px);
      }

      .GenericFile {
        text-align: center;

        .fas {
          font-size: 3rem;
        }
      }
    }
  }

  .Msg {
    min-width: 275px;
    border-radius: 4px;
    padding: 8px;
    background-color: rgba(255, 255, 255, 0.03);

    pre {
      font-size: 14.2px;
    }

    max-width: 90%;

    .expandable-image img,
    img {
      max-width: 128px;
    }

    .MsgContent {
      pre {
        white-space: pre-line;
      }
    }

    &.Quoted {
      box-shadow: 0 0 3px #ccc;
      background-color: rgba(255, 255, 255, 0.05);
      border-left: 5px solid white;
      max-width: 100%;

      .QuoteButton i {
        color: #1d8cf8;
      }
    }
  }

  .InputMessageContainer {
    .Msg {
      .MsgContent {
        max-height: 100px;
        overflow: auto;
      }

      .MsgFooter {
        display: none;
      }
    }
  }

  .FloatingActions {
    position: absolute;
    top: 16px;
    right: -38px;
    background-color: #30313c;
    border-radius: 2px;
    box-shadow: 0 0 2px black;

    button {
      display: block;
    }

    i {
      zoom: 2;
    }
  }

  .Title {
    position: absolute;
    top: -5px;
    left: 10px;
    border-radius: 4px;
    font-size: 14px;
    width: calc(100% - 20px);

    td:first-child {
      padding-right: 10px;
    }

    td {
      line-height: 18px;
    }
  }

  .MessageInput {
    font-family: $ff;
    font-size: 14.2px;
    border: none;
    border-radius: 8px;
    background-color: rgba(255, 255, 255, 0.08);

    &:focus {
      background-color: rgba(255, 255, 255, 0.05);
    }
  }

  .InfoContainer {
    padding-right: 70px;
  }

  .TagsInput {
    padding: 4px 8px;
    background-color: rgba(255, 255, 255, 0.02);
    border: 1px solid rgba(255, 255, 255, 0.05);
    border-radius: 8px;
  }

  .DateDivisor {
    position: sticky;
    top: 0;

    small {
      background-color: rgba(255, 255, 255, 0.075);
      border-radius: 4px;
      opacity: 0.5;
    }
  }

  .TitleBg,
  .FooterBg {
    display: none;
  }

  &.WhiteMode {
    $whiteBgColor: #e6e6e6;
    $whiteFontColor: #212529;

    color: $whiteFontColor;

    .DateDivisor {
      small {
        background-color: rgba(0, 0, 0, 0.075);
      }
    }

    .Msg,
    .MsgContent,
    .MsgContent pre,
    .text-white,
    .MsgSender,
    .MessageInput {
      color: #212529 !important;
    }

    .btn-github {
      color: rgba(33, 37, 41, 0.7) !important;

      &:hover,
      &:focus {
        color: rgba(33, 37, 41, 1) !important;
      }
    }

    .btn-success {
      color: darken(#00f2c3, 20%);

      &:hover {
        color: rgba(darken(#00f2c3, 20%), 0.5) !important;
      }
    }

    .btn-warning {
      color: darken(#ff8d72, 10%);

      &:hover {
        color: rgba(darken(#ff8d72, 10%), 0.5) !important;
      }
    }

    .btn-danger {
      color: darken(#fd5d93, 10%);

      &:hover {
        color: rgba(darken(#fd5d93, 10%), 0.5) !important;
      }
    }

    .modal-content {
      background-color: $whiteBgColor;

      .modal-header,
      .Title,
      .TitleBg,
      .FooterBg {
        background-color: #f7f7f7;
      }

      .TitleBg {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 90px;
      }

      .FooterBg {
        display: inline-block;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        height: 158px;
      }

      .Msg {
        background-color: white;
      }

      .FromMe {
        .Msg {
          background-color: rgb(220, 244, 198);
        }
      }
    }

    .MessageInput {
      background-color: rgba(0, 0, 0, 0.08);

      &:focus {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }

    .FloatingActions {
      background-color: $whiteBgColor;
    }

    .TagsInput {
      background-color: rgba(0, 0, 0, 0.05);
      border-color: rgba(0, 0, 0, 0.07);

      .TagInput {
        .badge {
          background-color: rgba(50, 50, 50, 0.25);
        }
      }
    }

    .SendQueue .fas {
      color: $whiteFontColor;
    }

    .Msg.Quoted {
      border-color: lighten($whiteFontColor, 30%) !important;
    }

    .CloseButton i {
      color: $whiteFontColor;
    }

    .Preview {
      .PreviewTitle {
        background-color: rgba(0, 0, 0, 0.1);

        h3 {
          color: $whiteFontColor;
        }
      }

      .PreviewContainer {
        background-color: rgba(0, 0, 0, 0.05);
      }
    }
  }
}
</style>
