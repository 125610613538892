<template>
  <CrudDialog
    :title="$t('attribute')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model="model.id"
          :readonly="true"
          number
        />
      </div>
    </div>
    <div class="row">
      <div class="col-5">
        <BaseInput
          name="name"
          :label="$t('name')"
          v-model="model.name"
          v-validate="'required|min:2|max:256'"
          required
        />
      </div>
      <div class="col-5">
        <BaseInput
          name="caption"
          :label="$t('caption')"
          v-model="model.caption"
          v-validate="'required|min:2|max:256'"
          required
        />
      </div>
      <div class="col d-flex align-items-center">
        <BaseCheckbox v-model="model.compound" :centralize="true">
          {{ $t('compound') }}
        </BaseCheckbox>
      </div>
    </div>

    <hr />

    <div>
      <div class="row">
        <div class="col-10">
          <h4 class="title title-up">{{ $t('values') }}</h4>
        </div>
        <div class="col text-right">
          <BaseButton @click="editAttributeValue()" size="">
            <i class="fas fa-plus" />
          </BaseButton>
        </div>
      </div>

      <div class="AttributeValueList mt-3">
        <Badge
          v-for="(attributeValue, idx) in model.values"
          :key="idx"
          class="mr-2 mb-2 noUpperCase"
        >
          <Tooltip :content="`${$t('value')}: ${attributeValue.value}`">
            <span>{{ attributeValue.caption }}</span>
          </Tooltip>
          <TableEditButton
            @click="editAttributeValue(attributeValue, idx)"
            size="xs"
            class="ml-2 mr-2"
          />
          <TableRemoveButton @click="model.values.removeIdx(idx)" size="xs" />
        </Badge>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';

import Badge from 'components/Badge';
import AttributeValueEditDialog from 'pages/product/attribute/AttributeValueEditDialog';

export default {
  name: 'AttributeEditDialog',
  components: { Badge },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        caption: '',
        compound: false,
        values: [],
      },
    };
  },
  methods: {
    async onSave() {
      await this.$api.Attribute.save(this.model);
      this.$bus.$emit(EventType.AttributeChanged);
    },
    editAttributeValue(attributeValue, idx) {
      this.$openModal(AttributeValueEditDialog, {
        attributeValue,
        onSave: (attrVal) => {
          if (attributeValue) {
            this.model.values.replaceIdx(idx, attrVal);
          } else {
            this.model.values.push(attrVal);
          }
        },
      });
    },
  },
  async mounted() {
    if (this.id) {
      try {
        this.loading = true;
        this.model = Object.assign(
          {},
          this.model,
          await this.$api.Attribute.findById(this.id, {
            eager:
              '[values.[materials.[product.[attributes.[values]], attributeValues]]]',
          })
        );
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
