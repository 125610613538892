<template>
  <Dialog
    class="DuplicatedCustomersDialog"
    :loading="loading"
    @close="$emit('close')"
    :title="$t('duplicatedContacts')"
  >
    <div class="row">
      <div class="col-6">
        <AppSelect
          value-key="phone"
          v-model="selectedPhone"
          :options="phoneOptions"
          class="w-100"
          :placeholder="`${$t('selectAnPhone')}...`"
        />
      </div>
    </div>

    <div v-if="!selectedPhone" class="mt-5 text-center">
      <h2 class="mt-1">
        {{ $t('noItemsFoundForTheAppliedFilter') }}
      </h2>
    </div>
    <table v-else class="table">
      <thead>
        <tr>
          <th>{{ $t('phone') }}</th>
          <th>{{ $t('customer') }} / {{ $t('orders') }}</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td style="width: 175px; vertical-align: top">
            {{ selectedPhone.phone }}
          </td>
          <td>
            <ul class="list-unstyled">
              <li v-for="customer in customers" :key="customer.id">
                <i class="fas fa-user mr-1" />
                {{ customer.name }}
                <Badge class="ml-1" type="info"> ID: {{ customer.id }}</Badge>

                <TableEditButton @click="editCustomer(customer)" class="ml-2" />
                <TableRemoveButton @click="removeCustomer(customer)" />

                <div class="ml-4" v-if="!_.isEmpty(customer.orders)">
                  <i class="fas fa-shopping-cart mr-1" />
                  {{ $t('orders') }}
                  <ul>
                    <li v-for="order in customer.orders" :key="order.id">
                      {{ order.id }}
                      <TableEditButton @click="editOrder(order)" />
                      <TableRemoveButton @click="removeOrder(order)" />
                      <Badge class="ml-1"> {{ $t('status') }}: {{ order.status.description }}</Badge>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </td>
        </tr>
      </tbody>
    </table>
  </Dialog>
</template>

<script>
import Dialog from 'components/app/Dialog';
import Badge from 'components/Badge';
import CustomerEditDialog from 'pages/customer/CustomerEditDialog';
import { commonAlerts } from 'util/commonAlerts';
import OrderEditDialog from 'pages/order/OrderEditDialog';
import { EventType } from 'plugins/eventBusPlugin';
import AppSelect from 'components/app/input/AppSelect';

export default {
  name: 'DuplicatedCustomersDialog',
  components: { AppSelect, Badge, Dialog },
  data() {
    return {
      loading: false,
      selectedPhone: null,
      data: [],
      customers: [],
    };
  },
  computed: {
    phoneOptions() {
      return this.data.map((d) => ({
        value: d,
        label: `${d.phone} (${d.count} ${this.$t('customers')})`,
      }));
    },
  },
  watch: {
    async selectedPhone(val) {
      this.customers = [];
      if (_.isEmpty(val)) return;
      await this.fetchCustomers(val);
    },
  },
  methods: {
    editCustomer({ id }) {
      this.$openModal(CustomerEditDialog, {
        id,
      });
    },
    async removeCustomer({ id }) {
      if (!(await commonAlerts.confirmOperation())) return;
      try {
        await this.$api.Customer.remove(id);
        this.$bus.$emit(EventType.CustomerChanged);
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    editOrder({ id }) {
      this.$openModal(OrderEditDialog, {
        id,
      });
    },
    async removeOrder({ id }) {
      if (!(await commonAlerts.confirmOperation())) return;
      try {
        await this.$api.Order.remove(id);
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    async fetchCustomers(selectedPhone) {
      this.customers = await this.$api.Customer.findAll({
        'id:in': selectedPhone.customerIds.join(','),
        eager: `[orders.[status]]`,
      });
    },
    async fetchData() {
      this.loading = true;
      try {
        this.data = await this.$api.Customer.fn({
          name: 'findCustomersWithDuplicatedContacts',
        });
        if (this.selectedPhone) {
          this.selectedPhone = this.data.find(
            (d) => d.phone === this.selectedPhone.phone
          );
          if (this.selectedPhone) {
            await this.fetchCustomers(this.selectedPhone);
          }
        }
      } finally {
        this.loading = false;
      }
    },
  },
  async created() {
    this.fetchData();
    this.$bus.$on(EventType.OrderChanged, this.fetchData);
    this.$bus.$on(EventType.CustomerChanged, this.fetchData);
  },
  beforeDestroy() {
    this.$bus.$off(EventType.OrderChanged, this.fetchData);
    this.$bus.$off(EventType.CustomerChanged, this.fetchData);
  },
};
</script>

<style scoped></style>
