<template>
  <Modal
    :show="true"
    :show-close="true"
    modalClasses="modal-lg"
    @close="$emit('close')"
  >
    <template v-slot:header>
      <h4 class="title title-up">{{ $t('integrations') }}</h4>
    </template>
    <fieldset v-if="!!business">
      <legend>
        {{ $t('wooCommerce') }}
      </legend>

      <div style="display: flex; align-items: center;">
        <div class="mr-3" style="white-space: nowrap;">
          {{ $t('orderCreation') }}
        </div>
        <Tooltip :content="$t('clickToCopyToClipboard')">
          <BaseInput
            type="text"
            :readonly="true"
            :value="wooOrderCreatedUrl"
            style="width: 100%;"
            copy-to-clipboard
          />
        </Tooltip>
      </div>
    </fieldset>
    <fieldset v-if="!!business">
      <legend>
        {{ $t('whatsApp') }}
      </legend>
      <div style="display: flex; align-items: center;">
        <div class="mr-3" style="white-space: nowrap;">
          {{ $t('mandeUmZap') }}
        </div>
        <Tooltip :content="$t('clickToCopyToClipboard')">
          <BaseInput
            :readonly="true"
            :value="mandeUmZapUrl"
            style="width: 100%;"
            copy-to-clipboard
          />
        </Tooltip>
      </div>
    </fieldset>
  </Modal>
</template>

<script>
export default {
  name: 'IntegrationDialog',
  data() {
    return {
      business: null,
    };
  },
  methods: {},
  computed: {
    wooOrderCreatedUrl() {
      return `${window.location.origin}/api/woo/orders?bKey=${this.business.token}`;
    },
    mandeUmZapUrl() {
      return `${window.location.origin}/api/whats/hooks/mandeUmZap?bKey=${this.business.token}`;
    },
  },
  async mounted() {
    const [business] = await this.$api.Business.findAll();
    this.business = business;
  },
};
</script>

<style scoped></style>
