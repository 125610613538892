<template>
  <CrudDialog :title="$t('payment')" :loading="loading" @save="save" @close="$emit('close')">
    <div class="row">
      <div class="col">
        <BaseInput name="id" :label="$t('id')" v-model.number="model.id" :readonly="true" />
      </div>
      <div class="col">
        <BaseInput name="date" :label="$t('date')">
          <AppDateTimePicker name="date" v-model="model.date" v-validate="'required'" type="date" />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="parcel" :label="$t('parcel')" required>
          <InputMoney
            name="parcel"
            v-model.number="model.parcel"
            v-validate="'required|min_value:1'"
            :prefix="''"
            :precision="0"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="value" :label="$t('value')" required>
          <InputMoney
            name="value"
            v-model.number="model.value"
            v-validate="'required|min_value:0'"
          />
        </BaseInput>
      </div>

      <div class="col-3">
        <BaseInput :label="$t('paidValue')">
          <div>
            {{ totalPaid | money }}
          </div>
        </BaseInput>
      </div>
      <div class="col-3">
        <BaseInput :label="$t('remainingValue')">
          <div>
            {{ (totalOrderValue - totalPaid) | money }}
          </div>
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="condition" :label="$t('condition')" required>
          <AppSelect
            name="condition"
            v-model="model.condition"
            :options="availableConditions"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="method" :label="$t('method')" required>
          <AppSelect
            name="method"
            v-model="model.method"
            :options="availableMethods"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput
          name="description"
          :label="$t('description')"
          v-model="model.description"
          v-validate="'max:512'"
        />
      </div>
      <div class="col-1 d-flex align-items-center">
        <BaseCheckbox name="deposit" v-model="model.deposit" :centralize="true">
          {{ $t('signal') }}
        </BaseCheckbox>
      </div>
      <div class="col-2 d-flex align-items-center">
        <BaseCheckbox name="validated" v-model="model.validated" :centralize="true">
          {{ $t('confirmed') }}
        </BaseCheckbox>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <BaseInput name="receipt" :label="$t('receipt')">
          <div>
            <AppAutoUpload
              v-model="model.receiptFileId"
              :preview="false"
              accept="application/pdf,image/*"
            />
          </div>
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import { mergeObjects } from 'util/utils';
import { OrderUi } from 'api/Models';
import AppAutoUpload from '@/components/app/input/AppAutoUpload';

export default {
  name: 'OrderPaymentEditDialog',
  components: { AppAutoUpload, AppDateTimePicker, InputMoney, AppSelect },
  mixins: [FormMixin],
  props: {
    entity: {},
    order: {},
    onSave: {},
  },
  data() {
    const parcel = this.order.payments.reduce((acc, p) => Math.max(acc, p.parcel), 0) + 1;

    const totalOrderValue = new OrderUi(this.order).totalValue();
    const totalPaid = new OrderUi(this.order).totalPaid();
    return {
      loading: false,
      totalOrderValue,
      totalPaid,
      model: mergeObjects(
        {},
        {
          parcel,
          value: Math.max(0, totalOrderValue - totalPaid),
          date: new Date(),
          description: '',
          validated: false,
          method: 'NÃO INFORMADO',
          condition: '',
          deposit: parcel === 1,
          receiptFileId: undefined,
        },
        this.entity
      ),
      availableMethods: [
        'NÃO INFORMADO',
        'TRANSFERÊNCIA',
        'TRANSFERÊNCIA - BRADESCO',
        'TRANSFERÊNCIA - OUTROS',
        'TRANSFERÊNCIA - SICREDI',
        'BOLETO',
        'CARTÃO',
        'DINHEIRO',
        'DESCONTO',
        'DEVENDO',
      ].map((value) => ({
        value,
        label: value,
      })),
      availableConditions: ['À VISTA', 'À PRAZO', 'DESCONTO/NÃO PAGOU'].map((value) => ({
        value,
        label: value,
      })),
    };
  },
};
</script>

<style scoped></style>
