import { nanoid } from 'nanoid';

export const fillObjectValuesWithKeyName = (obj) => {
  Object.keys(obj).forEach((k) => {
    obj[k] = k;
  });
  return obj;
};

export const formatNumber = function (value, style = 'currency') {
  if (_.isNumber(value)) {
    return `${value.toLocaleString('pt-BR', {
      style,
      currency: 'BRL',
    })}`;
  }
  return value;
};

export const formatDateTime = function (value, style = 'datetime') {
  let result = value || '';
  if (result) {
    const moment = window.moment.isMoment(result) ? result : window.moment(result);
    if (moment.isValid()) {
      let format = 'DD/MM/YYYY';
      if (style === 'dayMonth') {
        format = 'DD/MM';
      } else if (style === 'monthYear') {
        format = 'MM/YYYY';
      } else if (style === 'hourMin') {
        format = 'HH:mm';
      } else {
        if (style === 'datetime') {
          format += ' HH:mm';
        }
      }
      result = moment.format(format);
    }
  }
  return result;
};

export const fromNow = function (value) {
  let result = value || '';
  if (result) {
    const moment = window.moment(result);
    if (moment.isValid()) {
      result = moment.fromNow();
    }
  }
  return result;
};

export const formatOrderCode = (order) => {
  if (!order) {
    return '';
  }
  if (order.code) {
    return `#${order.code}`;
  } else {
    return `X${order.id}`;
  }
};

export const mergeObjects = (...objects) => {
  return _.mergeWith(...objects, (a, b) => (b === null ? a : undefined));
};

let baseUrl = location.origin;
if (process.env.NODE_ENV !== 'production') {
  baseUrl = baseUrl.replace('8080', '3000');
}

export const makeApiUrl = (uri) => {
  return `${baseUrl}${uri}`;
};

export const maxDate = () => {
  return new Date(9999, 1, 1);
};

export const generateUniqueId = () => {
  return nanoid();
};

export function stripNonDigits(val = '') {
  return val.replace(/\D/g, '');
}

export function uploadUrl(id) {
  return id ? `/api/uploads/${id}` : undefined;
}
