import i18n from 'i18n';

const customClass = {
  confirmButton: 'btn btn-fill btn-danger',
  cancelButton: 'btn btn-fill btn-success',
};

export const commonAlerts = {
  info(text, description) {
    return Swal.fire(text, description, 'success');
  },
  warn(text, description) {
    return Swal.fire(text, description, 'warning');
  },
  error(text, description) {
    return Swal.fire(text, description, 'error');
  },
  objectRemovedWithSuccess() {
    return Swal.fire({
      text: i18n.t('objectRemovedWithSuccess'),
      customClass,
      icon: 'success',
      buttonsStyling: false,
    });
  },
  defaultErrorMessage(e) {
    return Swal.fire({
      title: i18n.t('error'),
      text: _.get(e, 'response.data.message', e.message),
      customClass,
      confirmButtonText: i18n.t('close'),
      icon: 'error',
      buttonsStyling: false,
    });
  },
  async confirmOperation(params = {}) {
    const result = await Swal.fire({
      title: params.title || i18n.t('areYouSure'),
      text: params.text || i18n.t('thisOperationCannotBeReverted'),
      icon: 'warning',
      showCancelButton: true,
      customClass,
      confirmButtonText: i18n.t('yes'),
      cancelButtonText: params.cancelButtonText || i18n.t('cancel'),
      buttonsStyling: false,
    });
    return result.value === true;
  },
};
