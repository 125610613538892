<template>
  <CrudDialog
    :title="$t('product')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="ProductEditDialog"
  >
    <Tabs>
      <Tab>
        <div slot="label">
          {{ $t('general') }}
        </div>

        <div class="row">
          <div class="col-6">
            <BaseInput
              name="id"
              :label="$t('id')"
              v-model="model.id"
              :readonly="true"
              number
            />
          </div>
          <div class="col-6">
            <BaseInput name="provider" :label="$t('provider')">
              <AppSelect
                name="provider"
                value-key="id"
                v-model="model.provider"
                :options="availableProviders"
              />
            </BaseInput>
          </div>
        </div>

        <div class="row">
          <div class="col-6">
            <BaseInput
              name="name"
              :label="$t('name')"
              v-model="model.name"
              v-validate="'required|min:2|max:256'"
              required
            />
          </div>
          <div class="col-6">
            <BaseInput
              name="sku"
              :label="$t('sku')"
              v-model="model.sku"
              v-validate="'required|min:2|max:256'"
              required
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <BaseInput name="cost" :label="$t('cost')" required>
              <InputMoney
                name="cost"
                v-model.number="model.cost"
                v-validate="'required|min:0'"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="price" :label="$t('price')" required>
              <InputMoney
                name="price"
                v-model.number="model.price"
                v-validate="'required|min:0'"
              />
            </BaseInput>
          </div>
        </div>

        <div class="row">
          <div class="col-7">
            <BaseInput name="attributes" :label="$t('attributes')">
              <AppSelect
                size="large"
                name="attributes"
                value-key="id"
                v-model="model.attributes"
                :options="availableAttributes"
                multiple
              />
            </BaseInput>
          </div>
          <div class="col d-flex align-items-center">
            <BaseCheckbox
              name="validated"
              v-model="model.countOnShipping"
              :centralize="true"
            >
              {{ $t('countOnShipping') }}
            </BaseCheckbox>
          </div>
          <div class="col d-flex align-items-center">
            <BaseCheckbox
              name="rawMaterial"
              v-model="model.rawMaterial"
              :centralize="true"
            >
              {{ $t('rawMaterial') }}
            </BaseCheckbox>
          </div>
        </div>
      </Tab>

      <Tab>
        <div slot="label">
          {{ $t('materials') }}
        </div>
        <MaterialTable
          v-model="model.materials"
          :title="$t('materials')"
          :raw-material-only="true"
        />
      </Tab>

      <Tab>
        <div slot="label">
          {{ $t('images') }}
        </div>
        <div class="row">
          <div class="col-2">
            <BaseInput name="design" :label="$t('defaultImage')">
              <div>
                <AppAutoUpload
                  v-model="model.props.defaultImgId"
                  accept="image/*"
                />
              </div>
            </BaseInput>
          </div>

          <div class="col-10">
            <div class="row form-group">
              <div class="col">
                <label>
                  {{ $t('imageByAttribute') }}
                </label>
              </div>
              <div class="col-3 text-right">
                <BaseButton
                  size="sm"
                  @click="
                    model.props.attrImgs.push({
                      attributeValues: [],
                      imgId: null,
                    })
                  "
                >
                  <i class="fas fa-plus" />
                </BaseButton>
              </div>
            </div>

            <AppTable :data="model.props.attrImgs" max-height="400">
              <ElTableColumn :label="$t('attributes')">
                <template slot-scope="{ row }">
                  <BaseInput>
                    <AppSelect
                      name="attributes"
                      v-model="row.attributeValues"
                      :options="productAttributes"
                      :multiple="true"
                      :grouped="true"
                    >
                      <slot slot-scope="{ option }">
                        {{ option.item.value }}
                      </slot>
                    </AppSelect>
                  </BaseInput>
                </template>
              </ElTableColumn>
              <ElTableColumn :label="$t('image')" width="150">
                <template slot-scope="{ row }">
                  <AppAutoUpload v-model="row.imgId" accept="image/*" />
                </template>
              </ElTableColumn>
              <ElTableColumn width="75">
                <template slot-scope="{ $idx }">
                  <TableRemoveButton
                    @click="model.props.attrImgs.removeIdx($idx)"
                  />
                </template>
              </ElTableColumn>
            </AppTable>
          </div>
        </div>
      </Tab>

      <Tab>
        <div slot="label">
          {{ $t('priceList') }}
        </div>

        <div class="d-flex align-content-center justify-content-end">
          <BaseInput
            name="pricingAttributeValues"
            :label="$t('attributes')"
            inline
            style="width: 40%"
          >
            <AppSelect
              size="large"
              name="pricingAttributeValues"
              value-key="id"
              v-model="model.props.pricing.attributeValues"
              :options="productAttributes"
              :multiple="true"
              :grouped="true"
              :clearable="false"
              collapse-tags
            />
          </BaseInput>
          <div>
            <TableButton
              @click="newPriceListEntry"
              icon="fas fa-plus"
              type="info"
              :tooltip="$t('addBand')"
            />
          </div>
        </div>

        <ProductPricingTable
          :product-pricing="model.props.pricing"
          :edit-mode="true"
        />
      </Tab>
    </Tabs>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';
import { mergeObjects } from 'util/utils';
import AppAutoUpload from 'components/app/input/AppAutoUpload';
import Collapse from 'components/Collapse/Collapse';
import CollapseItem from 'components/Collapse/CollapseItem';
import MaterialTable from 'pages/product/attribute/MaterialTable';
import { PriceListEntryUi } from 'api/Models';
import Badge from 'components/Badge';
import TableButton from 'components/app/btn/TableButton';
import ProductPricingTable from 'pages/product/ProductPricingTable';

export default {
  name: 'ProductEditDialog',
  components: {
    ProductPricingTable,
    TableButton,
    Badge,
    MaterialTable,
    Collapse,
    CollapseItem,
    AppAutoUpload,
    InputMoney,
    AppSelect,
  },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        code: undefined,
        name: '',
        sku: '',
        price: 0.0,
        cost: 0.0,
        provider: undefined,
        countOnShipping: true,
        attributes: [],
        rawMaterial: false,
        materials: [],
        props: {
          defaultImgId: null,
          attrImgs: [],
          pricing: {
            attributeValues: [],
            list: [],
          },
        },
      },
      availableAttributes: [],
      availableProviders: [],
    };
  },
  methods: {
    async onSave() {
      await this.$api.Product.save(this.model);
      this.$bus.$emit(EventType.ProductChanged);
    },
    newPriceListEntry() {
      const { list } = this.model.props.pricing;
      const prevPrice = list[list.length - 1];
      const quantity = (prevPrice?.quantity || 25) * 2;
      const price = prevPrice?.price || this.model.price || 0;
      const prices = prevPrice ? _.cloneDeep(prevPrice.prices) : {};
      list.push(
        new PriceListEntryUi({
          quantity,
          price,
          prices,
        })
      );
    },
  },
  computed: {
    productAttributes() {
      const productAttributes = this.model.attributes;

      const result = this.availableAttributes
        .filter((option) =>
          productAttributes.some((a) => a.id === option.value.id)
        )
        .map((option) => {
          const attr = option.value;
          return {
            label: attr.getCaption(),
            options: _.sortBy(attr.values, 'value').map((val) => ({
              item: val,
              value: val.id,
              label: `${attr.getCaption()}: ${val.getCaption()}`,
            })),
          };
        });
      return result;
    },
  },
  async created() {
    this.availableAttributes = (
      await this.$api.Attribute.findAll({ eager: 'values' })
    ).map((a) => ({
      value: a,
      label: a.getCaption(),
      key: a.id,
    }));

    this.availableProviders = (
      await this.$api.Customer.findAll({
        provider: true,
        orderBy: 'name',
      })
    ).map((provider) => ({ value: provider, label: provider.name }));

    if (this.id) {
      try {
        this.loading = true;
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.Product.findById(this.id, {
            eager:
              '[attributes.[values], provider, materials.[product.[attributes.[values]], attributeValues]]',
          })
        );
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style lang="scss"></style>
