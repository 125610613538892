<template>
  <TagsInput :value="val" :editable="false" />
</template>

<script>
import TagsInput from 'components/Inputs/TagsInput';
export default {
  name: 'TagsCol',
  components: { TagsInput },
  props: {
    tableProps: {},
    colInfo: {},
  },
  computed: {
    val() {
      let values = _.get(this.tableProps.row, this.colInfo.prop) || [];
      if(!_.isArray(values)) {
          values = [values];
      }
      if (this.colInfo.toLabel) {
        values = values.map(this.colInfo.toLabel);
      }
      return values;
    },
  },
};
</script>

<style scoped></style>
