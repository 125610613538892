export class TempStorageApi {
  constructor($http) {
    this.$http = $http;
  }

  async set(content) {
    const { data } = await this.$http.post('/api/tempStorage', content);
    return data;
  }

  async get(id) {
    const { data } = await this.$http.get(`/api/tempStorage/${id}`);
    return data;
  }
}
