<template>
  <ElDropdown trigger="click" @command="moveOrderToStatus">
    <Tooltip :content="`${$t('move')} ${$t('order')}`">
      <slot>
        <BaseButton icon class="btn-link btn-github btn-simple" :size="size">
          <i class="fas fa-random" />
        </BaseButton>
      </slot>
    </Tooltip>

    <ElDropdownMenu slot="dropdown" class="MoveOrderToStatusButtonMenu">
      <div class="d-flex pl-2 pr-2">
        <MoveOrderToStatusButtonOptions
          v-for="funnelData in funnelOptions"
          v-if="funnelData"
          :key="funnelData.id"
          :data="funnelData"
          :order="order"
        />
      </div>
    </ElDropdownMenu>
  </ElDropdown>
</template>

<script>
import { commonAlerts } from 'util/commonAlerts';
import OrderCancelDialog from 'pages/order/OrderCancelDialog';
import { OrderActivityUi } from 'api/Models';
import MoveOrderToStatusButtonOptions from 'pages/funnel/MoveOrderToStatusButtonOptions';

export default {
  name: 'MoveOrderToStatusButton',
  components: { MoveOrderToStatusButtonOptions },
  props: {
    order: { required: true },
    funnels: { required: true },
    status: { required: true },
    size: { default: 'sm' },
  },
  data() {
    return {};
  },
  computed: {
    funnelOptions() {
      const idx = _.findIndex(
        this.funnels,
        (f) => f.id === this.status.funnelId
      );
      return [
        this.funnelData(this.funnels[idx - 1], (data) => {
          data.title = this.$t('previous');
          return data;
        }),
        this.funnelData(this.funnels[idx], (data) => {
          data.title = this.$t('current');
          return data;
        }),
        this.funnelData(
          this.funnels[idx + 1],
          (data) => {
            data.title = this.$t('next');
            return data;
          },
          true
        ),
      ];
    },
  },
  methods: {
    funnelData(funnel, customizer, createLastOnEmpty = false) {
      if (!funnel) {
        if (!createLastOnEmpty) return undefined;

        const finishedStatus = _.flatMap(
          this.funnels,
          (f) => f.statuses
        ).filter((os) => os.isFinished());
        return {
          id: -1,
          title: this.$t('next'),
          funnel: {
            name: this.$t('end'),
          },
          visible: finishedStatus.filter((os) => os.visible),
          notVisible: finishedStatus.filter((os) => !os.visible),
        };
      }

      const data = {
        id: funnel.id,
        funnel,
        visible: [],
        notVisible: [],
      };

      funnel.statuses.forEach((os) =>
        os.visible ? data.visible.push(os) : data.notVisible.push(os)
      );
      return customizer ? customizer(data) : data;
    },
    async moveOrderToStatus({ status }) {
      let order = this.order;
      if (
        !(await commonAlerts.confirmOperation({
          title: this.$t('attention'),
          text: this.$t('wishToMoveOrderToNewStatus', {
            order: order.orderCode,
            status: status.description,
          }),
        }))
      ) {
        return;
      }
      this.loading = true;
      try {
        let update = true;

        if (status.isCancelled()) {
          // If it is cancelled must collect the reason behind the cancellation
          update = await new Promise((resolve) => {
            this.$openModal(OrderCancelDialog, {
              order: this.order,
              resolve,
            });
          });
        } else if (status.isFinished()) {
          if (
            !order.hasOwnProperty('payments') ||
            !order.hasOwnProperty('items') ||
            !order.hasOwnProperty('shipments')
          ) {
            const {
              payments,
              items,
              shipments,
            } = await this.$api.Order.findById(order.id, {
              eager: '[payments, items, shipments]',
            });
            Object.assign(order, { payments, items, shipments });
          }

          const [validatedPayments] = order.classifiedPayments();
          const percentPaid = Math.ceil(validatedPayments.percent);
          if (percentPaid < 100) {
            // If the order is not 100% paid, then need to check if the user got proper permission to send
            // the order to a 'FINISHED' OrderStatus
            let sectors = status.props.sectors || [];
            if (!_.isEmpty(sectors)) {
              sectors = (
                await this.$api.Sector.findAll({
                  'id:in': sectors,
                  eager: 'users',
                })
              ).filter((s) =>
                s.users.some((u) => u.id === this.$store.state.user.id)
              );
              if (_.isEmpty(sectors)) {
                commonAlerts.warn(
                  `${this.$t('moveToFinishedStatusWarnMessage')}`
                );
                return;
              }
            }
          }
        }

        if (update) {
          const oldStatusId = this.order.statusId;

          await this.$api.Order.save({
            id: this.order.id,
            statusId: status.id,
          });

          await this.$api.OrderActivity.save({
            type: OrderActivityUi.Types.STATUS_CHANGE,
            orderId: order.id,
            userId: this.$store.state.user.id,
            props: {
              oldStatusId,
              newStatusId: status.id,
            },
          });
        }
      } catch (e) {
        console.error(e);
        await commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style>
.MoveOrderToStatusButtonMenu {
  max-height: 50vh;
  overflow: auto;
}
</style>
