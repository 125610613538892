<template>
  <ElTable
    :data="data"
    v-bind="$attrs"
    class="AppTable"
    :default-sort="defaultSort"
    @sort-change="sortChanged"
    ref="table"
  >
    <slot name="before" />
    <ElTableColumn
      v-for="col in columns"
      :key="col.prop"
      :prop="col.prop"
      :label="col.label || $t(col.prop)"
      v-bind="col.attrs || {}"
    >
      <template slot-scope="props" :col="col">
        <template v-if="col.render">
          {{ col.render(props) }}
        </template>
        <component
          v-else-if="col.component"
          :is="col.component"
          :tableProps="props"
          :colInfo="col"
        />
        <template v-else>
          {{ _.get(props.row, props.column.property) }}
        </template>
      </template>
    </ElTableColumn>
    <slot />
  </ElTable>
</template>
<script>
export default {
  name: 'AppTable',
  inheritAttrs: false,
  props: {
    columns: {},
    data: {},
    defaultSort: {},
  },
  data() {
    return {};
  },
  methods: {
    sortChanged(event) {
      this.$emit('sort-change', ...arguments);
    },
    sort(prop, order) {
      this.$refs.table.sort(prop, order);
    },
  },
};
</script>
<style>
.el-table.AppTable .el-table__header,
.el-table.AppTable .el-table__footer {
  margin-bottom: 0;
}
.el-table.AppTable .el-table__footer-wrapper td {
  background-color: unset;
}
.el-table.AppTable .el-table__header .cell {
  word-break: normal;
}

.el-table,
.el-table__expanded-cell {
  background-color: transparent;
}

.AppTable .BaseInput {
  margin-bottom: 0;
}
</style>
