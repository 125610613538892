import { axiosInstance } from 'api/AxiosInstance';
import { BrainApi } from 'api/BrainApi';
import { AuthApi } from 'api/AuthApi';
import { UserApi } from 'api/UserApi';
import { ViaCepApi } from 'api/ViaCepApi';
import { WooApi } from 'api/WooApi';
import { UploadApi } from 'api/UploadApi';
import {
  AccountingEntryUi,
  AttributeUi,
  CustomerEventUi,
  CustomerUi,
  FunnelUi,
  InitialContactUi,
  LogEntryUi,
  OrderActivityUi,
  OrderLayoutRequestUi,
  OrderStatusUi,
  OrderUi,
  ProductUi,
  ShippingMethodUi,
  StatusTypeUi,
  StockUi,
  TagUi,
  UserWhatsQueueConfigUi,
} from 'api/Models';
import { ConfApi } from 'api/ConfApi';
import i18n from 'i18n';
import { OrderPaymentApi } from 'api/OrderPaymentApi';
import { TempStorageApi } from 'api/TempStorageApi';
import { WhatsApi } from 'api/WhatsApi';
import { QuizApi } from 'api/QuizApi';
import { EnvApi } from 'api/EnvApi';

const $http = axiosInstance();

class OrderStatusApi extends BrainApi {
  constructor(props = {}) {
    super({
      resource: 'orderStatus',
      transform: (data) => new OrderStatusUi(data),
      defaultFindAllParams: {
        orderByDesc: 'visible',
        orderBy: ['funnel.order', 'funnel.name', 'order'],
      },
      ...props,
    });
  }

  async findGroupedForOptions() {
    return (
      await this.findAll({
        orderBy: ['order', 'description'],
      })
    ).reduce(
      (acc, val) => {
        acc[val.visible ? 0 : 1].options.push({
          value: val,
          label: val.description,
        });
        return acc;
      },
      [
        { label: i18n.t('visibles'), options: [] },
        { label: '', options: [] },
      ]
    );
  }
}

export const appApi = {
  $http,
  AccountingEntry: new BrainApi({
    resource: 'accountingEntries',
    transform: (data) => new AccountingEntryUi(data),
  }),
  AccountingEntryCategory: new BrainApi({
    resource: 'accountingEntryCategories',
    transform: (data) => data,
  }),
  Attribute: new BrainApi({
    resource: 'attributes',
    transform: (data) => new AttributeUi(data),
  }),
  Auth: new AuthApi(),
  Business: new BrainApi({ resource: 'business' }),
  Conf: new ConfApi(),
  Contact: new BrainApi({
    resource: 'contacts',
  }),
  Customer: new BrainApi({
    resource: 'customers',
    transform: (data) => new CustomerUi(data),
  }),
  CustomerEvent: new BrainApi({
    resource: 'customerEvents',
    transform: (data) => new CustomerEventUi(data),
  }),
  DesignWorkflow: new BrainApi({ resource: 'designWorkflows' }),
  DiscountCoupon: new BrainApi({ resource: 'discountCoupons' }),
  DbCache: new BrainApi({ resource: 'dbCaches' }),
  Env: new EnvApi(),
  EventProspection: new BrainApi({ resource: 'eventProspections' }),
  EventsToProspect: new BrainApi({ resource: 'eventsToProspect' }),
  EventType: new BrainApi({ resource: 'eventTypes' }),
  Funnel: new BrainApi({
    resource: 'funnels',
    defaultFindAllParams: {
      orderBy: ['order', 'name'],
    },
    transform: (data) => new FunnelUi(data),
  }),
  InitialContact: new BrainApi({
    resource: 'initialContacts',
    transform: (data) => new InitialContactUi(data),
  }),
  LogEntry: new BrainApi({
    resource: 'logEntries',
    transform: (data) => new LogEntryUi(data),
  }),
  MessageTemplate: new BrainApi({ resource: 'messageTemplates' }),
  Order: new BrainApi({
    resource: 'orders',
    transform: (data) => new OrderUi(data),
  }),
  OrderActivity: new BrainApi({
    resource: 'orderActivities',
    transform: (data) => new OrderActivityUi(data),
  }),
  OrderLayoutRequest: new BrainApi({
    resource: 'orderLayoutRequests',
    transform: (data) => new OrderLayoutRequestUi(data),
  }),
  OrderLayoutResponse: new BrainApi({
    resource: 'orderLayoutResponses',
    //transform: data => new OrderUi(data),
  }),
  OrderPayment: new OrderPaymentApi(),
  OrderStatus: new OrderStatusApi(),
  Product: new BrainApi({
    resource: 'products',
    transform: (data) => new ProductUi(data),
  }),
  Quiz: new QuizApi(),
  Sector: new BrainApi({ resource: 'sectors' }),
  ShippingMethod: new BrainApi({
    resource: 'shippingMethods',
    transform: (data) => new ShippingMethodUi(data),
  }),
  StatusAutomation: new BrainApi({ resource: 'statusAutomations' }),
  StatusType: new BrainApi({
    resource: 'statusTypes',
    transform: (data) => new StatusTypeUi(data),
  }),
  Stock: new BrainApi({
    resource: 'stocks',
    transform: (data) => new StockUi(data),
  }),
  Tag: new BrainApi({
    resource: 'tags',
    transform: (data) => new TagUi(data),
  }),
  TempStorage: new TempStorageApi($http),
  Upload: new UploadApi($http),
  User: new UserApi(),
  UserWhatsQueueConfig: new BrainApi({
    resource: 'userWhatsQueueConfig',
    transform: (data) => new UserWhatsQueueConfigUi(data),
  }),
  ViaCep: new ViaCepApi(),
  Whats: new WhatsApi(),
  WooApi: new WooApi(),
};

window.$appApi = appApi;
