import { axiosInstance } from 'api/AxiosInstance';

const $http = axiosInstance();

export class ViaCepApi {
  async findBy(postalCode) {
    const response = await $http.get(`/api/viaCep`, {
      params: {
        postalCode,
      },
    });
    return response.data;
  }
}
