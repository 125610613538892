<template>
  <ElPopover placement="bottom" trigger="click" width="500" popper-class="is-dark">
    <div v-loading="loading">
      <BaseInput :label="$t('tags')">
        <AppSelect v-model="model.tags" :options="tagOpts" multiple>
          <template v-slot="{ option: { entity, label } }">
            <Badge type="transparent" :style="entity.buildStyle()" pill>
              {{ label }}
            </Badge>
          </template>
        </AppSelect>
      </BaseInput>
      <BaseInput :label="$t('users')">
        <AppSelect v-model="model.users" :options="userOpts" multiple />
      </BaseInput>
      <BaseInput class="mt-2">
        <BaseCheckbox v-model="model.hideAlreadyAnswered">
          {{ $t('hideAlreadyAnswered') }} (<i class="fas fa-check text-success" />)
        </BaseCheckbox>
      </BaseInput>
    </div>
    <Tooltip :content="$t('filters')" slot="reference">
      <BaseButton
        icon
        simple
        link
        :type="isFiltered ? 'info' : 'github'"
        :class="['m-0', { isFiltered }]"
      >
        <i class="fas fa-filter" />
      </BaseButton>
    </Tooltip>
  </ElPopover>
</template>

<script>
import AppSelect from 'components/app/input/AppSelect';
import Badge from 'components/Badge';

export default {
  name: 'WhatsQueueFilterButton',
  components: { Badge, AppSelect },
  props: {
    initialState: {},
  },
  data() {
    return {
      loading: false,
      model: {
        tags: this.initialState?.tags || [],
        users: this.initialState?.users || [],
        hideAlreadyAnswered: this.initialState?.hideAlreadyAnswered ?? false,
      },
      tagOpts: [],
      userOpts: [],
    };
  },
  computed: {
    isFiltered() {
      return !_.isEmpty(this.model.tags) || !_.isEmpty(this.model.users);
    },
  },
  watch: {
    model: {
      deep: true,
      handler(val) {
        this.$emit('filter', val);
      },
    },
  },
  async created() {
    try {
      this.tagOpts = (await this.$api.Tag.findAll({ orderBy: 'name' })).map((value) => ({
        value: value.id,
        label: value.name,
        entity: value,
      }));
      this.userOpts = (await this.$api.User.findAll({ orderBy: 'name' })).map((value) => ({
        value: value.id,
        label: value.name,
        entity: value,
      }));
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped>
.isFiltered .fas {
  text-shadow: 0 0 2px #ffffff;
}

.fas.fa-filter {
  font-size: 0.82em !important;
}
</style>
