class Cache {
  constructor(storage) {
    this.storage = storage;
  }

  put(key, value) {
    this.storage.setItem(key, JSON.stringify(value));
  }

  exists(key) {
    const val = this.storage.getItem(key);
    return !!val;
  }

  get(key) {
    let val = this.storage.getItem(key);
    if (val) {
      val = JSON.parse(val);
    }
    return val;
  }

  remove(key) {
    this.storage.removeItem(key);
  }

  clear() {
    this.storage.clear();
  }
}

export const appCache = new Cache(window.localStorage);
export const appTabCache = new Cache(window.sessionStorage);
