var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"OrderToggleablePropsButton"},[_c('Tooltip',{attrs:{"content":_vm.$t('paintAlert')}},[_c('BaseButton',{class:[
        'btn-link',
        'FloatingButton',
        'PaintButton',
        { Enabled: _vm._.get(_vm.order, 'props.painted', false) },
      ],attrs:{"icon":"","size":"sm"},on:{"click":function($event){return _vm.toggleModelProp(_vm.order, 'painted')}}},[_c('i',{staticClass:"fas fa-paint-brush"})])],1),_c('Tooltip',{attrs:{"content":_vm.$t('drawnAlert')}},[_c('BaseButton',{class:[
        'btn-link',
        'FloatingButton',
        'DrawnButton',
        { Enabled: _vm._.get(_vm.order, 'props.drawn', false) },
      ],attrs:{"icon":"","size":"sm"},on:{"click":function($event){return _vm.toggleModelProp(_vm.order, 'drawn')}}},[_c('i',{staticClass:"fas fa-hammer"})])],1),_c('Tooltip',{attrs:{"content":_vm.$t('ribbonAlert')}},[_c('BaseButton',{class:[
        'btn-link',
        'FloatingButton',
        'RibbonButton',
        { Enabled: _vm._.get(_vm.order, 'props.ribbons', false) },
      ],attrs:{"icon":"","size":"sm"},on:{"click":function($event){return _vm.toggleModelProp(_vm.order, 'ribbons')}}},[_c('i',{staticClass:"fas fa-genderless"})])],1),_c('Tooltip',{attrs:{"content":_vm.$t('toggleAlert')}},[_c('BaseButton',{class:[
        'btn-link',
        'AlertButton',
        { Enabled: _vm._.get(_vm.order, 'props.alert', false) },
      ],attrs:{"icon":"","size":"sm"},on:{"click":function($event){return _vm.toggleModelProp(_vm.order, 'alert')}}},[_c('i',{staticClass:"fas fa-exclamation-triangle"})])],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }