<template>
  <base-nav v-model="showMenu" class="navbar-absolute top-navbar" type="white" :transparent="true">
    <div slot="brand" class="navbar-wrapper">
      <div class="navbar-minimize d-inline">
        <sidebar-toggle-button />
      </div>
      <div class="navbar-toggle d-inline" :class="{ toggled: $sidebar.showSidebar }">
        <button type="button" class="navbar-toggler" @click="toggleSidebar">
          <span class="navbar-toggler-bar bar1"></span>
          <span class="navbar-toggler-bar bar2"></span>
          <span class="navbar-toggler-bar bar3"></span>
        </button>
      </div>
      <a class="navbar-brand" href="#">{{ $t(routeName) }}</a>
    </div>

    <ul class="navbar-nav" :class="'ml-auto'">
      <li>
        <Tooltip :content="$t('WhatsActivity')">
          <BaseButton type="github" simple link icon @click="openEventsDialog">
            <i class="fas fa-address-book mr-2" />
          </BaseButton>
        </Tooltip>
        <RouterLink to="/whatsQueue">
          <BaseButton type="success" link class="m-0">
            <i class="fab fa-whatsapp mr-2" />
            {{ $t('whatsApp') }}
          </BaseButton>
        </RouterLink>
      </li>
      <li>
        <div class="OrderSearchContainer">
          <div class="position-relative d-flex align-items-center">
            <BaseInput
              class="m-0"
              type="text"
              name="orderDialog.search"
              v-model="orderDialog.search"
              :placeholder="`${$t('orderSearch')}...`"
              :disable-validation="true"
              @keypress.enter="orderDialog.open = true"
            />
            <BaseButton @click="orderDialog.open = true" icon>
              <i class="fas fa-search"></i>
            </BaseButton>
          </div>

          <Dialog v-if="orderDialog.open" @close="orderDialog.open = false" :title="$t('orders')">
            <Orders :initial-per-page="5" :initial-query="orderDialog.search" />
          </Dialog>
        </div>
      </li>
      <BaseDropdown
        tag="li"
        :menu-on-right="true"
        title-tag="a"
        class="nav-item"
        title-classes="nav-link"
        menu-classes="dropdown-navbar"
      >
        <template slot="title">
          <i class="fas fa-user-circle" />
          <b class="caret d-none d-lg-block d-xl-block"></b>
          <p class="d-lg-none"></p>
        </template>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" @click.prevent>
            <b>{{ user.name }}</b>
          </a>
        </li>
        <div class="dropdown-divider"></div>
        <li class="nav-link">
          <a href="#" class="nav-item dropdown-item" @click.prevent="logout">
            {{ $t('logout') }}
          </a>
        </li>
      </BaseDropdown>
    </ul>
  </base-nav>
</template>
<script>
import { CollapseTransition } from 'vue2-transitions';
import { BaseNav, Modal } from 'components';
import SidebarToggleButton from 'pages/Layout/SidebarToggleButton';
import Orders from 'pages/order/Orders';
import Dialog from 'components/app/Dialog';
import WhatsEventsDialog from 'pages/whatsQueue/WhatsEventsDialog';

export default {
  components: {
    Dialog,
    Orders,
    SidebarToggleButton,
    CollapseTransition,
    BaseNav,
    Modal,
  },
  computed: {
    routeName() {
      const { name } = this.$route;
      return name; //this.capitalizeFirstLetter(name);
    },
    user() {
      return this.$store.state.user;
    },
  },
  data() {
    return {
      activeNotifications: false,
      showMenu: false,
      searchModalVisible: false,
      searchQuery: '',
      orderDialog: {
        open: false,
        search: '',
      },
    };
  },
  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    toggleNotificationDropDown() {
      this.activeNotifications = !this.activeNotifications;
    },
    closeDropDown() {
      this.activeNotifications = false;
    },
    toggleSidebar() {
      this.$sidebar.displaySidebar(!this.$sidebar.showSidebar);
    },
    hideSidebar() {
      this.$sidebar.displaySidebar(false);
    },
    toggleMenu() {
      this.showMenu = !this.showMenu;
    },
    logout() {
      this.$store.commit('logout');
    },
    openEventsDialog() {
      this.$openModal(WhatsEventsDialog, {});
    },
  },
};
</script>
<style scoped>
.top-navbar {
  top: 0px;
}
</style>
