<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  />
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import EventTypeEditDialog from 'pages/eventType/EventTypeEditDialog';

export default {
  name: 'EventTypes',
  props: {
    providerView: {
      default: false,
    },
  },
  data() {
    return {
      dialog: EventTypeEditDialog,
      eventsToListen: [EventType.EventTypeChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'name', label: this.$t('type') },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|name:ilike'] = `%${query}%`;
      }
      return await this.$api.EventType.findAll({
        orderBy: ['name'],
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.EventType.remove(row.id);
    },
  },
};
</script>
<style scoped lang="scss"></style>
