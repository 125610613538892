<template>
  <CrudDialog
    :title="$t('shippingMethod')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col-6">
        <BaseInput
          type="text"
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
          number
        />
      </div>
      <div class="col-6">
        <BaseInput
          type="text"
          name="code"
          :label="$t('code')"
          v-model.number="model.code"
          :readonly="true"
          number
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput
          type="text"
          name="description"
          :label="$t('description')"
          v-model="model.description"
          v-validate="'required|min:2|max:256'"
          required
        />
      </div>
      <div class="col">
        <BaseInput
          :label="$t('defaultShippingTimeInDays')"
          name="defaultDays"
          v-model.number="model.props.defaultDays"
          v-mask="'###'"
          v-validate="'min:1'"
          :data-vv-as="$t('defaultShippingTimeInDays')"
        />
      </div>
      <div class="col">
        <BaseInput name="defaultValue" :label="$t('defaultValue')">
          <InputMoney
            name="defaultValue"
            v-model.number="model.props.defaultValue"
            v-validate="'min:0'"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <Tooltip :content="$t('trackingLinkHint')">
          <BaseInput
            type="text"
            name="trackingLink"
            :label="$t('trackingLink')"
            v-model="model.props.trackingLink"
          />
        </Tooltip>
      </div>
    </div>

    <hr />

    <div class="row">
      <div class="col">
        <h4>{{ $t('cepConfigs') }}</h4>
      </div>
      <div class="col text-right">
        <BaseButton @click="addPostalCodeRange()" icon>
          <i class="fas fa-plus" />
        </BaseButton>
      </div>
    </div>

    <AppTable :data="model.props.postalCodeRanges" max-height="600">
      <ElTableColumn :label="$t('initialCep')">
        <template slot-scope="props">
          <BaseInput
            :name="`initialCep${props.$index}`"
            v-model="props.row.initialCode"
            v-mask="'#####-###'"
            placeholder="#####-###"
            v-validate="'required|min:9|max:9'"
            :data-vv-as="$t('initialCep')"
          />
        </template>
      </ElTableColumn>
      <ElTableColumn :label="$t('finalCep')">
        <template slot-scope="props">
          <BaseInput
            :name="`finalCep${props.$index}`"
            v-model="props.row.finalCode"
            v-mask="'#####-###'"
            placeholder="#####-###"
            v-validate="'required|min:9|max:9'"
            :data-vv-as="$t('finalCep')"
          />
        </template>
      </ElTableColumn>
      <ElTableColumn :label="$t('shippingTimeInDays')">
        <template slot-scope="props">
          <BaseInput
            :name="`days${props.$index}`"
            v-model.number="props.row.days"
            v-mask="'###'"
            v-validate="'required|min:1'"
            :data-vv-as="$t('shippingTime')"
          />
        </template>
      </ElTableColumn>

      <ElTableColumn :label="$t('actions')" :width="100">
        <template slot-scope="props">
          <Tooltip :content="$t('quantityRanges')">
            <BaseButton
              size="sm"
              icon
              class="btn-link btn-warning"
              @click="editQuantityRanges(props.row)"
              :badge-value="
                props.row.quantityRanges ? props.row.quantityRanges.length : 0
              "
            >
              <i class="fas fa-dolly-flatbed" />
            </BaseButton>
          </Tooltip>
          <TableRemoveButton @click="removePostalCodeRange(props)" />
        </template>
      </ElTableColumn>
    </AppTable>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import { mergeObjects } from 'util/utils';
import InputMoney from 'components/app/input/InputMoney';
import PostalCodeRangeQuantityRangeEditDialog from 'pages/shippingMethod/PostalCodeRangeQuantityRangeEditDialog';

export default {
  name: 'ShippingMethodEditDialog',
  components: { InputMoney },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        code: undefined,
        description: '',
        props: {
          defaultDays: '',
          defaultValue: 0,
          postalCodeRanges: [],
          trackingLink: '',
        },
      },
    };
  },
  methods: {
    async onSave() {
      await this.$api.ShippingMethod.save(this.model);
      this.$bus.$emit(EventType.ShippingMethodChanged);
    },
    addPostalCodeRange() {
      this.model.props.postalCodeRanges.push(this.defaultPostalCodeRange());
    },
    removePostalCodeRange({ $index }) {
      this.model.props.postalCodeRanges.removeIdx($index);
    },
    defaultPostalCodeRange() {
      return {
        initialCode: '',
        finalCode: '',
        days: this.model.props.defaultDays,
        quantityRanges: [
          [10, 30],
          [31, 60],
          [61, 100],
          [101, 200],
          [201, 500],
          [501, 1000],
        ].map(([min, max]) => {
          return { min, max, value: this.model.props.defaultValue || 0 };
        }),
      };
    },
    editQuantityRanges(postalCodeRange) {
      this.$openModal(PostalCodeRangeQuantityRangeEditDialog, {
        entity: _.cloneDeep(postalCodeRange.quantityRanges),
        onSave(quantityRanges) {
          postalCodeRange.quantityRanges = quantityRanges;
        },
      });
    },
  },
  async mounted() {
    if (this.id) {
      this.loading = true;
      try {
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.ShippingMethod.findById(this.id)
        );
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
