<template>
  <div
    id="accordion"
    role="tablist"
    aria-multiselectable="true"
    class="card-collapse"
  >
    <slot></slot>
  </div>
</template>

<script>
export default {
  name: 'Collapse',
  props: {
    animationDuration: {
      type: Number,
      default: 250,
    },
    multipleActive: {
      type: Boolean,
      default: true,
    },
    activeIndex: {
      type: Number | Array,
      default: -1,
    },
  },
  provide() {
    return {
      animationDuration: this.animationDuration,
      multipleActive: this.multipleActive,
      addItem: this.addItem,
      removeItem: this.removeItem,
      deactivateAll: this.deactivateAll,
    };
  },
  data() {
    return {
      items: [],
    };
  },
  methods: {
    addItem(item) {
      const index = this.$slots.default.indexOf(item.$vnode);
      if (index !== -1) {
        this.items.splice(index, 0, item);
      }
    },
    removeItem(item) {
      const items = this.items;
      const index = items.indexOf(item);
      if (index > -1) {
        items.splice(index, 1);
      }
    },
    deactivateAll() {
      this.items.forEach(item => {
        item.active = false;
      });
    },
    activateItems() {
      if (this.activeIndex !== -1 || !_.isEmpty(this.activeIndex)) {
        let indexes = this.activeIndex;
        if (!_.isArray(indexes)) {
          indexes = [indexes];
        }
        indexes.forEach(idx => {
          const x = this.items[idx];
          if (x) {
            x.active = true;
          }
        });
      }
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.activateItems();
    });
  },
  watch: {
    activeIndex() {
      this.activateItems();
    },
  },
};
</script>

<style scoped></style>
