import { makeApiUrl } from 'util/utils';
import { appEventBus } from 'plugins/eventBusPlugin';

class WsClient {
  eventSource = null;
  $store = null;

  start = ($store) => {
    this.$store = $store;

    this.__interval = setInterval(this.connect, 5000);
    this.connect();
  };

  stop = () => {
    clearInterval(this.__interval);
  };

  closeEventSource = () => {
    try {
      this.eventSource?.close();
    } catch (e) {
      console.error('Error on closeEventSource()', e);
    }

    this.eventSource = null;
  };

  connect = () => {
    if (this.__connecting === true || this.eventSource) {
      return;
    }

    this.__connecting = true;
    try {
      const userNotLoggedIn = !this.$store.state.user;

      if (userNotLoggedIn) {
        return;
      }

      const socketUrl = makeApiUrl('/api/sse');
      console.log(`[WsClient] Connecting to url: ${socketUrl}`);
      this.eventSource = new EventSource(`${socketUrl}?uKey=${this.$store.state.token}`);
      this.eventSource.onerror = (e) => {
        console.error('eventSource error', e);
        this.closeEventSource();
      };

      this.eventSource.onmessage = (e) => {
        if (userNotLoggedIn) {
          this.closeEventSource();
          return;
        }

        const { event, data } = JSON.parse(e.data);
        switch (event) {
          case 'Ping': {
            break
          }
          case 'Brain': {
            appEventBus.$emit(`${data.model}Changed`, data);
            break;
          }
          default: {
            appEventBus.$emit(event, data);
            break;
          }
        }
      };
    } finally {
      this.__connecting = false;
    }
  };
}

export const wsClient = new WsClient();
