<template>
  <div class="OrderToggleablePropsButton">
    <Tooltip :content="$t('paintAlert')">
      <BaseButton
        icon
        size="sm"
        :class="[
          'btn-link',
          'FloatingButton',
          'PaintButton',
          { Enabled: _.get(order, 'props.painted', false) },
        ]"
        @click="toggleModelProp(order, 'painted')"
      >
        <i class="fas fa-paint-brush"></i>
      </BaseButton>
    </Tooltip>

    <Tooltip :content="$t('drawnAlert')">
      <BaseButton
        icon
        size="sm"
        :class="[
          'btn-link',
          'FloatingButton',
          'DrawnButton',
          { Enabled: _.get(order, 'props.drawn', false) },
        ]"
        @click="toggleModelProp(order, 'drawn')"
      >
        <i class="fas fa-hammer"></i>
      </BaseButton>
    </Tooltip>

    <Tooltip :content="$t('ribbonAlert')">
      <BaseButton
        icon
        size="sm"
        :class="[
          'btn-link',
          'FloatingButton',
          'RibbonButton',
          { Enabled: _.get(order, 'props.ribbons', false) },
        ]"
        @click="toggleModelProp(order, 'ribbons')"
      >
        <i class="fas fa-genderless"></i>
      </BaseButton>
    </Tooltip>
    <Tooltip :content="$t('toggleAlert')">
      <BaseButton
        icon
        size="sm"
        :class="[
          'btn-link',
          'AlertButton',
          { Enabled: _.get(order, 'props.alert', false) },
        ]"
        @click="toggleModelProp(order, 'alert')"
      >
        <i class="fas fa-exclamation-triangle" />
      </BaseButton>
    </Tooltip>
  </div>
</template>

<script>
import { commonAlerts } from 'util/commonAlerts';
import SeeActivitiesButton from 'pages/funnel/SeeActivitiesButton';
import OrderPaymentsProgressBar from 'pages/funnel/OrderPaymentsProgressBar';
import MoveOrderToStatusButton from 'pages/funnel/MoveOrderToStatusButton';
import OrderLayoutButton from 'pages/order/OrderLayoutButton';

export default {
  name: 'OrderToggleablePropsButton',
  components: {
    OrderLayoutButton,
    MoveOrderToStatusButton,
    OrderPaymentsProgressBar,
    SeeActivitiesButton,
  },
  props: {
    order: { required: true },
  },
  data() {
    return {};
  },
  methods: {
    async toggleModelProp(order, propName) {
      this.$emit('loadStart');
      try {
        const model = _.pick(order, ['id', 'props']);
        model.props[propName] = !_.get(model, `props.${propName}`, false);
        await this.$api.Order.save(model);
        order.props[propName] = model.props[propName];
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.$emit('loadEnd');
      }
    },
  },
};
</script>

<style scoped lang="scss">
.OrderToggleablePropsButton {
  position: absolute;

  @keyframes OrderToggleablePropsButtonGlow {
    0% {
      text-shadow: 0 0 1px white;
    }
    50% {
      text-shadow: 0 0 4px white;
    }
    100% {
      text-shadow: 0 0 1px white;
    }
  }

  button {
    margin-top: 0;
    margin-bottom: 0;
    height: 24px;
    color: rgba(255, 255, 255, 0.8) !important;
  }

  .FloatingButton {
    font-size: 0.8rem;

    &.Enabled * {
      animation: OrderToggleablePropsButtonGlow 2000ms infinite;
    }
  }

  .RibbonButton {
    font-size: 1.5rem;

    &.Enabled * {
      color: #3d9970 !important;
    }
  }

  .PaintButton {
    &.Enabled * {
      color: #0074d9 !important;
    }
  }

  .DrawnButton {
    &.Enabled * {
      color: #333 !important;
    }
  }

  button.AlertButton.Enabled * {
    animation: OrderToggleablePropsButtonGlow 2000ms infinite;
    color: #d63031 !important;
  }
}
</style>
