<template>
  <div>
    <div>
      {{ row.product.name }}
    </div>

    <AttributeValueBadge
      v-for="attributeValue in _.sortBy(row.attributes, 'attribute.name')"
      :key="attributeValue.id"
      :attribute-value="attributeValue"
    />
  </div>
</template>

<script>
import Badge from 'components/Badge';
import AttributeValueBadge from 'pages/order/AttributeValueBadge';
export default {
  name: 'OrderProductTableColumn',
  components: { AttributeValueBadge, Badge },
  props: {
    tableProps: {},
    colInfo: {},
  },
  computed: {
    row() {
      return this.tableProps.row;
    },
  },
};
</script>

<style scoped></style>
