import { axiosInstance } from 'api/AxiosInstance';

const $http = axiosInstance();

export class EnvApi {
  DEFAULT_COMPANY_NAME = 'catapulta';

  async findEnv() {
    const response = await $http.get(`/api/env`);
    return response.data;
  }
}
