<template>
  <div
    :class="['ImageUpload', { DragOver: drag.over, [`size-x${size}`]: true }]"
    @dragenter="dragEntered"
    @dragover="dragOver"
    @drop="drag.over = false"
  >
    <div class="position-relative">
      <span class="btn btn-simple btn-file btn-sm">
        <a
          :href="fileExists && !preview ? src : '#'"
          :target="fileExists && !preview ? '_blank' : ''"
          :class="[
            'img-circle d-flex align-items-center justify-content-center',
            {
              Upload: !fileExists,
              Image: fileExists && preview,
              File: fileExists && !preview,
            },
          ]"
        >
          <ExpandableImage
            :src="image"
            alt="preview"
            v-if="fileExists && preview"
            :hide-expand="drag.over"
          />

          <i class="fas fa-download" v-else-if="fileExists && !preview" />

          <i v-else class="fas fa-file-upload UploadIcon" />
        </a>
        <input type="hidden" value="" name="" />
        <input
          :accept="accept"
          @change="handlePreview"
          type="file"
          name="..."
          class="valid"
          :multiple="false"
          aria-invalid="false"
          @click="fileInputClicked"
        />
      </span>
      <BaseButton
        v-if="fileExists"
        @click="removeFile"
        type="danger"
        size="sm"
        icon
        class="RemoveBtn"
      >
        <i class="fas fa-times" />
      </BaseButton>
    </div>
  </div>
</template>
<script>
import ExpandableImage from 'components/app/ExpandableImage';

export default {
  name: 'ImageUpload',
  components: { ExpandableImage },
  props: {
    src: {
      type: String,
      default: '',
      description: 'Initial image to display',
    },
    accept: {
      type: String,
      default: '*/*',
    },
    preview: {
      type: Boolean,
      default: true,
    },
    size: { default: 1 },
  },
  data() {
    return {
      imagePreview: null,
      drag: {
        over: false,
      },
    };
  },
  computed: {
    fileExists() {
      return this.imagePreview !== null || this.src;
    },
    image() {
      return this.imagePreview || this.src;
    },
  },
  methods: {
    handlePreview(event) {
      const { target } = event;
      let file = target.files[0];
      this.imagePreview = file.type.includes('image')
        ? URL.createObjectURL(file)
        : null;
      this.$emit('change', file);
      target.value = '';
    },
    removeFile() {
      this.imagePreview = null;
      this.$emit('change', null);
    },
    dragEntered(event) {
      this.drag.over = true;
      this._dragTimeout = setTimeout(() => (this.drag.over = false), 1000);
    },
    dragOver(event) {
      this.drag.over = true;
      clearTimeout(this._dragTimeout);
      this._dragTimeout = setTimeout(() => (this.drag.over = false), 100);
    },
    fileInputClicked(event) {
      if (this.fileExists) {
        event.preventDefault();
        if (!this.preview) {
          window.open(this.src, '_blank');
        }
      }
    },
  },
};
</script>
<style lang="scss">
.ImageUpload {
  $size: 60px;
  $fontSize: 1.2rem;

  display: inline-block;
  cursor: pointer;
  transition: all ease-in-out 500ms;

  &.DragOver {
    transform: scale(1.3);

    .expand-button {
      display: none;
    }
  }

  i,
  input {
    cursor: pointer;
  }

  .img-circle.Upload,
  .img-circle.File {
    border: 2px solid white;
  }

  .img-circle.File {
    border-color: #ba54f5;
    color: #ba54f5;
  }

  .img-circle {
    cursor: pointer;
    color: #ffffff;
    font-size: $fontSize;
    border-radius: 100%;
    width: 60px;
    height: 60px;
    object-fit: cover;
    overflow: hidden;
    .expand-button {
      left: 0;
      top: 0;
      width: $size;
      height: $size;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    img {
      width: 60px;
      height: 60px;
      object-fit: cover;
    }
  }
  .thumbnail {
    box-shadow: none;
  }
  .btn.btn-icon.btn-sm.RemoveBtn {
    position: absolute !important;
    bottom: 0;
    right: 0;
    min-width: unset;
    width: 18px;
    height: 18px;
    z-index: 5;
  }
  .btn {
    border: none;
  }

  &.size-x4 {
    $size: $size * 4;
    $fontSize: $fontSize * 4;
    .img-circle {
      font-size: $fontSize;
      width: $size;
      height: $size;
      .expand-button {
        width: $size;
        height: $size;
      }
      img {
        width: $size;
        height: $size;
      }
    }
  }
}
.el-loading-parent--relative {
  .img-circle {
    border: none !important;
  }
  .img-circle *,
  .RemoveBtn {
    display: none;
  }
}
</style>
