<template>
  <CrudDialog
    :title="$t('user')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <BaseInput
      type="text"
      name="id"
      :label="$t('id')"
      v-model="model.id"
      :readonly="true"
    />

    <div class="row">
      <div class="col-6">
        <BaseInput
          type="text"
          name="username"
          :label="$t('user')"
          v-model="model.username"
          v-validate="'required|min:2|alpha_dash'"
          required
        />
      </div>
      <div class="col-6">
        <BaseInput
          type="email"
          name="email"
          :label="$t('email')"
          v-validate="'email'"
          v-model="model.email"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <BaseInput
          name="name"
          :label="$t('displayName')"
          v-validate="'required|min:2'"
          v-model="model.name"
          required
        />
      </div>
      <div class="col-6">
        <BaseInput name="role" :label="$t('role')">
          <ElSelect
            name="role"
            class="select-primary"
            size="large"
            :placeholder="$t('selectOne')"
            v-model="model.role"
            v-validate="'required'"
          >
            <ElOption
              v-for="option in ['ADMIN', 'USER', 'SELLER', 'DESIGNER']"
              class="select-primary"
              :value="option"
              :label="$t(option)"
              :key="option"
            />
          </ElSelect>
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <BaseInput
          :label="$t('password')"
          v-validate="`${isNew ? 'required|' : ''}min:8`"
          required
          type="password"
          name="password"
          ref="password"
          v-model="model.password"
          autocomplete="new-password"
        />
      </div>
      <div class="col-6">
        <BaseInput
          :label="$t('confirmPassword')"
          required
          type="password"
          name="confirmPassword"
          v-model="model.confirmPassword"
          v-validate="'confirmed:password'"
          autocomplete="new-password"
        />
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';

export default {
  name: 'UserEditDialog',
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: null,
        name: '',
        username: '',
        email: '',
        role: 'USER',
        password: '',
        confirmPassword: '',
      },
    };
  },
  methods: {
    async onSave() {
      await this.$api.User.saveUser(this.model);
      this.$bus.$emit(EventType.UserChanged);
    },
  },
  async mounted() {
    if (this.id) {
      try {
        this.loading = true;
        this.model = await this.$api.User.brain.findById(this.id);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
