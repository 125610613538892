<template>
  <Dialog
    class="OrderLayoutDialog"
    @close="$emit('close')"
    :title="`${$t('layoutRequests')} ${$t('order')}: ${model.orderCode}`"
    :loading="loading"
  >
    <div class="RequestDesignContainer text-right">
      <BaseButton
        type="warning"
        @click="requestDesign()"
        :disabled="requests.some((lr) => !lr.finished)"
        size="sm"
      >
        {{ $t('requestDesign') }}
      </BaseButton>
    </div>

    <div class="LayoutRequests">
      <div
        v-if="_.isEmpty(requests)"
        class="EmptyRequests d-flex align-items-center justify-content-center"
      >
        <h3>
          {{ $t('noRequestsFound') }}
        </h3>
      </div>
      <div
        v-for="(request, idx) in requests"
        :key="request.id"
        :class="['Request', 'mb-4', { Finished: request.finished }]"
      >
        <div class="Header d-flex align-items-center">
          <time>{{ request.createdAt | formatDateTime }}</time>

          <div class="ml-4">
            <span>{{ $t('seller') }}:</span>
            <Badge class="User m-0 ml-2">
              <i class="fas fa-user mr-1" />
              {{ request.user.name }}
            </Badge>
          </div>

          <div class="ml-4">
            <PriorityBadge class="m-0 ml-2" :priority="request.priority" />
          </div>

          <div class="ml-4">
            <BaseInput name="alert" class="m-0">
              <BaseCheckbox
                class="m-0"
                name="alert"
                :checked="request.alert"
                :disabled="true"
              >
                {{ $t('alertTheDesigners') }}
              </BaseCheckbox>
            </BaseInput>
          </div>
          <div class="ml-4">
            <BaseInput name="finished" class="m-0">
              <BaseCheckbox
                class="m-0"
                name="finished"
                :checked="request.finished"
                :disabled="true"
              >
                {{ $t('finished') }}
              </BaseCheckbox>
            </BaseInput>
          </div>

          <div class="flex-grow-1 text-right">
            <TableEditButton class="m-0" @click="requestDesign(request, idx)" />
            <TableRemoveButton class="m-0" @click="removeRequest(request)" />
          </div>
        </div>
        <div class="Body">
          <BaseInput :label="$t('notes')">
            <pre>{{ request.notes || '-' }}</pre>
          </BaseInput>

          <DropzoneUpload :read-only="true" :value="request.uploads" />

          <div class="Response mt-3 p-4">
            <div class="row">
              <div class="col d-flex">
                <h4>{{ $t('response') }}</h4>

                <div class="ml-4" v-if="request.response">
                  <span>{{ $t('designer') }}:</span>
                  <Badge class="User m-0 ml-2">
                    <i class="fas fa-user mr-1" />
                    {{ request.response.user.name }}
                  </Badge>
                </div>

                <BaseInput
                  v-if="request.response"
                  name="alert"
                  class="m-0 ml-4"
                >
                  <BaseCheckbox
                    class="m-0"
                    name="alert"
                    :checked="request.response.alert"
                    :disabled="true"
                  >
                    {{ $t('alertTheSeller') }}
                  </BaseCheckbox>
                </BaseInput>
              </div>
              <div v-if="request.response" class="col text-right">
                <TableEditButton @click="editResponse(request)" />
                <TableRemoveButton @click="removeResponse(request)" />
              </div>
            </div>

            <div v-if="!request.response">
              <BaseButton
                class="m-0"
                type="info"
                size="sm"
                @click="editResponse(request)"
              >
                <i class="fas fa-plus"></i>
                {{ $t('addDesign') }}
              </BaseButton>
            </div>

            <div v-if="request.response">
              <div>
                <BaseInput :label="$t('notes')">
                  <pre>{{ request.response.notes || '-' }}</pre>
                </BaseInput>

                <DropzoneUpload
                  :read-only="true"
                  :value="request.response.uploads"
                />
              </div>
            </div>
          </div>
        </div>
        <hr v-if="idx !== requests.length - 1" />
      </div>
    </div>
  </Dialog>
</template>

<script>
import Badge from 'components/Badge';
import AppSelect from 'components/app/input/AppSelect';
import { mergeObjects } from 'util/utils';
import OrderLayoutReqResEditDialog from 'pages/order/design/OrderLayoutReqResEditDialog';
import { OrderUi } from 'api/Models';
import Dialog from 'components/app/Dialog';
import { commonAlerts } from 'util/commonAlerts';
import PriorityBadge from 'pages/order/design/PriorityBadge';
import LayoutRequestCard from 'pages/order/design/LayoutRequestCard';
import DropzoneUpload from 'components/app/input/DropzoneUpload';

export default {
  name: 'OrderLayoutDialog',
  components: {
    DropzoneUpload,
    LayoutRequestCard,
    PriorityBadge,
    Dialog,
    AppSelect,
    Badge,
  },
  props: {
    orderId: Number,
  },
  data() {
    return {
      loading: false,
      model: {},
    };
  },
  computed: {
    requests() {
      return this.model.layoutRequests || [];
    },
  },
  methods: {
    requestDesign(request) {
      this.$openModal(OrderLayoutReqResEditDialog, {
        entity: request || { orderId: this.model.id },
        isResponse: false,
        onSave: async (values) => {
          this.loading = true;
          try {
            await this.$api.OrderLayoutRequest.save(values);
          } catch (e) {
            commonAlerts.defaultErrorMessage(e);
          } finally {
            this.loading = false;
          }
          await this.fetchOrder();
        },
      });
    },
    editResponse(request) {
      this.$openModal(OrderLayoutReqResEditDialog, {
        entity: request.response,
        isResponse: true,
        onSave: async (values) => {
          this.loading = true;
          try {
            request.response = values;
            await this.$api.OrderLayoutRequest.save(request);
          } catch (e) {
            commonAlerts.defaultErrorMessage(e);
          } finally {
            this.loading = false;
          }
          await this.fetchOrder();
        },
      });
    },
    async removeRequest(request) {
      this.loading = true;
      try {
        await this.$api.OrderLayoutRequest.remove(request.id);
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
      this.fetchOrder();
    },
    async removeResponse(request) {
      this.loading = true;
      try {
        await this.$api.OrderLayoutResponse.remove(request.response.id);
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
      this.fetchOrder();
    },
    async fetchOrder() {
      this.loading = true;
      try {
        const order = await this.$api.Order.findById(this.orderId, {
          eager: `[
              seller,
              layoutRequests.[
                user,
                uploads,
                response.[user, uploads]
              ]
            ]`,
        });
        this.model = new OrderUi(mergeObjects({}, order));
        this.model.layoutRequests = _.orderBy(
          this.model.layoutRequests,
          [(r) => new Date(r.createdAt)],
          ['desc']
        );
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    await this.fetchOrder();
  },
};
</script>

<style lang="scss">
.OrderLayoutDialog {
  .Header {
    padding-bottom: 4px;
    border-bottom: 1px solid;
  }

  .LayoutRequests,
  .EmptyRequests {
    min-height: 300px;
  }

  .RequestDesignContainer {
    position: absolute;
    top: -32px;
    right: 70px;
  }

  .Request {
    &.Finished {
      opacity: 0.6;
    }
  }

  .Response {
    background-color: lighten(#27293d, 5);
    border-radius: 4px;
  }
}
</style>
