<template>
  <Tooltip :content="$t('whatsApp')">
    <slot v-bind:openWhats="openWhats">
      <BaseButton
        size="sm"
        icon
        :class="[
          'OrderWhatsButton btn-link btn-simple btn-success',
          { Disabled: entity && _.isEmpty(entity.customer.contacts) },
        ]"
        @click="openWhats"
      >
        <i class="fab fa-whatsapp" />
      </BaseButton>
    </slot>
  </Tooltip>
</template>

<script>
import OrderWhatsDialog from 'pages/order/whats/OrderWhatsDialog';

export default {
  name: 'OrderWhatsButton',
  props: {
    entity: {},
    customer: {},
  },
  methods: {
    async openWhats(e, contact, modalProps = {}) {
      let customer = this.customer || this.entity?.customer;

      if (_.isEmpty(customer?.contacts)) {
        if (contact) {
          customer = (
            await this.$api.Customer.findAll({
              'contacts.content': contact.content,
              eager: 'contacts',
            })
          )[0];
          if (customer) {
            contact = customer.contacts.find((c) => c.content === contact.content);
          }
        }
        if (!customer) {
          this.$notify({
            type: 'warning',
            message: this.$t('whatsAppContactNotFoundMessage'),
          });
          return;
        }
      }

      this.$emit('click', e);
      this.$openModal(OrderWhatsDialog, {
        initialEntity: this.entity,
        selectedContact: contact,
        customer,
        ...modalProps,
      });
    },
  },
};
</script>

<style lang="scss">
.OrderWhatsButton {
  &.Disabled {
    opacity: 0.5;
  }
}
</style>
