<template>
  <div class="text-center">
    <BaseCheckbox
      :checked="_.get(tableProps.row, colInfo.prop, false)"
      :disabled="true"
    />
  </div>
</template>

<script>
export default {
  name: 'BooleanCol',
  props: {
    tableProps: {},
    colInfo: {},
  },
};
</script>

<style scoped></style>
