<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  >
    <template v-slot:additionalColumns>
      <ElTableColumn :label="$t('items')">
        <template slot-scope="{ row, $index }">
          <ul class="list-unstyled">
            <li v-for="item in row.items" :key="item.id" v-if="item.product">
              <Badge>
                {{ item.product.name }}
              </Badge>
              <Badge
                :type="row.operation === 'IN' ? 'success' : 'danger'"
                class="ml-2"
              >
                {{ item.quantity }}
              </Badge>
            </li>
          </ul>
        </template>
      </ElTableColumn>
      <ElTableColumn :label="$t('notes')">
        <template slot-scope="{ row, $index }">
          {{ row.notes }}
        </template>
      </ElTableColumn>
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import StockEditDialog from 'pages/stock/StockEditDialog';
import DateTimeCol from 'components/app/table/DateTimeCol';
import Badge from 'components/Badge';

export default {
  name: 'Stocks',
  components: { Badge },
  data() {
    return {
      dialog: StockEditDialog,
      eventsToListen: [EventType.StockChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { style: 'date', width: 120 },
        },
        {
          prop: 'operation',
          render: ({ row }) => this.$t(row.operation),
          attrs: { width: 120 },
        },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|items.product.name:ilike'] = `%${query}%`;
      }
      return await this.$api.Stock.findAll({
        eager: '[items.[product]]',
        orderBy: ['notes'],
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.Stock.remove(row.id);
    },
  },
};
</script>
<style lang="scss"></style>
