<template>
  <CrudDialog
    :title="$t('address')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col-6">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
        />
      </div>
      <div class="col-6">
        <BaseInput
          name="postalCode"
          :label="$t('postalCode')"
          v-model="model.postalCode"
          v-mask="'#####-###'"
          v-validate="'required|min:9|max:9'"
          @keyup.enter="findPostalCodeDate()"
          required
        />
      </div>
    </div>
    <div class="row">
      <div class="col-6">
        <BaseInput
          name="city"
          :label="$t('city')"
          v-model="model.city"
          v-validate="'required'"
          :readonly="true"
        />
      </div>
      <div class="col-6">
        <BaseInput
          name="state"
          :label="$t('state')"
          v-validate="'required'"
          v-model="model.state"
          :readonly="true"
        />
      </div>
    </div>

    <div class="row">
      <div class="col-9">
        <BaseInput
          name="address"
          :label="$t('address')"
          v-model="model.address"
          v-validate="'min:2|max:256'"
        />
      </div>
      <div class="col-3">
        <BaseInput
          name="neighborhood"
          :label="$t('neighborhood')"
          v-model="model.neighborhood"
          v-validate="'min:2|max:256'"
        />
      </div>
    </div>

    <template v-slot:footer>
      <BaseButton
        class="mr-4"
        @click="findPostalCodeDate()"
        :disabled="model.postalCode.length !== 9"
      >
        <i class="fas fa-search" />
        {{ $t('searchPostalCode') }}
      </BaseButton>
    </template>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import { mergeObjects } from 'util/utils';

export default {
  name: 'AddressEditDialog',
  mixins: [FormMixin],
  props: {
    entity: {},
    onSave: {},
  },
  data() {
    return {
      model: mergeObjects(
        {
          id: undefined,
          postalCode: '',
          city: '',
          state: '',
          address: '',
          neighborhood: '',
        },
        this.entity
      ),
    };
  },
  watch: {
    'model.postalCode': {
      deep: true,
      handler(val, oldVal) {
        if (val.substring(0, 9) === oldVal.substring(0, 9)) {
          return;
        }
        this.model.city = '';
        this.model.state = '';
        if (val.length === 9) {
          this.findPostalCodeDate();
        }
      },
    },
  },
  methods: {
    async findPostalCodeDate() {
      if (this.loading || this.model.postalCode.length !== 9) {
        return;
      }
      this.loading = true;
      try {
        const result = await this.$api.ViaCep.findBy(this.model.postalCode);
        this.model.city = result.localidade;
        this.model.state = result.uf;
        this.model.neighborhood = result.bairro;
        if (!_.trim(this.model.address) && result.logradouro) {
          this.model.address = `${result.logradouro}`;
        }

        this.$notify({
          message: this.$t('postalCodeFound'),
          type: 'success',
        });
      } catch (e) {
        console.warn(e);
        this.$notify({
          message: this.$t('postalCodeNotFound'),
          type: 'warning',
        });
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>

<style scoped></style>
