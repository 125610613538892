<template>
  <ElPopover placement="bottom" trigger="click" width="500" popper-class="is-dark">
    <div v-loading="loading">
      <div v-if="showQueryInput">
        <BaseInput
          name="search"
          v-model="model.query"
          :placeholder="`${$t('search')}...`"
          addon-left-icon="tim-icons icon-zoom-split"
          :disable-validation="true"
          @keyup.enter="runSearch"
        />
      </div>

      <div class="row">
        <div class="col-5">
          <BaseInput>
            <AppSelect v-model="model.date.field" :options="dateOptions" :clearable="false" />
          </BaseInput>
        </div>
        <div class="col">
          <div class="form-group">
            <AppFilterDatePicker v-model="model.date.range" />
          </div>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <BaseInput :label="$t('statusType')">
            <AppSelect
              value-key="id"
              :multiple="true"
              :collapse-tags="false"
              :options="statusTypeOptions"
              v-model="model.statusTypes"
              :placeholder="$t('all')"
            />
          </BaseInput>
        </div>
        <div class="col">
          <BaseInput :label="$t('status')">
            <AppSelect
              value-key="id"
              :grouped="true"
              :multiple="true"
              :collapse-tags="false"
              :options="processedStatusOptions"
              v-model="model.statuses"
              :placeholder="$t('all')"
            />
          </BaseInput>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <BaseInput
            v-model.number="model.quantity.min"
            type="number"
            :label="$t('itemQuantityGreaterThan')"
            :placeholder="$t('all')"
            step="5"
            :disableValidation="true"
            @keyup.enter="runSearch"
          />
        </div>
        <div class="col-1 d-flex align-items-center">-</div>
        <div class="col">
          <BaseInput
            v-model.number="model.quantity.max"
            type="number"
            :label="$t('itemQuantityLessThan')"
            :placeholder="$t('all')"
            step="5"
            :disableValidation="true"
            @keyup.enter="runSearch"
          />
        </div>
      </div>

      <div class="row">
        <div class="col">
          <BaseInput :label="$t('withPayment')" :disable-validation="true">
            <AppSelect :clearable="false" :options="paymentOptions" v-model="model.withPayment" />
          </BaseInput>
        </div>

        <div class="col">
          <BaseInput :label="$t('withShippingCost')" :disable-validation="true">
            <AppSelect
              :clearable="false"
              :options="yesNoOptions"
              v-model="model.withShippingCost"
            />
          </BaseInput>
        </div>
        <div class="col">
          <BaseInput :label="$t('withProductCost')" :disable-validation="true">
            <AppSelect :clearable="false" :options="yesNoOptions" v-model="model.withProductCost" />
          </BaseInput>
        </div>
      </div>

      <BaseInput :label="$t('products')">
        <AppSelect
          name="products"
          value-key="id"
          v-model="model.products"
          :options="productOptions"
          :multiple="true"
        />
      </BaseInput>

      <BaseInput :label="$t('attributes')">
        <AppSelect
          name="attributeValues"
          v-model="model.attributeValues"
          :options="attributeValueOptions"
          :multiple="true"
          :grouped="true"
        >
          <slot slot-scope="{ option }">
            {{ option.value.value }}
          </slot>
        </AppSelect>
      </BaseInput>

      <div class="row">
        <div class="col">
          <BaseInput :label="$t('providers')">
            <AppSelect
              value-key="id"
              :options="providerOptions"
              v-model="model.providers"
              :placeholder="$t('all')"
              multiple
            />
          </BaseInput>
        </div>
        <div class="col-4">
          <BaseInput :label="$t('withPaidProvider')" :disable-validation="true">
            <AppSelect
              :clearable="false"
              :options="yesNoOptions"
              v-model="model.withPaidProvider"
            />
          </BaseInput>
        </div>
        <div class="col-4">
          <BaseInput :label="$t('customers')" :disable-validation="true">
            <AppSelect :clearable="false" :options="customerOpts" v-model="model.customerFilter" />
          </BaseInput>
        </div>
      </div>

      <div class="row">
        <div class="col">
          <BaseInput :label="$t('orderFullyPaid')" :disable-validation="true">
            <AppSelect :clearable="false" :options="yesNoOptions" v-model="model.fullyPaid" />
          </BaseInput>
        </div>
      </div>

      <div v-if="showInvisibleStatusesCheckbox">
        <BaseCheckbox v-model="model.showInvisibleStatuses">
          {{ $t('showInvisibleStatuses') }}
        </BaseCheckbox>
      </div>

      <BaseButton @click="runSearch" block type="primary" class="mt-4">
        <i class="fas fa-search" />
        {{ $t('filtrate') }}
      </BaseButton>
    </div>

    <Tooltip :content="$t('filters')" slot="reference">
      <BaseButton
        size="sm"
        icon
        :class="`btn-link btn-github btn-simple ml-3 ${
          model.isAnyFilterSelected() ? 'text-primary' : ''
        }`"
      >
        <i class="fas fa-filter" />
      </BaseButton>
    </Tooltip>
  </ElPopover>
</template>

<script>
import { mergeObjects } from 'util/utils';
import AppSelect from 'components/app/input/AppSelect';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';

class OrderFilterButtonModel {
  constructor(data = {}) {
    Object.assign(
      this,
      mergeObjects(
        {
          query: '',
          showInvisibleStatuses: false,
          withPayment: 'all',
          withShippingCost: 'all',
          withProductCost: 'all',
          quantity: {
            min: null,
            max: null,
          },
          date: {
            field: 'date',
            range: [],
          },
          statuses: [],
          attributeValues: [],
          providers: [],
          customerFilter: 'all',
          statusTypes: [],
          products: [],
          withPaidProvider: 'all',
          fullyPaid: 'all',
        },
        data
      )
    );
  }

  isAnyFilterSelected() {
    const {
      query,
      date,
      quantity,
      withPayment,
      withShippingCost,
      withProductCost,
      statuses,
      attributeValues,
      providers,
      customerFilter,
      statusTypes,
      products,
      withPaidProvider,
      fullyPaid,
    } = this;

    return (
      !_.isEmpty(query) ||
      quantity.min > 0 ||
      quantity.max > 0 ||
      withPayment !== 'all' ||
      withShippingCost !== 'all' ||
      withProductCost !== 'all' ||
      !_.isEmpty(date.range) ||
      !_.isEmpty(statuses) ||
      !_.isEmpty(attributeValues) ||
      !_.isEmpty(providers) ||
      customerFilter !== 'all' ||
      !_.isEmpty(statusTypes) ||
      !_.isEmpty(products) ||
      withPaidProvider !== 'all' ||
      fullyPaid !== 'all'
    );
  }

  filterOrders(orders) {
    const {
      query,
      date,
      quantity,
      withPayment,
      withShippingCost,
      withProductCost,
      statuses,
      attributeValues,
      providers,
      customerFilter,
      statusTypes,
      products,
      withPaidProvider,
      fullyPaid,
    } = this;

    if (!_.isEmpty(statusTypes)) {
      const allowedStatuses = statusTypes.reduce((acc, val) => {
        val.orderStatus.forEach((os) => (acc[os.id] = os));
        return acc;
      }, {});
      orders = orders.filter((order) => allowedStatuses.hasOwnProperty(order.statusId));
    }

    if (!_.isEmpty(statuses)) {
      const statusIds = statuses.map((s) => s.id);
      orders = orders.filter((order) => {
        return statusIds.includes(order.statusId);
      });
    }

    if (query) {
      const queryLower = query.toLowerCase();
      orders = orders.filter((order) => {
        const orderStr = order.searchString();
        return orderStr.includes(queryLower);
      });
    }

    if (!_.isEmpty(date.range)) {
      const [start, end] = date.range;
      orders = orders.filter((o) => {
        const castedDate = moment(o[date.field]);
        return (
          (start ? castedDate.isSameOrAfter(start) : true) &&
          (end ? castedDate.isSameOrBefore(end) : true)
        );
      });
    }

    if (quantity.min || quantity.max) {
      orders = orders.filter((o) => {
        const count = o.shippingQuantity();
        if (quantity.min) {
          if (count < quantity.min) {
            return false;
          }
        }

        if (quantity.max) {
          if (count > quantity.max) {
            return false;
          }
        }
        return true;
      });
    }

    if (withPayment !== 'all') {
      const withoutPayment = withPayment === 'false';
      orders = orders.filter((order) => {
        const count = order.payments.length;
        if (withoutPayment) {
          return count === 0;
        } else {
          return count > 0;
        }
      });

      if (withPayment.includes('only')) {
        orders = orders.filter((order) => {
          const percent = Math.round(order.percentPaid());
          if (withPayment === 'onlyPartial') {
            return percent < 100;
          } else {
            return percent >= 100;
          }
        });
      }
    }

    if (withShippingCost !== 'all') {
      const without = withShippingCost === 'false';
      orders = orders.filter((order) => {
        return order.shipments.every((shipment) =>
          without ? shipment.cost === 0 : shipment.cost !== 0
        );
      });
    }

    if (withProductCost !== 'all') {
      const without = withProductCost === 'false';
      orders = orders.filter((order) => {
        return without
          ? order.items.some((item) => (item.cost || 0) === 0)
          : order.items.every((item) => (item.cost || 0) !== 0);
      });
    }

    if (!_.isEmpty(attributeValues)) {
      const attributeValueIds = attributeValues.map((av) => av.id);
      orders = orders.filter((order) => {
        return order.items.some((item) => {
          if (_.isEmpty(item.attributes)) return false;
          return attributeValueIds.every((avId) => !!item.attributes.some((a) => a.id === avId));
        });
      });
    }

    if (!_.isEmpty(providers)) {
      const providerIds = providers.map((p) => p.id);
      orders = orders.filter((order) => {
        return order.providerId && providerIds.includes(order.providerId);
      });
    }

    if (customerFilter !== 'all') {
      const withSource = customerFilter === 'withSource';
      orders = orders.filter((order) => {
        const source = order?.customer?.source;

        if (withSource && _.isEmpty(source)) {
          return false;
        } else if (!withSource && !_.isEmpty(source)) {
          return false;
        }

        return true;
      });
    }

    if (!_.isEmpty(products)) {
      const ids = products.map((p) => p.id);
      orders = orders.filter((order) => {
        return ids.every((id) => order.items.some((item) => item.productId === id));
      });
    }

    if (withPaidProvider !== 'all') {
      const without = withPaidProvider === 'false';
      orders = orders.filter((order) => {
        const withoutPayment = _.isEmpty(order.providerPaymentDate);
        return (without && withoutPayment) || (!without && !withoutPayment);
      });
    }

    if (fullyPaid !== 'all') {
      const without = fullyPaid === 'false';
      orders = orders.filter((order) => {
        let [{ percent }] = order.classifiedPayments();
        percent = Math.ceil(percent);
        if (without) {
          return percent < 100;
        } else {
          return percent >= 100;
        }
      });
    }

    return orders;
  }
}

export default {
  name: 'OrderFilterButton',
  components: { AppFilterDatePicker, AppSelect },
  props: {
    initialFilter: {
      default() {
        return {};
      },
    },
    showInvisibleStatusesCheckbox: { default: false },
    showQueryInput: { default: false },
  },
  OrderFilterButtonModel,
  data() {
    return {
      loading: false,
      model: new OrderFilterButtonModel(this.initialFilter),
      dateOptions: [
        { value: 'date', label: this.$t('orderDate') },
        { value: 'eventDate', label: this.$t('eventDate') },
        { value: 'shipmentDate', label: this.$t('shipmentDate') },
      ],
      yesNoOptions: _.sortBy(
        ['true', 'false', 'all'].map((value) => ({
          value,
          label: this.$t(value),
        })),
        ['label']
      ),
      statusOptions: [],
      paymentOptions: ['true', 'false', 'all', 'onlyPartial', 'onlyTotal'].map((value) => ({
        value,
        label: this.$t(value),
      })),
      attributeValueOptions: [],
      providerOptions: [],
      customerOpts: _.sortBy(
        ['all', 'withSource', 'withoutSource'].map((value) => ({
          value,
          label: this.$t(value),
        })),
        ['label']
      ),
      statusTypeOptions: [],
      productOptions: [],
    };
  },
  methods: {
    runSearch() {
      const copy = _.cloneDeep(this.model);
      const result = new OrderFilterButtonModel(copy);
      this.$emit('filter', result);
    },
  },
  computed: {
    processedStatusOptions() {
      if (_.isEmpty(this.model.statusTypes)) return this.statusOptions;

      const allowedStatus = this.model.statusTypes.reduce((acc, val) => {
        val.orderStatus.forEach((os) => (acc[os.id] = os));
        return acc;
      }, {});

      const clonedOptions = _.cloneDeep(this.statusOptions);

      for (const group of clonedOptions) {
        for (const option of group.options) {
          option.disabled = !allowedStatus.hasOwnProperty(option.value.id);
        }
      }

      return clonedOptions;
    },
  },
  watch: {
    'model.statusTypes'(val) {
      if (_.isEmpty(val) || _.isEmpty(this.model.statuses)) return;

      const allowedStatus = val.reduce((acc, val) => {
        val.orderStatus.forEach((os) => (acc[os.id] = os));
        return acc;
      }, {});
      this.model.statuses = this.model.statuses.filter((s) => allowedStatus.hasOwnProperty(s.id));
    },
  },
  async mounted() {
    try {
      const [statuses, attrs, providers, statusTypes, products] = await Promise.all([
        this.$api.OrderStatus.findGroupedForOptions(),

        this.$api.Attribute.findAll({
          eager: 'values',
          orderBy: ['name'],
        }),

        this.$api.Customer.findAll({ provider: true, orderBy: ['name'] }),

        this.$api.StatusType.findAll({
          eager: 'orderStatus',
        }),

        this.$api.Product.findAll({ orderBy: 'name' }),
      ]);

      this.statusOptions = statuses;

      this.attributeValueOptions = attrs
        .filter((attr) => !_.isEmpty(attr.values))
        .map((attr) => {
          return {
            label: attr.name,
            item: attr,
            options: _.sortBy(attr.values, 'value').map((val) => ({
              value: val,
              label: `${attr.name}: ${val.value}`,
            })),
          };
        });

      this.providerOptions = providers.map((value) => ({
        value,
        label: value.name,
      }));

      this.statusTypeOptions = statusTypes.map((val) => {
        return {
          value: val,
          label: this.$t(val.name),
        };
      });

      this.productOptions = products.map((val) => ({
        value: val,
        label: val.name,
      }));
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
