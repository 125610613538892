<template>
  <Modal
    :show="true"
    :show-close="true"
    modalClasses="OrderManagementDialog modal-lg"
    @close="$emit('close')"
    :loading="loading"
  >
    <div slot="header">
      <h4 class="title title-up">{{ $t('order') }}: {{ order | orderCode }}</h4>
    </div>

    <div class="Status">
      <MoveOrderToStatusButton
        :order="order"
        :funnels="funnels"
        :status="order.status"
        v-if="!!order.status"
      >
        <Badge type="danger" class="mr-2">
          <i class="fas fa-random mr-1" />
          {{ $t('status') }}:
          <span class="ml-1">{{ _.get(order, 'status.description') }}</span>
        </Badge>
      </MoveOrderToStatusButton>
    </div>

    <div class="row">
      <div class="col-6 position-relative Customer">
        <div class="ActionToolbar" v-if="$perm.hasAccess($permType.CustomerManagement)">
          <Tooltip :content="`${$t('edit')} ${$t('customer')}`">
            <BaseButton
              icon
              @click="openCustomerDialog(_.get(order, 'customer.id'))"
              class="btn-link btn-warning"
            >
              <i class="fas fa-cog" />
            </BaseButton>
          </Tooltip>
        </div>
        <table>
          <tr v-for="[key, value] in customerFields" :key="key" v-if="!_.isUndefined(value)">
            <td>{{ $t(key) }}:</td>
            <td class="pl-4">{{ value }}</td>
          </tr>
        </table>
      </div>
      <div class="col-6 position-relative Order">
        <div class="ActionToolbar" v-if="$perm.hasAccess($permType.OrderManagement)">
          <EditOrderButton :id="order.id" />
        </div>

        <table>
          <tr v-for="[key, value] in detailFields" :key="key">
            <td>{{ $t(key) }}:</td>
            <td class="pl-4">
              <a v-if="_.isPlainObject(value) && value.link" target="_blank" :href="value.link">
                {{ value.label }}
              </a>
              <span v-else>
                {{ value }}
              </span>
            </td>
          </tr>
        </table>
      </div>
    </div>

    <div class="row mt-4">
      <div class="col-12">
        <AppTable :data="order.items" :columns="itemCols" height="250" />
      </div>
    </div>

    <div class="row">
      <div class="col-6"></div>
      <div class="col-6">
        <table>
          <tr
            v-for="[key, value] in Object.entries({
              freight: $filters.money(_.get(order, 'shipments[0].total')),
              shippingMethod: _.get(order, 'shipments[0].method.description'),
              discount: order.discountValue ? $filters.money(order.discountValue()) : '',
            })"
            :key="key"
          >
            <td>{{ $t(key) }}:</td>
            <td class="pl-4">{{ value }}</td>
          </tr>
        </table>
      </div>
    </div>
    <div class="text-right mt-4">
      <h3>
        {{ $t('total') }}:
        {{ order.totalValue ? order.totalValue() : 0 | money }}
      </h3>
    </div>
    <div class="mt-2 position-relative">
      <OrderExportButton :order-id="order.id" />
      <OrderWhatsSendBudgedButton
        class="ml-2"
        btn-icon="fas fa-user-tag"
        btn-size="sm"
        :order-id="order.id"
        @exporting="loading = $event"
        @export="handleExport"
      />
      <SeeActivitiesButton :order="order" v-if="!!order" />
      <OrderWhatsButton :entity="order" v-if="order.customer" />

      <OrderToggleablePropsButton
        v-if="!!order"
        :order="order"
        @loadStart="loading = true"
        @loadEnd="loading = false"
      />
    </div>
  </Modal>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import Badge from 'components/Badge';
import QuantityCol from 'components/app/table/QuantityCol';
import MoneyCol from 'components/app/table/MoneyCol';
import { formatNumber } from 'util/utils';
import CustomerEditDialog from 'pages/customer/CustomerEditDialog';
import OrderProductTableColumn from 'pages/order/OrderProductTableColumn';
import OrderExportButton from './OrderExportButton';
import SeeActivitiesButton from 'pages/funnel/SeeActivitiesButton';
import MoveOrderToStatusButton from 'pages/funnel/MoveOrderToStatusButton';
import { ShippingMethodUi } from 'api/Models';
import OrderToggleablePropsButton from 'pages/funnel/OrderToggleablePropsButton';
import OrderWhatsButton from 'pages/order/whats/OrderWhatsButton';
import EditOrderButton from 'pages/order/EditOrderButton';
import OrderWhatsSendBudgedButton from 'pages/order/whats/OrderWhatsSendBudgedButton';
import { commonAlerts } from 'util/commonAlerts';

export default {
  name: 'OrderManagementDialog',
  components: {
    OrderWhatsSendBudgedButton,
    EditOrderButton,
    OrderWhatsButton,
    OrderToggleablePropsButton,
    MoveOrderToStatusButton,
    SeeActivitiesButton,
    OrderExportButton,
    Badge,
  },
  props: {
    orderId: {},
  },
  data() {
    return {
      loading: false,
      order: { id: this.orderId },
      itemCols: [
        { prop: 'product', component: OrderProductTableColumn },
        { prop: 'quantity', attrs: { width: 120 }, component: QuantityCol },
        { prop: 'price', attrs: { width: 100 }, component: MoneyCol },
        {
          prop: 'total',
          attrs: { width: 150 },
          render({ row }) {
            return formatNumber(row.quantity * row.price);
          },
        },
      ],
      funnels: [],
    };
  },
  provide() {
    return { errors: this.errors };
  },
  methods: {
    openCustomerDialog(id) {
      this.$openModal(CustomerEditDialog, {
        id,
      });
    },
    async fetchOrder() {
      this.loading = true;
      try {
        this.funnels = await this.$api.Funnel.findAll({
          eager: '[statuses.[types]]',
        });
        if (this.orderId) {
          this.order = await this.$api.Order.findById(this.orderId, {
            eager: `[
            shipments.[method],
            items.[product.[attributes], attributes.[attribute], design.[designer], provider],
            payments,
            customer.[addresses, contacts, discountCoupons],
            status.[types],
            designChangeUser,
            seller,
            appliedCoupons,
            recommendedByQuiz.[order.[customer]]
          ]`,
          });
        }
      } finally {
        this.loading = false;
      }
    },
    async handleExport({ data }) {
      try {
        await this.$api.Whats.sendMessage({
          customerId: this.order.customerId,
          inlineFile: JSON.stringify(data),
        });
        this.$notify({
          type: 'info',
          title: this.$t('budgetSentSuccessfully'),
        });
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      }
    },
  },
  computed: {
    customerFields() {
      const fields = {
        name: '-',
        cpf: undefined,
        cnpj: undefined,
        stateRegistration: undefined,
        city: '-',
        address: '-',
        neighborhood: '-',
        postalCode: '-',
        phone: '-',
        email: '-',
      };

      const { customer } = this.order;

      if (customer) {
        const address = customer.mainAddress();
        const contact = customer.mainContact();
        const isLegal = customer.isLegal();

        fields.name = customer.name;
        if (!isLegal) {
          fields.cpf = customer.cpf;
        } else {
          fields.cnpj = customer.cnpj;
          fields.stateRegistration = customer.stateRegistration();
        }
        if (address) {
          fields.city = address?.cityAndState();
          fields.address = address?.address;
          fields.neighborhood = address?.neighborhood;
          fields.postalCode = address?.postalCode;
        }
        if (contact) {
          fields.phone = contact?.content;
        }
        fields.email = customer?.email;
      }

      return Object.entries(fields);
    },
    detailFields() {
      const { order } = this;
      let method = _.get(order, 'shipments[0].method');
      if (method) {
        method = new ShippingMethodUi(method);
      }
      const containLink = method && method.containLink;
      const nf =
        containLink && order.nf
          ? { link: method.makeLink(order.nf), label: order.nf }
          : order.nf || '-';
      return Object.entries({
        productionDays: order.productionDays,
        productionDeadline: order.productionDeadline
          ? this.$filters.formatDateTime(order.productionDeadline, 'date')
          : '-',
        eventDate: order.eventDate ? this.$filters.formatDateTime(order.eventDate, 'date') : '-',
        shipmentDate: order.shipmentDate
          ? this.$filters.formatDateTime(order.shipmentDate, 'date')
          : '-',
        nf,
        shippingMethod: _.get(order, 'shipments[0].method.description') || '-',
        providers:
          (order.items || [])
            .filter((item) => !!item.provider)
            .map((item) => item.provider.name)
            .join(', ') || '-',
      });
    },
  },
  created() {
    this.$bus.$on(EventType.CustomerChanged, this.fetchOrder);
    this.$bus.$on(EventType.OrderChanged, this.fetchOrder);
    this.fetchOrder();
  },
  beforeDestroy() {
    this.$bus.$off(EventType.CustomerChanged, this.fetchOrder);
    this.$bus.$off(EventType.OrderChanged, this.fetchOrder);
  },
};
</script>

<style scoped lang="scss">
@import '~assets/sass/dashboard/custom/variables';

.OrderManagementDialog {
  .Status {
    position: absolute;
    top: -25px;
    right: 45px;
    display: flex;
    align-items: center;

    .badge {
      display: block;
      font-size: 0.8rem;
    }
  }

  .ActionToolbar {
    position: absolute;
    top: 0;
    right: 0;
    background-color: $body-bg;
    border-radius: $border-radius;
    opacity: 0.1;
    transition: opacity ease 0.2s;
    z-index: 1;
  }

  .Customer:hover .ActionToolbar,
  .Order:hover .ActionToolbar {
    opacity: 1;
  }

  .OrderToggleablePropsButton {
    right: 0;
    bottom: 8px;
  }
}
</style>
