<template>
  <div class="wrapper wrapper-full-page">
    <notifications></notifications>
    <router-view></router-view>
  </div>
</template>
<script>
  export default {};
</script>
<style lang="scss">
</style>
