<template>
  <Dialog
    :title="`${$t('order')} ${order ? order.orderCode : '-'} - ${$t(
      'researches'
    )}`"
    :loading="loading"
    @close="$emit('close')"
    class="OrderQuizDialog"
  >
    <template v-if="order">
      <Tooltip
        :content="$t('clickToCopyToClipboard')"
        :disabled="_.isEmpty(order.afterSaleQuiz)"
      >
        <BaseInput
          :label="$t('afterSaleQuizLink')"
          :value="afterSaleQuizLink"
          readonly
          copy-to-clipboard
        >
          <div v-if="_.isEmpty(order.afterSaleQuiz)">
            <BaseButton @click="createQuiz" size="sm">
              {{ $t('create') }}
            </BaseButton>
          </div>
        </BaseInput>
      </Tooltip>
      <div v-if="order.afterSaleQuiz">
        <div class="text-right">
          <BaseCheckbox
            :checked="order.afterSaleQuiz.closed"
            disabled
            class="d-inline-block mr-4"
          >
            {{ $t('answered') }}
          </BaseCheckbox>
          <BaseCheckbox
            v-if="order.afterSaleQuiz.closed"
            :checked="notificationsSent"
            disabled
            class="d-inline-block"
          >
            {{ $t('notificationsSent') }}
          </BaseCheckbox>
        </div>
        <div class="text-right" v-if="order.afterSaleQuiz.closed">
          <BaseButton
            size="sm"
            type="success"
            :class="['btn-link btn-simple pr-0']"
            @click="resendNotifications"
          >
            <i class="fab fa-whatsapp" />
            {{ $t('resend') }} {{ $t('notifications').toLocaleLowerCase() }}
          </BaseButton>
        </div>

        <div v-if="order.afterSaleQuiz.closed">
          <hr />

          <div class="mt-3">
            <h4>{{ $t('answers') }}</h4>
            <div class="row">
              <div
                v-for="answer in afterSaleQuizAnswers"
                :key="answer.key"
                class="col-6"
              >
                <BaseInput
                  :label="answer.label"
                  :value="answer.value"
                  readonly
                />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <BaseInput :label="$t('quizNotesLabel')">
                  <pre style="white-space: pre-wrap;">{{
                    order.afterSaleQuiz.props.answers.notes
                  }}</pre>
                </BaseInput>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
  </Dialog>
</template>

<script>
import Dialog from 'components/app/Dialog';

export default {
  name: 'OrderQuizDialog',
  components: { Dialog },
  props: {
    orderId: {},
  },
  data() {
    return {
      loading: false,
      order: null,
    };
  },
  methods: {
    async createQuiz() {
      this.order = await this.$api.Order.save({
        id: this.order.id,
        afterSaleQuiz: { type: 'AfterSaleQuiz' },
      });
    },
    async resendNotifications() {
      this.loading = true;
      try {
        await this.$api.Quiz.resendNotifications(this.order.afterSaleQuizId);
        await this.fetchOrder();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchOrder() {
      this.loading = true;
      try {
        this.order = await this.$api.Order.findById(this.orderId, {
          eager: '[afterSaleQuiz]',
        });
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    afterSaleQuizLink() {
      if (!this.order.afterSaleQuiz) return '-';

      return `${window.location.origin}/pesquisas/${this.order.afterSaleQuiz.identifier}`;
    },
    afterSaleQuizAnswers() {
      if (!this.order.afterSaleQuiz) return [];

      const { answers = {} } = this.order.afterSaleQuiz.props;

      return _.orderBy(
        Object.entries(answers)
          .filter((e) => e[0] !== 'notes')
          .map(([key, value]) => {
            return {
              id: key,
              label: this.$t(`quiz${key[0].toUpperCase()}${key.slice(1)}Label`),
              value: _.isNumber(value) ? value : this.$t(value),
            };
          }),
        ['label']
      );
    },
    notificationsSent() {
      if (!this.order.afterSaleQuiz) return false;

      const { notifications = {} } = this.order.afterSaleQuiz.props;
      return (
        !_.isEmpty(notifications) &&
        Object.values(notifications).every((val) => val === true)
      );
    },
  },
  mounted() {
    this.fetchOrder();
  },
};
</script>

<style lang="scss"></style>
