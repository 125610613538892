<template>
  <Badge :type="type"> {{ $t('priority') }}: {{ label }} </Badge>
</template>

<script>
import Badge from 'components/Badge';
export default {
  name: 'PriorityBadge',
  components: { Badge },
  props: {
    priority: {
      required: true,
    },
  },
  computed: {
    type() {
      switch (this.priority) {
        case 1:
          return 'success';
        case 2:
          return 'info';
        case 3:
          return 'warning';
        case 4:
          return 'danger';
      }
    },
    label() {
      switch (this.priority) {
        case 1:
          return this.$t('LOW');
        case 2:
          return this.$t('MID');
        case 3:
          return this.$t('HIGH');
        case 4:
          return this.$t('URGENT');
      }
    },
  },
};
</script>

<style scoped></style>
