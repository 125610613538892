<template>
  <CrudDialog
    class="CustomerEditDialog"
    :title="$t(providerView ? 'provider' : 'customer')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    :hide-save="newCustomerWizard.show"
  >
    <div v-if="newCustomerWizard.show" class="InsertPhoneContainer">
      <h3>{{ $t('newCustomerWizardTitle') }}</h3>
      <BaseInput
        name="newCustomerWizardPhone"
        :label="$t('phone')"
        v-model="newCustomerWizard.phone"
        v-validate="`required|min:15|max:15`"
        v-mask="['(##) ####-####', '(##) #####-####']"
        placeholder="(##) #####-####"
        :data-vv-as="$t('phone')"
        required
      />

      <div class="text-right">
        <BaseButton
          type="warning"
          class="mr-3"
          @click="newCustomerWizard.show = false"
        >
          <i class="fas fa-phone-slash mr-1"></i>
          {{ $t('withoutPhone') }}
        </BaseButton>

        <BaseButton type="primary" @click="checkNewCustomerPhone">
          <i class="fas fa-phone mr-1"></i>
          {{ $t('continue') }}
        </BaseButton>
      </div>
    </div>

    <Tabs v-else>
      <Tab>
        <div slot="label">
          {{ $t(providerView ? 'provider' : 'customer') }}
        </div>

        <div class="row">
          <div class="col">
            <BaseInput
              name="id"
              :label="$t('id')"
              v-model.number="model.id"
              :readonly="true"
              number
            />
          </div>
          <div class="col">
            <BaseInput
              name="email"
              :label="$t('email')"
              v-model="model.email"
              v-validate="'email'"
            />
          </div>
          <div class="col">
            <BaseInput name="birthdate" :label="$t('birthdate')">
              <AppDateTimePicker
                name="birthdate"
                v-model="model.birthdate"
                type="date"
              />
            </BaseInput>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <BaseInput
              name="name"
              :label="$t('name')"
              v-model="model.name"
              v-validate="'required|min:2'"
              required
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <BaseInput name="type" :label="$t('type')" required>
              <AppSelect
                name="type"
                v-model.number="model.type"
                :options="availableTypes"
                v-validate="'required'"
                :clearable="false"
                :filterable="false"
              />
            </BaseInput>
          </div>
          <div class="col" v-if="model.type === 'LEGAL'" :key="'legal'">
            <BaseInput
              name="cnpj"
              :label="$t('cnpj')"
              v-model="model.cnpj"
              v-validate="'min:18|max:18'"
              v-mask="'##.###.###/####-##'"
              :readonly="model.type !== 'LEGAL'"
            />
          </div>
          <div class="col" v-else :key="'natural'">
            <BaseInput
              name="cpf"
              :label="$t('cpf')"
              v-model="model.cpf"
              v-validate="'min:14|max:14'"
              v-mask="'###.###.###-##'"
              :readonly="model.type === 'LEGAL'"
            />
          </div>
          <div class="col" v-if="model.type === 'LEGAL'">
            <BaseInput
              name="stateRegistration"
              :label="$t('stateRegistration')"
              v-model="model.props.stateRegistration"
            />
          </div>
        </div>

        <div class="row">
          <div class="col">
            <BaseInput name="source" :label="$t('source')">
              <AppSelect
                name="source"
                v-model="model.source"
                :options="availableSources"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="customerType" :label="$t('customerType')">
              <AppSelect
                name="customerType"
                v-model="model.customerType"
                :options="availableCustomerTypes"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="gender" :label="$t('gender')">
              <AppSelect
                name="gender"
                v-model="model.gender"
                :options="availableGenders"
              />
            </BaseInput>
          </div>
        </div>

        <div class="row" v-if="providerView">
          <div class="col">
            <BaseCheckbox v-model="model.props.ownProduction">
              {{ $t('ownProduction') }}
            </BaseCheckbox>
          </div>
        </div>
      </Tab>

      <Tab>
        <template v-slot:label>
          {{ $t('addresses') }}
          <div class="badge badge-light m-0">{{ model.addresses.length }}</div>
        </template>

        <div>
          <AppTable :data="model.addresses" :columns="addressCols">
            <ElTableColumn :label="$t('actions')" width="100">
              <template slot-scope="props">
                <TableEditButton @click="openAddressDialog(props)" />
                <TableRemoveButton
                  @click="removeElement(model.addresses, props)"
                />
              </template>
            </ElTableColumn>
          </AppTable>

          <BaseButton class="AddNewBtn" @click="openAddressDialog()" icon>
            <i class="fas fa-plus" />
          </BaseButton>
        </div>
      </Tab>

      <Tab>
        <template v-slot:label>
          {{ $t('contacts') }}
          <div class="badge badge-light m-0">{{ model.contacts.length }}</div>
        </template>

        <div>
          <AppTable :data="model.sortedContacts" :columns="contactCols">
            <ElTableColumn :label="$t('actions')" width="125">
              <template slot-scope="props">
                <TableButton
                  :tooltip="$t('markAsPrincipal')"
                  @click="markAsMainContact(props)"
                  icon="fas fa-phone"
                  type="success"
                  simple
                />
                <TableEditButton @click="openContactDialog(props)" />
                <TableRemoveButton
                  @click="removeElement(model.contacts, props)"
                />
              </template>
            </ElTableColumn>
          </AppTable>

          <BaseButton class="AddNewBtn" @click="openContactDialog()" icon>
            <i class="fas fa-plus" />
          </BaseButton>
        </div>
      </Tab>

      <Tab>
        <template v-slot:label>
          {{ $t('events') }}
          <div class="badge badge-light m-0">{{ model.events.length }}</div>
        </template>

        <div>
          <AppTable :data="model.events" :columns="eventCols">
            <ElTableColumn :label="$t('actions')" width="100">
              <template slot-scope="props">
                <TableEditButton @click="openEventDialog(props)" />
                <TableRemoveButton
                  @click="removeElement(model.events, props)"
                />
              </template>
            </ElTableColumn>
          </AppTable>

          <BaseButton class="AddNewBtn" @click="openEventDialog()" icon>
            <i class="fas fa-plus" />
          </BaseButton>
        </div>
      </Tab>

      <Tab>
        <template v-slot:label>
          {{ $t('discountCoupons') }}
          <div class="badge badge-light m-0">
            {{ wrappedModel.notRedeemedCoupons.length }}
          </div>
        </template>

        <div>
          <AppTable
            :data="
              _.orderBy(
                wrappedModel.discountCoupons,
                ['createdAt', 'id'],
                ['desc']
              )
            "
            :columns="couponsCols"
            height="35vh"
          >
            <ElTableColumn :label="$t('actions')" width="100">
              <template slot-scope="props">
                <TableEditButton @click="openDiscountCouponDialog(props)" />
                <TableRemoveButton
                  @click="removeElement(model.discountCoupons, props)"
                />
              </template>
            </ElTableColumn>
          </AppTable>
        </div>
      </Tab>
    </Tabs>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import ContactEditDialog from 'pages/customer/ContactEditDialog';
import { commonAlerts } from 'util/commonAlerts';
import AddressEditDialog from 'pages/customer/AddressEditDialog';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import { mergeObjects } from 'util/utils';
import EventEditDialog from 'pages/customer/EventEditDialog';
import DateTimeCol from 'components/app/table/DateTimeCol';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import { CustomerUi } from 'api/Models';
import BooleanCol from 'components/app/table/BooleanCol';
import DiscountCouponEditDialog from 'pages/discountCoupon/DiscountCouponEditDialog';
import TableButton from 'components/app/btn/TableButton';

export default {
  name: 'CustomerEditDialog',
  components: { TableButton, AppDateTimePicker, AppSelect },
  mixins: [FormMixin],
  props: {
    id: {},
    providerView: {
      default: false,
    },
  },
  data() {
    return {
      loading: false,
      newCustomerWizard: {
        show: !this.id,
        phone: '',
      },
      model: new CustomerUi({
        id: undefined,
        code: undefined,
        name: '',
        email: '',
        addresses: [],
        contacts: [],
        type: this.providerView ? 'LEGAL' : 'NATURAL',
        cnpj: '',
        cpf: '',
        provider: this.providerView,
        events: [],
        source: '',
        birthdate: null,
        customerType: 'CONSUMIDOR FINAL',
        discountCoupons: [],
        props: {
          stateRegistration: '',
          ownProduction: false,
        },
      }),
      contactCols: [
        { prop: 'id', attrs: { width: 100 } },
        {
          prop: 'type',
          attrs: { width: 120 },
          render: ({ row }) => {
            return this.$t(row.type);
          },
        },
        { prop: 'content' },
        {
          prop: 'props.isMain',
          label: this.$t('main'),
          component: BooleanCol,
          attrs: { width: 100 },
        },
        { prop: 'updatedAt', component: DateTimeCol, attrs: { width: 180 } },
      ],
      addressCols: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'address' },
        { prop: 'neighborhood' },
        { prop: 'city' },
        { prop: 'state', attrs: { width: 90 } },
        { prop: 'postalCode', attrs: { width: 120 } },
      ],
      availableTypes: [
        { value: 'NATURAL', label: this.$t('naturalPerson') },
        { value: 'LEGAL', label: this.$t('legalPerson') },
      ],
      eventCols: [
        { prop: 'id', attrs: { width: 100 } },
        {
          prop: 'eventType.name',
          label: this.$t('eventType'),
          attrs: { width: 250 },
        },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { style: 'dayMonth', width: 100 },
        },
        { prop: 'note' },
      ],
      couponsCols: [
        { prop: 'id', attrs: { width: 100 } },
        {
          prop: 'type',
          label: this.$t('source'),
          render: ({ row }) => {
            return this.$t(row.type);
          },
        },
        { prop: 'redeemed', component: BooleanCol },
        { prop: 'targetOrderId', label: this.$t('orderId') },
        {
          prop: 'discount',
          render: ({ row }) => {
            return row.discountString();
          },
        },
        {
          prop: 'createdAt',
          component: DateTimeCol,
          attrs: { width: 175 },
        },
      ],
      availableSources: [
        'Google',
        'Facebook',
        'Instagram',
        'Indicação',
        'Prospecção',
      ]
        .sort()
        .map((value) => ({ value, label: value })),
      availableGenders: ['MASCULINO', 'FEMININO'].map((value) => ({
        value,
        label: value,
      })),
      availableCustomerTypes: [
        'CONSUMIDOR FINAL',
        'EMPRESA DE EVENTOS',
        'REPRESENTANTE DE GRUPO',
        'REVENDEDOR',
      ].map((value) => ({ value, label: value })),
    };
  },
  methods: {
    async checkNewCustomerPhone() {
      await this.$validator.validate();
      const containError = this.errors.items.some(
        (item) => item.field === 'newCustomerWizardPhone'
      );
      if (containError) {
        return;
      }

      const [customer] = await this.$api.Customer.findAll({
        'contacts.content': this.newCustomerWizard.phone,
        orderBy: 'id',
      });

      if (customer) {
        this.$emit('reopenModal', {
          id: customer.id,
        });
      } else {
        this.model.contacts.push({
          type: 'PHONE',
          content: this.newCustomerWizard.phone,
          updatedAt: new Date(),
        });
        this.newCustomerWizard.show = false;
      }
    },
    async onSave() {
      const result = await this.$api.Customer.save(this.model);
      this.$bus.$emit(EventType.CustomerChanged, result);
    },
    openContactDialog(props) {
      this.$openModal(ContactEditDialog, {
        contacts: this.model.contacts,
        entity: props ? { ...props.row } : null,
        onSave: (contact) => {
          contact.updatedAt = new Date();
          if (props) {
            this.model.contacts.splice(props.$index, 1, contact);
          } else {
            this.model.contacts.push(contact);
          }
        },
      });
    },
    openAddressDialog(props) {
      this.$openModal(AddressEditDialog, {
        entity: props ? { ...props.row } : null,
        onSave: (contact) => {
          if (props) {
            this.model.addresses.replaceIdx(props.$index, contact);
          } else {
            this.model.addresses.push(contact);
          }
        },
      });
    },
    openEventDialog(props) {
      this.$openModal(EventEditDialog, {
        entity: props ? { ...props.row } : null,
        onSave: (event) => {
          if (props) {
            this.model.events.replaceIdx(props.$index, event);
          } else {
            this.model.events.push(event);
          }
        },
      });
    },
    openDiscountCouponDialog(props) {
      this.$openModal(DiscountCouponEditDialog, {
        entity: props ? _.cloneDeep(props.row) : null,
        onSave: (event) => {
          if (props) {
            const idx = _.findIndex(
              this.model.discountCoupons,
              (dc) => dc.id === props.row.id
            );
            this.model.discountCoupons.replaceIdx(idx, event);
          } else {
            this.model.discountCoupons.push(event);
          }
        },
      });
    },
    async removeElement(list, { row }) {
      if (!(await commonAlerts.confirmOperation())) {
        return;
      }
      for (let i = 0; i < list.length; i++) {
        const item = list[i];
        if (item == row) {
          list.removeIdx(i);
          break;
        }
      }
    },
    markAsMainContact({ row }) {
      this.model.contacts.forEach((c) => (c.props.isMain = false));
      row.props.isMain = true;
      this.$forceUpdate();
    },
  },
  computed: {
    wrappedModel() {
      return new CustomerUi(this.model);
    },
  },
  async mounted() {
    if (this.id) {
      try {
        this.loading = true;
        this.model = new CustomerUi(
          mergeObjects(
            {},
            this.model,
            await this.$api.Customer.findById(this.id, {
              eager:
                '[addresses, contacts, events.[eventType], discountCoupons]',
            })
          )
        );
        if (!this.model.contacts.find((c) => c.props.isMain)) {
          let contact = this.model.sortedContacts[0];
          if (contact) {
            contact.props.isMain = true;
          }
        }
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style lang="scss">
.CustomerEditDialog {
  .AddNewBtn {
    position: absolute !important;
    top: 20px;
    right: 22px;
  }

  .InsertPhoneContainer {
  }
}
</style>
