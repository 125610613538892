<template>
  <div
    class="form-group BaseInput"
    :class="{
      'input-group-focus': focused,
      'has-danger': !disableValidation && fieldError,
      'has-success': !disableValidation && !fieldError && touched,
      'has-label': label,
      inline,
    }"
  >
    <div class="mb-0" :class="{ 'input-group': hasIcon }">
      <slot name="label">
        <label v-if="label"> {{ label }} {{ required ? '*' : '' }} </label>
      </slot>
      <slot name="addonLeft">
        <span v-if="addonLeftIcon" class="input-group-prepend">
          <div class="input-group-text"><i :class="addonLeftIcon"></i></div>
        </span>
      </slot>
      <slot v-bind="{ name: $attrs.name, label }">
        <input
          :value="value"
          v-bind="$attrs"
          v-on="listeners"
          class="form-control"
          aria-describedby="addon-right addon-left"
        />
      </slot>
    </div>

    <slot name="error" v-if="fieldError || $slots.error">
      <label class="error">{{ fieldError }}</label>
    </slot>

    <slot name="addonRight">
      <span v-if="addonRightIcon" class="input-group-append">
        <div class="input-group-text"><i :class="addonRightIcon"></i></div>
      </span>
    </slot>
    <slot name="helperText"></slot>
  </div>
</template>
<script>
export default {
  inheritAttrs: false,
  name: 'BaseInput',
  inject: {
    errors: { default: null },
  },
  props: {
    required: Boolean,
    label: {
      type: String,
      description: 'Input label',
    },
    error: {
      type: String,
      description: 'Input error',
      default: '',
    },
    value: {
      type: [String, Number],
      description: 'Input value',
    },
    addonRightIcon: {
      type: String,
      description: 'Input icon on the right',
    },
    addonLeftIcon: {
      type: String,
      description: 'Input icon on the left',
    },
    disableValidation: {
      type: Boolean,
      default: false,
    },
    copyToClipboard: {
      type: Boolean,
      default: false,
    },
    inline: {
      type: Boolean,
      default: false,
    },
  },
  model: {
    prop: 'value',
    event: 'input',
  },
  data() {
    return {
      focused: false,
      touched: false,
    };
  },
  computed: {
    hasIcon() {
      const { addonRight, addonLeft } = this.$slots;
      return (
        addonRight !== undefined ||
        addonLeft !== undefined ||
        this.addonRightIcon !== undefined ||
        this.addonLeftIcon !== undefined
      );
    },
    listeners() {
      const additionalListeners = {};
      if (this.copyToClipboard) {
        additionalListeners.click = this.copyTargetValueToClipboard;
      }
      return {
        ...this.$listeners,
        input: this.onInput,
        blur: this.onBlur,
        focus: this.onFocus,
        ...additionalListeners,
      };
    },
    fieldError() {
      if (this.error) {
        return this.error;
      } else if (this.errors) {
        return this.errors.first(this.$attrs.name);
      }
      return '';
    },
  },
  methods: {
    onInput(evt) {
      if (!this.touched) {
        this.touched = true;
      }
      this.$emit('input', evt.target.value);
    },
    onFocus() {
      this.focused = true;
    },
    onBlur() {
      this.focused = false;
    },
    copyTargetValueToClipboard({ target }) {
      target.select();
      document.execCommand('copy');
    },
  },
};
</script>
<style lang="scss">
.BaseInput .el-color-picker {
  display: block;
}

.BaseInput.inline {
  > div:first-child {
    display: flex;
    align-items: center;

    label {
      margin-right: 10px;
      white-space: nowrap;
    }
  }
}
</style>
