<template>
  <money @input="$emit('input', arguments[0])" v-bind="$attrs" />
</template>

<script>
export default {
  name: 'InputMoney',
  inheritAttrs: false,
};
</script>

<style scoped></style>
