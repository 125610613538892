<template>
  <ElDatePicker
    v-if="type.includes('range')"
    :type="type"
    :format="format"
    :readonly="readonly"
    v-bind="$attrs"
    :value="parsedValue"
    @input="processValue"
  />

  <ElDatePicker
    v-else
    :type="type"
    :format="format"
    v-mask="formatMask"
    :value="parsedValue"
    @input="processValue"
    :readonly="readonly"
  />
</template>

<script>
export default {
  name: 'AppDateTimePicker',
  inheritAttrs: false,
  props: {
    type: { default: 'datetime' },
    value: {},
    dateFormat: {},
    readonly: {},
  },
  methods: {
    processValue(date) {
      if (date) {
        if (_.isArray(date)) {
          date = date.map((d) => moment(d));
        } else {
          date = moment(date);
        }
      }
      this.$emit('input', date);
    },
  },
  computed: {
    parsedValue() {
      let date = this.value;
      if (!date) {
        return date;
      }
      if (_.isArray(date)) {
        date = date.map((d) => moment(d).toDate());
      } else {
        date = moment(date).toDate();
      }
      return date;
    },
    format() {
      if (this.dateFormat) {
        return this.dateFormat;
      }
      let format = 'dd/MM/yyyy';
      if (this.type === 'datetime') {
        format += '  HH:mm:ss';
      }
      return format;
    },
    formatMask() {
      let format = '##/##/####';
      if (this.type === 'datetime') {
        format += '  ##:##:##';
      }
      return format;
    },
  },
};
</script>

<style lang="scss">
.el-picker-panel__shortcut {
  white-space: nowrap;
}

.el-date-editor.el-range-editor {
  padding: 0 !important;
}

.el-date-editor.el-range-editor .el-range-input:first-child {
  padding-left: 50px !important;
}

.el-date-editor.el-range-editor .el-range-input {
  background-color: transparent;
  color: rgba(255, 255, 255, 0.8) !important;
  font-size: 0.75rem !important;
  width: calc(35% - 4px) !important;
}

.el-range-separator {
  padding: 0;
  color: rgba(255, 255, 255, 0.8) !important;
}

.el-input__icon.el-range__icon.el-icon-date {
  margin-left: 5px;
}

.el-input__icon.el-range__close-icon {
  margin-right: 5px;
}
</style>
