<template>
  <CrudPage
    class="WooCalls"
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
    :show-new-btn="false"
    action-col-width="125"
    v-loading="loading"
  >
    <template v-slot:headerButtons>
      <base-button @click="reprocessAll()">
        {{ $t('reprocessAll') }}
      </base-button>
    </template>

    <template v-slot:tableButtons="{ rowProps }">
      <Tooltip :content="$t('reprocess')">
        <BaseButton
          class="btn-link"
          type="secondary"
          size="sm"
          icon
          @click="reprocessCall(rowProps.row)"
          :disabled="rowProps.row.status === 'SUCCESS'"
        >
          <i class="fas fa-redo"></i>
        </BaseButton>
      </Tooltip>
    </template>
  </CrudPage>
</template>
<script>
import DateTimeCol from 'components/app/table/DateTimeCol';
import WooCallDialog from 'pages/woo/WooCallDialog';
import { commonAlerts } from 'util/commonAlerts';
import { EventType } from 'plugins/eventBusPlugin';

export default {
  name: 'WooCalls',
  data() {
    return {
      loading: false,
      dialog: WooCallDialog,
      eventsToListen: [EventType.WooCallChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'topic' },
        { prop: 'status' },
        { prop: 'createdAt', component: DateTimeCol },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|topic|status:ilike'] = `%${query}%`;
      }
      return await this.$api.WooApi.brain.findAll({
        orderByDesc: 'createdAt',
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.WooApi.brain.remove(row.id);
    },
    async reprocessCall(row) {
      this.loading = true;
      try {
        await this.$api.WooApi.reprocessOrder(row.id);
        this.$bus.$emit(EventType.WooCallChanged);
      } catch (e) {
        console.error(e);
        await commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async reprocessAll() {
      this.loading = true;
      try {
        const rows = await this.fetchData();
        for (const row of rows) {
          try {
            await this.$api.WooApi.reprocessOrder(row.id);
          } catch (e) {
            console.error(e);
          }
        }
        this.$bus.$emit(EventType.WooCallChanged);
      } finally {
        this.loading = false;
      }
    },
  },
};
</script>
<style scoped></style>
