<template>
  <Tooltip :content="`${$t('edit')} ${$t('order')}`">
    <BaseButton
      icon
      @click="openOrderDialog"
      class="btn-link btn-warning"
      :size="size"
    >
      <i class="fas fa-cog" />
    </BaseButton>
  </Tooltip>
</template>

<script>
import OrderEditDialog from 'pages/order/OrderEditDialog';

export default {
  name: 'EditOrderButton',
  props: {
    id: {},
    size: {},
  },
  methods: {
    openOrderDialog() {
      this.$openModal(OrderEditDialog, {
        id: this.id,
      });
    },
  },
};
</script>

<style scoped></style>
