<template>
  <div :class="['DropzoneUpload', { ReadOnly: readOnly }]" ref="root">
    <div v-if="!_.isEmpty(files)" class="Uploads p-2">
      <div
        class="Upload p-2 mb-2"
        v-for="(data, idx) in files"
        :key="idx"
        v-loading="data.loading"
      >
        <div class="Content d-flex">
          <div class="Image mr-4">
            <div>
              <ExpandableImage
                v-if="data.isImage()"
                :src="data.imageLink($filters)"
                alt=""
              />
              <i v-else :class="['Icon', data.findIcon()]"></i>
            </div>
            <div class="text-center">
              <TableButton
                v-if="!!data.uploadData"
                tag="a"
                :href="data.uploadData.id | uploadUrl"
                :tooltip="$t('clickToDownloadFile')"
                icon="fas fa-download"
                download
              />
              <TableRemoveButton v-if="!readOnly" @click="removeFile(idx)" />
            </div>
          </div>
          <div class="Info flex-grow-1">
            <div v-if="!readOnly" class="row">
              <div class="col">
                <div :title="data.fileName()">
                  {{ data.fileName() }}
                </div>
              </div>
            </div>

            <BaseProgress
              v-if="data.loading"
              :value="data.progress"
              :show-value="false"
              class="m-0"
              type="warning"
            >
              {{ data.progress }}
            </BaseProgress>
            <div v-else>
              <BaseInput
                v-if="data.uploadData"
                :name="`uploadNotes-${idx}`"
                :label="$t('notes')"
              >
                <AppTextArea
                  :name="`uploadNotes-${idx}`"
                  v-model="data.uploadData.props.notes"
                  maxLength="8192"
                  @input="emitInput()"
                  :read-only="readOnly"
                  rows="3"
                />
              </BaseInput>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!readOnly" class="UploadWaterMark">
      <div class="Center">
        <div class="text-center">
          <i class="fas fa-upload"></i>
        </div>
        <div class="text-center">
          {{ $t('dropTheFilesHere') }}
        </div>
      </div>
    </div>

    <div v-if="readOnly && _.isEmpty(value)" class="UploadWaterMark">
      <div class="Center">
        <div class="text-center">
          <i class="far fa-file"></i>
        </div>
        <div class="text-center">
          {{ $t('noFilesAroundHere') }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { commonAlerts } from 'util/commonAlerts';
import BaseProgress from 'components/BaseProgress';
import ExpandableImage from 'components/app/ExpandableImage';
import { mergeObjects } from 'util/utils';
import AppTextArea from 'components/app/AppTextArea';
import TableButton from 'components/app/btn/TableButton';

class Upload {
  constructor(props = {}) {
    Object.assign(
      this,
      mergeObjects(
        {
          file: null,
          loading: false,
          progress: 0,
          uploadData: null,
          error: null,
        },
        props
      )
    );
  }

  fileName() {
    if (this.file) return this.file.name;
    if (this.uploadData) return this.uploadData.name;
    return '-';
  }

  isImage() {
    let mime = '';
    if (this.uploadData) {
      mime = this.uploadData.mime;
    } else if (this.file) {
      mime = this.file.type;
    }

    return mime.toLowerCase().includes('image');
  }

  imageLink($filters) {
    if (!this._imageLinkCache) {
      if (this.uploadData) {
        this._imageLinkCache = $filters.uploadUrl(this.uploadData.id);
      }

      if (this.file) {
        const reader = new FileReader();

        reader.onload = (e) => {
          this._imageLinkCache = e.target.result;
        };

        reader.readAsDataURL(this.file);
      }
    }
    return this._imageLinkCache;
  }

  findIcon() {
    let mime = '';
    if (this.uploadData) {
      mime = this.uploadData.mime;
    } else if (this.file) {
      mime = this.file.type;
    }
    mime = mime.toLowerCase();

    if (mime.includes('audio')) {
      return 'fas fa-volume-up';
    } else if (mime.includes('video')) {
      return 'fas fa-video';
    }

    return 'fas fa-file';
  }
}

export default {
  name: 'DropzoneUpload',
  components: { TableButton, AppTextArea, ExpandableImage, BaseProgress },
  props: {
    value: {},
    readOnly: { default: false },
  },
  data() {
    return {
      loading: false,
      progress: 0,
      files: (this.value || []).map((uploadData) => new Upload({ uploadData })),
    };
  },
  methods: {
    async uploadFile(fileData) {
      fileData.loading = true;
      try {
        const [uploadData] = await this.$api.Upload.uploadFiles(
          fileData.file,
          (progress) => {
            fileData.progress = progress;
          }
        );
        if (!uploadData) {
          throw new Error(
            this.$t('uploadSizeExceeded', { name: fileData.file.name })
          );
        }
        fileData.uploadData = { ...uploadData, props: { notes: '' } };
      } finally {
        fileData.loading = false;
      }
    },
    async handleUploads(uploadedFiles) {
      if (uploadedFiles.length === 0) return;

      uploadedFiles = Array.from(uploadedFiles);

      try {
        await Promise.all(
          uploadedFiles.map(async (file) => {
            const fileData = new Upload({ file });

            const idx = this.files.push(fileData) - 1;

            try {
              await this.uploadFile(fileData);
            } catch (e) {
              console.warn(e);
              commonAlerts.defaultErrorMessage(e);
              this.files.removeIdx(idx);
            }

            return fileData;
          })
        );
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      }
      this.emitInput(this.files);
    },
    removeFile(index) {
      this.files.removeIdx(index);
      this.emitInput(this.files);
    },
    fromClipboard(event) {
      const {
        clipboardData: { files },
      } = event;
      this.handleUploads(files);
    },
    fromDrop(event) {
      const {
        dataTransfer: { files },
      } = event;
      this.handleUploads(files);
    },
    emitInput(files = this.files) {
      this.$emit(
        'input',
        files.map((f) => f.uploadData)
      );
    },
  },
  mounted() {
    const $rootDiv = this.$refs.root;

    const preventDefaults = (e) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const highlight = (e) => {
      $rootDiv.classList.add('Highlight');
    };

    const unhighlight = (e) => {
      $rootDiv.classList.remove('Highlight');
    };

    ['dragenter', 'dragover', 'dragleave', 'drop'].forEach((eventName) => {
      $rootDiv.addEventListener(eventName, preventDefaults, false);
    });
    // Highlight drop area when item is dragged over it
    ['dragenter', 'dragover'].forEach((eventName) => {
      $rootDiv.addEventListener(eventName, highlight, false);
    });

    ['dragleave', 'drop'].forEach((eventName) => {
      $rootDiv.addEventListener(eventName, unhighlight, false);
    });

    // Handle dropped files
    $rootDiv.addEventListener('drop', this.fromDrop, false);

    window.addEventListener('paste', this.fromClipboard);
  },
  updated() {
    if (_.isEmpty(this.files) && !_.isEmpty(this.value)) {
      this.files = this.value.map((uploadData) => new Upload({ uploadData }));
    }
  },
  beforeDestroy() {
    window.removeEventListener('paste', this.fromClipboard);
  },
};
</script>

<style lang="scss">
.DropzoneUpload {
  width: 100%;
  height: 180px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  position: relative;
  overflow: auto;
  resize: vertical;

  transition: all ease-in-out 0.2s;

  * {
    transition: all ease-in-out 0.2s;
  }

  &.Highlight {
    background-color: lighten(#27293d, 5);

    .Uploads {
      opacity: 0.1;
    }

    .UploadWaterMark {
      opacity: 0.5;
    }
  }

  .UploadWaterMark {
    font-size: 2rem;
    color: white;
    opacity: 0.2;
    transition: all ease-in-out 0.2s;

    .fas {
      font-size: 5rem;
    }
  }

  .Uploads {
    .Upload {
      background-color: lighten(#27293d, 2);
      border-radius: 4px;

      .Icon {
        font-size: 4rem;
        margin: 15px 0;
      }

      .expandable-image img {
        min-height: 94px;
      }

      .Image {
        width: 94px;
        max-width: 94px;
      }

      .Info {
        position: relative;
        .progress-container {
          position: absolute;
          bottom: 0;
          width: 100%;
        }
      }
    }
  }
}
</style>
