<template>
  <Customers :provider-view="true" />
</template>
<script>
import Customers from 'pages/customer/Customers';
export default {
  name: 'Providers',
  components: { Customers },
};
</script>
<style></style>
