import clickOutside from 'directives/click-ouside.js';
import VueTheMask from 'vue-the-mask';
import money from 'v-money';

/**
 * You can register global directives here and use them as a plugin in your main Vue instance
 */

const GlobalDirectives = {
  install(Vue) {
    Vue.directive('click-outside', clickOutside);
    Vue.use(VueTheMask);
    Vue.use(money, {
      decimal: ',',
      thousands: '.',
      prefix: 'R$ ',
      suffix: '',
      precision: 2,
      masked: false,
    });
  },
};

export default GlobalDirectives;
