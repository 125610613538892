<template>
  <CrudDialog
    :title="$t('updateValues')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col-6">
        <BaseInput name="percent" :label="$t('percent')">
          <InputMoney
            name="percent"
            v-model.number="model.percent"
            v-validate="'min_value:-100|max_value:100'"
            :prefix="''"
            :suffix="'%'"
            :precision="0"
            maxlength="4"
          />
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import InputMoney from 'components/app/input/InputMoney';

export default {
  name: 'UpdateValuesDialog',
  components: { InputMoney },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        percent: 0,
      },
    };
  },
  methods: {
    async onSave() {
      if (this.model.percent === 0) return;

      const methods = await this.$api.ShippingMethod.findAll();
      const updatePercent = this.model.percent / 100 + 1;
      const promises = [];
      for (const method of methods) {
        if (!method.props) {
          method.props = {};
        }

        method.props.defaultValue =
          (method.props.defaultValue ?? 0) * updatePercent;

        const ranges = method.props.postalCodeRanges ?? [];
        for (const range of ranges) {
          const quantityRanges = range?.quantityRanges ?? [];
          for (const quantityRange of quantityRanges) {
            quantityRange.value = (quantityRange.value || 0) * updatePercent;
          }
        }

        promises.push(this.$api.ShippingMethod.save(method));
      }
      await Promise.all(promises);
      this.$bus.$emit(EventType.ShippingMethodChanged);
    },
  },
  async mounted() {},
};
</script>

<style scoped></style>
