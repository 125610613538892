<template>
  <OrderExportButton
    class="OrderWhatsSendBudgedButton"
    :order-id="orderId"
    :direct-export-type="{ type: 'png' }"
    @export="handleExport"
    :btn-attrs="{
      icon: true,
      size: btnSize,
      class: 'btn-link btn-github btn-simple',
      noBtnText: true,
    }"
    :btn-icon="btnIcon"
    :tooltip="$t('sendBudgetToCustomer')"
  />
</template>

<script>
import OrderExportButton from 'pages/order/OrderExportButton';
import { commonAlerts } from 'util/commonAlerts';

export default {
  name: 'OrderWhatsSendBudgedButton',
  components: { OrderExportButton },
  props: {
    orderId: {},
    btnIcon: {

    },
    btnSize: {
      default: 'lg',
    }
  },
  data() {
    return {
    };
  },
  methods: {
    async handleExport({ exportUrl }) {
      if (
        !(await commonAlerts.confirmOperation({
          title: this.$t('attention'),
          text: this.$t('sendBudgetToCustomerConfirmMessage'),
        }))
      ) {
        return;
      }
      this.$emit('exporting', true);
      try {
        const response = await this.$api.$http.get(exportUrl, {
          params: {
            toBase64: true,
          },
        });
        const contentType = response.headers['content-type'].split(';')[0];
        this.$emit('export', {
          data: {
            name: `${this.$t('budget')}.${contentType.split('/')[1]}`,
            mimetype: response.headers['content-type'].split(';')[0],
            base64: response.data,
          },
        });
      } finally {
        this.$emit('exporting', false);
      }
    },
  },
};
</script>

<style scoped></style>
