<template>
  <div :class="['Msg pt-1 pb-2', `MsgType-${message.type}`, { Quoted: quoted }]">
    <div class="Header d-flex align-items-center">
      <span v-if="message.from" :class="['MsgSender', { You: !!message.origin }]">
        {{ customerName }}
      </span>

      <Tooltip
        :content="`${$filters.formatDateTime((message.timestamp || message.createdAt))} (${$filters.fromNow(
          (message.timestamp || message.createdAt)
        )})`"
      >
        <time class="ml-auto mr-2">
          <small>
            {{ (message.timestamp || message.createdAt) | formatDateTime('hourMin') }}
          </small>
        </time>
      </Tooltip>
      <Tooltip v-if="message.type !== 'ticket'" :content="$t('reply')">
        <BaseButton
          class="QuoteButton"
          @click="$emit('quote', message)"
          size="xs"
          type="github"
          simple
          icon
          link
        >
          <i class="fas fa-quote-right" />
        </BaseButton>
      </Tooltip>
    </div>

    <div class="MsgContent">
      <WhatsMsg
        v-if="message.quotedMessage"
        class="InnerQuote mb-3 ml-3"
        :message="message.quotedMessage"
        quoted
      />

      <div v-if="message.file">
        <div v-if="message.file.mimetype.includes('image')" class="text-center">
          <a :href="message.file.url" target="_blank">
            <img :src="message.file.url" />
          </a>
        </div>
        <div v-else-if="message.file.mimetype.includes('audio')">
          <audio controls>
            <source :src="message.file.url" :type="message.file.mimetype" />
            Audio not supported :(
          </audio>
        </div>
        <Tooltip v-else :content="$t('clickToDownloadFile')">
          <a :href="message.file.url" download class="text-info" target="_blank">
            <i class="fas fa-download" />
            {{ message.file.publicFilename || message.file.name }}
          </a>
        </Tooltip>
      </div>

      <pre class="m-0" v-html="message.text" />

      <div v-if="message.type === 'ticket'" class="text-white">
        Transferência de ticket para outro setor
      </div>
    </div>
  </div>
</template>

<script>
import ExpandableImage from 'components/app/ExpandableImage';
import Badge from 'components/Badge';

export default {
  name: 'WhatsMsg',
  components: { Badge, ExpandableImage },
  props: {
    customer: {},
    message: {},
    quoted: { type: Boolean, default: false },
  },
  computed: {
    customerName() {
      if (this.message.origin) return this.$t('you');

      if (this.customer) return this.customer.name;

      if (this.message.from) {
        return this.message.from.name || this.message.from.internalName;
      }

      return this.$t('customer');
    },
  },
};
</script>

<style scoped lang="scss">
.Msg.Quoted.InnerQuote {
  border: none !important;

  .QuoteButton {
    display: none;
  }
}

.Time {
  cursor: help;
  opacity: 0.6;
  transition: opacity ease-in-out 100ms;

  &:hover {
    opacity: 1;
  }
}

.QuoteButton {
  font-size: 0.65rem !important;
}

.MsgSender {
  font-size: 13px;
  font-weight: 500;
  color: #6bcbef;

  &.You {
    color: #35cd96;
  }
}
</style>
