<template>
  <Card class="card-timeline card-plain">
    <ul class="timeline" :class="{ 'timeline-simple': type === 'simple' }">
      <slot></slot>
    </ul>
  </Card>
</template>
<script>
export default {
  name: 'time-line',
  props: {
    type: {
      type: String,
      default: ''
    }
  }
};
</script>
<style></style>
