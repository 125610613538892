<template>
  <ElPopover
    popperClass="AppPopoverButton m-0 p-1"
    :placement="placement"
    :trigger="disabled ? 'manual' : 'click'"
    v-model="isOpen"
    :visible-arrow="false"
    v-bind="$attrs"
  >
    <slot v-bind:close="close" />

    <BaseButton
      slot="reference"
      size="sm"
      :type="btnType"
      icon
      link
      simple
      v-bind="btnAttrs"
      @click="$emit('open', { open, close, isOpening: !isOpen })"
      :disabled="disabled"
    >
      <i :class="btnIcon"></i>
    </BaseButton>
  </ElPopover>
</template>

<script>
export default {
  name: 'AppPopoverButton',
  inheritAttrs: false,
  props: {
    btnType: {
      default: 'github',
    },
    btnIcon: {
      default: 'fas fa-ellipsis-v',
    },
    btnAttrs: {
      default() {
        return {};
      },
    },
    placement: {
      default: 'bottom',
    },
    disabled: {
      default: false,
    },
  },
  data() {
    return {
      isOpen: false,
    };
  },
  methods: {
    open() {
      this.isOpen = true;
    },
    close() {
      this.isOpen = false;
    },
  },
};
</script>

<style lang="scss">
.AppPopoverButton.el-popover {
  min-width: 25px;
}
</style>
