<template>
  <CrudDialog
    :title="$t('contact')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="DiscountCouponEditDialog"
  >
    <div class="row">
      <div class="col-4">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
        />
      </div>
      <div class="col">
        <BaseInput name="type" :label="$t('type')">
          <AppSelect
            name="type"
            v-model="model.type"
            :options="typeOpts"
            v-validate="'required'"
            :disabled="!!model.id"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col-8">
        <BaseInput name="discount" :label="$t('discount')">
          <template v-if="model.discountType === 'Percent'">
          <InputMoney
            name="discount"
            v-model.number="model.discount"
            v-validate="'min_value:0|max_value:100'"
            :prefix="''"
            :suffix="'%'"
            :precision="0"
            maxlength="4"
          />
          </template>
          <template v-else>
            <InputMoney
              name="discount"
              v-model.number="model.discount"
              v-validate="'min_value:0'"
            />
          </template>
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput
          :label="$t('orderId')"
          v-model="model.targetOrderId"
          readonly
        />
      </div>
      <div class="col">
        <BaseInput label=" ">
          <BaseCheckbox
            name="redeemed"
            :checked="!!model.targetOrderId"
            :centralize="true"
            :disabled="true"
          >
            {{ $t('redeemed') }}
          </BaseCheckbox>
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import { mergeObjects } from 'util/utils';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';

const Type = {
  AfterSaleQuiz: 'AfterSaleQuiz',
  Recommendation: 'Recommendation',
};

export default {
  name: 'DiscountCouponEditDialog',
  components: { InputMoney, AppSelect },
  mixins: [FormMixin],
  props: {
    entity: {},
    onSave: {},
  },
  data() {
    return {
      loading: false,
      model: mergeObjects(
        {
          id: undefined,
          type: '',
          discount: 0,
          discountType: 'Percent',
          customerId: null,
          orderId: null,
          targetOrderId: null,
        },
        this.entity
      ),
      typeOpts: Object.values(Type).map((value) => ({
        value,
        label: this.$t(value),
      })),
    };
  },
};
</script>

<style scoped></style>
