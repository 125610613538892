<template>
  <CrudDialog :title="$t('orderItem')" :loading="loading" @save="save" @close="$emit('close')">
    <div class="row">
      <div class="col-6">
        <BaseInput name="id" :label="$t('id')" v-model.number="model.id" :readonly="true" />
      </div>
      <div class="col-6">
        <BaseInput name="product" :label="$t('product')" required>
          <AppSelect
            name="product"
            value-key="id"
            v-model="model.product"
            :options="availableProducts"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="quantity" :label="$t('quantity')" required>
          <InputMoney
            name="quantity"
            v-model.number="model.quantity"
            v-validate="'required|min_value:1'"
            :prefix="''"
            :precision="0"
          />
        </BaseInput>
      </div>
      <div class="col d-flex align-items-end">
        <BaseInput name="price" :label="$t('price')" required class="flex-grow-1">
          <InputMoney
            name="price"
            v-model.number="model.price"
            v-validate="'required|min_value:0'"
          />
        </BaseInput>

        <ElPopover placement="bottom" trigger="click" width="90vw">
          <template v-if="productGotPriceList">
            <ProductPricingTable
              :current-quantity="model.quantity"
              :selected-attribute-values="model.attributes"
              :product-pricing="model.product.props.pricing"
              @calculatedValue="model.price = $event"
              @matchAnyItem="pricingListMatch = $event"
            />
          </template>

          <Tooltip slot="reference" :content="$t('priceList')">
            <BaseButton
              icon
              size="sm"
              :type="pricingListMatch ? 'info' : 'github'"
              :simple="!pricingListMatch"
              class="ml-1 mb-3"
              :disabled="!productGotPriceList"
            >
              <i class="fas fa-money-bill-alt" />
            </BaseButton>
          </Tooltip>
        </ElPopover>
      </div>
      <div class="col">
        <BaseInput name="cost" :label="$t('cost')" required>
          <InputMoney name="cost" v-model.number="model.cost" v-validate="'required|min_value:0'" />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <BaseInput name="attributes" :label="$t('attributes')">
          <AppSelect
            name="attributes"
            value-key="id"
            v-model="model.attributes"
            :options="productAttributes"
            :multiple="true"
            :grouped="true"
            :disabled="_.isEmpty(model.product)"
            :on-select-filter="retainDuplicatedAttributes"
          >
            <slot slot-scope="{ option }">
              {{ option.value.getCaption() }}
            </slot>
          </AppSelect>
        </BaseInput>
      </div>
      <!--      <div class="col" v-if="model.provider">
        <BaseInput name="provider" :label="$t('provider')">
          <AppSelect
            name="provider"
            v-model="model.provider"
            :options="providerOptions"
            value-key="id"
            :filterable="true"
            :clearable="true"
          />
        </BaseInput>
      </div>-->
    </div>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';
import { mergeObjects } from 'util/utils';
import ProductPricingTable from 'pages/product/ProductPricingTable';

export default {
  name: 'OrderItemEditDialog',
  components: { ProductPricingTable, InputMoney, AppSelect },
  mixins: [FormMixin],
  props: {
    entity: {},
    onSave: {},
  },
  data() {
    return {
      loading: false,
      model: mergeObjects(
        {},
        {
          id: undefined,
          product: undefined,
          productId: undefined,
          quantity: 1,
          price: 0,
          cost: 0,
          attributes: [],
        },
        this.entity
      ),
      availableProducts: [],
      availableAttributes: [],
      //providerOptions: [],
      pricingListMatch: false,
    };
  },
  watch: {
    'model.product'(val) {
      this.model.attributes = this.model.attributes.filter((attrVal) => {
        return val.attributes.some((attr) => attr.id === attrVal.attributeId);
      });
      if (val) {
        this.model.price = val.price || 0;
        this.model.cost = val.cost || 0;
      } else {
        this.model.price = 0;
        this.model.cost = 0;
      }
    },
  },
  methods: {
    retainDuplicatedAttributes(attributes) {
      const ids = Object.values(
        attributes.reduce((accumulator, val) => {
          accumulator[val.attributeId] = val.id;
          return accumulator;
        }, {})
      );
      return attributes.filter((a) => ids.includes(a.id));
    },
  },
  computed: {
    productAttributes() {
      if (this.model.product) {
        const productAttributes = _.get(this.model, 'product.attributes', []);
        return this.availableAttributes
          .filter((attr) => productAttributes.some((a) => a.id === attr.id))
          .map((attr) => ({
            value: attr.id,
            label: attr.getCaption(),
            item: attr,
            options: _.sortBy(attr.values, 'value').map((val) => ({
              value: val,
              label: `${attr.getCaption()}: ${val.getCaption()}`,
            })),
          }));
      }
      return [];
    },
    productGotPriceList() {
      return !(!this.model.product || _.isEmpty(this.model.product?.props?.pricing?.list));
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await Promise.all([
        this.$api.Product.findAll({
          eager: 'attributes',
          orderBy: 'name',
        }).then(
          (products) =>
            (this.availableProducts = products.map((p) => ({
              value: p,
              label: p.name,
            })))
        ),
        this.$api.Attribute.findAll({
          eager: 'values.[attribute]',
          orderBy: ['name'],
        }).then((attributes) => {
          this.availableAttributes = attributes.filter((attr) => !_.isEmpty(attr.values));
        }),
        /*this.$api.Customer.findAll({
          provider: true,
          orderBy: ['name'],
        }).then((providers) => {
          this.providerOptions = providers.map((value) => ({
            value,
            label: value.name,
          }));
        }),*/
      ]);
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
