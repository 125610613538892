<template>
  <CrudDialog
    :title="$t('sector')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <BaseInput
      name="id"
      :label="$t('id')"
      v-model.number="model.id"
      :readonly="true"
      number
    />

    <BaseInput
      name="name"
      :label="$t('name')"
      v-model="model.name"
      v-validate="'required|min:2|max:256'"
      required
    />

    <BaseInput name="users" :label="$t('users')">
      <AppSelect
        name="users"
        value-key="id"
        v-model="model.users"
        :options="availableUsers"
        :multiple="true"
      />
    </BaseInput>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';

export default {
  name: 'SectorEditDialog',
  components: { AppSelect },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        users: [],
      },
      availableUsers: [],
    };
  },
  methods: {
    async onSave() {
      const sector = await this.$api.Sector.save(this.model);
      this.$bus.$emit(EventType.SectorChanged, sector);
    },
  },
  async mounted() {
    this.availableUsers = (await this.$api.User.brain.findAll({
      orderBy: 'name',
    })).map(value => ({ value, label: value.name || value.email }));
    if (this.id) {
      try {
        this.loading = true;
        this.model = await this.$api.Sector.findById(this.id, {
          eager: 'users',
        });
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
