<template>
  <CrudDialog
    :title="$t('event')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="EventToProspectDialog"
  >
    <div class="row">
      <div class="col-2">
        <BaseInput name="id" :label="$t('id')" v-model.number="model.id" :readonly="true" />
      </div>
      <div class="col">
        <BaseInput name="date" :label="$t('date')" required>
          <AppDateTimePicker name="date" v-model="model.date" type="date" v-validate="'required'" />
        </BaseInput>
      </div>
      <div class="col position-relative">
        <BaseInput name="type" :label="$t('type')" required>
          <AppSelect
            name="type"
            value-key="id"
            v-model="model.eventType"
            :options="availableTypes"
            v-validate="'required|min:2|max:128'"
            allow-create
          />
        </BaseInput>
        <Tooltip :content="$t('new')">
          <BaseButton
            icon
            class="btn-link btn-github btn-simple"
            size="sm"
            style="position: absolute; top: -7px; right: 15px"
            @click="editEventType()"
          >
            <i class="fas fa-plus" />
          </BaseButton>
        </Tooltip>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <BaseInput name="name" :label="$t('name')" v-model="model.name" v-validate="'min:2'" />
      </div>
      <div class="col">
        <BaseInput
          name="phone"
          :label="$t('phone')"
          v-model="model.phone"
          v-validate="'min:15|max:15'"
          v-mask="['(##) ####-####', '(##) #####-####']"
          placeholder="(##) #####-####"
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="notes" :label="$t('notes')">
          <AppTextArea name="notes" v-model="model.notes" rows="3" />
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import { mergeObjects } from 'util/utils';
import AppTextArea from 'components/app/AppTextArea';
import EventTypeEditDialog from 'pages/eventType/EventTypeEditDialog';

export default {
  name: 'EventToProspectDialog',
  components: {
    AppDateTimePicker,
    AppSelect,
    AppTextArea,
  },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        date: new Date(),
        notes: '',
        eventType: null,
        props: {},
      },
      availableTypes: [],
    };
  },
  methods: {
    editEventType(id) {
      this.$openModal(EventTypeEditDialog, {
        id,
      });
    },
    async fetchEventTypes(eventType) {
      this.availableTypes = (
        await this.$api.EventType.findAll({
          orderBy: 'name',
        })
      ).map((value) => ({ value, label: value.name }));
      if (eventType) {
        this.model.eventType = eventType;
      }
    },
    async onSave() {
      if (_.isString(this.model.eventType)) {
        const eventType = await this.$api.EventType.save({ name: this.model.eventType });
        this.availableTypes.push({ value: eventType, label: eventType.name });
        this.model.eventType = eventType;
      }
      await this.$api.EventsToProspect.save(this.model);
      this.$bus.$emit(EventType.EventsToProspectChanged);
    },
  },
  computed: {},
  async mounted() {
    this.loading = true;
    this.$bus.$on(EventType.EventTypeChanged, this.fetchEventTypes);
    try {
      await this.fetchEventTypes();
      if (this.id) {
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.EventsToProspect.findById(this.id, {
            eager: '[eventType]',
          })
        );
      }
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$bus.$off(EventType.EventTypeChanged, this.fetchEventTypes);
  },
};
</script>

<style lang="scss"></style>
