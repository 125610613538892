<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  >
    <template v-slot:additionalColumns>
      <ElTableColumn :label="$t('values')">
        <template slot-scope="{ row }">
          <Badge
            v-for="(attributeValue, idx) in row.values"
            :key="idx"
            class="mr-2 noUpperCase"
          >
            <Tooltip :content="`${$t('value')}: ${attributeValue.value}`">
              <span>{{ attributeValue.caption }}</span>
            </Tooltip>
          </Badge>
        </template>
      </ElTableColumn>
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import AttributeEditDialog from 'pages/product/attribute/AttributeEditDialog';
import Badge from 'components/Badge';
import BooleanCol from 'components/app/table/BooleanCol';

export default {
  name: 'Attributes',
  components: { Badge },
  data() {
    return {
      dialog: AttributeEditDialog,
      eventsToListen: [EventType.AttributeChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'name' },
        { prop: 'caption' },
        { prop: 'compound', component: BooleanCol, attrs: { width: 125 } },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|name|values.value:ilike'] = `%${query}%`;
      }
      const data = await this.$api.Attribute.findAll({
        eager: 'values',
        orderBy: ['name'],
        ...filters,
        ...pagination,
      });
      data.results.forEach(a => {
        a.values = _.sortBy(a.values, ['value']);
      });
      return data;
    },
    async remove(row) {
      return await this.$api.Attribute.remove(row.id);
    },
  },
};
</script>
<style scoped lang="scss"></style>
