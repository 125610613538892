<template>
  <div class="wrapper" :class="{ 'nav-open': $sidebar.showSidebar }">
    <notifications />
    <sidebar-fixed-toggle-button />
    <side-bar :background-color="sidebarBackground">
      <template slot-scope="props" slot="links">
        <sidebar-item
          :link="{
            name: $t('sidebar.dashboard'),
            icon: 'tim-icons icon-chart-pie-36',
            path: '/dashboard',
          }"
        />

        <sidebar-item
          :link="{
            name: $t('initialContacts'),
            icon: 'fab fa-whatsapp-square',
            path: '/initialContacts',
          }"
          :badge-value="initialContactsCount"
        />

        <!--
        <sidebar-item :link="{ name: $t('design'), icon: 'fas fa-palette' }">
          <sidebar-item
            :link="{
              name: $t('queue'),
              path: '/designQueue',
            }"
          />
        </sidebar-item>
        -->
        <sidebar-item
          :link="{ name: $t('financial'), icon: 'fas fa-dollar-sign' }"
          v-if="$perm.hasAccess($permType.FinancialManagement)"
        >
          <sidebar-item
            v-if="$perm.hasAccess($permType.AccountingEntryCategoryManagement)"
            :link="{
              name: $t('accountingEntryCategories'),
              path: '/accounting/categories',
            }"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.AccountingEntryManagement)"
            :link="{
              name: $t('accountingEntries'),
              path: '/accounting/entries',
            }"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.OrderPaymentManagement)"
            :link="{
              name: $t('orderMissingPayments'),
              path: '/orderMissingPayments',
            }"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.OrderPaymentManagement)"
            :link="{
              name: $t('payments'),
              path: '/orderPayments',
            }"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.ProviderPaymentManagement)"
            :link="{
              name: $t('providerPayments'),
              path: '/providerPayments',
            }"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.FinancialReport)"
            :link="{
              name: $t('report'),
              path: '/financial/report',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('design'), icon: 'fas fa-palette' }"
          :badge-value="layoutRequestsCount"
        >
          <sidebar-item
            :link="{
              name: $t('orderLayoutRequests'),
              path: '/orderLayoutRequests',
            }"
            :badge-value="layoutRequestsCount"
          />
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('sales'), icon: 'fas fa-shopping-cart' }"
          :badge-value="layoutResponsesCount"
        >
          <sidebar-item
            :link="{
              name: $t('orderLayoutResponses'),
              path: '/orderLayoutResponses',
            }"
            :badge-value="layoutResponsesCount"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.CustomerManagement)"
            :link="{
              name: $t('customers'),
              path: '/customers',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.EventTypeManagement)"
            :link="{
              name: $t('eventTypes'),
              path: '/eventTypes',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.EventProspectionManagement)"
            :link="{
              name: $t('eventProspections'),
              path: '/eventProspections',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.OrderManagement)"
            :link="{
              name: $t('orders'),
              path: '/orders',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.OrderManagement)"
            :link="{
              name: $t('afterSaleQuizReport'),
              path: '/afterSaleQuizReport',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.OrderManagement)"
            :link="{
              name: $t('cancelledOrdersReport'),
              path: '/cancelledOrdersReport',
            }"
          />
          <sidebar-item
            @click.stop.prevent="openOrderCostsAndSalesReportDialog"
            v-if="$perm.hasAccess($permType.OrderManagement)"
            :link="{
              name: $t('orderCostsAndSalesReport'),
              path: '#orderCostsAndSalesReport',
            }"
            action-button
          />
          <sidebar-item
            @click.stop.prevent="openProductCostsAndSalesReportDialog"
            v-if="$perm.hasAccess($permType.OrderManagement)"
            :link="{
              name: $t('productCostsAndSalesReport'),
              path: '#productCostsAndSalesReport',
            }"
            action-button
          />
          <sidebar-item
            @click.stop.prevent="openSellersReportDialog"
            v-if="$perm.hasAccess($permType.OrderManagement)"
            :link="{
              name: $t('sellersReport'),
              path: '#sellersReport',
            }"
            action-button
          />
        </sidebar-item>

        <sidebar-item :link="{ name: $t('production'), icon: 'fas fa-tools' }">
          <sidebar-item
            :link="{
              name: $t('productionSchedule'),
              path: '/productionSchedule',
            }"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.OrderManagement)"
            :link="{
              name: $t('orders'),
              path: '/orders',
            }"
          />
        </sidebar-item>

        <sidebar-item
          :link="{ name: $t('funnels'), icon: 'fas fa-filter' }"
          :badge-value="funnels.reduce((a, b) => (b.badgeValue ? a + b.badgeValue : a), 0)"
        >
          <sidebar-item
            :key="funnel.id"
            v-for="funnel in funnels"
            :link="{
              name: funnel.name,
              path: `/funnels/${funnel.id}`,
            }"
            :badge-value="funnel.badgeValue"
          />
        </sidebar-item>

        <!--<sidebar-item
          :link="{ name: $t('reports'), icon: 'fas fa-file-alt' }"
          v-if="$perm.hasAccess($permType.ReportsView)"
        >
          <sidebar-item
            :link="{
              name: $t('customersByOrigin'),
              path: '/reports/customersByOrigin',
            }"
          />
          <sidebar-item
            :link="{
              name: $t('topSellingProducts'),
              path: '/reports/topSellingProducts',
            }"
          />
          <sidebar-item
            :link="{
              name: $t('salesByState'),
              path: '/reports/salesByState',
            }"
          />
        </sidebar-item>-->

        <sidebar-item
          :link="{ name: $t('settings'), icon: 'fas fa-cog' }"
          v-if="$perm.hasAccess($permType.SettingsManagement)"
        >
          <sidebar-item
            @click.stop.prevent="openApplicationConf"
            v-if="$perm.hasAccess($permType.ApplicationConfigManagement)"
            :link="{
              name: $t('application'),
              path: '#applicationConf',
            }"
            action-button
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.AttributeManagement)"
            :link="{
              name: $t('attributes'),
              path: '/attributes',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.StatusAutomationManagement)"
            :link="{
              name: $t('statusAutomations'),
              path: '/statusAutomations',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.StockManagement)"
            :link="{
              name: $t('stock'),
              path: '/stock',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.ProviderManagement)"
            :link="{
              name: $t('providers'),
              path: '/providers',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.FunnelManagement)"
            :link="{
              name: $t('funnels'),
              path: '/funnels',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.ShippingMethodManagement)"
            :link="{
              name: $t('shippingMethods'),
              path: '/shippingMethods',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.ProductManagement)"
            :link="{
              name: $t('products'),
              path: '/products',
            }"
          />

          <sidebar-item
            v-if="$perm.hasAccess($permType.SectorManagement)"
            :link="{
              name: $t('sectors'),
              path: '/sectors',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.OrderStatusManagement)"
            :link="{
              name: $t('orderStatus'),
              path: '/orderStatus',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.UserManagementAccess)"
            :link="{
              name: $t('users'),
              path: '/users',
            }"
          />
          <sidebar-item
            v-if="$perm.hasAccess($permType.WooManagement)"
            :link="{
              name: $t('wooCommerce'),
              path: '/wooCalls',
            }"
          />
        </sidebar-item>

        <li v-if="$perm.hasAccess($permType.IntegrationView)">
          <a href="#" @click.prevent="toggleIntegrationsModal">
            <i class="fas fa-paperclip"></i>
            <p>{{ $t('integrations') }}</p>
          </a>
        </li>
      </template>
    </side-bar>
    <div class="main-panel" :data="sidebarBackground">
      <dashboard-navbar></dashboard-navbar>
      <router-view name="header"></router-view>

      <div :class="{ content: !$route.meta.hideContent }" @click="toggleSidebar">
        <zoom-center-transition :duration="200" mode="out-in">
          <!-- your content here -->
          <router-view></router-view>
          <slot></slot>
        </zoom-center-transition>
      </div>
      <!--<content-footer v-if="!$route.meta.hideFooter"></content-footer>-->
    </div>
    <ModalRoot />
  </div>
</template>
<script>
import DashboardNavbar from './DashboardNavbar.vue';
import ContentFooter from './ContentFooter.vue';
import DashboardContent from './Content.vue';
import SidebarFixedToggleButton from './SidebarFixedToggleButton.vue';
import { SlideYDownTransition, ZoomCenterTransition } from 'vue2-transitions';
import IntegrationDialog from 'pages/integrations/IntegrationDialog';
import ModalRoot from 'pages/Layout/ModalRoot';
import AppConfDialog from 'pages/conf/AppConfDialog';
import { EventType } from 'plugins/eventBusPlugin';
import ProductCostsAndSalesReportDialog from 'pages/reports/ProductCostsAndSalesReportDialog';
import SellersReportDialog from 'pages/reports/SellersReportDialog';
import OrderCostsAndSalesReportDialog from 'pages/reports/OrderCostsAndSalesReportDialog';

export default {
  components: {
    IntegrationDialog,
    DashboardNavbar,
    ContentFooter,
    SidebarFixedToggleButton,
    DashboardContent,
    SlideYDownTransition,
    ZoomCenterTransition,
    ModalRoot,
  },
  data() {
    return {
      sidebarBackground: 'blue', //vue|blue|orange|green|red|primary
      funnels: [],
      layoutResponsesCount: 0,
      layoutRequestsCount: 0,
      initialContactsCount: 0,
    };
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    toggleIntegrationsModal() {
      this.$openModal(IntegrationDialog);
    },
    openApplicationConf() {
      this.$openModal(AppConfDialog);
    },
    openProductCostsAndSalesReportDialog() {
      this.$openModal(ProductCostsAndSalesReportDialog);
    },
    openSellersReportDialog() {
      this.$openModal(SellersReportDialog);
    },
    openOrderCostsAndSalesReportDialog() {
      this.$openModal(OrderCostsAndSalesReportDialog);
    },
    async loadFunnels() {
      const funnels = await this.$api.Funnel.findAll({ eager: '[statuses]' });
      funnels.forEach((f) => (f.firstStatus = f.statuses.find((s) => s.visible)));

      const orders = await $appApi.Order.findAll({
        count: 'id',
        groupBy: 'statusId',
        'statusId:in': funnels.map((f) => (f.firstStatus ? f.firstStatus.id : 0)).join(','),
      });

      funnels.forEach((f) => {
        const match = orders.find((o) => o.statusId === (f.firstStatus ? f.firstStatus.id : 0));
        if (match) {
          const count = _.parseInt(match.count);
          if (count > 0) {
            f.badgeValue = count;
          }
        }
      });
      this.funnels = funnels;
    },
    throttledOrderLayouts: _.throttle(function () {
      return this.orderLayouts();
    }, 10000),
    async orderLayouts() {
      this.layoutResponsesCount = (
        await $appApi.OrderLayoutRequest.findAll({
          finished: false,
          'response.alert': true,
          userId: this.$store.state.user.id,
        })
      ).length;

      this.layoutRequestsCount = (
        await $appApi.OrderLayoutRequest.findAll({
          eager: '[response]',
          finished: false,
        })
      ).filter(
        (l) => !l.response || (!l.response.alert && l.response.userId === this.$store.state.user.id)
      ).length;
      {
        const [{ count }] = await this.$api.InitialContact.findAll({
          count: 'id',
        });
        this.initialContactsCount = _.parseInt(count);
      }
    },
  },
  async mounted() {
    this.$bus.$on(EventType.FunnelChanged, this.loadFunnels);
    this.$bus.$on(EventType.OrderStatusChanged, this.loadFunnels);
    this.$bus.$on(EventType.OrderChanged, this.loadFunnels);
    this.$bus.$on(EventType.OrderChanged, this.orderLayouts);
    this.$bus.$on(EventType.OrderLayoutRequestChanged, this.orderLayouts);
    this.$bus.$on(EventType.InitialContactChanged, this.throttledOrderLayouts);
    this.loadFunnels();
    this.orderLayouts();
  },
  beforeDestroy() {
    this.$bus.$off(EventType.FunnelChanged, this.loadFunnels);
    this.$bus.$off(EventType.OrderStatusChanged, this.loadFunnels);
    this.$bus.$off(EventType.OrderChanged, this.loadFunnels);
    this.$bus.$off(EventType.OrderChanged, this.orderLayouts);
    this.$bus.$off(EventType.OrderLayoutRequestChanged, this.orderLayouts);
    this.$bus.$off(EventType.InitialContactChanged, this.throttledOrderLayouts);
  },
};
</script>
<style lang="scss">
$scaleSize: 0.95;
@keyframes zoomIn95 {
  from {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
  to {
    opacity: 1;
  }
}

.main-panel .zoomIn {
  animation-name: zoomIn95;
}

@keyframes zoomOut95 {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    transform: scale3d($scaleSize, $scaleSize, $scaleSize);
  }
}

.main-panel .zoomOut {
  animation-name: zoomOut95;
}
</style>
