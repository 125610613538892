<template>
  <div class="QuizPage" v-loading="loading">
    <div class="text-center mt-4 mb-4">
      <img src="~/assets/images/OrderExportPage/logoBranco.svg" class="Logo" />
    </div>

    <template v-if="notFound">
      <h1 class="text-center">👀</h1>
      <h2 class="text-center">{{ $t('quizNotFound') }}</h2>
    </template>
    <template v-else-if="thanksForYourAnswer">
      <h2 class="text-center">{{ $t('thanksForYourAnswer') }}!</h2>
    </template>

    <div v-else-if="type" class="container">
      <div class="text-center mb-4">
        <h3 class="m-0">{{ $t('satisfactionSurvey') }}</h3>
      </div>

      <form @submit.stop.prevent="save">
        <div class="row">
          <div class="col">
            <div class="Question">
              <BaseInput
                key="orderSourceSelect"
                name="orderSource"
                :label="$t('quizOrderSourceLabel')"
                required
              >
                <AppSelect
                  name="orderSource"
                  v-model="model.props.answers.orderSource"
                  :options="orderSourceOptions"
                  :filterable="false"
                  v-validate="'required'"
                  data-vv-as=" "
                />
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput
                name="productPrice"
                :label="$t('productPriceLabel')"
                required
              >
                <AppSelect
                  name="productPrice"
                  v-model="model.props.answers.productPrice"
                  :options="productPriceOpts"
                  :filterable="false"
                  v-validate="'required'"
                  data-vv-as=" "
                />
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput
                name="attendanceSpeed"
                :label="$t('quizAttendanceSpeedLabel')"
                required
              >
                <AppSelect
                  name="attendanceSpeed"
                  v-model="model.props.answers.attendanceSpeed"
                  :options="attendanceSpeedOptions"
                  :filterable="false"
                  v-validate="'required'"
                  data-vv-as=" "
                />
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput
                name="productionSpeed"
                :label="$t('quizProductionSpeedLabel')"
                required
              >
                <AppSelect
                  name="productionSpeed"
                  v-model="model.props.answers.productionSpeed"
                  :options="attendanceSpeedOptions"
                  :filterable="false"
                  v-validate="'required'"
                  data-vv-as=" "
                />
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput
                name="freightCost"
                :label="$t('quizFreightCostLabel')"
                required
              >
                <AppSelect
                  name="freightCost"
                  v-model="model.props.answers.freightCost"
                  :options="freightCostOptions"
                  :filterable="false"
                  v-validate="'required'"
                  data-vv-as=" "
                />
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput
                name="productQuality"
                :label="$t('productQuality')"
                required
              >
                <div class="pl-2 pr-2">
                  <Slider
                    name="productQuality"
                    v-model="model.props.answers.productQuality"
                    :min="1"
                    :max="10"
                    show-stops
                    v-validate="'required'"
                    data-vv-as=" "
                    :marks="sliderMarks"
                  />
                </div>
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput
                name="customizationQuality"
                :label="$t('quizCustomizationQualityLabel')"
                required
              >
                <AppSelect
                  name="customizationQuality"
                  v-model="model.props.answers.customizationQuality"
                  :options="customizationQualityOptions"
                  :filterable="false"
                  v-validate="'required'"
                  data-vv-as=" "
                />
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput
                name="shoppingExperience"
                :label="$t('quizShoppingExperienceLabel')"
                required
              >
                <AppSelect
                  name="shoppingExperience"
                  v-model="model.props.answers.shoppingExperience"
                  :options="shoppingExperienceOptions"
                  :filterable="false"
                  v-validate="'required'"
                  data-vv-as=" "
                />
              </BaseInput>
            </div>

            <div class="Question">
              <BaseInput :label="$t('quizNotesLabel')">
                <AppTextArea v-model="model.props.answers.notes" rows="4" />
              </BaseInput>
            </div>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col">
            <BaseButton native-type="submit" type="primary" class="btn-block">
              <i class="fas fa-clipboard-check mr-1" />
              {{ $t('sendAnswers') }}
            </BaseButton>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mergeObjects } from 'util/utils';
import { Slider } from 'element-ui';
import AppSelect from 'components/app/input/AppSelect';
import AppTextArea from 'components/app/AppTextArea';
import { FormMixin } from 'mixins/FormMixin';
import { commonAlerts } from 'util/commonAlerts';
import { QuizUi } from '@/api/Models';

export default {
  name: 'QuizPage',
  components: { AppTextArea, Slider, AppSelect },
  mixins: [FormMixin],
  data() {
    return {
      lock: false,
      notFound: false,
      thanksForYourAnswer: false,
      model: {
        props: {
          answers: {
            orderSource: '',
            productPrice: '',
            attendanceSpeed: '',
            productionSpeed: '',
            freightCost: '',
            customizationQuality: '',
            shoppingExperience: '',
            productQuality: 1,
          },
        },
      },
      orderSourceOptions: _.orderBy(
        QuizUi.Type.AfterSaleQuiz.orderSourceOptions.map((value) => ({
          value,
          label: value,
        })),
        ['label']
      ),
      attendanceSpeedOptions: QuizUi.Type.AfterSaleQuiz.attendanceSpeedOptions.map(
        (value) => ({
          value,
          label: value,
        })
      ),
      freightCostOptions: QuizUi.Type.AfterSaleQuiz.freightCostOptions.map(
        (value) => ({
          value,
          label: value,
        })
      ),
      customizationQualityOptions: QuizUi.Type.AfterSaleQuiz.customizationQualityOptions.map(
        (value) => ({
          value,
          label: value,
        })
      ),
      shoppingExperienceOptions: QuizUi.Type.AfterSaleQuiz.shoppingExperienceOptions.map(
        (value) => ({
          value,
          label: value,
        })
      ),
      productPriceOpts: QuizUi.Type.AfterSaleQuiz.productPriceOpts.map(
        (value) => ({
          value,
          label: value,
        })
      ),
      type: null,
      sliderMarks: _.range(10, 0).reduce((acc, el) => {
        acc[el] = `${el}`;
        return acc;
      }, {}),
    };
  },
  watch: {
    model: {
      deep: true,
      handler(value) {
        if (this.___IGNORE_NEXT) {
          delete this.___IGNORE_NEXT;
          return;
        }
        this.partialSave(value);
      },
    },
  },
  methods: {
    partialSave: _.debounce(async function (value) {
      if (this.lock) return;

      if (this._saving) {
        await this._saving;
      }
      this._saving = this.$api.Quiz.saveResponse(value);
      this._saving.finally(() => delete this._saving);
      return this._saving;
    }, 1000),
    async onSave(model) {
      this.lock = true;
      try {
        if (this._saving) {
          try {
            await this._saving;
          } catch (e) {}
        }

        await this.$api.Quiz.saveResponse(model, { close: true });
        this.thanksForYourAnswer = true;
      } catch (e) {
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.lock = false;
      }
    },
  },
  async mounted() {
    try {
      const quiz = await this.$api.Quiz.findByIdentifier(
        this.$route.params.identifier
      );
      this.___IGNORE_NEXT = true;
      this.model = mergeObjects({}, this.model, quiz);
      this.type = this.model.type;
    } catch (e) {
      if (e.response && e.response.status === 404) {
        this.notFound = true;
      } else {
        console.error(e);
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.QuizPage {
  .Logo {
    height: 96px;
  }

  .Question {
    margin-bottom: 24px;
  }

  .RestoreCustomOrderSource {
  }
}
</style>
