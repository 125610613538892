<template>
  <component :is="tag" class="badge" :class="`badge-${type} ${pill && 'badge-pill'}`">
    <slot></slot>
  </component>
</template>
<script>
export default {
  name: 'Badge',
  props: {
    tag: {
      type: String,
      default: 'span',
      description: 'Badge tag',
    },
    type: {
      type: String,
      default: 'default',
      validator: (value) => {
        let acceptedValues = [
          'primary',
          'info',
          'success',
          'warning',
          'danger',
          'default',
          'white',
          'transparent',
        ];
        return acceptedValues.indexOf(value) !== -1;
      },
      description: 'Badge type (primary|info|success|warning|danger|default|transparent)',
    },
    pill: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
<style lang="scss">
.badge {
  &.topRight {
    position: absolute;
    top: -8px;
    right: -10px;
    padding: 2px 4px;
    min-width: 21px;
    min-height: 16px;
  }

  &.badge-transparent {
    background-color: rgba(50, 50, 50, 0.5);
  }
}
</style>
