<template>
  <div class="ProductionSchedule">
    <div>
      Consigo produzir
      <input
        v-model.number="productionCapacity"
        class="ml-2 mr-2"
        @keypress.enter="calculateTree()"
      />
      canecas.
      <button @click="calculateTree()">Recalcular</button>
    </div>

    <div class="Year" v-for="(yearObj, yearLabel) in tree" :key="yearLabel">
      <h1 class="text-center">
        {{ yearLabel }}
      </h1>
      <div
        class="Month mb-5"
        v-for="(monthObj, monthLabel) in yearObj"
        :key="monthLabel"
      >
        <h2 class="text-center">
          {{ (_.parseInt(monthLabel) + 1) | monthName }}/{{ yearLabel }}
        </h2>
        <div
          class="Day mb-3"
          v-for="(dayData, dayLabel) in monthObj"
          :key="dayLabel"
        >
          <BaseAlert
            v-if="!dayData.$moment.isBusinessDay()"
            type="danger"
            class="mt-2 mb-2"
          >
            {{ $t('notBusinessDay') }}
          </BaseAlert>

          <div class="d-flex">
            <div class="DayDetails mr-4" style="width: 150px">
              <div>
                <b>
                  {{ $t('day') }} {{ dayLabel }} {{ $t('of') }}
                  {{ (_.parseInt(monthLabel) + 1) | monthName }}
                </b>
                <div>
                  {{ dayData.$moment.day() | weekdayName }}
                </div>
              </div>
              <div v-if="!_.isEmpty(dayData.agg)">
                <BaseButton @click="showDetails(dayData.details)">
                  {{ $t('details') }}
                </BaseButton>
              </div>
            </div>
            <div class="DeadlineCol col">
              <div v-if="Object.keys(dayData.agg).length !== 0">
                <h3 class="mb-2">{{ $t('productionDeadline') }}:</h3>
                <TotalProduction :data="dayData.agg" />
              </div>
            </div>
            <div class="SuggestionCol col">
              <div v-if="Object.keys(dayData.suggested).length !== 0">
                <h3 class="mb-2">{{ $t('suggestion') }}:</h3>
                <div class="d-flex align-items-center">
                  <h6 class="mr-2 mb-0">{{ $t('productionUsage') }}:</h6>
                  <BaseProgress
                    :value="dayData.suggested.usage.percent"
                    :show-value="false"
                    :type="dayData.suggested.usage.type"
                    class="flex-grow-1"
                  >
                    {{ dayData.suggested.usage.quantity }}
                    /
                    {{ productionCapacity }}
                  </BaseProgress>
                </div>
                <TotalProduction :data="dayData.suggested.data" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { StatusTypeUi } from 'api/Models';
import Badge from 'components/Badge';
import AttributeValueBadge from 'pages/order/AttributeValueBadge';
import ProductionDetailDialog from 'pages/productionSchedule/ProductionDetailDialog';
import BaseAlert from 'components/BaseAlert';
import BaseProgress from 'components/BaseProgress';
import TotalProduction from 'pages/productionSchedule/TotalProduction';

export default {
  name: 'ProductionSchedule',
  components: {
    TotalProduction,
    BaseProgress,
    BaseAlert,
    AttributeValueBadge,
    Badge,
  },
  data() {
    return {
      productionCapacity: 100,
      orders: [],
      tree: {},
    };
  },
  methods: {
    showDetails(details) {
      this.$openModal(ProductionDetailDialog, {
        details,
      });
    },
    async fetchOrders() {
      this.orders = await this.$api.Order.findAll({
        eager: '[items.[product,attributes.attribute]]',
        'status.types.name': StatusTypeUi.Type.PRODUCTION,
        'productionDeadline:notNull': '',
        orderBy: 'productionDeadline',
      });
      return this.orders;
    },
    async calculateTree() {
      const root = {};

      let now = moment();
      if (!now.isBusinessDay()) {
        now = now.nextBusinessDay();
      }

      let maxDate = now;

      const getDayRoot = (date, create = true) => {
        let [year, month, day] = [date.year(), date.month(), date.date()];

        if (!root[year] && create) {
          const yearData = {};
          _.range(month, 11).forEach((monthNumber) => {
            const monthData = {};
            const days = moment({ year, month: monthNumber }).daysInMonth();
            const start =
              year === now.year() && monthNumber === now.month()
                ? now.date()
                : 1;
            _.range(start, days + 1).forEach((dayNumber) => {
              return (monthData[dayNumber] = {
                $moment: moment({ day: dayNumber, month: monthNumber, year }),
                showDetails: false,
                details: [],
                agg: {},
                suggested: {},
                quantity: 0,
              });
            });
            return (yearData[monthNumber] = monthData);
          });
          root[year] = yearData;
        }

        const yearRoot = root[year];
        const monthRoot = yearRoot[month];
        return monthRoot[day];
      };

      const buildItemKey = ({ item }) => {
        return `${item.product.id}`;
      };

      const buildAttrsKey = ({ attrVals }) => {
        return attrVals
          .map((attrVal) => `${attrVal.attribute.id}-${attrVal.id}`)
          .join(',');
      };

      const byDate = {};
      const setByDate = (dayData) => {
        const key = dayData.$moment.format('YYYY-MM-DD');
        if (!byDate.hasOwnProperty(key)) {
          byDate[key] = [];
        }
        byDate[key] = dayData;
      };

      this.orders.forEach((order) => {
        const date = moment(order.productionDeadline);
        maxDate = moment.max(maxDate, date);
        order.$productionDeadline = date;

        let distance = date.diff(now, 'd');
        const isLate = distance < 0;
        distance = Math.abs(distance);
        const dayData = getDayRoot(isLate ? now : date);
        setByDate(dayData);
        const items = order.items.map((item) => {
          const itemData = {
            item,
            attrVals: _.orderBy(
              item.attributes.filter((attrVal) => attrVal.attribute.compound),
              'attribute.caption'
            ),
          };
          const itemKey = buildItemKey(itemData);
          if (!dayData.agg[itemKey]) {
            dayData.agg[itemKey] = {
              product: item.product,
              attrs: {},
            };
          }

          const { attrs } = dayData.agg[itemKey];
          const attrsKey = buildAttrsKey(itemData);
          if (!attrs[attrsKey]) {
            attrs[attrsKey] = { attrVals: itemData.attrVals, quantity: 0 };
          }

          attrs[attrsKey].quantity += item.quantity;

          return itemData;
        });

        dayData.details.push({
          order,
          deadline: date,
          distance,
          isLate,
          items,
        });
      });

      {
        let dayCap = null;

        const data = JSON.parse(JSON.stringify(byDate));

        const processDayCapUsage = (dayCap) => {
          if (dayCap) {
            dayCap.usage.quantity = this.productionCapacity - dayCap.cap;
            dayCap.usage.percent =
              (dayCap.usage.quantity / this.productionCapacity) * 100;
            dayCap.usage.percentLimited = Math.max(dayCap.usage.percent, 100);
            dayCap.usage.type =
              dayCap.usage.percent < 60
                ? 'success'
                : dayCap.usage.percent < 85
                ? 'warning'
                : 'danger';
          }
        };
        for (const [dateKey, dayData] of Object.entries(data)) {
          for (const [productId, aggData] of Object.entries(dayData.agg)) {
            if (!aggData.product.name.toLowerCase().includes('caneca'))
              continue;
            for (const [attrKey, data] of Object.entries(aggData.attrs)) {
              while (data.quantity !== 0) {
                if (!dayCap || dayCap.cap === 0) {
                  processDayCapUsage(dayCap);
                  dayCap = {
                    $moment: dayCap
                      ? dayCap.$moment.clone().nextBusinessDay()
                      : now.clone(),
                    cap: this.productionCapacity,
                    agg: {},
                    usage: {
                      quantity: 0,
                      percent: 0,
                      percentLimited: 0,
                      type: 'default',
                    },
                  };
                  maxDate = moment.max(maxDate, dayCap.$moment);
                  root[dayCap.$moment.year()][dayCap.$moment.month()][
                    dayCap.$moment.date()
                  ].suggested = {
                    data: dayCap.agg,
                    usage: dayCap.usage,
                  };
                }

                let dayCapAttrAgg;
                {
                  if (!dayCap.agg[productId]) {
                    dayCap.agg[productId] = {
                      product: aggData.product,
                      attrs: {},
                    };
                  }

                  const dayCapProductAgg = dayCap.agg[productId];

                  if (!dayCapProductAgg.attrs[attrKey]) {
                    dayCapProductAgg.attrs[attrKey] = {
                      attrVals: data.attrVals,
                      quantity: 0,
                    };
                  }
                  dayCapAttrAgg = dayCapProductAgg.attrs[attrKey];
                }

                if (dayCap.cap >= data.quantity) {
                  dayCapAttrAgg.quantity += data.quantity;
                  dayCap.cap -= data.quantity;
                  data.quantity = 0;
                } else {
                  dayCapAttrAgg.quantity += dayCap.cap;
                  data.quantity -= dayCap.cap;
                  dayCap.cap = 0;
                }
              }
            }
          }
        }
        processDayCapUsage(dayCap);
      }

      const cleanWithoutData = (tree) => {
        let year = maxDate.year();
        let yearData = tree[year];
        for (let i = 0; i < 999; i++) {
          if (!yearData) break;

          let month = maxDate.month() + 1;
          let monthData = yearData[month];
          for (let j = 0; j < 12; j++) {
            if (!monthData) break;

            delete yearData[month];
            month++;
            monthData = yearData[month];
          }

          year++;
          yearData = tree[year];
        }
      };
      cleanWithoutData(root);

      this.tree = root;
    },
  },
  async mounted() {
    await this.fetchOrders();
    await this.calculateTree();
  },
};
</script>

<style lang="scss">
.ProductionSchedule {
  .OrderBox {
    border: 1px solid white;
  }

  .Day {
    border-bottom: 1px solid white;

    .alert {
      padding-top: 4px;
      padding-bottom: 4px;
    }
  }
}
</style>
