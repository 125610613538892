<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  />
</template>
<script>
import UserEditDialog from 'pages/user/UserEditDialog';
import { EventType } from 'plugins/eventBusPlugin';

export default {
  name: 'Users',
  data() {
    return {
      dialog: UserEditDialog,
      eventsToListen: [EventType.UserChanged],
      tableColumns: [
        { prop: 'id' },
        { prop: 'name' },
        { prop: 'username' },
        { prop: 'email' },
        { prop: 'role', render: ({ row }) => this.$t(row.role) },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|name|username|email|role:ilike'] = `%${query}%`;
      }
      return await this.$api.User.brain.findAll({
        orderBy: 'name',
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      await this.$api.User.brain.remove(row.id);
    },
  },
};
</script>
<style scoped lang="scss"></style>
