<template>
  <Dialog @close="$emit('close')" :loading="loading" :title="title">
    <form @submit.stop.prevent="$emit('save')">
      <div>
        <slot />
      </div>

      <div class="text-right mt-4">
        <slot name="footer" />
        <BaseButton native-type="submit" type="primary" v-if="!hideSave">
          {{ buttonLabel || $t('save') }}
        </BaseButton>
      </div>
    </form>
  </Dialog>
</template>

<script>
import Dialog from 'components/app/Dialog';

export default {
  name: 'CrudDialog',
  components: { Dialog },
  props: {
    loading: {},
    title: {},
    hideSave: { default: false },
    buttonLabel: {},
  },
};
</script>

<style scoped></style>
