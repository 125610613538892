<template>
  <div class="container">
    <div class="col-lg-6 col-md-8 ml-auto mr-auto">
      <form @submit.prevent="login">
        <Card class="card-login card-white">
          <template slot="header">
            <img src="/img/card-primary.png" alt="" />
            <h1 class="card-title">Log in</h1>
          </template>

          <div>
            <BaseInput
              v-validate="'required'"
              name="username"
              v-model="model.username"
              placeholder="Email"
              addon-left-icon="tim-icons icon-email-85"
              autocomplete="username"
              data-test="UserField"
            />

            <BaseInput
              v-validate="'required'"
              name="password"
              v-model="model.password"
              type="password"
              placeholder="Password"
              addon-left-icon="tim-icons icon-lock-circle"
              autocomplete="password"
              data-test="PasswordField"
            />
          </div>

          <div slot="footer">
            <BaseButton
              native-type="submit"
              type="primary"
              size="lg"
              block
              data-test="LoginButton"
            >
              {{ $t('login') }}
            </BaseButton>
          </div>
        </Card>
      </form>
    </div>
  </div>
</template>
<script>
import { appCache } from 'state/Cache';

export default {
  data() {
    return {
      model: {
        username: '',
        password: '',
      },
    };
  },
  provide() {
    return { errors: this.errors };
  },
  methods: {
    async login() {
      let isValidForm = await this.$validator.validateAll();
      if (isValidForm) {
        try {
          const authData = await this.$api.Auth.login(
            this.model.username,
            this.model.password
          );
          this.$store.commit('login', authData);
          this.$router.push('/');
        } catch (e) {
          console.warn(e);
          this.$notify({
            message: this.$t('loginInvalidUserOrPassword'),
            type: 'warning',
          });
        }
      }
    },
  },
  mounted() {
    appCache.clear();
  },
};
</script>
<style>
.navbar-nav .nav-item p {
  line-height: inherit;
  margin-left: 5px;
}
</style>
