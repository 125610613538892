<template>
  <Modal
    class="OrderActivitiesViewDialog"
    :show="true"
    :show-close="true"
    modalClasses="modal-lg"
    @close="$emit('close')"
    :loading="loading"
  >
    <div slot="header">
      <h4 class="title title-up">
        {{ $t('activities') }} {{ $t('order') }}: {{ order | orderCode }}
      </h4>
      <div class="TypeFilter">
        <label class="mr-2 d-inline-block">{{ $t('see') }}:</label>
        <AppSelect
          class="d-inline-block"
          :multiple="true"
          :clearable="false"
          :collapse-tags="true"
          v-model="types"
          :options="availableTypes"
        />
      </div>
    </div>

    <div class="Activities">
      <h3 v-if="_.isEmpty(activities)" class="text-center">
        {{ $t('noActivitiesFound') }}
      </h3>

      <div
        v-for="activity in activities"
        :key="activity.id"
        :class="['Activity', activity.type, 'mb-4']"
      >
        <div class="Header">
          <time>{{ activity.createdAt | formatDateTime }}</time>
          <Badge class="User ml-2">
            <i class="fas fa-user mr-1" />
            {{ activity.user.name }}
          </Badge>

          <Badge class="ml-2" type="danger" v-if="activity.isCancellation()">
            {{ $t('withdrawal') }}
          </Badge>

          <Badge class="ml-2" type="danger" v-if="activity.isStatusChange()">
            {{ $t('statusChange') }}
          </Badge>

          <Tooltip
            :content="$t('remove')"
            v-if="
              activity.userId === $store.state.user.id ||
                $perm.hasAccess($permType.OrderActivityRemoval)
            "
          >
            <BaseButton
              icon
              size="sm"
              type="danger"
              class="btn-link pull-right"
              @click="removeActivity(activity)"
            >
              <i class="far fa-trash-alt" />
            </BaseButton>
          </Tooltip>
        </div>
        <div class="Body">
          <p v-if="!_.isEmpty(activity.getReasons())">
            <b class="text-danger">{{ $t('reasons') }}: </b>
            <Badge
              class="ml-2"
              type="danger"
              v-for="(reason, idx) in activity.getReasons()"
              :key="idx"
            >
              {{ reason }}
            </Badge>
          </p>
          <div v-if="activity.isStatusChange()">
            <Badge type="info">
              {{ findStatus(activity.props.oldStatusId).description }}
            </Badge>
            <i class="fas fa-arrow-right ml-2 mr-2" />
            <Badge type="info">
              {{ findStatus(activity.props.newStatusId).description }}
            </Badge>
          </div>
          <pre>{{ activity.getMessage() }}</pre>
        </div>
      </div>
    </div>

    <div
      class="Pagination col-12 d-flex justify-content-center align-items-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">
          {{ from + 1 }} - {{ to }} {{ $t('of') }} {{ total }}
        </p>
      </div>

      <div class="d-flex align-items-center">
        <AppSelect
          :options="pagination.perPageOptions"
          v-model="pagination.perPage"
          :clearable="false"
          :filterable="false"
          style="width: 70px"
        />
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          style="margin: 0"
        >
        </base-pagination>
      </div>
    </div>

    <div class="MessageBox mt-4">
      <Tooltip :content="$t('orderActivityCommentFieldPlaceholder')">
        <BaseInput name="message">
          <textarea
            name="message"
            v-model="model.props.message"
            @keyup.ctrl.enter="save"
            class="form-control"
            :placeholder="$t('orderActivityCommentFieldPlaceholder')"
            maxlength="8192"
          >
          </textarea>
        </BaseInput>
      </Tooltip>
    </div>
  </Modal>
</template>

<script>
import { commonAlerts } from 'util/commonAlerts';
import Badge from 'components/Badge';
import AppSelect from 'components/app/input/AppSelect';
import { OrderActivityUi } from 'api/Models';

export default {
  name: 'OrderActivitiesViewDialog',
  components: { AppSelect, Badge },
  props: {
    order: { required: true },
  },
  data() {
    return {
      loading: false,
      model: {
        type: 'MESSAGE',
        props: {
          message: '',
        },
      },
      activities: [],
      pagination: {
        perPage: 100,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100].map(v => ({ value: v })),
        total: 0,
      },
      types: ['MESSAGE'],
      availableStatus: [],
      availableTypes: Object.keys(OrderActivityUi.Types).map(value => ({
        value,
        label: this.$t(value),
      })),
    };
  },
  watch: {
    'pagination.currentPage'(val) {
      this.fetchActivities();
    },
    'pagination.perPage'(val) {
      this.fetchActivities();
    },
    types() {
      this.fetchActivities();
    },
  },
  computed: {
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  methods: {
    async fetchActivities() {
      this.loading = true;
      try {
        const data = _.isEmpty(this.types)
          ? []
          : await this.$api.OrderActivity.findAll({
              orderId: this.order.id,
              eager: 'user',
              orderByDesc: 'createdAt',
              'type:in': this.types.join(','),
              rangeStart: this.from,
              rangeEnd: this.from + this.pagination.perPage - 1,
            });

        this.activities = data.results;
        this.pagination.total = data.total;
      } finally {
        this.loading = false;
      }
    },
    async save() {
      if (!this.model.props.message) {
        return;
      }
      this.loading = true;
      try {
        const model = _.cloneDeep(this.model);
        model.orderId = this.order.id;
        model.userId = this.$store.state.user.id;
        await this.$api.OrderActivity.save(model);

        this.model = {
          type: 'MESSAGE',
          props: {
            message: '',
          },
        };

        this.fetchActivities();
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async removeActivity(activity) {
      if (!(await commonAlerts.confirmOperation())) {
        return;
      }

      try {
        await this.$api.OrderActivity.remove(activity.id);
        this.fetchActivities();
      } catch (e) {
        console.error(e);
        await commonAlerts.defaultErrorMessage(e);
      }
    },
    findStatus(id) {
      const status = this.availableStatus.find(s => s.id === id);
      if (status) {
        return status;
      }
      return { description: `${id}: ${this.$t('notFound')}` };
    },
  },
  mounted() {
    this.$api.OrderStatus.findAll().then(data => (this.availableStatus = data));
    this.fetchActivities();
  },
};
</script>

<style lang="scss">
@import '~assets/sass/dashboard/custom/variables';

.OrderActivitiesViewDialog {
  .Activities {
    height: 400px;
    overflow-y: auto;
  }
  .Activity,
  .modal-content .modal-body p.card-category {
    color: $opacity-8;
  }

  .Activity .Header {
    font-size: $font-size-sm;
  }

  .Activity .Body pre {
    font-size: 13.5px;
    white-space: pre-line;
  }

  .TypeFilter {
    position: absolute;
    top: 5px;
    right: 75px;
  }
}
</style>
