<template>
  <div class="CancelledOrdersReport" v-loading="loading">
    <div class="row">
      <div class="col-11 col-sm-6 col-md-4">
        <StatsCard
          :title="`${orderCount}`"
          :sub-title="$t('cancelledOrders')"
          type="warning"
          :icon="'fas fa-thumbs-down'"
        />
      </div>
      <div class="col text-right">
        <OrderFilterButton @filter="runSearch" ref="filterButton" />
      </div>
    </div>

    <div class="row mb-5">
      <div class="w-100" v-if="chart" style="max-width: 700px; margin: 0 auto">
        <PieChart :chart-data="chart.data" :extra-options="chart.options" />
      </div>
    </div>

    <div class="Notes">
      <h3>{{ $t('notes') }}</h3>
      <div
        class="Note"
        v-for="({ order, message, reasons }, idx) in messages"
        :key="order.id"
      >
        <div class="row mb-2">
          <div class="col">
            <Badge class="mr-2">
              {{ order.orderCode }}
            </Badge>
            <small
              ><b>{{ order.customer.name }}</b></small
            >
            <div class="pull-right">
              <small> {{ $t('reasons') }}: </small>

              <Badge
                v-for="(reason, idx) in reasons"
                :key="reason"
                :class="{ 'mr-2': reasons.length - 1 !== idx }"
                type="warning"
              >
                {{ reason }}
              </Badge>
              |
              <Badge>
                {{ order.date | formatDateTime('date') }}
              </Badge>
            </div>
          </div>
        </div>
        <pre>
          {{ message }}
        </pre>
        <hr v-if="idx !== messages.length - 1" />
      </div>
    </div>
  </div>
</template>

<script>
import PieChart from 'components/Charts/PieChart';
import { StatusTypeUi } from '@/api/Models';
import StatsCard from '@/components/Cards/StatsCard';
import OrderFilterButton from '@/pages/funnel/OrderFilterButton';
import AppFilterDatePicker from '@/components/app/input/AppFilterDatePicker';
import Badge from '@/components/Badge';

export default {
  name: 'CancelledOrdersReport',
  components: { Badge, OrderFilterButton, StatsCard, PieChart },
  data() {
    return {
      loading: false,
      filter: null,
      chart: null,
      orderCount: 0,
      messages: [],
    };
  },
  methods: {
    async runSearch(filter) {
      this.filter = filter;
      await this.fetchData();
    },
    async fetchData() {
      this.loading = true;
      try {
        const filters = {};

        Object.assign(
          filters,
          AppFilterDatePicker.formatAsFilter(
            this.filter.date.field,
            this.filter.date.range
          )
        );

        if (!_.isEmpty(this.filter.statusTypes)) {
          filters['statusId:in'] = this.filter.statusTypes
            .reduce((acc, type) => {
              for (const os of type.orderStatus) {
                if (!acc.includes(os.id)) {
                  acc.push(os.id);
                }
              }
              return acc;
            }, [])
            .join(',');
        }

        let orders = await this.$api.Order.findAll({
          eager:
            '[items.[product, attributes], shipments, payments, customer.[contacts], status, activities, appliedCoupons]',
          orderByDesc: 'date',
          ...filters,
        });

        if (this.filter.isAnyFilterSelected()) {
          orders = this.filter.filterOrders(orders);
        }

        this.orderCount = orders.length;

        const reasons = {};
        const messages = [];
        for (const order of orders) {
          const cancellationActivity = _.orderBy(
            order.activities,
            (a) => new Date(a.updatedAt),
            'desc'
          ).find((a) => a.type === 'CANCELLATION');

          order.activities = null;

          if (!cancellationActivity) {
            const reason = '(Sem informações sobre a desistência)';
            const agg = reasons[reason] || { count: 0, orders: [] };
            agg.count++;
            agg.orders.push(order);
            if (!reasons.hasOwnProperty(reason)) {
              reasons[reason] = agg;
            }
            continue;
          }

          const activityReasons = cancellationActivity.props?.reasons || [];
          const message = (cancellationActivity.props?.message || '').trim();
          let automaticMoved = false;
          if (_.isEmpty(activityReasons)) {
            if (message.includes('automaticamente')) {
              automaticMoved = true;
              activityReasons.push('(Movido automaticamente)');
            } else {
              activityReasons.push('(Não informado)');
            }
          }
          activityReasons.sort();

          if (!automaticMoved && !_.isEmpty(message)) {
            messages.push({ order, message, reasons: activityReasons });
          }

          const reason = activityReasons.join(', ');
          //for (const reason of activityReasons) {
          const agg = reasons[reason] || { count: 0, orders: [] };
          agg.count++;
          agg.orders.push(order);
          if (!reasons.hasOwnProperty(reason)) {
            reasons[reason] = agg;
          }
          //}
        }
        this.messages = messages;

        let manyColors = [
          '#003f5c',
          '#00876c',
          '#2f4b7c',
          '#665191',
          '#a05195',
          '#78a97f',
          '#d45087',
          '#f95d6a',
          '#ff7c43',
          '#ffa600',
          '#4e9873',
          '#9dba8f',
          '#bfcca3',
          '#dddebb',
          '#f9f1d6',
          '#f0d8b1',
          '#eabe90',
          '#e28461',
          '#dc6455',
          '#d43d51',
        ];

        this.chart = {
          options: {
            name: this.$t(`reasons`),
            title: {
              display: true,
              text: this.$t(`reasons`),
              fontSize: 24,
              fontStyle: 'normal',
              fontColor: 'rgba(255, 255, 255, 0.8)',
            },
            legend: {
              position: 'bottom',
              labels: {
                fontSize: 11,
                boxWidth: 12,
                fontColor: 'rgba(255, 255, 255, 0.8)',
              },
            },
          },
          data: {
            labels: Object.keys(reasons),
            datasets: [
              {
                data: Object.values(reasons).map((r) => r.count),
                backgroundColor: manyColors,
                borderColor: '#1e1e2f',
                borderWidth: 1,
              },
            ],
          },
        };
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    const [statusType] = await this.$api.StatusType.findAll({
      name: StatusTypeUi.Type.CANCELLED,
      eager: '[orderStatus]',
    });
    this.filter = new OrderFilterButton.OrderFilterButtonModel({
      date: {
        range: [
          moment().subtract(3, 'months').startOf('month'),
          moment().endOf('month'),
        ],
      },
      statusTypes: [statusType],
    });
    this.$refs.filterButton.model = this.filter;
    this.fetchData();
  },
};
</script>

<style lang="scss">
.CancelledOrdersReport {
  .Notes {
    .Note {
      pre {
        white-space: pre-line;
      }
    }
  }
}
</style>
