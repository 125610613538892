<template>
  <Card class="CrudPage" body-classes="CrudPageBody" footer-classes="CrudPageFooter">
    <div class="row">
      <div class="col" style="max-width: 240px" v-if="showDefaultSearch">
        <div class="form-group">
          <Tooltip :content="$t('pressEnterToSearch')">
            <BaseInput
              v-model="query"
              :placeholder="`${$t('search')}...`"
              addon-left-icon="tim-icons icon-zoom-split"
              :disable-validation="true"
              @keyup.enter="runSearch"
            />
          </Tooltip>
        </div>
      </div>
      <div class="col" style="max-width: 280px" v-if="dateField">
        <div class="form-group">
          <AppFilterDatePicker v-model="filters.dateRange" />
        </div>
      </div>
      <slot name="headerFilters" :fetchEntities="this.fetchEntities" />
      <div class="col" v-if="(showNewBtn && showEditBtn) || !_.isEmpty($slots.headerButtons)">
        <div class="text-right mb-3">
          <slot name="headerButtons" />
          <base-button @click="handleNew()" v-if="showNewBtn && showEditBtn">
            {{ $t('new') }}
          </base-button>
        </div>
      </div>
    </div>

    <slot v-bind="{ visibleEntities: visibleEntities() }">
      <AppTable
        :data="visibleEntities()"
        :columns="tableColumns"
        v-loading="loading"
        @sort-change="sortChanged"
        v-bind="tableAttrs"
        ref="table"
      >
        <template v-slot:before>
          <slot name="beforeColumns" />
        </template>

        <slot name="additionalColumns" />

        <ElTableColumn
          :label="$t('actions')"
          :width="actionColWidth"
          v-if="showDeleteBtn || showEditBtn || showActionCol"
        >
          <template slot-scope="props">
            <slot name="tableButtons" :rowProps="props" />
            <TableEditButton v-if="showEditBtn" @click="handleEdit(props)" />
            <TableRemoveButton v-if="showDeleteBtn" @click="handleDelete(props)" />
          </template>
        </ElTableColumn>
      </AppTable>
    </slot>
    <slot name="afterTable" :visibleEntities="visibleEntities()" />
    <div
      slot="footer"
      class="d-flex justify-content-center align-items-center justify-content-sm-between flex-wrap"
    >
      <div class="">
        <p class="card-category">{{ from + 1 }} - {{ to }} {{ $t('of') }} {{ total }}</p>
      </div>

      <div class="d-flex align-items-center">
        <AppSelect
          :options="pagination.perPageOptions"
          v-model="pagination.perPage"
          :clearable="false"
          :filterable="false"
          style="width: 70px"
        />
        <base-pagination
          class="pagination-no-border"
          v-model="pagination.currentPage"
          :per-page="pagination.perPage"
          :total="total"
          style="margin: 0"
        >
        </base-pagination>
      </div>
    </div>
  </Card>
</template>
<script>
import { commonAlerts } from 'util/commonAlerts';
import AppSelect from './input/AppSelect';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';

export default {
  name: 'CrudPage',
  components: { AppDateTimePicker, AppFilterDatePicker, AppSelect },
  props: {
    columns: {},
    dialog: {},
    dialogProps: {
      default: () => ({}),
    },
    fetchData: {
      type: Function,
    },
    listenToEvents: {
      default() {
        return [];
      },
    },
    onRemove: {
      type: Function,
    },
    showNewBtn: { default: true },
    actionColWidth: { default: 100 },
    dateField: {},
    tableAttrs: {},
    showActionCol: {
      default: false,
    },
    initialQuery: { default: '' },
    initialPerPage: { default: 10 },
    initialDateRange: {},
    showDefaultSearch: {
      default: true,
    },
  },
  data() {
    return {
      loading: false,
      pagination: {
        perPage: this.initialPerPage,
        currentPage: 1,
        perPageOptions: [5, 10, 25, 50, 100].map((v) => ({ value: v })),
        total: 0,
      },
      entities: [],
      query: this.initialQuery,
      filters: {
        dateRange: this.initialDateRange || [],
      },
      sort: _.cloneDeep(_.get(this, 'tableAttrs.defaultSort', {})),
      dataIsPaged: true,
    };
  },
  computed: {
    showEditBtn() {
      return !!this.dialog;
    },
    showDeleteBtn() {
      return !!this.onRemove;
    },
    tableColumns() {
      if (_.isFunction(this.columns)) {
        return this.columns();
      } else {
        return this.columns;
      }
    },
    listenedEvents() {
      if (_.isFunction(this.listenToEvents)) {
        return this.listenToEvents();
      } else {
        return this.listenToEvents;
      }
    },
    to() {
      let highBound = this.from + this.pagination.perPage;
      if (this.total < highBound) {
        highBound = this.total;
      }
      return highBound;
    },
    from() {
      return this.pagination.perPage * (this.pagination.currentPage - 1);
    },
    total() {
      return this.pagination.total;
    },
  },
  watch: {
    'pagination.currentPage'(val) {
      if (!this.dataIsPaged) return;
      this.fetchEntities();
    },
    'pagination.perPage'(val) {
      if (!this.dataIsPaged) return;
      this.fetchEntities();
    },
    'filters.dateRange'(val) {
      this.fetchEntities();
    },
    sort(val) {
      this.fetchEntities();
    },
  },
  methods: {
    runSearch() {
      this.fetchEntities();
    },
    async fetchEntities() {
      this.loading = true;
      try {
        const data = await this.fetchData({
          query: this.query,
          pagination: {
            rangeStart: this.from,
            rangeEnd: this.from + this.pagination.perPage - 1,
          },
          filters: this.filters,
          sort: this.sort,
        });
        if (_.isArray(data)) {
          this.entities = data;
          this.pagination.total = data.length;
          this.dataIsPaged = false;
        } else {
          this.entities = data.results;
          this.pagination.total = data.total;
          this.dataIsPaged = true;
        }
      } finally {
        this.loading = false;
      }
    },
    handleNew(id = undefined, entity = undefined) {
      this.$openModal(this.dialog, {
        ...this.dialogProps,
        id,
        entity,
      });
    },
    async handleEdit({ row }) {
      this.handleNew(row.id, row);
    },
    async handleDelete({ row }) {
      if (!(await commonAlerts.confirmOperation())) {
        return;
      }

      try {
        await this.onRemove(row);
        this.fetchEntities();
      } catch (e) {
        console.error(e);
        await commonAlerts.defaultErrorMessage(e);
      }
    },
    sortChanged({ column, prop, order }) {
      this.sort = order ? { prop, order } : null;
    },
    visibleEntities() {
      const { dataIsPaged, entities, from, to } = this;
      return dataIsPaged ? entities : entities.slice(from, to);
    },
  },
  mounted() {
    this.listenedEvents.forEach((event) => {
      this.$bus.$on(event, this.fetchEntities);
    });

    this.fetchEntities();
  },
  beforeDestroy() {
    this.listenedEvents.forEach((event) => {
      this.$bus.$off(event, this.fetchEntities);
    });
  },
};
</script>
<style lang="scss">
.Dialog {
  .CrudPage {
    box-shadow: none;
    margin-bottom: 0;

    .CrudPageBody {
      padding: 0;
    }

    .CrudPageFooter {
      padding-bottom: 0;
      padding-left: 0;
      padding-right: 0;
    }
  }
}
</style>
