<template>
  <CrudDialog
    :title="$t('orderStatus')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <BaseInput
      type="text"
      name="id"
      :label="$t('id')"
      v-model.number="model.id"
      :readonly="true"
      number
    />

    <div class="row">
      <div class="col-3">
        <BaseInput name="funnel" :label="$t('funnel')" required>
          <AppSelect
            value-key="id"
            name="funnel"
            v-model="model.funnel"
            :options="funnelOptions"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput
          type="text"
          name="description"
          :label="$t('description')"
          v-model="model.description"
          v-validate="'required|min:2'"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput
          type="number"
          name="order"
          :label="$t('position')"
          v-model.number="model.order"
          v-validate="'required|integer'"
          required
        />
      </div>
      <div class="col">
        <BaseInput name="visible" :label="$t('visible')">
          <AppSelect
            name="ordination"
            v-model="model.visible"
            :options="visibleOptions"
            v-validate="'required'"
            :clearable="false"
            :filterable="false"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="ordination" :label="$t('ordination')">
          <AppSelect
            name="ordination"
            v-model="model.props.sortBy"
            :options="sortOptions"
            v-validate="'required'"
            :clearable="false"
            :filterable="false"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="sectors" :label="$t('sectors')">
          <AppSelect
            name="sectors"
            v-model="model.props.sectors"
            :options="availableSectors"
            :multiple="true"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="statusBgColor" :label="$t('bgColor')">
          <AppColorPicker name="statusBgColor" v-model="model.props.bgColor" />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="cardBgColor" :label="$t('cardBgColor')">
          <AppColorPicker
            name="cardBgColor"
            v-model="model.props.cardBgColor"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="cardTextColor" :label="$t('cardTextColor')">
          <AppColorPicker
            name="cardTextColor"
            v-model="model.props.cardTextColor"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="size" :label="$t('size')">
          <AppSelect
            name="size"
            v-model.number="model.props.size"
            :options="sizeOptions"
            :filterable="false"
            :clearable="false"
            v-validate="'required|integer|min:1|max:8'"
          />
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppColorPicker from 'components/app/input/AppColorPicker';
import Collapse from 'components/Collapse/Collapse';
import CollapseItem from 'components/Collapse/CollapseItem';
import { OrderStatusUi } from 'api/Models';
import { mergeObjects } from 'util/utils';

export default {
  name: 'OrderStatusEditDialog',
  components: { CollapseItem, Collapse, AppColorPicker, AppSelect },
  mixins: [FormMixin],
  props: {
    id: {},
    funnel: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        description: '',
        order: 1,
        visible: true,
        funnel: this.funnel,
        props: {
          sortBy: 'Date',
          bgColor: 'rgba(39, 41, 61, 1)',
          cardBgColor: 'rgba(58, 62, 91, 1)',
          cardTextColor: 'rgba(255, 255, 255, 0.8)',
          sectors: [],
          size: 1,
        },
      },
      sortOptions: Object.keys(OrderStatusUi.Sort).map(value => ({
        value,
        label: this.$t(`OrderStatus.Sort.${value}`),
      })),
      sizeOptions: _.range(1, 9).map(value => ({ value })),
      availableSectors: [],
      visibleOptions: [
        { value: true, label: this.$t('yes') },
        { value: false, label: this.$t('no') },
      ],
      funnelOptions: [],
    };
  },
  methods: {
    async onSave() {
      await this.$api.OrderStatus.save(this.model);
      this.$bus.$emit(EventType.OrderStatusChanged);
    },
  },
  async mounted() {
    this.availableSectors = (
      await this.$api.Sector.findAll({
        orderBy: 'name',
      })
    ).map(sector => ({
      value: sector.id,
      label: sector.name,
    }));

    this.funnelOptions = (
      await this.$api.Funnel.findAll({ orderBy: 'name' })
    ).map(value => ({ value, label: value.name }));

    if (this.id) {
      try {
        this.loading = true;
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.OrderStatus.findById(this.id, { eager: '[funnel]' })
        );
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
