<template>
  <div
    class="container-fluid position-relative"
    ref="container"
    v-if="!_.isEmpty(order)"
  >
    <OrderMailTagExportPageContent :order="order" />
  </div>
</template>

<script>
import Badge from 'components/Badge';
import OrderMailTag from 'pages/order/OrderMailTag';
import SenderOrderMailTag from 'pages/order/SenderOrderMailTag';
import OrderMailTagExportPageContent from 'pages/order/OrderMailTagExportPageContent';

export default {
  name: 'OrderMailTagExportPage',
  components: {
    OrderMailTagExportPageContent,
    SenderOrderMailTag,
    OrderMailTag,
    Badge,
  },
  props: {
    orderId: {},
  },
  data() {
    return {
      order: null,
    };
  },
  methods: {
    async fetchOrder() {
      this.order = {};
      if (this.orderId) {
        this.loading = true;
        try {
          this.order = await this.$api.Order.findById(this.orderId, {
            eager: `[status, customer.[contacts, addresses], items.[product, attributes.[attribute]], shipments.[method]]`,
          });
        } finally {
          this.loading = false;
        }
      }
    },
  },
  async mounted() {
    const body = document.querySelector('body');
    body.classList.add('white-content');
    body.classList.add('forPrint');
    await this.fetchOrder();
    setTimeout(function() {
      window._PAGE_LOAD_FINISHED = true;
    }, 1000);
  },
};
</script>

<style scoped lang="scss">
.container-fluid {
  height: 100vh;
  overflow: hidden;
}
</style>
