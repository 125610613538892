<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  />
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import ProductEditDialog from 'pages/product/ProductEditDialog';
import MoneyCol from 'components/app/table/MoneyCol';
import BooleanCol from 'components/app/table/BooleanCol';

export default {
  name: 'Products',
  data() {
    return {
      dialog: ProductEditDialog,
      eventsToListen: [EventType.ProductChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 80 } },
        { prop: 'name' },
        /*{ prop: 'sku' },*/
        {
          prop: 'cost',
          component: MoneyCol,
          attrs: { width: 150 },
        },
        {
          prop: 'price',
          component: MoneyCol,
          attrs: { width: 150 },
        },
        /*        {
          prop: 'provider',
          render({ row }) {
            return row.provider ? row.provider.name : '-';
          },
        },*/
        {
          prop: 'countOnShipping',
          component: BooleanCol,
          attrs: { width: 100 },
        },
        /*        {
          prop: 'rawMaterial',
          component: BooleanCol,
          attrs: { width: 100 },
        },*/
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|code|name|sku:ilike'] = `%${query}%`;
      }
      return await this.$api.Product.findAll({
        eager: '[provider]',
        orderBy: ['name'],
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.Product.remove(row.id);
    },
  },
};
</script>
<style scoped lang="scss"></style>
