<template>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <h4>{{ title }}</h4>
        </div>
        <div class="col text-right">
          <BaseButton size="sm" @click="addMaterial">
            <i class="fas fa-plus" />
          </BaseButton>
        </div>
      </div>

      <AppTable :data="value" max-height="400">
        <ElTableColumn :label="$t('product')">
          <template slot-scope="{ row, $index }">
            <BaseInput :name="`itemProduct${instance}${$index}`" required>
              <AppSelect
                :name="`itemProduct${instance}${$index}`"
                v-model="row.product"
                :data-vv-as="$t('product')"
                :options="productOptions"
                value-key="id"
                v-validate="'required'"
              />
            </BaseInput>
          </template>
        </ElTableColumn>

        <ElTableColumn :label="$t('attributes')">
          <template slot-scope="{ row, $index }">
            <BaseInput :name="`itemAttribute${instance}${$index}`">
              <AppSelect
                :name="`itemAttribute${instance}${$index}`"
                value-key="id"
                v-model="row.attributeValues"
                :options="attributeOptions(row.product)"
                :multiple="true"
                :grouped="true"
                :on-select-filter="retainDuplicatedAttributes"
                :disabled="_.isEmpty(row.product)"
              >
                <slot slot-scope="{ option }">
                  {{ option.value.value }}
                </slot>
              </AppSelect>
            </BaseInput>
          </template>
        </ElTableColumn>

        <ElTableColumn width="120" :label="$t('quantity')">
          <template slot-scope="{ row, $index }">
            <BaseInput :name="`ItemQuantity${instance}${$index}`" required>
              <InputMoney
                :name="`ItemQuantity${instance}${$index}`"
                v-model.number="row.quantity"
                :prefix="''"
                :precision="0"
                :data-vv-as="$t('quantity')"
              />
            </BaseInput>
          </template>
        </ElTableColumn>

        <ElTableColumn width="80" :label="$t('actions')">
          <template slot-scope="{ row, $index }">
            <TableRemoveButton @click="removeMaterial($index)" />
          </template>
        </ElTableColumn>
      </AppTable>
    </div>
  </div>
</template>

<script>
import TagsInput from 'components/Inputs/TagsInput';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';

let instance = 0;

export default {
  name: 'MaterialTable',
  components: { InputMoney, AppSelect, TagsInput },
  props: {
    title: {},
    rawMaterialOnly: {
      type: Boolean,
      default() {
        return undefined;
      },
    },
    value: {
      type: Array,
      default() {
        return [];
      },
    },
  },
  data() {
    instance++;
    return {
      instance,
      loading: false,
      productOptions: [],
    };
  },
  methods: {
    attributeOptions(product) {
      if (!product) return [];
      return product.attributes.map(attribute => {
        return {
          value: attribute,
          label: attribute.name,
          options: _.sortBy(attribute.values, ['value']).map(
            attributeValue => ({
              value: attributeValue,
              label: `${attribute.name}: ${attributeValue.value}`,
            })
          ),
        };
      });
    },
    retainDuplicatedAttributes(attributes) {
      const ids = Object.values(
        attributes.reduce((accumulator, val) => {
          accumulator[val.attributeId] = val.id;
          return accumulator;
        }, {})
      );
      return attributes.filter(a => ids.includes(a.id));
    },
    addMaterial() {
      const copy = this.value.slice();
      copy.push({
        quantity: 1,
        product: null,
        attributeValues: [],
      });
      this.$emit('input', copy);
    },
    removeMaterial(index) {
      const copy = this.value.slice();
      copy.removeIdx(index);
      this.$emit('input', copy);
    },
  },
  async mounted() {
    this.productOptions = (
      await this.$api.Product.findAll({
        rawMaterial: this.rawMaterialOnly,
        eager: '[attributes.[values]]',
        orderBy: 'name',
      })
    ).map(product => ({ value: product, label: product.name }));
  },
};
</script>

<style scoped></style>
