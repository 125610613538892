<template>
  <CrudPage
    :fetch-data="fetchData"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    dateField="date"
    :table-attrs="tableAttrs"
    ref="crudPage"
    :action-col-width="125"
    :showActionCol="true"
  >
    <template v-slot:additionalColumns>
      <ElTableColumn :label="''" width="150">
        <template slot-scope="props">
          <OrderPaymentsProgressBar :order="props.row" />
        </template>
      </ElTableColumn>
    </template>

    <template v-slot:tableButtons="{ rowProps }">
      <Tooltip :content="$t('seeDetails')">
        <EditOrderButton :id="rowProps.row.id" />
      </Tooltip>
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import DateTimeCol from 'components/app/table/DateTimeCol';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import OrderEditDialog from 'pages/order/OrderEditDialog';
import EditOrderButton from '@/pages/order/EditOrderButton';
import OrderPaymentsProgressBar from '@/pages/funnel/OrderPaymentsProgressBar';

export default {
  name: 'OrderPayments',
  components: { OrderPaymentsProgressBar, EditOrderButton, AppSelect, AppFilterDatePicker },
  data() {
    return {
      eventsToListen: [EventType.OrderChanged],
      tableAttrs: {
        defaultSort: { prop: 'date', order: 'ascending' },
        showSummary: true,
        summaryMethod: this.computeItemsSummary,
      },
      validatedOptions: [
        { value: true, label: this.$t('confirmed') },
        { value: false, label: this.$t('notConfirmed') },
      ],
      validatedFilter: [false],
      conditionOptions: [{ value: 'À VISTA' }, { value: 'À PRAZO' }],
      conditionFilter: [],
      methodOptions: [],
      methodFilter: [],
      tableColumns: [
        {
          prop: 'orderCode',
          label: this.$t('order'),
          attrs: { width: 100 },
        },
        {
          label: this.$t('customer'),
          render({ row }) {
            return row.customer.name;
          },
        },
        {
          label: this.$t('contact'),
          render({ row }) {
            return row.customer.contacts.map((c) => c.content).join(', ');
          },
          attrs: { width: 150 },
        },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { style: 'date', width: 120, sortable: 'custom' },
        },
        {
          label: this.$t('status'),
          prop: 'status.description',
          width: 120,
        },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination, sort, filters: queryFilters }) {
      const filters = {};

      if (query) {
        filters['id|code:ilike'] = `%${query}%`;
      }

      if (queryFilters.dateRange) {
        Object.assign(filters, AppFilterDatePicker.formatAsFilter('date', queryFilters.dateRange));
      }

      const orderIdsWithoutPayment = await this.$api.Order.fn({ name: 'ordersMissingPayment' });
      const orders = await this.$api.Order.findAll({
        eager:
          '[customer.[contacts], status, items.[product, attributes], shipments, payments, appliedCoupons]',
        'id:in': orderIdsWithoutPayment.join(','),
        orderBy: sort && sort.order === 'ascending' ? [sort.prop] : undefined,
        orderByDesc: sort && sort.order === 'descending' ? [sort.prop] : undefined,
        ...filters,
        ...pagination,
      });

      return orders;
    },
    manageOrder(order) {
      this.$openModal(OrderEditDialog, {
        orderId: order.id,
      });
    },
    filterChanged(property, values) {
      if (property === 'validated') {
        if (values.length === 1) {
          if (values[0]) {
            this.$refs.crudPage.$refs.table.sort('validationDate', 'ascending');
          } else {
            this.$refs.crudPage.$refs.table.sort('date', 'ascending');
          }
        }
      }

      this.$refs.crudPage.fetchEntities();
    },
    computeItemsSummary({ columns, data }) {
      const totalValue = data.reduce((acc, item) => acc + item.value, 0);
      return [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.$filters.money(totalValue),
        null,
        null,
      ];
    },
  },
  async mounted() {
    this.methodOptions = (await this.$api.OrderPayment.findMethods()).map(({ method }) => {
      return {
        value: method,
        label: method,
      };
    });
  },
};
</script>
<style scoped lang="scss"></style>
