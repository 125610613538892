<template>
  <ElPopover
    placement="left"
    width="500"
    trigger="manual"
    v-model="visible"
    :popper-class="`${!_.get(layoutConfig, 'darkMode', true) && 'white-bg'}`"
  >
    <Tooltip :content="$t('notes')" slot="reference">
      <TableButton
        @click="visible = !visible"
        :type="this.containMessage ? 'info' : 'github'"
        :icon="buttonIcon"
        size="lg"
        simple
      />
    </Tooltip>

    <form @submit.stop.prevent="save" v-loading="loading">
      <AppTextArea v-model="message" :placeholder="$t('whatsNotePlaceholder')" rows="20" />
      <div class="text-right mt-2">
        <BaseButton size="sm" type="primary" native-type="submit" :disabled="!canSave">
          {{ $t('save') }}
        </BaseButton>
      </div>
    </form>
  </ElPopover>
</template>

<script>
import TableButton from 'components/app/btn/TableButton';
import AppTextArea from 'components/app/AppTextArea';
import { commonAlerts } from 'util/commonAlerts';

export default {
  name: 'OrderWhatsCommentPopup',
  components: { AppTextArea, TableButton },
  inject: {
    layoutConfig: {
      default: null,
    },
  },
  props: {
    currentMessage: {
      required: true
    }
  },
  data() {
    return {
      loading: false,
      visible: false,
      message: this.currentMessage || '',
    };
  },
  computed: {
    canSave() {
      return (this.currentMessage || '') !== this.message;
    },
    containMessage() {
      const message = this.currentMessage;
      return !_.isEmpty(message);
    },
    buttonIcon() {
      let prefix = this.containMessage ? 'fas' : 'far';
      return `${prefix} fa-comment`;
    },
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        this.$emit('save', {message: this.message});
        this.visible = false;
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
  async mounted() {
    if (this.initialMessage) {
      await this.$nextTick();
      this.visible = true;
    }
  },
};
</script>

<style scoped>
.AppTextArea {
  border-color: rgba(0, 0, 0, 0.1);
}

.white-bg .AppTextArea {
  color: #212529;
}
</style>
