<template>
  <CrudDialog :title="$t('settings')" :loading="loading" @save="save" @close="$emit('close')">
    <Card>
      <div class="row">
        <div class="col-10">
          <BaseInput
            name="productionDays"
            :label="$t('productionDays')"
            v-model.number="model.props.defaultProductionDays"
            required
            v-validate="'required|min:0'"
          />

          <BaseCheckbox v-model="model.props.useNewOrderExportLayout">
            {{ $t('useNewOrderExportLayout') }}
          </BaseCheckbox>

          <Tooltip :content="$t('clickToCopyToClipboard')">
            <BaseInput
              class="mt-2"
              :label="$t('portfolioUrl')"
              :value="portfolioUrl"
              readonly
              copy-to-clipboard
            />
          </Tooltip>
        </div>

        <div class="col text-center">
          <BaseInput name="portfolioId" :label="$t('portfolio')">
            <div>
              <AppAutoUpload
                v-model="model.portfolioId"
                :preview="false"
                accept="application/pdf"
              />
            </div>
          </BaseInput>
        </div>
      </div>
    </Card>

    <Card>
      <h4>{{ $t('mandeUmZap') }}</h4>

      <div class="row">
        <div class="col">
          <BaseInput name="mandeUmZapUrl" label="Url" v-model="model.props.mandeUmZap.url" />
        </div>
        <div class="col">
          <BaseInput name="mandeUmZapToken" label="Token" v-model="model.props.mandeUmZap.token" />
        </div>
        <div class="col">
          <BaseInput
            name="mandeUmZapServiceIdToken"
            label="ServiceID"
            v-model="model.props.mandeUmZap.serviceId"
          />
        </div>
      </div>

      <div class="text-right">
        <BaseCheckbox v-model="model.props.whatsMessages.sendInitialMessage" class="d-inline-block">
          {{ $t('sendInitialMessage') }}
        </BaseCheckbox>

        <ElPopover placement="left" width="600" trigger="click">
          <BaseButton
            size="sm"
            slot="reference"
            :class="{ 'text-white': !model.props.whatsMessages.initialContact }"
            :type="model.props.whatsMessages.initialContact ? 'info' : undefined"
            link
          >
            Configurar mensagem inicial
          </BaseButton>

          <AppTextArea
            v-model="model.props.whatsMessages.initialContact"
            :placeholder="$t('whatsMessagesInitialContactPlaceholder')"
            rows="20"
          />

          <div class="text-right">
            <BaseButton type="danger" link size="sm" @click="clearInitialContactMessage">
              {{ $t('clear') }}
            </BaseButton>
          </div>
        </ElPopover>
        <BaseButton @click="validateZapConfig" size="sm">
          {{ $t('validateConfig') }}
        </BaseButton>
      </div>
    </Card>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import { mergeObjects } from 'util/utils';
import { commonAlerts } from 'util/commonAlerts';
import AppAutoUpload from 'components/app/input/AppAutoUpload';
import AppTextArea from 'components/app/AppTextArea';

export default {
  name: 'AppConfDialog',
  components: { AppTextArea, AppAutoUpload, AppSelect },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        portfolioId: null,
        props: {
          defaultProductionDays: 12,
          useNewOrderExportLayout: false,
          mandeUmZap: {
            url: '',
            token: '',
            serviceId: '',
          },
          whatsMessages: {
            initialContact: '',
            sendInitialMessage: true,
          },
        },
      },
    };
  },
  methods: {
    async onSave() {
      const conf = await this.$api.Conf.save(this.model);
      this.$bus.$emit(EventType.ConfChanged, conf);
    },
    async validateZapConfig() {
      try {
        const result = await this.$api.Whats.validateConfig(this.model.props.mandeUmZap);
        if (result.id === this.model.props.mandeUmZap.serviceId) {
          commonAlerts.info(this.$t('connectionSuccessfullyEstablished'));
        } else {
          commonAlerts.warn(this.$t('reviewConfigParamsWarning'));
        }
      } catch (e) {
        console.error(e);
        commonAlerts.error(this.$t('reviewConfigParamsWarning'), e.message);
      }
    },
    clearInitialContactMessage() {
      this.model.props.whatsMessages.initialContact = '';
    },
  },
  computed: {
    portfolioUrl() {
      return `${window.location.origin}/api/public/portfolio`;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      this.model = mergeObjects({}, this.model, await this.$api.Conf.appConf());
      delete this.model.portfolio;
    } catch (e) {
      console.error(e);
      this.$emit('close');
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
