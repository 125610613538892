<template>
  <div class="ProductPricingTable overflow-auto">
    <table class="w-100 PriceListTable">
      <colgroup>
        <col style="width: 20px" v-if="!editMode" />
        <col style="width: 100px" />
      </colgroup>
      <thead>
        <tr>
          <th v-if="!editMode"></th>
          <th class="text-center">
            <span class="m-1">
              {{ $t('quantity') }}
            </span>
          </th>
          <th class="text-center">
            <span class="m-1">
              {{ $t('basePrice') }}
            </span>
          </th>
          <th
            v-for="(attributeValueId, idx) in productPricing.attributeValues"
            v-if="attributeValues.hasOwnProperty(attributeValueId)"
            :key="attributeValueId"
            :class="{
              'text-center': true,
              AttributeMatch: selectedAttributeValues.some(
                (a) => a.id === attributeValueId
              ),
            }"
            :set="(attrData = attributeValues[attributeValueId])"
          >
            <Badge type="info" class="m-1">
              {{ attrData.attribute.caption }}:
              {{ attrData.value.caption }}
              <BaseButton
                v-if="editMode"
                @click="productPricing.attributeValues.removeIdx(idx)"
                icon
                size="xs"
                type="danger"
                class="m-0 ml-1"
              >
                <i class="fas fa-times p-0"></i>
              </BaseButton>
            </Badge>
          </th>
          <th v-if="editMode"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          v-for="(item, idx) in productPricing.list"
          :key="idx"
          :class="{ QuantityMatch: currentQuantity >= item.quantity }"
        >
          <td v-if="!editMode" class="ApplyCol">
            <Tooltip :content="$t('applyPriceListTooltip')">
              <BaseButton
                :type="currentQuantity >= item.quantity ? 'info' : 'github'"
                @click="applyValue(item)"
                icon
                link
                simple
              >
                <i class="fas fa-check" />
              </BaseButton>
            </Tooltip>
          </td>
          <td>
            <InputMoney
              name="quantity"
              v-model.number="item.quantity"
              :prefix="''"
              :precision="0"
              :readonly="!editMode"
            />
          </td>
          <td>
            <InputMoney v-model.number="item.price" :readonly="!editMode" />
          </td>
          <td
            v-for="attributeValueId in productPricing.attributeValues"
            v-if="attributeValues.hasOwnProperty(attributeValueId)"
            :key="attributeValueId"
            :class="{
              AttributeMatch: selectedAttributeValues.some(
                (a) => a.id === attributeValueId
              ),
            }"
          >
            <InputMoney
              v-model.number="item.getPriceFor(attributeValueId).value"
              :readonly="!editMode"
            />
          </td>
          <td v-if="editMode" class="ActionCol">
            <TableRemoveButton @click="productPricing.list.removeIdx(idx)" />
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import InputMoney from 'components/app/input/InputMoney';
import Badge from 'components/Badge';
import { commonAlerts } from 'util/commonAlerts';

export default {
  name: 'ProductPricingTable',
  components: { Badge, InputMoney },
  props: {
    currentQuantity: { type: Number, default: 0 },
    selectedAttributeValues: {
      type: Array,
      default: () => [],
    },
    productPricing: {},
    editMode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      loading: true,
      attributeValues: {},
    };
  },
  methods: {
    applyValue(item) {
      const matchedPrices = this.selectedAttributeValues
        .filter((attrVal) => item.prices.hasOwnProperty(attrVal.id))
        .map((attrVal) => item.prices[attrVal.id]);
      const val =
        (item.price || 0) +
        matchedPrices.reduce((acc, el) => acc + (el.value || 0), 0);
      this.$emit('calculatedValue', val);
    },
  },
  async mounted() {
    try {
      const attributes = await this.$api.Attribute.findAll({ eager: 'values' });
      const attributeValues = {};
      for (const attribute of attributes) {
        for (const value of attribute.values) {
          attributeValues[value.id] = {
            attribute,
            value,
          };
        }
      }
      this.attributeValues = attributeValues;
    } catch (e) {
      console.error(e);
      commonAlerts.defaultErrorMessage(e);
    }
    this.loading = false;
  },
  updated() {
    let match = (this.productPricing?.list || []).some(
      (entry) => this.currentQuantity >= entry.quantity
    );
    if (!match) {
      match = this.selectedAttributeValues?.some((a) =>
        this.productPricing?.attributeValues?.includes(a.id)
      );
    }
    this.$emit('matchAnyItem', match);
  },
};
</script>

<style scoped lang="scss">
.ProductPricingTable {
  td {
    min-width: 100px;
    padding: 5px;
  }

  .ActionCol,
  .ApplyCol {
    min-width: unset;
    padding: unset;
    width: 50px;
    text-align: center;
  }

  .AttributeMatch,
  .QuantityMatch {
    background-color: rgba(255, 255, 255, 0.05);

    .AttributeMatch {
      background-color: unset;
    }
  }
}
</style>
