<template>
  <textarea
    class="AppTextArea form-control"
    :name="name"
    :rows="rows"
    @input="changed"
    :value="value"
    :readonly="readOnly"
  >
  </textarea>
</template>

<script>
export default {
  name: 'AppTextArea',
  props: {
    name: {},
    maxLength: {},
    rows: {},
    value: {},
    readOnly: {},
  },
  methods: {
    changed(event) {
      this.$emit('input', event.target.value);
    },
  },
};
</script>

<style scoped>
.AppTextArea {
  padding: 5px 10px !important;
  border: 1px solid #2b3553;
  border-radius: 0.4285rem;
}

.AppTextArea:focus {
  border: 1px solid;
  border-color: #e14eca;
}

textarea.form-control.AppTextArea {
  max-height: unset;
}

.AppTextArea:read-only {
  color: rgba(255, 255, 255, 0.8);
}
</style>
