<template>
  <Dialog
    :loading="loading"
    @close="$emit('close')"
    class="WhatsEventsDialog"
    :title="$t('WhatsActivity')"
  >
    <CrudPage
      :fetch-data="fetchData"
      :columns="columns"
      date-field="createdAt"
      :initial-per-page="5"
      :initial-date-range="initialDateRange"
      :show-default-search="false"
      ref="crudPage"
    >
      <template v-slot:headerFilters>
        <AppSelect
          v-model="filter.userId"
          :options="userOpts"
          @input="$refs.crudPage.fetchEntities()"
        />
      </template>
      <template v-slot:default="{ visibleEntities }">
        <Card v-if="_.isEmpty(visibleEntities)" class="Empty">
          <h1 class="text-center m-0 mt-2">
            <i class="far fa-frown"></i>
          </h1>
          <h4 class="text-center mt-2">
            {{ $t('noItemsFoundForTheAppliedFilter') }}
          </h4>
        </Card>
        <TimeLine class="m-0" v-else>
          <TimeLineItem
            :key="log.id"
            v-for="log in visibleEntities"
            :inverted="log.props.type === 'ChatView'"
            :badge-type="log.props.type === 'ChatView' ? 'info' : 'success'"
            :badge-icon="log.props.type === 'ChatView' ? 'fas fa-eye' : 'fas fa-envelope'"
          >
            <div slot="header" class="text-white d-flex align-items-center">
              <span class="mr-2">{{ $t('customer') }}</span>

              <Badge
                slot="header"
                :type="log.props.type === 'ChatView' ? 'info' : 'success'"
                class="m-0"
                :title="`ID ${log.customer.id}`"
                pill
              >
                {{ log.customer.name }}
              </Badge>

              <OrderWhatsButton :customer="log.customer" />
            </div>

            <p slot="content">
              <span class="mr-1">{{ $t('user') }}</span>
              <Badge class="mr-1">{{ log.user.name }}</Badge>
              <span>
                {{ $t(log.props.type).toLowerCase() }}
              </span>
            </p>

            <h6 slot="footer"><i class="fas fa-clock"></i> {{ log.createdAt | fromNow }}</h6>
          </TimeLineItem>
        </TimeLine>
      </template>
    </CrudPage>
  </Dialog>
</template>

<script>
import Dialog from 'components/app/Dialog';
import DateTimeCol from 'components/app/table/DateTimeCol';
import TimeLine from 'components/Timeline/TimeLine';
import TimeLineItem from 'components/Timeline/TimeLineItem';
import Badge from 'components/Badge';
import OrderWhatsButton from 'pages/order/whats/OrderWhatsButton';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';

export default {
  name: 'WhatsEventsDialog',
  components: { AppSelect, OrderWhatsButton, Badge, TimeLineItem, TimeLine, Dialog },
  data() {
    return {
      loading: false,
      columns: [
        { prop: 'id' },
        { prop: 'props.type' },
        { prop: 'customer.name' },
        { prop: 'user.name' },
        { prop: 'createdAt', component: DateTimeCol },
      ],
      filter: {
        userId: this.$store.state.user.id,
      },
      userOpts: [],
      initialDateRange: [moment().subtract(30, 'd').startOf('d'), moment().startOf('d')],
    };
  },
  methods: {
    async fetchData(queryParams) {
      const filters = {};
      if (queryParams.filters.dateRange) {
        Object.assign(
          filters,
          AppFilterDatePicker.formatAsFilter('createdAt', queryParams.filters.dateRange)
        );
      }
      if (this.filter.userId) {
        filters.userId = this.filter.userId;
      }
      return await this.$api.LogEntry.findAll({
        eager: `[user, customer.[contacts]]`,
        event: 'Whats',
        ...filters,
        orderByDesc: 'createdAt',
        ...queryParams.pagination,
      });
    },
  },
  async created() {
    this.userOpts = (await this.$api.User.findAll({ orderBy: 'name' })).map((user) => ({
      value: user.id,
      label: user.name,
    }));
  },
};
</script>

<style lang="scss">
.WhatsEventsDialog {
  .card.card-timeline .timeline:before {
    background-color: lighten(#222a42, 5);
  }

  .card.card-timeline .timeline > li > .timeline-panel {
    background: lighten(#27293d, 3);
  }

  .OrderWhatsButton {
    height: auto !important;
  }

  .card.card-timeline .timeline {
    padding: 0;
    margin: 0;
  }

  .card.card-timeline .card-body {
    padding: 0;
  }

  .card.card-timeline .timeline > li:last-child {
    margin-bottom: 0;
  }

  .card.card-timeline .timeline > li > .timeline-panel {
    margin-bottom: 0;
    padding: 10px 15px;
  }

  .Empty {
    box-shadow: none;
  }
}
</style>
