import { axiosInstance } from 'api/AxiosInstance';

const $http = axiosInstance();

export class WhatsApi {
  async validateConfig({ url, token, serviceId }) {
    const response = await $http.post(`/api/whats/validateConfig`, {
      url,
      token,
      serviceId,
    });
    return response.data;
  }

  async findContact(customerId, contactId, clearCache = false) {
    const response = await $http.get(`/api/whats/contacts`, {
      params: {
        customerId,
        contactId,
        clearCache,
      },
    });
    return response.data;
  }

  async findMessages(customerId, contactId, props) {
    const response = await $http.get(`/api/whats/messages`, {
      params: {
        customerId,
        contactId,
        ...props,
      },
    });
    return response.data;
  }

  async sendMessage(messageData) {
    const formData = new FormData();

    Object.keys(messageData).forEach((key) => {
      const val = messageData[key];
      if (_.isUndefined(val)) return;
      formData.append(key, val);
    });

    const response = await $http.post(`/api/whats/messages`, formData);
    return response.data;
  }

  async syncContacts() {
    const { data } = await $http.post(`/api/whats/sync`);
    return data;
  }

  async sendInitialMessages(customerId, contactId) {
    const response = await $http.post(`/api/whats/sendInitialMessages`, {
      customerId,
      contactId,
    });
    return response.data;
  }

  async updateCustomersWhatsInfo() {
    const response = await $http.post(`/api/whats/updateCustomersWhatsInfo`);
    return response.data;
  }

  async findMessagesSince(date) {
    const response = await $http.get(`/api/whats/messagesSince`, {
      params: {
        date: date.toISOString(),
      },
    });
    return response.data;
  }
}
