<template>
  <OrderMailTag :custom-customer-props="senderProps" />
</template>

<script>
import OrderMailTag from 'pages/order/OrderMailTag';

export default {
  name: 'SenderOrderMailTag',
  components: { OrderMailTag },
  props: {
    orderId: {},
  },
  data() {
    return {
      order: null,
      senderProps: {
        name: 'Catapulta Personalizados',
        city: 'Arvorezinha - RS',
        address: 'Rua Osvaldo Aranha, Nº 49',
        neighborhood: 'Centro',
        cep: '95995-000',
      },
    };
  },
};
</script>

<style lang="scss"></style>
