<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  />
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import SectorEditDialog from 'pages/sector/SectorEditDialog';
import TagsCol from 'components/app/table/TagsCol';

export default {
  name: 'Sectors',
  data() {
    return {
      dialog: SectorEditDialog,
      eventsToListen: [EventType.SectorChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'name' },
        {
          prop: 'users',
          component: TagsCol,
          toLabel: val => val.name || val.email,
        },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|name:ilike'] = `%${query}%`;
      }
      return await this.$api.Sector.findAll({
        eager: 'users',
        orderByDesc: 'name',
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.Sector.remove(row.id);
    },
  },
};
</script>
<style scoped lang="scss"></style>
