<template>
  <CrudDialog
    :title="$t('accountingEntry')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="AccountingEntryEditDialog"
  >
    <div class="row">
      <div class="col">
        <BaseInput
          type="text"
          name="id"
          :label="$t('id')"
          v-model="model.id"
          :readonly="true"
          number
        />
      </div>
      <div class="col">
        <BaseInput name="date" :label="$t('date')">
          <AppDateTimePicker
            name="date"
            type="date"
            v-model="model.date"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <BaseInput name="category" :label="$t('category')" required>
          <AppSelect
            value-key="id"
            name="category"
            v-model="model.subcategory"
            :options="subcategoryOptions"
            :grouped="true"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="type" :label="$t('type')" required>
          <AppSelect
            name="category"
            v-model="model.type"
            :options="availableTypes"
            v-validate="'required|min:2|max:128'"
            :searchable="false"
            :clearable="false"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="value" :label="`${$t('value')}`" required>
          <InputMoney
            name="value"
            v-model.number="model.value"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <BaseInput
          name="description"
          :label="$t('description')"
          v-model="model.description"
        />
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import InputMoney from 'components/app/input/InputMoney';
import { AccountingEntryUi } from 'api/Models';
import { mergeObjects } from 'util/utils';

export default {
  name: 'AccountingEntryEditDialog',
  components: {
    InputMoney,
    AppDateTimePicker,
    AppSelect,
  },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        subcategory: null,
        type: AccountingEntryUi.Type.DEBIT,
        date: new Date(),
        value: 0,
        description: '',
      },
      availableTypes: Object.values(AccountingEntryUi.Type).map(value => ({
        value,
        label: this.$t(value),
      })),
      subcategoryOptions: [],
    };
  },
  methods: {
    async onSave() {
      await this.$api.AccountingEntry.save(this.model);
      this.$bus.$emit(EventType.AccountingEntryChanged);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      this.subcategoryOptions = (
        await this.$api.AccountingEntryCategory.findAll({
          eager: '[subcategories]',
          'parentId:isNull': '',
        })
      ).map(category => {
        return {
          label: category.name,
          options: category.subcategories.map(subcategory => ({
            value: subcategory,
            label: subcategory.name,
          })),
        };
      });
      if (this.id) {
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.AccountingEntry.findById(this.id, {eager: '[subcategory]'})
        );
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style></style>
