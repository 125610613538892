<template>
  <div class="WhatsQueuePage">
    <div class="row">
      <div class="col text-right">
        <BaseButton @click="addNewQueue" size="sm" class="text-nowrap" type="info">
          <i class="fas fa-plus mr-1" />
          {{ $t('newQueue') }}
        </BaseButton>
        <Tooltip :content="$t('syncWhats')">
          <BaseButton @click="syncContacts" icon simple link type="github">
            <i class="fas fa-sync-alt"></i>
          </BaseButton>
        </Tooltip>
      </div>
    </div>
    <div class="WhatsQueue mr-2" v-loading="loading">
      <div>
        <InitialContactsContainerCard :parent-items="findItemsFor({ id: 0 })" hide-title />
      </div>
      <div>
        <ElDropdown trigger="click" @command="changeUser">
          <span class="text-white">
            {{ $t('userQueue') }}:
            <b>
              {{ (selectedUser && selectedUser.name) || $t('selectOne') }}
            </b>

            <i class="ml-1 fas fa-chevron-down" />
          </span>
          <ElDropdownMenu slot="dropdown">
            <ElDropdownItem
              v-for="(user, idx) in users"
              :key="`drop-user-${user.id}-${idx}`"
              :command="{ user }"
              :disabled="selectedUser && selectedUser.id === user.id"
            >
              <Badge type="warning" class="mr-1">
                {{ findItemsFor(user).length }}
              </Badge>
              <Badge class="mr-1">
                {{ $t(user.role) }}
              </Badge>

              {{ user.name }}
            </ElDropdownItem>
          </ElDropdownMenu>
        </ElDropdown>
        <div class="d-flex" v-if="selectedUser">
          <InitialContactsContainerCard
            :parent-items="findItemsFor(selectedUser)"
            :user="selectedUser"
            hide-title
          />
        </div>
      </div>
      <template v-if="userQueueConfig">
        <div v-for="(queue, idx) in userQueueConfig.props.queues" :key="`queue-${queue.id}-${idx}`">
          <InitialContactsContainerCard
            :parent-items="findItemsForQueue(queue)"
            @filterChanged="queueFilterChanged(queue, $event)"
            :initial-state="queue.persistedState"
          >
            <template v-slot:title>
              <div class="mb-2">
                <template v-if="editingQueue && queue.id === editingQueue.id">
                  <div class="d-flex align-items-center">
                    <input
                      class="TitleInput flex-grow-1"
                      v-model="editingQueue.title"
                      @keydown.enter.prevent="updateQueueTitle"
                      @keydown.esc="editingQueue = null"
                      ref="titleInput"
                    />
                    <Tooltip :content="$t('save')">
                      <BaseButton
                        @click="updateQueueTitle"
                        size="xs"
                        class="m-0 ml-2 p-0"
                        type="success"
                        simple
                        icon
                        link
                      >
                        <i class="fas fa-check" />
                      </BaseButton>
                    </Tooltip>

                    <Tooltip :content="$t('cancel')">
                      <BaseButton
                        @click.stop="editingQueue = null"
                        size="xs"
                        class="m-0 ml-2 p-0"
                        type="warning"
                        simple
                        icon
                        link
                      >
                        <i class="fas fa-times" />
                      </BaseButton>
                    </Tooltip>
                  </div>
                </template>
                <template v-else>
                  <Tooltip :content="$t('clickToEdit')">
                    <h3 class="Title m-0" @click="editQueueTitle(queue)">
                      {{ queue.title || $t('title') }}
                    </h3>
                  </Tooltip>
                </template>
              </div>
            </template>
            <template v-slot:additionalButtons>
              <div>
                <Tooltip :content="$t('removeThisQueue')">
                  <BaseButton icon simple link type="github" class="m-0" @click="removeQueue(idx)">
                    <i class="fas fa-times" />
                  </BaseButton>
                </Tooltip>
              </div>
            </template>
          </InitialContactsContainerCard>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import InitialContactsContainerCard from 'pages/funnel/InitialContactsContainerCard';
import { StatusTypeUi, UserWhatsQueueConfigUi } from 'api/Models';
import Badge from 'components/Badge';
import { commonAlerts } from 'util/commonAlerts';
import { EventType } from 'plugins/eventBusPlugin';

export default {
  name: 'WhatsQueue',
  components: { Badge, InitialContactsContainerCard },
  data() {
    return {
      loading: false,
      orders: [],
      initialContacts: [],
      users: [],
      selectedUser: null,
      userQueueConfig: new UserWhatsQueueConfigUi(),
      editingQueue: null,
      cache: {
        userItems: {},
        queueItems: {},
        orderStatusToIgnore: null,
      },
    };
  },
  watch: {
    orders() {
      this.cache.userItems = {};
      this.cache.queueItems = {};
    },
    initialContacts() {
      this.cache.userItems = {};
      this.cache.queueItems = {};
    },
    users() {
      this.cache.userItems = {};
      this.cache.queueItems = {};
    },
  },
  methods: {
    async editQueueTitle(queue) {
      this.editingQueue = _.cloneDeep(queue);
      await this.$nextTick();
      this.$refs.titleInput[0].focus();
    },
    async addNewQueue() {
      try {
        this.userQueueConfig.addNewQueue();
        await this.$api.UserWhatsQueueConfig.save(this.userQueueConfig);
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    async queueFilterChanged(queue, filters) {
      try {
        queue.persistedState = filters;
        await this.$api.UserWhatsQueueConfig.save(this.userQueueConfig);
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    async removeQueue(idx) {
      if (!(await commonAlerts.confirmOperation())) return;

      try {
        this.userQueueConfig.props.queues.removeIdx(idx);
        await this.$api.UserWhatsQueueConfig.save(this.userQueueConfig);
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    async updateQueueTitle() {
      try {
        const q = this.userQueueConfig.props.queues.find((q) => q.id === this.editingQueue.id);
        q.title = this.editingQueue.title;
        await this.$api.UserWhatsQueueConfig.save(this.userQueueConfig);
        this.editingQueue = null;
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
    changeUser({ user }) {
      this.selectedUser = user;
    },
    findItemsFor(user) {
      const CACHE = this.cache.userItems;

      if (!CACHE[user.id]) {
        const contacts = this.findContactsFor(user);
        const orders = this.findOrdersFor(user);
        CACHE[user.id] = [...contacts, ...orders];
      }

      return CACHE[user.id];
    },
    findItemsForQueue(queue) {
      const CACHE = this.cache.queueItems;

      if (!CACHE[queue.id]) {
        CACHE[queue.id] = [...this.initialContacts, ...this.orders];
      }

      return CACHE[queue.id];
    },
    findContactsFor(user) {
      const isNullUser = user.id === 0;
      return this.initialContacts.filter(
        (ic) => ic.responsibleUserId === user.id || (isNullUser && !ic.responsibleUserId)
      );
    },
    findOrdersFor(user) {
      const isNullUser = user.id === 0;
      return this.orders.filter(
        (order) => (isNullUser && !order.responsibleUserId) || order.responsibleUserId === user.id
      );
    },
    async ordersData(eventData) {
      if (!this.cache.orderStatusToIgnore) {
        const result = await this.$api.StatusType.findAll({
          'name:in': [StatusTypeUi.Type.FINISHED, StatusTypeUi.Type.CANCELLED].join(','),
          eager: '[orderStatus]',
        });
        this.cache.orderStatusToIgnore = Array.from(
          result.reduce((acc, el) => {
            el.orderStatus.forEach((os) => acc.add(os.id));
            return acc;
          }, new Set())
        );
      }

      this.orders = await this.$api.Order.findAll({
        'statusId:notIn':
          this.cache.orderStatusToIgnore.length > 0
            ? this.cache.orderStatusToIgnore.join(',')
            : undefined,
        eager: `[
          customer.[contacts],
          status,
          tags,
          activities
        ]`,
      });

      const customerId = eventData?.data?.customerId;
      if (customerId) {
        this.initialContacts = (this.initialContacts ?? []).filter(
          (ic) => ic.customerId !== customerId
        );
      }
    },
    async initialContactsData() {
      this.initialContacts = await this.$api.InitialContact.findAll({
        eager: `[
            customer.[contacts],
            tags
          ]`,
        orderByDesc: 'updatedAt',
      });
    },
    async syncContacts() {
      this.loading = true;
      try {
        await this.$api.Whats.syncContacts();
        await this.$api.Whats.updateCustomersWhatsInfo();
        await this.fetchAllData();
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    async fetchAllData() {
      await Promise.allSettled([this.initialContactsData(), this.ordersData()]);
    },
    async initializeUserView() {
      let [queue] = await this.$api.UserWhatsQueueConfig.findAll({
        userId: this.$store.state.user.id,
      });
      if (!queue) {
        queue = await this.$api.UserWhatsQueueConfig.save({ userId: this.$store.state.user.id });
      }
      this.userQueueConfig = queue;
    },
  },
  async created() {
    this.loading = true;
    try {
      this.users = _.orderBy(await this.$api.User.findAll({ orderBy: 'name' }), [
        (u) => this.$t(u.role),
      ]);
      await this.initializeUserView();
      await this.fetchAllData();

      if (!this.selectedUser) {
        this.selectedUser = this.users.find((u) => u.id === this.$store.state.user.id);
      }

      this.$bus.$on(EventType.CustomerChanged, this.fetchAllData);
      this.$bus.$on(EventType.OrderChanged, this.ordersData);
      this.$bus.$on(EventType.InitialContactChanged, this.initialContactsData);
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$bus.$off(EventType.CustomerChanged, this.fetchAllData);
    this.$bus.$off(EventType.OrderChanged, this.ordersData);
    this.$bus.$off(EventType.InitialContactChanged, this.initialContactsData);
  },
};
</script>

<style lang="scss">
.WhatsQueuePage {
  position: relative;

  .WhatsQueue {
    display: flex;
    width: 100%;
    overflow-x: auto;

    .Queue {
      min-width: 325px;
      max-width: 325px;

      .ContainerCard {
        .Cards {
          max-height: calc(100vh - 265px);
          min-height: calc(100vh - 265px);
          overflow: auto;

          > .card {
            position: relative;
            background: rgba(0, 0, 0, 0.3);
          }
        }
      }
    }

    .InitialContactsCard {
      min-width: 375px;
      max-width: 375px;
    }
  }

  .Title {
    cursor: pointer;
    transition: text-shadow ease-in-out 200ms;

    &:hover {
      text-shadow: 0 0 1px white;
    }
  }

  .TitleInput {
    background-color: rgba(255, 255, 255, 0.1);
    border: none;
    color: rgba(255, 255, 255, 0.8);
    font-size: 1.3rem;
    border-radius: 4px;
    box-shadow: 0 0 2px rgba(255, 255, 255, 0.8);
  }
}
</style>
