<template>
  <Tooltip :content="$t('seeActivities')" v-loading="loading">
    <BaseButton
      icon
      size="sm"
      class="btn-link btn-github btn-simple"
      @click="viewActivities"
      :badge-value="activities"
    >
      <i class="fas fa-file-alt" />
    </BaseButton>
  </Tooltip>
</template>

<script>
import OrderActivitiesViewDialog from 'pages/order/OrderActivitiesViewDialog';
import { EventType } from 'plugins/eventBusPlugin';

class FetchQueue {
  queue = [];
  params = [];

  constructor(resolver) {
    this.resolver = resolver;
  }

  executeQuery = _.debounce(async () => {
    const params = [...this.params];
    const queue = [...this.queue];
    this.params = [];
    this.queue = [];

    const result = await this.resolver(params);
    queue.forEach((fn) => fn(result));
  }, 200);

  addBatch(id, fn) {
    this.queue.push(fn);
    this.params.push(id);
    this.executeQuery();
  }
}

let batchOps = null;

export default {
  name: 'SeeActivitiesButton',
  props: {
    order: { required: true },
  },
  data() {
    const activitiesCount = this.order.activities
      ? this.order.activities.filter((a) => a.isMessage()).length
      : undefined;
    return {
      loading: _.isUndefined(activitiesCount),
      activities: activitiesCount,
    };
  },
  methods: {
    viewActivities(e) {
      this.$emit('click', e);
      this.$openModal(OrderActivitiesViewDialog, {
        order: this.order,
      });
    },
    updateActivities() {
      this.loading = true;
      batchOps.addBatch(this.order.id, (result) => {
        if (result.hasOwnProperty(this.order.id)) {
          this.activities = result[this.order.id];
        } else {
          this.activities = undefined;
        }
        this.loading = false;
      });
    },
  },
  async mounted() {
    if (!this.loading) return;
    if (!batchOps) {
      batchOps = new FetchQueue(async (orders) => {
        if (_.isEmpty(orders)) {
          return {};
        }

        const data = await this.$api.OrderActivity.findAll({
          'orderId:in': orders.join(','),
          type: 'MESSAGE',
          count: 'id',
          groupBy: 'orderId',
        });
        return data.reduce((acc, row) => {
          acc[row.orderId] = _.parseInt(row.count);
          return acc;
        }, {});
      });
    }
    this.updateActivities();
    this.$bus.$on(EventType.OrderActivityChanged, this.updateActivities);
  },
  beforeDestroy() {
    this.$bus.$off(EventType.OrderActivityChanged, this.updateActivities);
  },
};
</script>

<style scoped></style>
