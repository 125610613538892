<template>
  <CrudDialog
    :title="$t('eventProspection')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="EventProspectionDialog"
  >
    <div class="row">
      <div class="col-2">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
        />
      </div>
      <div class="col">
        <BaseInput name="date" :label="$t('date')" required>
          <AppDateTimePicker
            name="date"
            v-model="model.date"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="user" :label="$t('user')" required>
          <AppSelect
            name="user"
            v-model="model.user"
            :options="userOptions"
            value-key="id"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="notes" :label="$t('notes')">
          <AppTextArea name="notes" v-model="model.notes" rows="3" />
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import { mergeObjects } from 'util/utils';
import AppTextArea from 'components/app/AppTextArea';

export default {
  name: 'EventProspectionEditDialog',
  components: {
    AppDateTimePicker,
    AppSelect,
    AppTextArea,
  },
  mixins: [FormMixin],
  props: {
    id: {},
    customerEventId: {},
    eventToProspectId: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        date: new Date(),
        notes: '',
        customerEventId: this.customerEventId,
        eventToProspectId: this.eventToProspectId,
        user: this.$store.state.user,
        props: {},
      },
      userOptions: [],
    };
  },
  methods: {
    async onSave() {
      await this.$api.EventProspection.save(this.model);
      this.$bus.$emit(EventType.EventProspectionChanged);
    },
  },
  computed: {},
  async mounted() {
    this.loading = true;
    try {
      this.userOptions = (
        await this.$api.User.brain.findAll({
          orderBy: 'name',
        })
      ).map((value) => ({ value, label: value.name }));

      if (this.id) {
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.EventProspection.findById(this.id, {
            eager: '[customerEvent, user]',
          })
        );
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss"></style>
