<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :dialogProps="dialogProps"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  >
    <template v-slot:headerButtons>
      <BaseButton @click="openDuplicatedContacts" type="warning" class="mr-2">
        <i class="fas fa-phone mr-1" />
        {{ $t('duplicatedContacts') }}
      </BaseButton>
    </template>
  </CrudPage>
</template>
<script>
import CustomerEditDialog from 'pages/customer/CustomerEditDialog';
import { EventType } from 'plugins/eventBusPlugin';
import TranslateCol from 'components/app/table/TranslateCol';
import DuplicatedCustomersDialog from 'pages/customer/DuplicatedCustomersDialog';

export default {
  name: 'Customers',
  components: { DuplicatedCustomersDialog },
  props: {
    providerView: {
      default: false,
    },
  },
  data() {
    return {
      dialog: CustomerEditDialog,
      eventsToListen: [EventType.CustomerChanged],
      dialogProps: {
        providerView: this.providerView,
      },
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        {
          prop: 'name',
        },
        { prop: 'email' },
        {
          prop: 'type',
          component: TranslateCol,
        },
        {
          label: `${this.$t('cpf')}/${this.$t('cnpj')}`,
          render({ row }) {
            return row.type === 'LEGAL' ? row.cnpj : row.cpf;
          },
        },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {
        provider: this.providerView,
      };
      if (query) {
        filters['id|code|name|email|cpf|contacts.content:ilike'] = `%${query}%`;
      }
      return await this.$api.Customer.findAll({
        orderBy: ['name'],
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.Customer.remove(row.id);
    },
    openDuplicatedContacts() {
      this.$openModal(DuplicatedCustomersDialog);
    },
  },
};
</script>
<style scoped lang="scss"></style>
