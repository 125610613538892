<template>
  <CrudDialog
    :title="$t('attributeValue')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col-2">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model="model.id"
          :readonly="true"
          number
        />
      </div>
      <div class="col-5">
        <BaseInput
          name="value"
          :label="$t('value')"
          v-model="model.value"
          v-validate="'required|min:2|max:256'"
          required
        />
      </div>
      <div class="col-5">
        <BaseInput
          name="caption"
          :label="$t('caption')"
          v-model="model.caption"
          v-validate="'required|min:2|max:256'"
          required
        />
      </div>
    </div>

    <MaterialTable
      v-model="model.materials"
      :raw-material-only="true"
      :title="$t('materials')"
    />
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';

import TagsInput from 'components/Inputs/TagsInput';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';
import MaterialTable from 'pages/product/attribute/MaterialTable';

export default {
  name: 'AttributeValueEditDialog',
  components: { MaterialTable, InputMoney, AppSelect, TagsInput },
  mixins: [FormMixin],
  props: {
    attributeValue: {},
    onSave: {},
  },
  data() {
    return {
      loading: false,
      model: Object.assign(
        {},
        {
          id: undefined,
          value: '',
          caption: '',
          materials: [],
        },
        _.cloneDeep(this.attributeValue || {})
      ),
    };
  },
  methods: {},
  async mounted() {},
};
</script>

<style scoped></style>
