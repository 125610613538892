<template>
  <CrudDialog
    :title="$t('statusAutomation')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
          number
        />
      </div>
    </div>
    <div class="row">
      <div class="col">
        <BaseInput
          name="name"
          :label="$t('name')"
          v-model="model.name"
          v-validate="'required|min:2'"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="statuses" :label="$t('statusToBeObserved')">
          <AppSelect
            name="statuses"
            value-key="id"
            v-model="model.statuses"
            :options="availableStatusesForSource"
            multiple
            :clearable="false"
            :grouped="true"
          />
        </BaseInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <BaseInput name="dateField" :label="$t('dateField')">
          <AppSelect
            name="dateField"
            v-model="model.dateField"
            :options="dateFieldOpts"
            v-validate="'required'"
            :clearable="false"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput
          name="interval"
          :label="$t('afterIdleTimeInMinutes')"
          required
        >
          <InputMoney
            name="idleTime"
            v-model.number="model.idleTime"
            v-validate="'required|min_value:1'"
            :prefix="''"
            :precision="0"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="moveToStatus" :label="$t('moveToStatus')">
          <AppSelect
            name="moveToStatus"
            value-key="id"
            v-model="model.moveToStatus"
            :options="availableStatusesForMoveTo"
            :grouped="true"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="action" :label="$t('action')">
          <AppSelect
            name="action"
            v-model="model.action"
            :options="actionOpts"
            :clearable="false"
            :filterable="false"
          />
        </BaseInput>
      </div>
    </div>

    <div v-if="model.action === 'SendAfterSaleQuizLink'">
      <BaseInput name="actionMessage" :label="$t('message')" required>
        <AppTextArea
          name="actionMessage"
          v-model="model.props.message"
          rows="4"
          v-validate="'required'"
          :data-vv-as="$t('message')"
          :placeholder="$t('sendAfterSaleQuizLinkMessagePlaceholder')"
        />
      </BaseInput>
    </div>

    <div v-else-if="model.action === 'SendWhatsMessage'">
      <BaseInput name="actionMessage" :label="$t('message')" required>
        <AppTextArea
          name="actionMessage"
          v-model="model.props.message"
          rows="4"
          v-validate="'required'"
          :data-vv-as="$t('message')"
          :placeholder="$t('message')"
        />
      </BaseInput>
      <div class="row">
        <div class="col-4">
          <BaseCheckbox v-model="model.props.sendOnlyOnceForEachCustomer">
            {{ $t('sendOnlyOnceForEachCustomer') }}
          </BaseCheckbox>
          <BaseButton
            size="sm"
            @click="model.props.sendOnlyOnceForEachCustomerData = []"
            :disabled="_.isEmpty(model.props.sendOnlyOnceForEachCustomerData)"
          >
            {{ $t('clearCustomerList') }}
            <span class="ml-1">
              -
              {{
                model.props.sendOnlyOnceForEachCustomerData
                  ? model.props.sendOnlyOnceForEachCustomerData.length
                  : 0
              }}</span
            >
          </BaseButton>
        </div>
        <div class="col-4">
          <BaseCheckbox
            v-model="model.props.sendOnlyToCustomerWithoutPurchases"
          >
            {{ $t('sendOnlyToCustomerWithoutPurchases') }}
          </BaseCheckbox>
        </div>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import { mergeObjects } from 'util/utils';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import InputMoney from 'components/app/input/InputMoney';
import AppTextArea from 'components/app/AppTextArea';

export default {
  name: 'StatusAutomationEditDialog',
  components: { AppTextArea, InputMoney, AppDateTimePicker, AppSelect },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        idleTime: 0,
        moveToStatus: null,
        statuses: [],
        createdById: this.$store.state.user.id,
        action: 'Nothing',
        dateField: 'updatedAt',
        props: {},
      },
      availableStatuses: [],
      actionOpts: ['Nothing', 'SendAfterSaleQuizLink', 'SendWhatsMessage'].map(
        (value) => ({
          value,
          label: this.$t(value),
        })
      ),
      dateFieldOpts: ['updatedAt', 'lastInteractionOn', 'lastWhatsMessage'].map(
        (value) => ({
          value,
          label: this.$t(value),
        })
      ),
    };
  },
  methods: {
    async onSave() {
      const result = await this.$api.StatusAutomation.save(this.model);
      this.$bus.$emit(EventType.StatusAutomationChanged, result);
    },
  },
  computed: {
    availableStatusesForSource() {
      if(!this.model.moveToStatus) {
         return this.availableStatuses;
      }
      return this.availableStatuses.map(groupedStatuses => {
        return {
          ...groupedStatuses,
          options: groupedStatuses.options.filter(({value}) => value.id !== this.model.moveToStatus.id)
        }
      });
    },
    availableStatusesForMoveTo() {
      return this.availableStatuses.map(groupedStatuses => {
        return {
          ...groupedStatuses,
          options: groupedStatuses.options.filter(({value}) => !this.model.statuses.some(inUseStatus => inUseStatus.id === value.id))
        }
      });
    }
  },
  async mounted() {
    this.availableStatuses = await this.$api.OrderStatus.findGroupedForOptions();
    if (this.id) {
      try {
        this.loading = true;
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.StatusAutomation.findById(this.id, {
            eager: '[statuses, moveToStatus]',
          })
        );
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
