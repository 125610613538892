<template>
  <div class="d-flex align-items-center">
    <Tooltip :content="$t('payments')">
      <i class="fas fa-dollar-sign mr-2"></i>
    </Tooltip>

    <ElPopover
      class="w-100"
      trigger="hover"
      placement="top-start"
      popper-class="is-dark"
    >
      <div class="Popover">
        <div class="mb-2">
          {{ order.totalPaid() | money }} /
          {{ order.totalValue() | money }}
        </div>

        <div
          v-for="(payment, idx) in order.classifiedPayments()"
          class="d-flex align-items-center"
          :key="idx"
        >
          <div
            style="width: 12px; height: 12px;"
            :class="['mr-2', payment.class]"
          ></div>
          {{ payment.value | money }}
          {{
            $t(payment.validated ? 'confirmed' : 'notConfirmed').toLowerCase()
          }}
        </div>
      </div>

      <div class="progress" slot="reference">
        <div
          :class="['progress-bar', payment.class]"
          :style="`width: ${payment.percent}%;`"
          v-for="payment in order.classifiedPayments()"
          :key="payment.class"
        >
          {{ payment.percent.toFixed(0) }}%
        </div>
      </div>
    </ElPopover>
  </div>
</template>

<script>
export default {
  name: 'OrderPaymentsProgressBar',
  props: {
    order: {},
  },
};
</script>

<style scoped></style>
