<template>
  <div class="DotPagination text-center">
    <div
      v-for="idx in numberOfPages"
      @click="page === idx ? undefined : $emit('pageSelected', idx)"
      :class="[{ Active: page === idx }]"
      class="PageItem"
    />
  </div>
</template>

<script>
export default {
  name: 'DotPagination',
  props: {
    page: { type: Number, required: true },
    pages: { type: Number, required: true },
  },
  computed: {
    numberOfPages() {
      return _.range(0, Math.max(1, Math.ceil(this.pages)));
    },
  },
};
</script>

<style scoped lang="scss">
.DotPagination {
  .PageItem {
    width: 11px;
    height: 11px;
    border-radius: 100%;
    background-color: rgba(255, 255, 255, 0.5);
    display: inline-block;
    margin-right: 8px;

    transition: background-color ease-in-out 100ms;
    cursor: pointer;

    &:hover {
      background-color: rgba(255, 255, 255, 0.7);
    }

    &.Active {
      background-color: rgba(255, 255, 255, 0.9);
    }

    &:last-child {
      margin-right: 0;
    }
  }
}
</style>
