<template>
  <CrudDialog
    :title="$t('order')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <BaseInput
      type="text"
      name="id"
      :label="$t('id')"
      v-model.number="model.id"
      :readonly="true"
      number
    />

    <div class="row">
      <div class="col-6">
        <pre>
          {{ this.model.data }}
        </pre>
      </div>
      <div class="col-6">
        <pre>
          {{ this.model.error }}
        </pre>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import InputMoney from 'components/app/input/InputMoney';

export default {
  name: 'WooCallDialog',
  components: { InputMoney, AppDateTimePicker, AppSelect },
  mixins: [FormMixin],
  props: {
    entity: {},
  },
  data() {
    return {
      loading: false,
      model: this.entity,
    };
  },
  methods: {
    async onSave() {},
  },
};
</script>

<style scoped>
fieldset {
  position: relative;
}

.AddNewBtn {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
