<template>
  <CrudDialog
    :title="$t('eventType')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
          number
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput
          name="name"
          :label="$t('name')"
          v-model="model.name"
          v-validate="'required|min:2'"
          required
        />
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import { mergeObjects } from 'util/utils';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';

export default {
  name: 'EventTypeEditDialog',
  components: { AppDateTimePicker, AppSelect },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        props: {},
      },
    };
  },
  methods: {
    async onSave() {
      const result = await this.$api.EventType.save(this.model);
      this.$bus.$emit(EventType.EventTypeChanged, result);
    },
  },
  async mounted() {
    if (this.id) {
      try {
        this.loading = true;
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.EventType.findById(this.id)
        );
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
