<template>
  <div class="OrderMailTagExportPageContent">
    <div class="row mt-3 mb-2" v-if="!hideCustomerInfo">
      <div class="col text-center">
        <h3 class="mb-0">{{ $t('addressee') }}</h3>
      </div>
      <div class="col-4 text-center">
        <h3 class="mb-0">{{ $t('sender') }}</h3>
      </div>
    </div>
    <div class="row" v-if="!hideCustomerInfo">
      <div class="col">
        <OrderMailTag :order="order" :customer="order.customer" class="p-3" />
      </div>
      <div class="col-4">
        <SenderOrderMailTag class="p-3" style="height: 100%" />
      </div>
    </div>
    <div class="NF text-center">
          {{$t('nf')}}
    </div>
  </div>
</template>

<script>
import Badge from 'components/Badge';
import OrderMailTag from 'pages/order/OrderMailTag';
import SenderOrderMailTag from 'pages/order/SenderOrderMailTag';

export default {
  name: 'OrderMailTagExportPageContent',
  components: { SenderOrderMailTag, OrderMailTag, Badge },
  props: {
    order: {},
    hideCustomerInfo: {}
  },
  data() {
    return {};
  },
  computed: {
    customerProps() {
      const order = this.order;
      const props = {
        name: _.get(order, 'customer.name'),
        address: _.get(order, 'customer.addresses[0].address') || '-',
        neighborhood: _.get(order, 'customer.addresses[0].neighborhood') || '-',
        postalCode: _.get(order, 'customer.addresses[0].postalCode') || '-',
        phone: _.get(order, 'customer.contacts[0].content') || '-',
      };
      return Object.entries(props);
    },

    orderProps() {
      const order = this.order;
      const props = {
        eventDate: this.$filters.formatDateTime(order.eventDate, 'date') || '-',
        shipmentDate:
          this.$filters.formatDateTime(order.shipmentDate, 'date') || '-',
        nf: order.nf || '-',
        shippingMethod: _.get(order, 'shipments[0].method.description') || '-',
      };
      return Object.entries(props);
    },
  },
};
</script>

<style lang="scss">
.OrderMailTagExportPageContent {
  page-break-inside: avoid;

  .NF {
    margin-top: 500px;
    font-size: 100px;
    text-transform: uppercase;
  }
}
</style>
