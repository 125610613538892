<template>
  <div class="OrderLayoutResponses">
    <CenterIconMessage
      v-if="_.isEmpty(responseChunks)"
      icon="fas fa-palette"
      :message="$t('noLayoutRequestFound')"
    />

    <div class="row" v-for="(requests, idx) in responseChunks" :key="idx">
      <div class="col-6" v-for="req in requests" :key="req.id">
        <LayoutRequestCard
          :request="req"
          @editRequest="editRequest(req)"
          :is-request-view="false"
        />
      </div>
    </div>
  </div>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import Badge from 'components/Badge';
import PriorityBadge from 'pages/order/design/PriorityBadge';
import DropzoneUpload from 'components/app/input/DropzoneUpload';
import AppTextArea from 'components/app/AppTextArea';
import LayoutRequestCard from 'pages/order/design/LayoutRequestCard';
import OrderLayoutReqResEditDialog from 'pages/order/design/OrderLayoutReqResEditDialog';
import { commonAlerts } from 'util/commonAlerts';
import CenterIconMessage from 'components/app/CenterIconMessage';

export default {
  name: 'OrderLayoutResponses',
  components: {
    CenterIconMessage,
    LayoutRequestCard,
    AppTextArea,
    DropzoneUpload,
    PriorityBadge,
    Badge,
    AppSelect,
    AppFilterDatePicker,
  },
  data() {
    return {
      responseChunks: [],
    };
  },
  methods: {
    async fetchData() {
      const result = await $appApi.OrderLayoutRequest.findAll({
        eager:
          '[uploads, user, response.[uploads, user], order.[customer.[contacts]]]',
        finished: false,
        'response.alert': true,
        userId: this.$store.state.user.id,
        orderBy: ['createdAt'],
        orderByDesc: ['priority'],
      });

      this.responseChunks = _.chunk(result, 2);
    },
    editRequest(request) {
      this.$openModal(OrderLayoutReqResEditDialog, {
        entity: request,
        onSave: async (values) => {
          try {
            await $appApi.OrderLayoutRequest.save(values);
            this.fetchData();
          } catch (e) {
            console.error(e);
            commonAlerts.defaultErrorMessage(e);
          }
        },
      });
    },
  },
  async mounted() {
    this.$bus.$on(EventType.OrderChanged, this.fetchData);
    this.$bus.$on(EventType.OrderLayoutRequestChanged, this.fetchData);
    await this.fetchData();
  },
  beforeDestroy() {
    this.$bus.$off(EventType.OrderChanged, this.fetchData);
    this.$bus.$off(EventType.OrderLayoutRequestChanged, this.fetchData);
  },
};
</script>
<style scoped lang="scss"></style>
