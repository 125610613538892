<template>
  <Badge class="mr-2">
    <span class="mr-1">{{ attributeValue.attribute.caption }}:</span>
    {{ attributeValue.caption }}
  </Badge>
</template>

<script>
import Badge from 'components/Badge';
export default {
  name: 'AttributeValueBadge',
  components: { Badge },
  props: {
    attributeValue: {},
  },
};
</script>

<style scoped></style>
