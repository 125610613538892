<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
    :actionColWidth="185"
    :initial-query="initialQuery"
    :initial-per-page="initialPerPage"
    ref="crudPage"
  >
    <template v-slot:headerFilters>
      <OrderFilterButton :initial-filter="filter" @filter="runSearch" />
    </template>

    <template v-slot:tableButtons="{ rowProps }">
      <AppPopoverButton placement="left" width="175">
        <template v-slot:default="slotProps">
          <div class="d-flex justify-content-around">
            <OrderWhatsButton :entity="rowProps.row" @click="slotProps.close()" />
            <OrderQuizButton :order="rowProps.row" @click="slotProps.close()" />
            <OrderLayoutButton
              :key="`OL-${rowProps.row.id}`"
              :order="rowProps.row"
              @click="slotProps.close()"
            />
            <OrderCloneButton :order-id="rowProps.row.id" @click="slotProps.close()" />
            <SeeActivitiesButton
              :key="`OA-${rowProps.row.id}`"
              :order="rowProps.row"
              @click="slotProps.close()"
            />
          </div>
        </template>
      </AppPopoverButton>

      <ManageOrderButton :order-id="rowProps.row.id" />

      <OrderExportButton
        :order-id="rowProps.row.id"
        :btn-attrs="{
          icon: true,
          size: 'sm',
          class: 'btn-link btn-github btn-simple',
          noBtnText: true,
        }"
      />
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import DateTimeCol from 'components/app/table/DateTimeCol';
import OrderEditDialog from 'pages/order/OrderEditDialog';
import OrderExportButton from './OrderExportButton';
import OrderActivitiesViewDialog from 'pages/order/OrderActivitiesViewDialog';
import SeeActivitiesButton from 'pages/funnel/SeeActivitiesButton';
import OrderCloneButton from 'pages/order/OrderCloneButton';
import AppSelect from 'components/app/input/AppSelect';
import OrderFilterButton from 'pages/funnel/OrderFilterButton';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import OrderLayoutButton from 'pages/order/OrderLayoutButton';
import ManageOrderButton from 'pages/order/ManageOrderButton';
import OrderWhatsButton from 'pages/order/whats/OrderWhatsButton';
import OrderQuizButton from 'pages/order/quiz/OrderQuizButton';
import AppPopoverButton from 'components/app/AppPopoverButton';
import { stripNonDigits } from 'util/utils';

export default {
  name: 'Orders',
  components: {
    AppPopoverButton,
    OrderQuizButton,
    OrderWhatsButton,
    ManageOrderButton,
    OrderLayoutButton,
    AppFilterDatePicker,
    OrderFilterButton,
    AppSelect,
    OrderCloneButton,
    SeeActivitiesButton,
    OrderExportButton,
  },
  props: {
    initialQuery: {},
    initialPerPage: {},
  },
  data() {
    return {
      dialog: OrderEditDialog,
      eventsToListen: [EventType.OrderChanged, EventType.OrderLayoutRequestChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'code', attrs: { width: 100 } },
        {
          prop: 'customer',
          render: ({ row }) => {
            const customer = row.customer;
            return customer.name;
          },
        },
        {
          prop: 'contact',
          render: ({ row }) => {
            return row?.customer?.mainContact()?.content || '-';
          },
        },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { style: 'date' },
        },
        {
          prop: 'productionDeadline',
          component: DateTimeCol,
          attrs: { style: 'date' },
        },
        {
          prop: 'eventDate',
          component: DateTimeCol,
          attrs: { style: 'date' },
        },
        { prop: 'status.description', label: this.$t('status') },
      ],
      filter: new OrderFilterButton.OrderFilterButtonModel(),
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        const digitsOnlyQuery = stripNonDigits(query);
        const possibleCep = query.includes('-') && query.split('-')[1].length === 3;
        if (query.toLowerCase().startsWith('x') && query.length > 1) {
          filters['id:ilike'] = `%${digitsOnlyQuery}%`;
        } else if (query.startsWith('#') && query.length > 1) {
          filters['code:ilike'] = `%${digitsOnlyQuery}%`;
        } else if (
          (query.toLowerCase().startsWith('t') && query.length > 1) ||
          (digitsOnlyQuery.length >= 8 && !possibleCep)
        ) {
          filters['customer.contacts.content:digitsOnlyLike'] = `%${digitsOnlyQuery}%`;
        } else {
          filters[
            'id|code|productionDeadline|nf|customer.name|customer.email|customer.contacts.content|customer.addresses.postalCode:ilike'
          ] = `%${query}%`;
        }
      }

      if (!_.isEmpty(this.filter.statuses)) {
        filters['statusId:in'] = this.filter.statuses.map((s) => s.id).join(',');
      }

      if (!_.isEmpty(this.filter.providers)) {
        filters['providerId:in'] = this.filter.providers.map((s) => s.id).join(',');
      }

      if (this.filter.withPaidProvider !== 'all') {
        const withPayment = this.filter.withPaidProvider === 'true';
        if (withPayment) {
          filters['providerPaymentDate:notNull'] = '';
        } else {
          filters['providerPaymentDate:isNull'] = '';
        }
      }

      Object.assign(
        filters,
        AppFilterDatePicker.formatAsFilter(this.filter.date.field, this.filter.date.range)
      );

      const result = await this.$api.Order.findAll({
        eager: this.filter.isAnyFilterSelected()
          ? '[items.[product, attributes], shipments, payments, customer.[contacts], status, layoutRequests, appliedCoupons]'
          : '[customer.[contacts], status, layoutRequests]',
        orderByDesc: 'date',
        ...filters,
        ...pagination,
        ...(this.filter.isAnyFilterSelected() ? { rangeEnd: 99999 } : {}),
      });

      if (this.filter.isAnyFilterSelected()) {
        return this.filter.filterOrders(result.results);
      }

      return result;
    },
    async remove(row) {
      return await this.$api.Order.remove(row.id);
    },
    viewActivities(order) {
      this.$openModal(OrderActivitiesViewDialog, {
        order: order,
      });
    },
    filterChanged() {
      this.$refs.crudPage.fetchEntities();
    },
    runSearch(filter) {
      this.filter = filter;
      this.$refs.crudPage.fetchEntities();
    },
  },
};
</script>
<style scoped lang="scss"></style>
