import { render, staticRenderFns } from "./ManageOrderButton.vue?vue&type=template&id=3b68c362&scoped=true"
import script from "./ManageOrderButton.vue?vue&type=script&lang=js"
export * from "./ManageOrderButton.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3b68c362",
  null
  
)

export default component.exports