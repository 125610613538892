<template>
  <Dialog
    :title="$t('details')"
    class="ProductionDetailDialog"
    @close="$emit('close')"
  >
    <div v-for="data in details" :key="data.order.id" class="OrderBox mb-4">
      <div class="d-flex justify-content-between align-items-center">
        <Tooltip :content="$t('seeDetails')">
          <BaseButton
            class="mr-2"
            size="sm"
            icon
            @click="openDetails(data.order)"
          >
            <i class="fas fa-search-plus" />
          </BaseButton>
        </Tooltip>
        <h4 class="flex-grow-1 mb-0">
          {{ data.order.orderCode }}
        </h4>
        <span>
          {{ $t('productionDeadline') }}:
          {{ data.order.productionDeadline | formatDateTime('date') }}
        </span>
        <div v-if="data.isLate" class="ml-2">
          <Badge type="danger">
            {{ data.distance }}
            {{ $t('days').toLowerCase() }}
            {{ $t('late').toLowerCase() }}
          </Badge>
        </div>
      </div>

      <ul>
        <li
          class="Item"
          v-for="({ item, attrVals }, idx) in data.items"
          :key="idx"
        >
          <Badge type="warning">{{ item.quantity }}x</Badge>
          <span class="ml-2 mr-2">{{ item.product.name }}</span>
          <AttributeValueBadge
            v-for="(attrVal, idx) in attrVals"
            :key="idx"
            :attribute-value="attrVal"
            type="success"
          />
        </li>
      </ul>
    </div>
  </Dialog>
</template>

<script>
import Dialog from 'components/app/Dialog';
import Badge from 'components/Badge';
import AttributeValueBadge from 'pages/order/AttributeValueBadge';
import OrderManagementDialog from 'pages/order/OrderManagementDialog';

export default {
  name: 'ProductionDetailDialog',
  components: { AttributeValueBadge, Badge, Dialog },
  props: {
    details: {},
  },
  data() {
    return {};
  },
  methods: {
    openDetails(order) {
      this.$openModal(OrderManagementDialog, {
        orderId: order.id,
      });
    },
  },
  computed: {},
};
</script>

<style lang="scss"></style>
