<template>
  <CrudDialog
    :title="$t('accountingEntryCategory')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="AccountingEntryCategoryEditDialog"
  >
    <div class="row">
      <div class="col-2">
        <BaseInput
          type="text"
          name="id"
          :label="$t('id')"
          v-model="model.id"
          :readonly="true"
          number
        />
      </div>
      <div class="col">
        <BaseInput
          type="text"
          name="name"
          :label="$t('name')"
          v-model="model.name"
          v-validate="'required|min:2'"
          required
        />
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import { mergeObjects } from 'util/utils';

export default {
  name: 'AccountingEntryCategoryEditDialog',
  components: {},
  mixins: [FormMixin],
  props: {
    id: {},
    parentId: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        parentId: this.parentId,
      },
    };
  },
  methods: {
    async onSave() {
      await this.$api.AccountingEntryCategory.save(this.model);
      this.$bus.$emit(EventType.AccountingEntryCategoryChanged);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.id) {
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.AccountingEntryCategory.findById(this.id, {
            eager: '[subcategories]',
          })
        );
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss">
.AccountingEntryCategoryEditDialog {
}
</style>
