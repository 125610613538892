<template>
  <CrudDialog
    :title="$t('funnel')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="FunnelEditDialog"
  >
    <div class="row">
      <div class="col">
        <BaseInput
          type="text"
          name="id"
          :label="$t('id')"
          v-model="model.id"
          :readonly="true"
          number
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput
          type="text"
          name="name"
          :label="$t('name')"
          v-model="model.name"
          v-validate="'required|min:2'"
          required
        />
      </div>
      <div class="col-3">
        <BaseInput
          type="number"
          name="order"
          :label="$t('position')"
          v-model.number="model.order"
          v-validate="'required|integer'"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseCheckbox v-model="model.props.showInitialContacts">
          {{ $t('showInitialContacts') }}
        </BaseCheckbox>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import { mergeObjects } from 'util/utils';

export default {
  name: 'FunnelEditDialog',
  components: {},
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        props: {
          showInitialContacts: false,
        },
      },
    };
  },
  methods: {
    async onSave() {
      await this.$api.Funnel.save(this.model);
      this.$bus.$emit(EventType.FunnelChanged);
    },
  },
  async mounted() {
    try {
      this.loading = true;
      if (this.id) {
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.Funnel.findById(this.id)
        );
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss"></style>
