import { axiosInstance } from 'api/AxiosInstance';
import { BrainApi } from 'api/BrainApi';

const $http = axiosInstance();

export class WooApi {

  brain = new BrainApi({ resource: 'wooCommerceHookCalls' });

  async reprocessOrder(id) {
    const response = await $http.post(`/api/woo/orders/${id}`);
    return response.data;
  }
}
