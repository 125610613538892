<template>
  <div class="TagArrayInput">
    <TagInput
      :key="tag.id"
      class="mr-1"
      v-for="tag in sortedTags"
      :value="tag"
      @input="tagChanged($event, tag)"
      :excluded-tags="value"
    />
    <TagInput @input="addNewTag" :excluded-tags="value" />
  </div>
</template>

<script>
import TagInput from 'components/app/input/TagInput';

export default {
  name: 'TagArrayInput',
  components: { TagInput },
  props: {
    value: {
      default() {
        return [];
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    sortedTags() {
      return _.orderBy(this.value, ['name']);
    },
  },
  methods: {
    tagChanged(tag, oldTag) {
      if (tag && this.value.some((t) => t.id === tag.id)) return;

      const tags = this.value.slice();
      const idx = tags.findIndex((t) => t.id === oldTag.id);
      if (tag) {
        if (tag.name === oldTag.name) return;
        tags[idx] = tag;
      } else {
        tags.removeIdx(idx);
      }
      this.$emit('input', tags);
    },
    addNewTag(tag) {
      if (!tag || this.value.some((t) => t.name === tag.name)) return;

      const tags = this.value.slice();
      tags.push(tag);
      this.$emit('input', tags);
    },
  },
};
</script>

<style scoped lang="scss"></style>
