<template>
  <CrudPage
    :fetch-data="fetchData"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
    dateField="date"
    :table-attrs="tableAttrs"
    ref="crudPage"
    :action-col-width="125"
  >
    <template v-slot:headerFilters>
      <div class="col">
        <div class="form-group">
          <AppSelect
            :options="validatedOptions"
            v-model="validatedFilter"
            multiple
            @input="filterChanged('validated', ...arguments)"
            :placeholder="$t('confirmation')"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <AppSelect
            :options="conditionOptions"
            v-model="conditionFilter"
            multiple
            @input="filterChanged('condition', ...arguments)"
            :placeholder="$t('condition')"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <AppSelect
            :options="methodOptions"
            v-model="methodFilter"
            multiple
            @input="filterChanged('method', ...arguments)"
            :placeholder="$t('method')"
          />
        </div>
      </div>
    </template>

    <template v-slot:additionalColumns>
      <ElTableColumn :label="$t('confirmed')" width="120">
        <template slot-scope="props">
          <BaseCheckbox
            v-model="props.row.validated"
            @input="toggleValidated(props.row)"
            :disabled="
              props.row.validated &&
              !$perm.hasAccess($permType.OrderPaymentManagement)
            "
          />
        </template>
      </ElTableColumn>
    </template>

    <template v-slot:tableButtons="{ rowProps }">
      <Tooltip :content="$t('seeDetails')">
        <BaseButton
          size="sm"
          icon
          class="btn-link btn-github btn-simple"
          @click="manageOrder(rowProps.row.order)"
        >
          <i class="fas fa-search-plus" />
        </BaseButton>
      </Tooltip>

      <TableEditButton @click="editPayment(rowProps.row)" />
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import DateTimeCol from 'components/app/table/DateTimeCol';
import OrderManagementDialog from 'pages/order/OrderManagementDialog';
import MoneyCol from 'components/app/table/MoneyCol';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import { commonAlerts } from 'util/commonAlerts';
import OrderPaymentEditDialog from 'pages/order/OrderPaymentEditDialog';

export default {
  name: 'OrderPayments',
  components: { AppSelect, AppFilterDatePicker },
  data() {
    return {
      eventsToListen: [EventType.OrderPaymentChanged],
      tableAttrs: {
        defaultSort: { prop: 'date', order: 'ascending' },
        showSummary: true,
        summaryMethod: this.computeItemsSummary,
      },
      validatedOptions: [
        { value: true, label: this.$t('confirmed') },
        { value: false, label: this.$t('notConfirmed') },
      ],
      validatedFilter: [false],
      conditionOptions: [{ value: 'À VISTA' }, { value: 'À PRAZO' }],
      conditionFilter: [],
      methodOptions: [],
      methodFilter: [],
      tableColumns: [
        {
          prop: 'order.orderCode',
          label: this.$t('order'),
          attrs: { width: 100 },
        },
        {
          label: this.$t('customer'),
          render({ row }) {
            return row.order.customer.name;
          },
        },
        {
          label: this.$t('contact'),
          render({ row }) {
            return row.order.customer.contacts.map((c) => c.content).join(', ');
          },
          attrs: { width: 150 },
        },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { style: 'date', width: 120, sortable: 'custom' },
        },
        { prop: 'method', attrs: { width: 150 } },
        { prop: 'condition', attrs: { width: 105 } },
        {
          prop: 'description',
        },
        {
          prop: 'value',
          component: MoneyCol,
          attrs: { width: 110 },
        },
        {
          prop: 'validationDate',
          label: this.$t('confirmationDate'),
          component: DateTimeCol,
          attrs: { style: 'date', width: 190, sortable: 'custom' },
        },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination, sort, filters: queryFilters }) {
      const filters = {
        'validated:in': _.isEmpty(this.validatedFilter)
          ? undefined
          : this.validatedFilter.join(','),
        'condition:in': _.isEmpty(this.conditionFilter)
          ? undefined
          : this.conditionFilter.join(','),
        'method:in': _.isEmpty(this.methodFilter)
          ? undefined
          : this.methodFilter.join(','),
      };
      if (query) {
        filters['id|method|description|order.code:ilike'] = `%${query}%`;
      }
      if (queryFilters.dateRange) {
        Object.assign(
          filters,
          AppFilterDatePicker.formatAsFilter('date', queryFilters.dateRange)
        );
      }
      return await this.$api.OrderPayment.findAll({
        eager: '[order.[customer.[contacts]]]',
        orderBy: sort && sort.order === 'ascending' ? [sort.prop] : undefined,
        orderByDesc:
          sort && sort.order === 'descending' ? [sort.prop] : undefined,
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.OrderPayment.remove(row.id);
    },
    manageOrder(order) {
      this.$openModal(OrderManagementDialog, {
        orderId: order.id,
      });
    },
    async editPayment(payment) {
      const order = await this.$api.Order.findById(payment.order.id, {
        eager: '[items, shipments, payments]',
      });
      this.$openModal(OrderPaymentEditDialog, {
        entity: payment,
        order,
        async onSave(model) {
          const result = await this.$api.OrderPayment.save(model);
          this.$bus.$emit(EventType.OrderPaymentChanged, result);
        },
      });
    },
    async toggleValidated(payment) {
      const confirmed = await commonAlerts.confirmOperation({
        title: '',
        text: this.$t('paymentConfirmationMessage', {
          method: payment.method,
          value: this.$filters.money(payment.value),
          name: payment.order.customer.name,
        }),
        cancelButtonText: this.$t('no'),
      });
      if (confirmed) {
        const model = _.pick(payment, ['id', 'validated']);
        await this.$api.OrderPayment.fn({
          name: 'confirmPayment',
          params: model,
        });
      }
      await this.$refs.crudPage.fetchEntities();
    },
    filterChanged(property, values) {
      if (property === 'validated') {
        if (values.length === 1) {
          if (values[0]) {
            this.$refs.crudPage.$refs.table.sort('validationDate', 'ascending');
          } else {
            this.$refs.crudPage.$refs.table.sort('date', 'ascending');
          }
        }
      }

      this.$refs.crudPage.fetchEntities();
    },
    computeItemsSummary({ columns, data }) {
      const totalValue = data.reduce((acc, item) => acc + item.value, 0);
      return [
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        null,
        this.$filters.money(totalValue),
        null,
        null,
      ];
    },
  },
  async mounted() {
    this.methodOptions = (await this.$api.OrderPayment.findMethods()).map(
      ({ method }) => {
        return {
          value: method,
          label: method,
        };
      }
    );
  },
};
</script>
<style scoped lang="scss"></style>
