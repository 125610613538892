<template>
  <span>
    {{ translatedValue }}
  </span>
</template>

<script>
export default {
  name: 'TranslateCol',
  props: {
    tableProps: {},
    colInfo: {},
  },
  computed: {
    translatedValue() {
      return this.$t(_.get(this.tableProps.row, this.colInfo.prop));
    },
  },
};
</script>

<style scoped></style>
