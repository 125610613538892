<template>
  <ElSelect
    class="select-info"
    size="large"
    :placeholder="placeholder || $t('selectOne')"
    :clearable="clearable"
    :filterable="filterable"
    @input="changeValue"
    :value-key="valueKey"
    v-bind="$attrs"
  >
    <ElOptionGroup
      v-if="grouped"
      v-for="(group, idx) in options"
      :label="group.label"
      :key="`group-${idx}`"
      :disabled="group.disabled"
    >
      <ElOption
        class="select-info"
        v-for="(opt, idx) in group.options"
        :value="opt.value"
        :label="computeLabel(opt)"
        :key="computeKey(opt, idx)"
        :disabled="opt.disabled"
      >
        <slot :option="opt"></slot>
      </ElOption>
    </ElOptionGroup>

    <ElOption
      v-if="!grouped"
      class="select-info"
      v-for="(opt, idx) in options"
      :value="opt.value"
      :label="computeLabel(opt)"
      :key="computeKey(opt, idx)"
      :disabled="opt.disabled"
    >
      <slot :option="opt"></slot>
    </ElOption>
  </ElSelect>
</template>

<script>
export default {
  name: 'AppSelect',
  inheritAttrs: false,
  props: {
    grouped: { default: false },
    options: { default: () => [] },
    onSelectFilter: { default: (value) => value },
    filterable: { default: true },
    clearable: { default: true },
    placeholder: {},
    valueKey: {},
  },
  methods: {
    computeLabel(option) {
      return option.label || `${option.value}`;
    },
    computeKey(option, idx) {
      return option.key || (_.isNumber(option.value) ? option.value : idx);
    },
    changeValue(value) {
      value = this.onSelectFilter ? this.onSelectFilter(value) : value;
      this.$emit('input', value);
    },
  },
};
</script>

<style scoped></style>
