<template>
  <Modal
    modalClasses="Dialog modal-xl"
    @close="$emit('close')"
    :loading="loading"
  >
    <div slot="header">
      <h4 class="title title-up">{{ title }}</h4>
    </div>

    <slot />
  </Modal>
</template>

<script>
export default {
  name: 'Dialog',
  props: {
    title: {},
    loading: {},
  },
};
</script>

<style lang="scss">
@media (max-width: 1199px) {
  .modal-xl {
    min-width: 90%;
  }
}
</style>
