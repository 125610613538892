<template>
  <div class="SimplePagination text-center">
    <BaseButton
      size="sm"
      type="github"
      simple
      link
      icon
      :disabled="disablePrev"
      @click="$emit('pageSelected', 0)"
    >
      <i class="fas fa-angle-double-left"></i>
    </BaseButton>
    <BaseButton
      size="sm"
      type="github"
      simple
      link
      icon
      :disabled="disablePrev"
      @click="$emit('pageSelected', page - 1)"
    >
      <i class="fas fa-angle-left"></i>
    </BaseButton>
    {{ page + 1 }} / {{ pages }}
    <BaseButton
      size="sm"
      type="github"
      simple
      link
      icon
      :disabled="disableNext"
      @click="$emit('pageSelected', page + 1)"
    >
      <i class="fas fa-angle-right"></i>
    </BaseButton>
    <BaseButton
      size="sm"
      type="github"
      simple
      link
      icon
      :disabled="disableNext"
      @click="$emit('pageSelected', pages - 1)"
    >
      <i class="fas fa-angle-double-right"></i>
    </BaseButton>
  </div>
</template>

<script>
export default {
  name: 'SimplePagination',
  props: {
    page: { type: Number, required: true },
    pages: { type: Number, required: true },
  },
  computed: {
    disablePrev() {
      return this.page <= 0;
    },
    disableNext() {
      return this.page + 1 >= this.pages;
    },
  },
};
</script>

<style scoped lang="scss">
.SimplePagination {
}
</style>
