<template>
  <ColorPicker v-bind="$attrs" @input="onChange" class="AppColorPicker" />
</template>

<script>
import { ColorPicker } from 'element-ui';

export default {
  name: 'AppColorPicker',
  components: { ColorPicker },
  inheritAttrs: false,
  props: {},
  methods: {
    onChange(val) {
      this.$emit('input', val);
    },
  },
};
</script>

<style lang="scss">
.AppColorPicker {
  &.xs {
    height: 16px;

    .el-color-picker__trigger {
      width: 16px;
      height: 16px;
      padding: 0;
    }
  }
}
</style>
