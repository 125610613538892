import { render, staticRenderFns } from "./MetricRow.vue?vue&type=template&id=c21f2bbc&scoped=true"
import script from "./MetricRow.vue?vue&type=script&lang=js"
export * from "./MetricRow.vue?vue&type=script&lang=js"
import style0 from "./MetricRow.vue?vue&type=style&index=0&id=c21f2bbc&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "c21f2bbc",
  null
  
)

export default component.exports