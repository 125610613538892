<template>
  <div class="MailTag">
    <b>{{ order | orderCode }}</b>
    <div class="row">
      <div class="col">
        <table class="w-100">
          <tr
            v-for="[key, value] in customerProps"
            :key="key"
            v-if="!_.isUndefined(value)"
          >
            <td>
              <b>{{ $t(key) }}:</b>
            </td>
            <td class="pl-2">{{ value }}</td>
          </tr>
        </table>
      </div>
      <div class="col-5">
        <table class="w-100">
          <tr
            v-for="[key, value] in orderProps"
            :key="key"
            v-if="!_.isUndefined(value)"
          >
            <td>
              <b>{{ $t(key) }}:</b>
            </td>
            <td class="pl-4">{{ value }}</td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OrderMailTag',
  props: {
    order: {},
    customCustomerProps: {},
  },
  computed: {
    customerProps() {
      if (this.customCustomerProps) {
        return Object.entries(this.customCustomerProps);
      }
      const order = this.order;
      const { customer } = order;

      const address = customer.mainAddress();
      const contact = customer.mainContact();

      const props = {
        name: customer.name,
        city: address?.cityAndState() || '-',
        address: address?.address || '-',
        neighborhood: address?.neighborhood || '-',
        postalCode: address?.postalCode || '-',
        phone: contact?.content || '-',
      };
      return Object.entries(props);
    },

    orderProps() {
      const order = this.order;
      if (!order) {
        return [];
      }
      const props = {
        eventDate: this.$filters.formatDateTime(order.eventDate, 'date') || '-',
        nf: order.nf || '-',
        shippingMethod: _.get(order, 'shipments[0].method.description') || '-',
      };
      return Object.entries(props);
    },
  },
};
</script>

<style scoped>
.MailTag {
  font-size: 0.8rem;
  border: 4px solid;
  border-radius: 2px;
}

.MailTag td {
  padding-top: 8px;
}
</style>
