<template>
  <CrudDialog
    :title="$t('shippingMethod')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="pull-right">
      <BaseButton @click="addQuantityRange()" icon>
        <i class="fas fa-plus" />
      </BaseButton>
    </div>

    <AppTable :data="model">
      <ElTableColumn :label="$t('min')">
        <template slot-scope="props">
          <BaseInput
            :name="`min${props.$index}`"
            v-model.number="props.row.min"
            v-validate="'required|min_value:0'"
            type="number"
            :data-vv-as="$t('min')"
          />
        </template>
      </ElTableColumn>
      <ElTableColumn :label="$t('max')">
        <template slot-scope="props">
          <BaseInput
            :name="`max${props.$index}`"
            v-model.number="props.row.max"
            v-validate="`required|min_value:${props.row.min + 1}`"
            type="number"
            :data-vv-as="$t('max')"
          />
        </template>
      </ElTableColumn>

      <ElTableColumn :label="$t('value')">
        <template slot-scope="props">
          <BaseInput :name="`value${props.$index}`">
            <InputMoney
              :name="`value${props.$index}`"
              v-model.number="props.row.value"
              :data-vv-as="$t('value')"
            />
          </BaseInput>
        </template>
      </ElTableColumn>
      <ElTableColumn :label="$t('actions')" :width="80">
        <template slot-scope="props">
          <TableRemoveButton @click="removeQuantityRange(props.$index)" />
        </template>
      </ElTableColumn>
    </AppTable>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import InputMoney from 'components/app/input/InputMoney';

export default {
  name: 'PostalCodeRangeQuantityRangeEditDialog',
  components: { InputMoney },
  mixins: [FormMixin],
  props: {
    entity: {},
    onSave: {},
  },
  data() {
    return {
      loading: false,
      model: this.entity || [],
    };
  },
  methods: {
    addQuantityRange() {
      const data = {
        min: 0,
        max: 0,
        value: 0,
      };
      if (!_.isEmpty(this.model)) {
        const last = this.model[this.model.length - 1];
        data.min = last.max + 1;
        data.max = last.max * 2;
        data.value = last.value * 2;
      }
      this.model.push(data);
    },
    removeQuantityRange(idx) {
      this.model.removeIdx(idx);
    },
  },
};
</script>

<style scoped></style>
