<template>
  <router-view></router-view>
</template>

<script>
export default {
  mounted() {
    this.$ws.start(this.$store);
  },
};
</script>
