import { formatDateTime, formatNumber, formatOrderCode, fromNow, uploadUrl } from 'util/utils';
import { OrderUi } from 'api/Models';

export const GlobalFilters = {
  install(Vue) {
    Vue.filter('fromNow', fromNow);
    Vue.filter('formatDateTime', formatDateTime);
    Vue.filter('money', formatNumber);
    Vue.filter('orderCode', formatOrderCode);
    Vue.filter('number', (val) => {
      return formatNumber(val, 'decimal');
    });
    Vue.filter('orderTotalValue', (order) => {
      let val = 0;
      if (order) {
        val = new OrderUi(order).totalValue();
      }
      return val;
    });
    Vue.filter('uploadUrl', (id) => {
      return uploadUrl(id);
    });
    Vue.filter('percent', (val) => (val ? (val * 100).toFixed(0) : '0'));

    {
      const monthFormatter = new Intl.DateTimeFormat('pt-BR', {
        month: 'long',
      });
      const months = [];
      for (let month = 0; month < 12; month++) {
        const testDate = new Date(Date.UTC(2000, month, 1, 0, 0, 0));
        months.push(_.capitalize(monthFormatter.format(testDate)));
      }
      Vue.filter('monthName', (val) => months[val]);
    }

    {
      const weekdayFormatter = new Intl.DateTimeFormat('pt-BR', {
        weekday: 'long',
      });
      let weekStart = moment().startOf('week');
      const weekdayNames = [];
      for (let i = 0; i < 7; i++) {
        weekdayNames[i] = _.capitalize(weekdayFormatter.format(weekStart.toDate()));
        weekStart.add({ day: 1 });
      }
      Vue.filter('weekdayName', (val) => weekdayNames[val]);
    }

    Vue.prototype.$filters = Vue.options.filters;
  },
};
