<template>
  <Tooltip :content="$t('layouts')">
    <BaseButton
      :size="size"
      icon
      class="OrderLayoutButton btn-link btn-github btn-simple"
      @click="manageLayout"
    >
      <i class="fas fa-palette" />

      <template v-if="badgeData.show">
        <Badge :type="badgeData.type">
          <div style="padding: 5px">
            <i :class="badgeData.icon" />
          </div>
        </Badge>
      </template>
      <!--<div v-if="layout" class="Badges">
        <span
          v-if="layout.approvalDate"
          class="badge badge-success"
          :title="$t('approved')"
        >
          <i class="fas fa-thumbs-up" />
        </span>

        <span
          v-if="layout.rejectionDate"
          class="badge badge-danger"
          :title="$t('rejected')"
        >
          <i class="fas fa-thumbs-down" />
        </span>

        <span
          v-if="layout.approvalRequestDate"
          class="badge badge-warning"
          :title="$t('waitingApproval')"
        >
          <i class="far fa-clock" />
        </span>
      </div>-->
    </BaseButton>
  </Tooltip>
</template>

<script>
import OrderLayoutDialog from 'pages/order/design/OrderLayoutDialog';
import Badge from 'components/Badge';

export default {
  name: 'OrderLayoutButton',
  components: { Badge },
  props: {
    order: {},
    size: { default: 'sm' },
  },
  methods: {
    manageLayout(e) {
      this.$emit('click', e);
      this.$openModal(OrderLayoutDialog, {
        orderId: this.order.id,
      });
    },
  },
  computed: {
    badgeData() {
      const requests = this.order.layoutRequests || [];
      let inProgress = requests.some((r) => r.alert);
      let finished = inProgress && requests.some((r) => r.finished);
      return {
        show: !_.isEmpty(requests) && inProgress,
        type: inProgress && !finished ? 'warning' : 'success',
        icon: inProgress && !finished ? 'fas fa-sync-alt' : 'fas fa-check',
      };
    },
  },
};
</script>

<style lang="scss">
.OrderLayoutButton {
  .Badges {
    position: absolute;
    bottom: 0;
    right: 0;

    .badge {
      padding: 6px;

      i {
        font-size: 8px !important;
      }
    }
  }
}
</style>
