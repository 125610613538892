<template>
  <CrudDialog
    :title="$t('order')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="OrderEditDialog"
  >
    <Collapse :active-index="expandedPanels">
      <CollapseItem :title="$t('general')">
        <div class="row">
          <div class="col-2">
            <BaseInput
              type="text"
              name="id"
              :label="$t('id')"
              v-model.number="model.id"
              :readonly="true"
              number
            />
          </div>
          <div class="col-2">
            <BaseInput
              type="text"
              name="code"
              :label="$t('code')"
              v-model.number="model.code"
              :readonly="true"
              number
            />
          </div>
          <div class="col">
            <BaseInput name="createdAt" :label="$t('createdAt')">
              <AppDateTimePicker name="createdAt" v-model="model.createdAt" :readonly="true" />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="date" :label="$t('date')">
              <AppDateTimePicker
                name="date"
                v-model="model.date"
                v-validate="'required'"
                :readonly="disableDateSelection"
              />
            </BaseInput>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <template v-if="model.recommendedByQuiz">
              <div class="d-flex">
                <BaseInput
                  class="flex-grow-1 mr-1"
                  name="recommendedByQuiz"
                  :label="$t('recommendedBy')"
                  v-model="model.recommendedByQuiz.order.customer.name"
                  readonly
                />

                <!-- <BaseButton
                  @click="model.recommendedByQuiz = null"
                  icon
                  link
                  class="align-with-base-field"
                  type="neutral"
                >
                  <i class="fas fa-times" />
                </BaseButton>-->
              </div>
            </template>
            <template v-else>
              <div class="d-flex">
                <BaseInput
                  class="flex-grow-1 mr-1"
                  name="recommendedByCode"
                  :label="$t('recommendedBy')"
                  :placeholder="$t('recommendationCode')"
                  v-model="recommendedByCode"
                  @keypress.prevent.enter="processRecommendedByCode"
                />
                <BaseButton
                  @click="processRecommendedByCode"
                  icon
                  :disabled="!recommendedByCode || !model.customer"
                  class="align-with-base-field"
                >
                  <i class="fas fa-check-circle" />
                </BaseButton>
              </div>
            </template>
          </div>
          <div class="col-3">
            <BaseInput name="orderStatus" :label="$t('orderStatus')" required>
              <AppSelect
                value-key="id"
                name="orderStatus"
                v-model="model.status"
                v-validate="'required'"
                :options="availableStatus"
                :grouped="true"
              />
            </BaseInput>
          </div>
          <div class="col-5 position-relative">
            <BaseInput name="customer" :label="$t('customer')" required class="flex-grow-1">
              <AppSelect
                value-key="id"
                name="customer"
                v-model="model.customer"
                :options="mergedCustomers"
                v-validate="'required'"
                remote
                :remote-method="findCustomers"
                :loading="loadingCustomers"
              >
                <template v-slot="props">
                  <div>
                    <div>
                      <span class="mr-2">
                        {{ props.option.label }}
                      </span>
                      <small class="mr-2">
                        {{ customerPhoneString(props.option.value) }}
                      </small>
                      <small class="mr-2">
                        {{ props.option.value.email }}
                      </small>
                    </div>
                  </div>
                </template>
              </AppSelect>
            </BaseInput>

            <Tooltip :content="$t('new')">
              <BaseButton
                icon
                class="btn-link btn-github btn-simple"
                size="sm"
                style="position: absolute; top: -7px; right: 55px"
                @click="editCustomer()"
              >
                <i class="fas fa-plus" />
              </BaseButton>
            </Tooltip>

            <Tooltip :content="$t('edit')">
              <BaseButton
                icon
                class="btn-link btn-github btn-simple"
                size="sm"
                style="position: absolute; top: -7px; right: 16px"
                @click="editCustomer(model.customer)"
                :disabled="!model.customer"
              >
                <i class="fas fa-edit" />
              </BaseButton>
            </Tooltip>
          </div>
        </div>
      </CollapseItem>

      <CollapseItem :title="$t('delivery')">
        <div class="row">
          <div class="col">
            <BaseInput name="shippingMethod" :label="$t('shippingMethod')" required>
              <AppSelect
                value-key="id"
                name="shippingMethod"
                v-model="shipment.method"
                :options="availableShippingMethods"
                v-validate="'required'"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="shippingTime" :label="$t('shippingTimeInDays')" required>
              <InputMoney
                name="shippingTime"
                v-model.number="model.shippingDays"
                v-validate="'required|min_value:0'"
                :prefix="''"
                :precision="0"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="shippingValue" :label="$t('value')">
              <InputMoney
                name="shippingValue"
                v-model.number="shipment.total"
                v-validate="'min:0'"
                :disabled="model.status && model.status.isFinished()"
              />
            </BaseInput>
          </div>
          <div class="col-1">
            <ElDropdown trigger="click" @command="recalculateShipping">
              <Tooltip :content="$t('searchShippingInfo')">
                <BaseButton
                  class="m-0 mt-4"
                  icon
                  type="info"
                  @click="processShippingButtonOptions()"
                  :disabled="!model.customer"
                >
                  <i class="fas fa-search" />
                </BaseButton>
              </Tooltip>
              <ElDropdownMenu slot="dropdown" class="ShippingButtonOptionsMenu">
                <ElDropdownItem disabled>
                  <div>
                    <b>{{ $t('postalCode') }}:</b>
                    {{ _.get(shippingButtonOptions, '[0].postalCode') }}
                  </div>
                  <div>
                    <b>{{ $t('quantity') }}:</b>
                    {{ _.get(shippingButtonOptions, '[0].quantity') }}
                  </div>
                </ElDropdownItem>

                <ElDropdownItem
                  v-for="opt in shippingButtonOptions"
                  :key="opt.method.id"
                  :command="opt"
                  divided
                >
                  <div>
                    {{ opt.method.description }}
                  </div>
                  <div>
                    <small>
                      <b>{{ $t('deadline') }}:</b>
                      <span class="ml-1">{{ opt.days }} {{ $t('days') }}</span>
                      <span class="ml-1" v-if="opt.postalCodeRangeString">
                        ({{ $t('band') }}: {{ opt.postalCodeRangeString }})
                      </span>
                    </small>
                  </div>
                  <div>
                    <small>
                      <b>{{ $t('value') }}:</b>
                      <span class="ml-1">{{ opt.value | money }}</span>
                      <span class="ml-1" v-if="opt.quantityRangeString">
                        ({{ $t('band') }}: {{ opt.quantityRangeString }})
                      </span>
                    </small>
                  </div>
                </ElDropdownItem>
              </ElDropdownMenu>
            </ElDropdown>
          </div>
        </div>
        <BaseAlert type="warning" class="m-0" v-if="showShippingAlert">
          {{ $t('customerShippingMethodWarningMessage') }}
        </BaseAlert>
        <div class="row">
          <div class="col">
            <BaseInput name="nf" :label="$t('nf')" v-model="model.nf" />
          </div>
          <div class="col">
            <BaseInput name="shipmentCost" :label="`${$t('shipmentCost')}`" required>
              <InputMoney
                name="shipmentCost"
                v-model.number="shipment.cost"
                v-validate="'required|min_value:0'"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="daysToDelivery" :label="`${$t('daysToDeliveryLabel')}`">
              <InputMoney
                name="daysToDelivery"
                v-model.number="model.daysToDelivery"
                :prefix="''"
                :precision="0"
              />
            </BaseInput>
          </div>
          <div class="col-1"></div>
        </div>
      </CollapseItem>

      <CollapseItem :title="$t('execution')">
        <div class="row">
          <div class="col">
            <BaseInput name="designChanges" :label="$t('designChanges')">
              <InputMoney
                name="designChanges"
                v-model.number="model.designChanges"
                v-validate="'|min_value:0'"
                :prefix="''"
                :precision="0"
                type="number"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="designChangeUser" :label="$t('whoCreatedTheDesign')">
              <AppSelect
                name="designChangeUser"
                v-model="model.designChangeUser"
                :options="userOptions"
                value-key="id"
              />
            </BaseInput>
          </div>

          <div class="col">
            <BaseInput name="seller" :label="$t('seller')" required>
              <AppSelect
                name="seller"
                v-model="model.seller"
                :options="userOptions"
                value-key="id"
                v-validate="'required'"
              />
            </BaseInput>
          </div>

          <div class="col">
            <BaseInput name="responsibleUser" :label="$t('responsible')">
              <template v-slot="{ name }">
                <AppSelect
                  :name="name"
                  v-model="model[name]"
                  :options="userOptions"
                  value-key="id"
                />
              </template>
            </BaseInput>
          </div>
        </div>

        <div class="row">
          <div class="col">
            <BaseInput name="productionDeadline" :label="$t('productionDeadline')">
              <AppDateTimePicker
                name="productionDeadline"
                v-model="model.productionDeadline"
                type="date"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="productionFinished" :label="$t('productionFinished')">
              <AppDateTimePicker
                name="productionFinished"
                v-model="model.productionFinished"
                type="date"
              />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="eventDate" :label="$t('eventDate')">
              <AppDateTimePicker name="eventDate" v-model="model.eventDate" type="date" />
            </BaseInput>
          </div>
          <div class="col">
            <BaseInput name="shipmentDate" :label="$t('shipmentDate')">
              <AppDateTimePicker name="shipmentDate" v-model="model.shipmentDate" type="date" />
            </BaseInput>
          </div>
        </div>

        <div class="row">
          <div class="col-3">
            <BaseInput name="productionDays" :label="`${$t('productionDays')}`" required>
              <InputMoney
                name="productionDays"
                v-model.number="model.productionDays"
                v-validate="'required|min_value:0'"
                :prefix="''"
                :precision="0"
              />
            </BaseInput>
          </div>
          <div class="col-3">
            <BaseInput name="provider" :label="$t('provider')" required>
              <AppSelect
                name="provider"
                v-model="model.provider"
                :options="providerOptions"
                value-key="id"
                :filterable="true"
                :clearable="true"
                v-validate="'required'"
              />
            </BaseInput>
          </div>
          <div class="col-3">
            <BaseInput name="providerPaymentDate" :label="$t('providerPaymentDate')">
              <AppDateTimePicker name="date" v-model="model.providerPaymentDate" type="date" />
            </BaseInput>
          </div>
        </div>
      </CollapseItem>

      <CollapseItem :title="$t('items')">
        <BaseButton class="AddNewBtn" @click="openItemDialog()" size="sm">
          <i class="fas fa-plus" />
          {{ $t('item') }}
        </BaseButton>

        <div>
          <AppTable :data="model.items" :columns="itemCols" max-height="400">
            <ElTableColumn :label="$t('actions')" width="155">
              <template slot-scope="props">
                <Tooltip :content="$t('export')">
                  <BaseButton
                    size="sm"
                    icon
                    :class="['btn-link btn-github btn-simple', { NoContent: !props.row.design }]"
                    @click="exportItem(props)"
                  >
                    <i class="fas fa-file-download" />
                  </BaseButton>
                </Tooltip>

                <Tooltip :content="$t('design')">
                  <BaseButton
                    size="sm"
                    icon
                    :class="['btn-link btn-github btn-simple', { NoContent: !props.row.design }]"
                    @click="openItemDesignDialog(props.row)"
                  >
                    <i class="fas fa-palette" />
                  </BaseButton>
                </Tooltip>
                <TableEditButton @click="openItemDialog(props)" />
                <TableRemoveButton @click="removeElement(model.items, props)" />
              </template>
            </ElTableColumn>
          </AppTable>
        </div>
        <div class="row">
          <div class="col position-relative">
            <BaseInput name="generalDiscount" :label="$t('generalDiscount')">
              <InputMoney
                name="generalDiscount"
                v-model.number="model.generalDiscount"
                v-validate="'min_value:0|max_value:100'"
                :prefix="''"
                :suffix="'%'"
                :precision="0"
                maxlength="4"
              />
            </BaseInput>
            <CustomerDiscountButton
              :order="wrappedModel"
              @couponSelected="applyDiscountCoupon"
              style="position: absolute; top: -5px; left: 110px"
            />
            <div class="d-flex flex-wrap">
              <div v-for="(coupon, idx) in wrappedModel.appliedCoupons" :key="coupon.id">
                <Badge type="warning" class="mr-2">
                  {{ $t('coupon') }} {{ coupon.id }}: -{{ coupon.discountString() }}
                  <Tooltip :content="$t('remove')">
                    <BaseButton
                      icon
                      simple
                      link
                      size="xs"
                      type="neutral"
                      @click="model.appliedCoupons.removeIdx(idx)"
                    >
                      <i class="fas fa-times"></i>
                    </BaseButton>
                  </Tooltip>
                </Badge>
              </div>
            </div>
          </div>
        </div>

        <div class="row mt-2">
          <div class="col">
            <table>
              <tr v-for="([key, value], idx) in summaryData" :key="idx">
                <td class="pr-2">{{ $t(key) }}:</td>
                <td>
                  {{ value }}
                </td>
              </tr>
            </table>
          </div>
        </div>
      </CollapseItem>

      <CollapseItem :title="$t('payments')">
        <BaseButton class="AddNewBtn" @click="openPaymentDialog()" size="sm">
          <i class="fas fa-plus" />
          {{ $t('payment') }}
        </BaseButton>

        <div class="PaymentProgressWrapper">
          <OrderPaymentsProgressBar :order="wrappedModel" />
        </div>

        <div>
          <AppTable :data="sortedPayments" :columns="paymentCols" max-height="175">
            <ElTableColumn :label="$t('status')" width="165">
              <template
                slot-scope="props"
                v-if="!props.row.validated || $perm.hasAccess($permType.OrderPaymentManagement)"
              >
                <div class="position-relative">
                  <div>
                    <BaseCheckbox :checked="props.row.deposit" :disabled="true" inline />

                    {{ $t('signal') }}
                  </div>
                  <div>
                    <BaseCheckbox :checked="props.row.validated" :disabled="true" inline />
                    {{ $t('confirmed') }}
                  </div>
                  <div>
                    <a :href="props.row.receiptFileId | uploadUrl">
                      <BaseCheckbox :checked="!!props.row.receiptFileId" :disabled="true" inline />
                      {{ $t('receipt') }}
                    </a>
                  </div>
                </div>
              </template>
            </ElTableColumn>
            <ElTableColumn :label="$t('actions')" width="100">
              <template
                slot-scope="props"
                v-if="!props.row.validated || $perm.hasAccess($permType.OrderPaymentManagement)"
              >
                <TableEditButton @click="openPaymentDialog(props)" />
                <TableRemoveButton @click="removeElement(model.payments, props)" />
              </template>
            </ElTableColumn>
          </AppTable>
        </div>
      </CollapseItem>

      <CollapseItem :title="$t('others')">
        <div class="row">
          <div class="col">
            <BaseInput name="design" :label="$t('design')">
              <div>
                <AppAutoUpload v-model="model.designFileId" :preview="false" />
              </div>
            </BaseInput>
          </div>
        </div>
      </CollapseItem>
    </Collapse>
    <template v-slot:footer>
      <SeeActivitiesButton :order="model" v-if="model.id" class="mr-4" />
    </template>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import InputMoney from 'components/app/input/InputMoney';
import OrderItemEditDialog from 'pages/order/OrderItemEditDialog';
import { commonAlerts } from 'util/commonAlerts';
import MoneyCol from 'components/app/table/MoneyCol';
import QuantityCol from 'components/app/table/QuantityCol';
import { formatNumber, makeApiUrl, mergeObjects } from 'util/utils';
import OrderProductTableColumn from 'pages/order/OrderProductTableColumn';
import ImageUpload from 'components/ImageUpload';
import Collapse from 'components/Collapse/Collapse';
import CollapseItem from 'components/Collapse/CollapseItem';
import OrderPaymentEditDialog from 'pages/order/OrderPaymentEditDialog';
import DateTimeCol from 'components/app/table/DateTimeCol';
import CustomerEditDialog from 'pages/customer/CustomerEditDialog';
import OrderPaymentsProgressBar from 'pages/funnel/OrderPaymentsProgressBar';
import { CustomerUi, OrderActivityUi, OrderUi, ShippingMethodUi } from 'api/Models';
import OrderCancelDialog from 'pages/order/OrderCancelDialog';
import AppAutoUpload from 'components/app/input/AppAutoUpload';
import OrderItemDesignEditDialog from 'pages/order/design/OrderItemDesignEditDialog';
import BaseAlert from 'components/BaseAlert';
import SeeActivitiesButton from 'pages/funnel/SeeActivitiesButton';
import CustomerDiscountButton from 'pages/order/discount/CustomerDiscountButton';
import Badge from 'components/Badge';

let LAST_PRODUCTION_DAYS = 12;
const CUSTOMER_EAGER_DATA = '[contacts, addresses, discountCoupons]';

export default {
  name: 'OrderEditDialog',
  components: {
    Badge,
    CustomerDiscountButton,
    SeeActivitiesButton,
    BaseAlert,
    AppAutoUpload,
    OrderPaymentsProgressBar,
    CollapseItem,
    Collapse,
    ImageUpload,
    InputMoney,
    AppDateTimePicker,
    AppSelect,
  },
  mixins: [FormMixin],
  props: {
    id: {},
    forCloning: { type: Boolean, default: false },
    initialCustomerId: {},
    initialWhatsDialogMessage: {},
  },
  data() {
    return {
      loading: false,
      loadingCustomers: false,
      model: {
        id: undefined,
        code: undefined,
        businessId: undefined,
        status: null,
        customer: null,
        date: new Date(),
        shipments: [],
        items: [],
        shipmentDate: undefined,
        productionDeadline: undefined,
        productionFinished: undefined,
        eventDate: undefined,
        shippingDays: 10,
        productionDays: LAST_PRODUCTION_DAYS,
        payments: [],
        designFileId: undefined,
        daysToDelivery: '',
        generalDiscount: 0,
        designChanges: 0,
        designChangeUser: null,
        seller: null,
        appliedCoupons: [],
        recommendedByQuiz: null,
        provider: null,
        providerPaymentDate: undefined,
        props: {},
      },
      availableBusiness: [],
      availableStatus: [],
      availableCustomers: [],
      availableShippingMethods: [],
      itemCols: [
        { prop: 'product', component: OrderProductTableColumn },
        { prop: 'quantity', attrs: { width: 120 }, component: QuantityCol },
        { prop: 'price', attrs: { width: 125 }, component: MoneyCol },
        { prop: 'cost', attrs: { width: 125 }, component: MoneyCol },
        {
          prop: 'total',
          attrs: { width: 125 },
          render({ row }) {
            return formatNumber(row.quantity * row.price);
          },
        },
      ],
      paymentCols: [
        { prop: 'parcel', attrs: { width: 90 } },
        { prop: 'value', component: MoneyCol, attrs: { width: 150 } },
        {
          prop: 'date',
          component: DateTimeCol,
          attrs: { width: 120, style: 'date' },
        },
        { prop: 'method', attrs: { width: 150 } },
        { prop: 'condition', attrs: { width: 105 } },
        { prop: 'description' },
      ],
      uploading: false,
      expandedPanels: _.range(0, 10),
      originalStatus: undefined,
      shippingButtonOptions: [],
      userOptions: [],
      recommendedByCode: '',
      providerOptions: [],
    };
  },
  methods: {
    async onSave() {
      const validations = [];

      //Valor do frete
      {
        const value = _.get(this.model, 'shipments[0].total', 0);
        validations.push({
          isInvalid: () => {
            return value >= 1000;
          },
          buildMessage: () => {
            return this.$t('shippingValueValidationMessage', {
              value: this.$filters.money(value),
            });
          },
        });
      }

      //Custo do frete
      {
        const value = _.get(this.model, 'shipments[0].cost', 0);
        validations.push({
          isInvalid: () => {
            return value >= 1000;
          },
          buildMessage: () => {
            return this.$t('shippingCostValidationMessage', {
              value: this.$filters.money(value),
            });
          },
        });
      }

      //Quantos dias demorou a entrega
      {
        const value = this.model.daysToDelivery || 0;
        validations.push({
          isInvalid: () => {
            return value > 20;
          },
          buildMessage: () => {
            return this.$t('daysToDeliveryValidationMessage', { value });
          },
        });
      }

      for (const validation of validations) {
        if (validation.isInvalid(this.model)) {
          if (
            !(await commonAlerts.confirmOperation({
              text: validation.buildMessage(this.model),
            }))
          ) {
            throw new Error(this.$t('validationError'));
          }
        }
      }

      const newStatus = this.model.status;
      const originalStatus = this.originalStatus;

      if (newStatus.isCancelled()) {
        if (originalStatus && !originalStatus.isCancelled()) {
          const continueWithSave = await new Promise((resolve) => {
            this.$openModal(OrderCancelDialog, {
              order: this.model,
              resolve,
            });
          });
          if (!continueWithSave) {
            throw new Error(this.$t('fillWithdrawalErrorMessage'));
          }
        }
      } else if (newStatus.isFinished()) {
        if (originalStatus && !originalStatus.isFinished()) {
          const [validatedPayments] = this.wrappedModel.classifiedPayments();
          const percentPaid = Math.ceil(validatedPayments.percent);
          if (percentPaid < 100) {
            let sectors = newStatus.props.sectors || [];
            if (!_.isEmpty(sectors)) {
              sectors = (
                await this.$api.Sector.findAll({
                  'id:in': sectors,
                  eager: 'users',
                })
              ).filter((s) => s.users.some((u) => u.id === this.$store.state.user.id));
              if (_.isEmpty(sectors)) {
                throw new Error(this.$t('moveToFinishedStatusWarnMessage'));
              }
            }
          }
        }
      }

      const order = _.cloneDeep(this.model);
      let recommendedByQuiz = order.recommendedByQuiz;
      if (order.recommendedByQuiz) {
        order.recommendedByQuizId = order.recommendedByQuiz.id;
        delete order.recommendedByQuiz;
      } else {
        order.recommendedByQuizId = null;
      }
      order.items.forEach((i) => {
        i.productId = i.product.id;
      });

      let persistedOrder = await this.$api.Order.save(order);

      if (originalStatus && originalStatus.id !== newStatus.id) {
        await this.$api.OrderActivity.save({
          type: OrderActivityUi.Types.STATUS_CHANGE,
          orderId: order.id,
          userId: this.$store.state.user.id,
          props: {
            oldStatusId: originalStatus.id,
            newStatusId: newStatus.id,
          },
        });
      }

      // Quando realizar o primeiro pagamento adiciona cupom de desconto para o cliente que indicou o negócio
      if (recommendedByQuiz && !_.isEmpty(order.payments)) {
        const searchProps = {
          type: 'Recommendation',
          customerId: recommendedByQuiz.order.customer.id,
          orderId: persistedOrder.id,
        };
        const [recommendation] = await this.$api.DiscountCoupon.findAll(searchProps);
        if (!recommendation) {
          await this.$api.DiscountCoupon.save({
            ...searchProps,
            discount: this.wrappedModel.totalValue() * 0.03,
            discountType: 'Value',
          });
        }
      }

      this.$bus.$emit(EventType.OrderPaymentChanged);
    },
    openItemDialog(props) {
      this.$openModal(OrderItemEditDialog, {
        entity: props ? _.cloneDeep(props.row) : null,
        onSave: (item) => {
          if (props) {
            this.model.items.replaceIdx(props.$index, item);
          } else {
            this.model.items.push(item);
          }
        },
      });
    },
    async removeElement(list, props) {
      if (!(await commonAlerts.confirmOperation())) {
        return;
      }
      list.removeIdx(props.$index);
    },
    customerPhoneString(customer) {
      return customer.contacts.map((c) => c.content).join(', ');
    },
    async findCustomers(query) {
      this.loadingCustomers = true;
      try {
        const { results } = await this.$api.Customer.findAll({
          'name|email|contacts.content:ilike': query ? `%${query}%` : undefined,
          //provider: false,
          eager: CUSTOMER_EAGER_DATA,
          orderBy: ['name'],
          rangeStart: 0,
          rangeEnd: 49,
        });
        this.availableCustomers = results.map((c) => ({
          value: c,
          label: c.name || `ID: ${c.id}`,
        }));
      } catch (e) {
        console.error(e);
      } finally {
        this.loadingCustomers = false;
      }
    },
    openPaymentDialog(props) {
      this.$openModal(OrderPaymentEditDialog, {
        order: this.model,
        entity: props ? _.cloneDeep(props.row) : null,
        onSave: (item) => {
          if (props) {
            this.model.payments.replaceIdx(props.$index, item);
          } else {
            this.model.payments.push(item);
            this.model.payments = _.orderBy(this.model.payments, ['parcel']);
          }
          const firstParcel = this.model.payments.find((p) => !p.deposit);
          if (firstParcel) {
            this.model.date = moment(firstParcel.date).startOf('day').toDate();
          }
        },
      });
    },
    editCustomer(customer) {
      this.$openModal(CustomerEditDialog, {
        id: customer ? customer.id : undefined,
      });
    },
    customerChanged(customer) {
      customer = new CustomerUi(customer);
      const customerOpt = { value: customer, label: customer.name };

      this.model.customer = customer;
      const idx = _.findIndex(this.availableCustomers, (p) => p.value.id === customer.id);
      if (idx !== -1) {
        this.availableCustomers.replaceIdx(idx, customerOpt);
      }
    },
    openItemDesignDialog(orderItem) {
      this.$openModal(OrderItemDesignEditDialog, {
        entity: orderItem.design,
        onSave(model) {
          orderItem.design = model;
        },
        onRemove() {
          orderItem.design = null;
        },
      });
    },
    async exportItem(props) {
      const orderCopy = _.cloneDeep(this.model);
      orderCopy.items = [props.row];
      const { id } = await this.$api.TempStorage.set(orderCopy);

      const link = `${location.origin}/orders/${this.model.id}/export/old?${new URLSearchParams({
        tempStorageKey: id,
        providerView: 'true',
        itemView: 'true',
      })}`;
      window.open(
        `${makeApiUrl(`/api/export/pdf`)}?${new URLSearchParams({
          link,
          uKey: this.$store.state.token,
        })}`,
        '_blank'
      );
    },
    processShippingButtonOptions() {
      const options = [];

      if (!this.model.customer) {
        this.$notify({
          message: this.$t('selectSomeCustomer'),
          type: 'warning',
        });
        return;
      }

      const address = this.model.customer.mainAddress();
      if (!address) {
        this.$notify({
          message: this.$t('noAddressFoundForSelectedCustomer'),
          type: 'warning',
        });
        return;
      }
      const quantity = this.wrappedModel.shippingQuantity();

      for (const opt of this.availableShippingMethods) {
        const method = opt.value;
        let days = method.props.defaultDays || 0;
        let value = method.props.defaultValue || 0;
        let postalCodeRangeString = null;
        let quantityRangeString = null;

        if (!method.isManual) {
          const postalCodeRange = method.findPostalCodeRange(address.postalCode);

          if (!postalCodeRange) continue;

          postalCodeRangeString = `${postalCodeRange.initialCode} - ${postalCodeRange.finalCode}`;
          days = postalCodeRange.days;
          const quantityRange = method.findQuantityRange(postalCodeRange, quantity);
          if (quantityRange) {
            value = quantityRange.value;
            quantityRangeString = `${quantityRange.min} - ${quantityRange.max}`;
          }
        }

        options.push({
          method,
          days,
          value,
          postalCode: address.postalCode,
          quantity,
          postalCodeRangeString,
          quantityRangeString,
        });
      }

      this.shippingButtonOptions = _.sortBy(options, ['method.description']);
    },
    recalculateShipping({ method, days, value }) {
      this.shipment.method = method;
      this.shipment.total = value;
      this.model.shippingDays = days;
    },
    applyDiscountCoupon(coupon) {
      if (this.model.appliedCoupons.find((c) => c.id === coupon.id)) return;

      this.model.appliedCoupons.push(coupon);
    },
    async processRecommendedByCode() {
      if (!this.recommendedByCode || !this.model.customer) return;

      try {
        const code = this.recommendedByCode;
        const quizId = _.parseInt(code.slice(code.lastIndexOf('F') + 1));
        if (_.isFinite(quizId)) {
          const [quiz] = await this.$api.Quiz.findAll({
            id: quizId,
            closed: true,
            eager: '[order.[customer]]',
          });
          if (quiz && quiz.order.id !== this.model.id) {
            const searchCouponParams = {
              customerId: this.model.customer.id,
              type: 'Indicated',
              orderId: quiz.order.id,
            };

            //Checa se cupom já foi utilizado por este cliente
            let [discountCoupon] = await this.$api.DiscountCoupon.findAll(searchCouponParams);
            if (discountCoupon) {
              this.$notify({
                type: 'warning',
                message: this.$t('couponAlreadyUsed'),
              });
            } else {
              discountCoupon = await this.$api.DiscountCoupon.save({
                ...searchCouponParams,
                discount: 3,
              });
              this.model.customer.discountCoupons.push(discountCoupon);
            }

            this.model.recommendedByQuiz = quiz;

            if (!discountCoupon.targetOrderId) {
              this.applyDiscountCoupon(discountCoupon);
            }

            this.recommendedByCode = '';
            return;
          }
        }
        this.$notify({ type: 'warning', message: this.$t('codeNotFound') });
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
  },
  computed: {
    sortedPayments() {
      return (this.model.payments || []).sort((o1, o2) => o1.parcel - o2.parcel);
    },
    shipment() {
      if (_.isEmpty(this.model.shipments)) {
        // eslint-disable-next-line
        this.model.shipments.push({
          id: undefined,
          method: null,
          total: 0,
          cost: 0,
        });
      }
      return this.model.shipments[0];
    },
    wrappedModel() {
      return new OrderUi(this.model);
    },
    showShippingAlert() {
      if (!this.model.customer) {
        return false;
      }

      const address = this.model.customer.mainAddress();
      if (!address) {
        return false;
      }

      if (!this.shipment.method) {
        return false;
      }

      const shippingMethod = new ShippingMethodUi(this.shipment.method);
      if (!shippingMethod.isManual) {
        const postalCodeRange = shippingMethod.findPostalCodeRange(address.postalCode);
        return !postalCodeRange;
      }

      return false;
    },
    mergedCustomers() {
      const customers = this.availableCustomers;
      const result = [...customers];
      if (this.model.customer) {
        if (!result.some((o) => o.value.id === this.model.customer.id)) {
          result.unshift({
            value: this.model.customer,
            label: this.model.customer.name,
          });
        }
      }
      result.forEach((c) => (c.key = `${c.value.id}${c.value.name}`));
      return result;
    },
    summaryData() {
      const model = this.wrappedModel;
      const discountValue = model.discountValue();
      let discountString = `${this.$filters.money(discountValue)}`;
      const totalValueDiscount = model.totalValueDiscount();
      if (totalValueDiscount > 0) {
        discountString += ` (-${this.$filters.money(totalValueDiscount)})`;
      }
      const totalPercentDiscount = model.totalPercentDiscount();
      if (totalPercentDiscount > 0) {
        discountString += ` (-${totalPercentDiscount}%)`;
      }
      return Object.entries({
        discount: discountString,
        finalValue: `${this.$filters.money(model.totalValue())} (${this.$t(
          'items'
        )}: ${this.$filters.money(model.totalItemsValue())} + ${this.$t(
          'freight'
        )}: ${this.$filters.money(model.shipmentValue())} - ${this.$t(
          'discount'
        )}: ${this.$filters.money(discountValue)})`,
        totalCost: `${this.$filters.money(model.totalCost() + model.shipmentCost())} (${this.$t(
          'items'
        )}: ${this.$filters.money(model.totalCost())} + ${this.$t(
          'freight'
        )}: ${this.$filters.money(model.shipmentCost())})`,
      });
    },
    disableDateSelection() {
      const payments = this.model.payments || [];
      return payments.some((p) => !p.deposit);
    },
  },
  watch: {
    'model.customer'(val, prev) {
      if (!prev) return;
      if (val) {
        if (val.id === prev.id) {
          return;
        }
      }
      this.model.appliedCoupons.splice(0, this.model.appliedCoupons.length);
    },
  },
  async created() {
    try {
      this.$bus.$on(EventType.CustomerChanged, this.customerChanged);

      this.loading = true;

      await Promise.all([
        this.$api.Business.findAll().then((businesses) => {
          this.availableBusiness = businesses.map((b) => ({
            value: b.id,
            label: b.name,
          }));
          this.model.businessId = _.get(this, 'availableBusiness[0].value');
        }),

        await this.$api.Funnel.findAll({
          eager: '[statuses.[types]]',
        }).then((funnels) => {
          let status = [];
          funnels.forEach((funnel) => {
            return (status = status.concat(funnel.statuses));
          });
          this.availableStatus = status.reduce(
            (acc, val) => {
              acc[val.visible ? 0 : 1].options.push({
                value: val,
                label: val.description,
              });
              return acc;
            },
            [
              { label: this.$t('visibles'), options: [] },
              { label: '', options: [] },
            ]
          );
        }),
        this.findCustomers(),
        this.$api.ShippingMethod.findAll({ orderBy: 'description' }).then((methods) => {
          return (this.availableShippingMethods = methods.map((m) => ({
            value: m,
            label: m.description,
          })));
        }),
        this.$api.User.brain.findAll({ orderBy: 'name' }).then(
          (users) =>
            (this.userOptions = users.map((value) => {
              return {
                value,
                label: value.name,
              };
            }))
        ),
        this.$api.Customer.findAll({
          provider: true,
          orderBy: ['name'],
        }).then((providers) => {
          this.providerOptions = providers.map((value) => ({
            value,
            label: value.name,
          }));
        }),
      ]);
      const appConfPromise = this.$api.Conf.appConf().then((appConf) => {
        LAST_PRODUCTION_DAYS = appConf.defaultProductionDays;
        this.model.productionDays = LAST_PRODUCTION_DAYS;
      });

      if (this.initialCustomerId) {
        this.model.customer = await this.$api.Customer.findById(this.initialCustomerId, {
          eager: CUSTOMER_EAGER_DATA,
        });
      }

      if (this.initialWhatsDialogMessage) {
        this.model.props.whatsDialogMessage = this.initialWhatsDialogMessage;
      }

      if (this.id) {
        const orderPromise = this.$api.Order.findById(this.id, {
          eager: `[
            shipments.[method],
            items.[product.[attributes], attributes.[attribute], design.[designer], provider],
            payments,
            customer.[addresses, contacts, discountCoupons],
            status.[types],
            designChangeUser,
            seller,
            appliedCoupons,
            recommendedByQuiz.[order.[customer]],
            responsibleUser,
            provider
          ]`,
        });

        await appConfPromise;
        const order = await orderPromise;
        this.model = mergeObjects({}, this.model, this.forCloning ? order.duplicate() : order);
        this.originalStatus = this.model.status;
      }
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$bus.$off(EventType.CustomerChanged, this.customerChanged);
  },
};
</script>

<style>
.OrderEditDialog .AddNewBtn {
  position: absolute;
  top: 5px;
  right: 40px;
}

.OrderEditDialog .PaymentProgressWrapper {
  position: absolute;
  top: 15px;
  right: 200px;
  width: 150px;
}

.OrderEditDialog .card-body {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.NoContent {
  opacity: 0.5;
}

.ShippingButtonOptionsMenu {
  max-height: 50vh;
  overflow: auto;
}

.ShippingButtonOptionsMenu .el-dropdown-menu__item div {
  line-height: normal;
}

.OrderEditDialog .el-table table .form-check label .form-check-sign::before,
.OrderEditDialog .el-table table .form-check label .form-check-sign::after {
  top: -14px;
}
</style>
