<template>
  <CrudDialog
    :title="$t('events')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
        />
      </div>
    </div>

    <div class="row">
      <div class="col position-relative">
        <BaseInput name="type" :label="$t('type')" required>
          <AppSelect
            name="type"
            value-key="id"
            v-model="model.eventType"
            :options="availableTypes"
            v-validate="'required|min:2|max:128'"
            allow-create
          />
        </BaseInput>
        <Tooltip :content="$t('new')">
          <BaseButton
            icon
            class="btn-link btn-github btn-simple"
            size="sm"
            style="position: absolute; top: -7px; right: 15px"
            @click="editEventType()"
          >
            <i class="fas fa-plus" />
          </BaseButton>
        </Tooltip>
      </div>

      <div class="col">
        <BaseInput name="date" :label="$t('date')" required>
          <AppDateTimePicker
            name="date"
            v-model="model.date"
            type="date"
            date-format="dd/MM"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col-6">
        <BaseInput name="note" :label="$t('note')">
          <AppTextArea name="note" v-model="model.note" :rows="8" />
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import { mergeObjects } from 'util/utils';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import AppSelect from 'components/app/input/AppSelect';
import { EventType } from 'plugins/eventBusPlugin';
import EventTypeEditDialog from 'pages/eventType/EventTypeEditDialog';
import AppTextArea from 'components/app/AppTextArea';

export default {
  name: 'ContactEditDialog',
  components: { AppTextArea, AppSelect, AppDateTimePicker },
  mixins: [FormMixin],
  props: {
    entity: {},
    onSave: {},
  },
  data() {
    return {
      loading: false,
      model: mergeObjects(
        {
          id: undefined,
          eventType: null,
          date: null,
          note: '',
        },
        this.entity
      ),
      availableTypes: [],
    };
  },
  methods: {
    editEventType(id) {
      this.$openModal(EventTypeEditDialog, {
        id,
      });
    },
    async fetchEventTypes(eventType) {
      this.availableTypes = (
        await this.$api.EventType.findAll({
          orderBy: 'name',
        })
      ).map((value) => ({ value, label: value.name }));
      if (eventType) {
        this.model.eventType = eventType;
      }
    },
  },
  async mounted() {
    this.$bus.$on(EventType.EventTypeChanged, this.fetchEventTypes);
    this.loading = true;
    try {
      await this.fetchEventTypes();
    } finally {
      this.loading = false;
    }
  },
  beforeDestroy() {
    this.$bus.$off(EventType.EventTypeChanged, this.fetchEventTypes);
  },
};
</script>

<style scoped></style>
