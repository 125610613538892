import { fillObjectValuesWithKeyName } from 'util/utils';

export const RoleUi = fillObjectValuesWithKeyName({
  ADMIN: '',
  USER: '',
  SELLER: '',
  DESIGNER: '',
});

export const Permission = fillObjectValuesWithKeyName({
  AccountingEntryManagement: '',
  AccountingEntryCategoryManagement: '',
  ApplicationConfigManagement: '',
  AttributeManagement: '',
  CustomerManagement: '',
  EventProspectionManagement: '',
  EventTypeManagement: '',
  IntegrationView: '',
  FinancialManagement: '',
  FinancialReport: '',
  FunnelManagement: '',
  OrderManagement: '',
  OrderActivityRemoval: '',
  OrderStatusManagement: '',
  OrderPaymentManagement: '',
  ProductManagement: '',
  ProviderManagement: '',
  ProviderPaymentManagement: '',
  ReportsView: '',
  SectorManagement: '',
  SettingsManagement: '',
  ShippingMethodManagement: '',
  StatusAutomationManagement: '',
  StockManagement: '',
  UserManagementAccess: '',
  WooManagement: '',
});

export const RolePermissions = {
  [RoleUi.ADMIN]: Object.keys(Permission),
  [RoleUi.USER]: [
    Permission.OrderManagement,
    Permission.CustomerManagement,
    Permission.EventProspectionManagement,
  ],
};

RolePermissions[RoleUi.SELLER] = [...RolePermissions[RoleUi.USER]];
RolePermissions[RoleUi.DESIGNER] = [...RolePermissions[RoleUi.USER]];

export class Permissions {
  constructor({ roleSupplier }) {
    this.roleSupplier = roleSupplier;
  }

  get role() {
    return this.roleSupplier();
  }

  get rolePermission() {
    return RolePermissions[this.role];
  }

  hasAccess(permissions) {
    if (!Array.isArray(permissions)) {
      permissions = [permissions];
    }
    const rolePermission = this.rolePermission;
    return permissions.every(p => rolePermission.includes(p));
  }
}
