import DashboardLayout from 'pages/Layout/DashboardLayout.vue';
import AuthLayout from 'pages/Layout/AuthLayout.vue';
import NotFound from 'pages/GeneralViews/NotFoundPage.vue';
import Login from 'pages/Login';
import Users from 'pages/user/Users';
import ForbiddenAccess from 'pages/GeneralViews/ForbiddenAccess';
import { Permission } from 'security/Permission';
import OrderStatus from 'pages/orderStatus/OrderStatus';
import Products from 'pages/product/Products';
import Attributes from 'pages/product/attribute/Attributes';
import ShippingMethods from 'pages/shippingMethod/ShippingMethods';
import Orders from 'pages/order/Orders';
import WooCalls from 'pages/woo/WooCalls';
import OrderExportPage from 'pages/order/OrderExportPage';
import Sectors from 'pages/sector/Sectors';
import OrderMailTagExportPage from 'pages/order/OrderMailTagExportPage';
import AccountingEntries from 'pages/accountingEntry/AccountingEntries';
import FinancialReport from 'pages/financial/FinancialReport';
import OrderPayments from 'pages/orderPayments/OrderPayments';
import Providers from 'pages/provider/Providers';
import EventTypes from 'pages/eventType/EventTypes';
import StatusAutomations from 'pages/statusAutomation/StatusAutomations';
import OrderExportPageNewLayout from 'pages/order/OrderExportPageNewLayout';
import TopSellingProducts from 'pages/reports/TopSellingProducts';
import Stocks from 'pages/stock/Stocks';
import EventProspections from 'pages/customer/EventProspections';
import AccountingEntryCategories from 'pages/accountingEntryCategory/AccountingEntryCategories';
import Funnels from 'pages/funnel/Funnels';
import ProductionSchedule from 'pages/productionSchedule/ProductionSchedule';
import OrderLayoutRequests from 'pages/order/design/OrderLayoutRequests';
import OrderLayoutResponses from 'pages/order/design/OrderLayoutResponses';
import SalesByState from 'pages/reports/SalesByState';
import CustomersByOrigin from 'pages/reports/CustomersByOrigin';
import EmptyLayout from 'pages/Layout/EmptyLayout';
import QuizPage from 'pages/quiz/QuizPage';
import AfterSaleQuizReport from 'pages/reports/AfterSaleQuizReport';
import CancelledOrdersReport from 'pages/reports/CancelledOrdersReport';
import WhatsQueue from 'pages/whatsQueue/WhatsQueue';
import ProviderPayments from 'pages/providerPayments/ProviderPayments';
import OrdersMissingPayments from '@/pages/orderPayments/OrdersMissingPayments';

const Dashboard = () => import(/* webpackChunkName: "Dashboard" */ 'pages/Dashboard/Dashboard');

const SalesFunnelTracking = () =>
  import(/* webpackChunkName: "SalesFunnelTracking" */ 'pages/funnel/SalesFunnelTracking');

const InitialContacts = () =>
  import(/* webpackChunkName: "InitialContacts" */ 'pages/initialContact/InitialContacts');

const Customers = () => import(/* webpackChunkName: "Customers" */ 'pages/customer/Customers');

const auth = {
  path: '/auth',
  component: AuthLayout,
  redirect: '/auth/login',
  children: [
    {
      path: 'login',
      name: 'login',
      component: Login,
      meta: {
        public: true,
        onlyWhenLoggedOut: true,
      },
    },
  ],
};

const routes = [
  {
    path: '/',
    redirect: '/dashboard',
    name: 'home',
  },
  auth,
  {
    path: '/',
    component: DashboardLayout,
    redirect: '/dashboard',
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: Dashboard,
      },
    ],
  },
  {
    path: '/users',
    component: DashboardLayout,
    meta: {
      perm: [Permission.UserManagementAccess],
    },
    children: [
      {
        path: '',
        name: 'users',
        component: Users,
      },
    ],
  },
  {
    path: '/orderStatus',
    component: DashboardLayout,
    meta: {
      perm: [Permission.OrderStatusManagement],
    },
    children: [
      {
        path: '',
        name: 'orderStatus',
        component: OrderStatus,
      },
    ],
  },
  {
    path: '/customers',
    component: DashboardLayout,
    meta: {
      perm: [Permission.CustomerManagement],
    },
    children: [
      {
        path: '',
        name: 'customers',
        component: Customers,
      },
    ],
  },
  {
    path: '/products',
    component: DashboardLayout,
    meta: {
      perm: [Permission.ProductManagement],
    },
    children: [
      {
        path: '',
        name: 'products',
        component: Products,
      },
    ],
  },
  {
    path: '/attributes',
    component: DashboardLayout,
    meta: {
      perm: [Permission.AttributeManagement],
    },
    children: [
      {
        path: '',
        name: 'attributes',
        component: Attributes,
      },
    ],
  },
  {
    path: '/shippingMethods',
    component: DashboardLayout,
    meta: {
      perm: [Permission.ShippingMethodManagement],
    },
    children: [
      {
        path: '',
        name: 'shippingMethods',
        component: ShippingMethods,
      },
    ],
  },
  {
    path: '/orders',
    component: DashboardLayout,
    meta: {
      perm: [Permission.OrderManagement],
    },
    children: [
      {
        path: '',
        name: 'orders',
        component: Orders,
      },
    ],
  },
  {
    path: '/orders/:orderId/export/mail',
    name: 'orderMailTagExportPage',
    component: OrderMailTagExportPage,
    props: true,
  },
  {
    path: '/orders/:orderId/export',
    name: 'ordersExportPage',
    component: OrderExportPageNewLayout,
    props: true,
  },
  {
    path: '/orders/:orderId/export/old',
    name: 'ordersExportPageOld',
    component: OrderExportPage,
    props: (route) => ({
      orderId: route.params.orderId,
      tempStorageKey: route.query.tempStorageKey,
      providerView: route.query.providerView === 'true',
      hideCustomerInfo: route.query.hideCustomerInfo === 'true',
      itemView: route.query.itemView === 'true',
    }),
  },
  {
    path: '/wooCalls',
    component: DashboardLayout,
    meta: {
      perm: [Permission.WooManagement],
    },
    children: [
      {
        path: '',
        name: 'wooCommerce',
        component: WooCalls,
      },
    ],
  },
  {
    path: '/sectors',
    component: DashboardLayout,
    meta: {
      perm: [Permission.SectorManagement],
    },
    children: [
      {
        path: '',
        name: 'sectors',
        component: Sectors,
      },
    ],
  },
  {
    path: '/financial',
    component: DashboardLayout,
    children: [
      {
        meta: {
          perm: [Permission.FinancialReport],
        },
        path: 'report',
        name: 'financialReport',
        component: FinancialReport,
      },
    ],
  },
  {
    path: '/accounting',
    component: DashboardLayout,
    children: [
      {
        meta: {
          perm: [Permission.AccountingEntryManagement],
        },
        path: 'entries',
        name: 'accountingEntries',
        component: AccountingEntries,
      },
      {
        meta: {
          perm: [Permission.AccountingEntryCategoryManagement],
        },
        path: 'categories',
        name: 'accountingEntryCategories',
        component: AccountingEntryCategories,
      },
    ],
  },
  {
    path: '/orderPayments',
    component: DashboardLayout,
    children: [
      {
        meta: {
          perm: [Permission.OrderPaymentManagement],
        },
        path: '',
        name: 'payments',
        component: OrderPayments,
      },
    ],
  },
  {
    path: '/orderMissingPayments',
    component: DashboardLayout,
    children: [
      {
        meta: {
          perm: [Permission.OrderPaymentManagement],
        },
        path: '',
        name: 'orderMissingPayments',
        component: OrdersMissingPayments,
      },
    ],
  },
  {
    path: '/providers',
    component: DashboardLayout,
    meta: {
      perm: [Permission.ProviderManagement],
    },
    children: [
      {
        path: '',
        name: 'providers',
        component: Providers,
      },
    ],
  },
  {
    path: '/providerPayments',
    component: DashboardLayout,
    children: [
      {
        meta: {
          perm: [Permission.OrderPaymentManagement],
        },
        path: '',
        name: 'providerPayments',
        component: ProviderPayments,
      },
    ],
  },
  {
    path: '/eventTypes',
    component: DashboardLayout,
    meta: {
      perm: [Permission.EventTypeManagement],
    },
    children: [
      {
        path: '',
        name: 'eventTypes',
        component: EventTypes,
      },
    ],
  },
  {
    path: '/statusAutomations',
    component: DashboardLayout,
    meta: {
      perm: [Permission.StatusAutomationManagement],
    },
    children: [
      {
        path: '',
        name: 'statusAutomations',
        component: StatusAutomations,
      },
    ],
  },
  {
    path: '/reports',
    component: DashboardLayout,
    meta: {
      perm: [Permission.ReportsView],
    },
    children: [
      {
        path: 'topSellingProducts',
        name: 'topSellingProducts',
        component: TopSellingProducts,
      },
      {
        path: 'salesByState',
        name: 'salesByState',
        component: SalesByState,
      },
      {
        path: 'customersByOrigin',
        name: 'customersByOrigin',
        component: CustomersByOrigin,
      },
    ],
  },
  {
    path: '/stock',
    component: DashboardLayout,
    meta: {
      perm: [Permission.StockManagement],
    },
    children: [
      {
        path: '',
        name: 'stock',
        component: Stocks,
      },
    ],
  },
  {
    path: '/eventProspections',
    component: DashboardLayout,
    meta: {
      perm: [Permission.EventProspectionManagement],
    },
    children: [
      {
        path: '',
        name: 'eventProspections',
        component: EventProspections,
      },
    ],
  },
  {
    path: '/funnels',
    component: DashboardLayout,
    children: [
      {
        path: '/funnels',
        name: 'funnels',
        component: Funnels,
        meta: {
          perm: [Permission.FunnelManagement],
        },
      },
      {
        path: '/funnels/:id',
        name: 'funnel',
        component: SalesFunnelTracking,
      },
    ],
  },
  {
    path: '/productionSchedule',
    component: DashboardLayout,
    children: [
      {
        path: '/productionSchedule',
        name: 'productionSchedule',
        component: ProductionSchedule,
      },
    ],
  },
  {
    path: '/orderLayoutResponses',
    component: DashboardLayout,
    children: [
      {
        path: '/orderLayoutResponses',
        name: 'orderLayoutResponses',
        component: OrderLayoutResponses,
      },
    ],
  },
  {
    path: '/orderLayoutRequests',
    component: DashboardLayout,
    children: [
      {
        path: '/orderLayoutRequests',
        name: 'orderLayoutRequests',
        component: OrderLayoutRequests,
      },
    ],
  },
  {
    path: '/afterSaleQuizReport',
    component: DashboardLayout,
    children: [
      {
        path: '/afterSaleQuizReport',
        name: 'afterSaleQuizReport',
        component: AfterSaleQuizReport,
      },
    ],
  },
  {
    path: '/cancelledOrdersReport',
    component: DashboardLayout,
    children: [
      {
        path: '/cancelledOrdersReport',
        name: 'cancelledOrdersReport',
        component: CancelledOrdersReport,
      },
    ],
  },
  {
    path: '/initialContacts',
    component: DashboardLayout,
    children: [
      {
        path: '/initialContacts',
        name: 'initialContacts',
        component: InitialContacts,
      },
    ],
  },
  {
    path: '/whatsQueue',
    component: DashboardLayout,
    children: [
      {
        path: '/whatsQueue',
        name: 'whatsQueue',
        component: WhatsQueue,
      },
    ],
  },
  {
    path: '/pesquisas/:identifier',
    component: EmptyLayout,
    children: [
      {
        path: '/pesquisas/:identifier',
        name: 'pesquisas',
        component: QuizPage,
        meta: {
          public: true,
        },
      },
    ],
  },
  { path: '/errors/403', component: ForbiddenAccess },
  { path: '*', component: NotFound },
];

export default routes;
