<template>
  <Card class="card-stats" :show-footer-line="true" @click="$emit('click')">
    <div class="row">
      <div class="col-3" v-if="$slots.icon || icon">
        <div class="info-icon" :class="`icon-${type}`">
          <slot name="icon"> <i :class="icon"></i> </slot>
        </div>
      </div>
      <div class="col-9" v-if="$slots.content || title || subTitle">
        <div class="numbers">
          <slot>
            <p v-if="subTitle" class="card-category">{{ subTitle }}</p>
            <h3 v-if="title" class="card-title">{{ title }}</h3>
          </slot>
        </div>
      </div>
    </div>
    <div class="stats" slot="footer" v-if="$slots.footer">
      <slot name="footer"></slot>
    </div>
  </Card>
</template>
<script>
import Card from './Card.vue';

export default {
  name: 'StatsCard',
  components: {
    Card,
  },
  props: {
    type: {
      type: String,
      default: 'primary',
    },
    icon: String,
    title: String,
    subTitle: String,
  },
};
</script>
<style scoped>
.card-stats .info-icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.card-stats .info-icon i {
  padding: 0;
}
</style>
