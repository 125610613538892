<template>
  <Dialog
    :title="$t('topSellingProducts')"
    class="TopSellingProducts"
    @close="$emit('close')"
  >
    <div v-loading="loading">
      <AppTable :data="products">
        <ElTableColumn :label="$t('product')">
          <template slot-scope="props">
            <span class="mr-2">
              {{ props.row.product.name }}
            </span>
            <Badge
              v-for="attributeValue in props.row.attributes"
              :key="attributeValue.id"
              class="mr-2"
            >
              <span class="mr-1">{{ attributeValue.attribute.caption }}:</span>
              {{ attributeValue.caption }}
            </Badge>
          </template>
        </ElTableColumn>
        <ElTableColumn :label="$t('quantity')" width="150">
          <template slot-scope="props">
            {{ props.row.quantity }}
          </template>
        </ElTableColumn>
      </AppTable>
    </div>
  </Dialog>
</template>

<script>
import OrderFilterButton from 'pages/funnel/OrderFilterButton';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import Badge from 'components/Badge';
import Dialog from 'components/app/Dialog';

export default {
  name: 'TopSellingProducts',
  components: { Dialog, Badge, OrderFilterButton },
  props: {
    orders: {},
  },
  data() {
    return {
      loading: false,
      products: [],
    };
  },
  methods: {
    async fetchData() {
      let orders = this.orders;

      let products = {};
      for (const order of orders) {
        for (const item of order.items) {
          let info = products[item.productId];
          if (!info) {
            info = {
              product: item.product,
              quantity: 0,
              attrGroups: {},
            };
            products[item.productId] = info;
          }
          info.quantity += item.quantity;

          const attrKey = item.attributes
            .map((attributeValue) => attributeValue.id)
            .sort()
            .join('-');
          let attrGroup = info.attrGroups[attrKey];
          if (!attrGroup) {
            attrGroup = {
              attributes: item.attributes,
              quantity: 0,
            };
            info.attrGroups[attrKey] = attrGroup;
          }
          attrGroup.quantity += item.quantity;
        }
      }

      products = _.flatMap(products, (info) => {
        return Object.values(info.attrGroups).map(
          ({ attributes, quantity }) => ({
            product: info.product,
            attributes,
            quantity,
          })
        );
      });

      this.products = _.orderBy(products, ['quantity'], ['desc']);
    },
  },
  async mounted() {
    this.loading = true;
    try {
      await this.fetchData();
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
