<template>
  <Tooltip :content="$t('seeDetails')">
    <BaseButton
      :size="size"
      icon
      class="btn-link btn-github btn-simple ManageOrderButton"
      @click="manageOrder"
    >
      <i class="fas fa-search-plus" />
    </BaseButton>
  </Tooltip>
</template>

<script>
import OrderManagementDialog from 'pages/order/OrderManagementDialog';

export default {
  name: 'ManageOrderButton',
  props: {
    orderId: { required: true },
    size: { default: 'sm' },
  },
  methods: {
    manageOrder() {
      this.$openModal(OrderManagementDialog, {
        orderId: this.orderId,
      });
    },
  },
};
</script>

<style scoped></style>
