<template>
  <div class="OrderLayoutRequests" v-loading="loading">
    <CenterIconMessage
      v-if="_.isEmpty(requestChunks)"
      icon="fas fa-palette"
      :message="$t('noLayoutRequestFound')"
    />

    <div class="row" v-for="(requests, idx) in requestChunks" :key="idx">
      <div class="col-6" v-for="req in requests" :key="req.id">
        <LayoutRequestCard :request="req" @editResponse="editResponse(req)" />
      </div>
    </div>

    <div class="LastDesigns p-2" v-if="!_.isEmpty(finishedRequests)">
      <div
        class="Header d-flex align-items-center"
        @click="showFinishedRequests = !showFinishedRequests"
      >
        <h4 class="m-0">{{ $t('lastDesigns') }}</h4>
        <i
          :class="`ml-auto fas fa-window-${
            showFinishedRequests ? 'maximize' : 'minimize'
          }`"
        />
      </div>
      <div class="Body mt-2" v-if="showFinishedRequests">
        <ul class="list-unstyled">
          <li
            :key="request.id"
            v-for="request in finishedRequests"
            class="mb-2"
          >
            <div class="d-flex align-items-center">
              <span class="text-info">
                {{ request.order.orderCode }}
              </span>
              <OrderLayoutButton :order="request.order" class="ml-auto" />
            </div>

            <div class="text-secondary">
              <small>
                <span class="mr-1"> {{ $t('lastModification') }}: </span>
                {{ request.response.updatedAt | fromNow }}
              </small>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import Badge from 'components/Badge';
import PriorityBadge from 'pages/order/design/PriorityBadge';
import DropzoneUpload from 'components/app/input/DropzoneUpload';
import AppTextArea from 'components/app/AppTextArea';
import LayoutRequestCard from 'pages/order/design/LayoutRequestCard';
import OrderLayoutReqResEditDialog from 'pages/order/design/OrderLayoutReqResEditDialog';
import { commonAlerts } from 'util/commonAlerts';
import CenterIconMessage from 'components/app/CenterIconMessage';
import OrderLayoutButton from 'pages/order/OrderLayoutButton';

export default {
  name: 'OrderLayoutRequests',
  components: {
    OrderLayoutButton,
    CenterIconMessage,
    LayoutRequestCard,
    AppTextArea,
    DropzoneUpload,
    PriorityBadge,
    Badge,
    AppSelect,
    AppFilterDatePicker,
  },
  data() {
    return {
      eventsToListen: [EventType.OrderChanged],
      loading: false,
      requestChunks: [],
      finishedRequests: [],
      showFinishedRequests: false,
    };
  },
  methods: {
    async fetchData() {
      this.loading = true;
      try {
        let result = await $appApi.OrderLayoutRequest.findAll({
          eager:
            '[uploads, user, response.[uploads, user], order.[customer.[contacts]]]',
          finished: false,
          orderBy: ['createdAt'],
          orderByDesc: ['priority'],
        });

        result = result.filter(
          (l) => !l.response || l.response.userId === this.$store.state.user.id
        );
        this.requestChunks = _.chunk(result, 2);

        this.finishedRequests = (
          await $appApi.OrderLayoutRequest.findAll({
            eager: '[order, response.[user]]',
            'response.userId': this.$store.state.user.id,
            finished: true,
            orderByDesc: ['createdAt'],
            rangeStart: 0,
            rangeEnd: 50,
          })
        ).results;
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    editResponse(request) {
      this.$openModal(OrderLayoutReqResEditDialog, {
        entity: request.response,
        isResponse: true,
        onSave: async (values) => {
          try {
            const copy = _.cloneDeep(request);
            copy.response = values;
            await $appApi.OrderLayoutRequest.save(copy);
            this.fetchData();
          } catch (e) {
            console.error(e);
            commonAlerts.defaultErrorMessage(e);
          }
        },
      });
    },
  },
  async mounted() {
    this.$bus.$on(EventType.OrderChanged, this.fetchData);
    this.$bus.$on(EventType.OrderLayoutRequestChanged, this.fetchData);
    await this.fetchData();
  },
  beforeDestroy() {
    this.$bus.$off(EventType.OrderChanged, this.fetchData);
    this.$bus.$off(EventType.OrderLayoutRequestChanged, this.fetchData);
  },
};
</script>
<style scoped lang="scss">
.OrderLayoutRequests {
  min-height: 100%;
}

.LastDesigns {
  position: fixed;
  bottom: 0;
  right: 10px;
  background-color: #2b3553;
  border-radius: 4px;

  width: 250px;

  .Header {
    cursor: pointer;
  }

  .Body {
    max-height: 300px;
    overflow-y: auto;
    white-space: nowrap;
  }
}
</style>
