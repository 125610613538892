<template>
  <div class="ModalRoot">
    <component
      :is="m.component"
      v-for="m in modals.open"
      :key="m.modalId"
      v-bind="m.props"
      @close="closeModal(m)"
      @reopenModal="reopenModal(m, $event)"
    />
  </div>
</template>

<script>
import { Mutation } from 'state/Store';

export default {
  name: 'ModalRoot',
  methods: {
    closeModal(modal) {
      this.$store.commit(Mutation.closeModal, modal.modalId);
    },
    async reopenModal(modal, newProps = {}) {
      this.closeModal(modal);
      await this.$nextTick();
      this.$openModal(modal.component, Object.assign({}, modal.props, newProps));
    },
  },
  computed: {
    modals() {
      return this.$store.state.modals;
    },
  },
};
</script>

<style scoped></style>
