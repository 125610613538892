<template>
  <div :class="['InitialContactsCard mr-3']">
    <Card class="ContainerCard m-0" v-loading="loading">
      <slot name="title">
        <h3 v-if="!hideTitle" class="m-0 mb-2">
          <i class="fab fa-whatsapp mr-1"></i>
          {{ $t('initialContacts') }}
        </h3>
      </slot>

      <div class="row mb-1">
        <div class="col d-flex align-items-center">
          <Tooltip :content="$t('pressEnterToSearch')">
            <BaseInput
              class="SearchInput flex-grow-1 m-0"
              v-model="search"
              :placeholder="`${$t('searchByPhone')}...`"
              addon-left-icon="tim-icons icon-zoom-split"
              :disable-validation="true"
              @keyup.enter="runSearch"
            />
          </Tooltip>
          <div>
            <ElDropdown trigger="click" @command="changeSortMode">
              <Tooltip :content="sortMode.label">
                <BaseButton icon simple link :type="sortMode.type" class="m-0">
                  <i class="fas fa-sort" />
                </BaseButton>
              </Tooltip>
              <ElDropdownMenu slot="dropdown">
                <ElDropdownItem
                  v-for="opt in sortOpts"
                  :key="`sort-${opt.value}`"
                  :command="opt"
                  :disabled="opt.value === sortMode.value"
                >
                  {{ opt.label }}
                </ElDropdownItem>
              </ElDropdownMenu>
            </ElDropdown>
          </div>
          <div>
            <WhatsQueueFilterButton @filter="filterChanged" :initial-state="buttonFilter" />
          </div>
          <slot name="additionalButtons" />
        </div>
      </div>
      <div class="InitialContactsCards Cards">
        <Card v-if="_.isEmpty(filteredItems)">
          <h1 class="text-center m-0 mt-2">
            <i class="far fa-frown"></i>
          </h1>
          <h4 class="text-center mt-2">
            {{ $t('noItemsFoundForTheAppliedFilter') }}
          </h4>
        </Card>
        <InitialContactCard
          class="mb-3"
          v-for="(item, idx) in filteredItems.slice(
            page * itemsPerPage,
            page * itemsPerPage + itemsPerPage
          )"
          :key="`item-${item.id}-${idx}`"
          :item="item"
          :user="user"
          @generateOrder="generateOrderFromInitialContact(item)"
          @editCustomer="editCustomer(item)"
          @remove="confirmRemoveInitialContact(item)"
        />
      </div>

      <SimplePagination :page="page" :pages="numberOfPages" @pageSelected="page = $event" />
    </Card>
  </div>
</template>

<script>
import OrderWhatsButton from 'pages/order/whats/OrderWhatsButton';
import TableButton from 'components/app/btn/TableButton';
import { EventType } from 'plugins/eventBusPlugin';
import InitialContactMixin from 'pages/initialContact/InitialContactMixin';
import InitialContactCard from 'pages/funnel/InitialContactCard';
import DotPagination from 'components/app/DotPagination';
import WhatsQueueFilterButton from 'pages/whatsQueue/WhatsQueueFilterButton';
import SimplePagination from 'components/app/SimplePagination';
import { InitialContactUi, OrderUi } from 'api/Models';
import { stripNonDigits } from 'util/utils';

export default {
  name: 'InitialContactsContainerCard',
  components: {
    SimplePagination,
    WhatsQueueFilterButton,
    DotPagination,
    InitialContactCard,
    TableButton,
    OrderWhatsButton,
  },
  mixins: [InitialContactMixin],
  props: {
    parentItems: {},
    user: {},
    hideTitle: { type: Boolean, default: false },
    initialState: {},
  },
  data() {
    const sortOpts = Object.entries(InitialContactUi.SortOpts).map(([k, v]) => ({
      value: k,
      label: this.$t(`InitialContactUi.SortOpts.${k}`),
      ...v,
    }));

    let sortMode = sortOpts[0];
    if (this.initialState?.sortMode) {
      const mode = sortOpts.find((o) => o.value === this.initialState?.sortMode);
      if (mode) {
        sortMode = mode;
      }
    }
    return {
      loading: false,
      items: [],
      filteredItems: [],
      buttonFilter: {
        tags: this.initialState?.tags || [],
        users: this.initialState?.users || [],
        hideAlreadyAnswered: this.initialState?.hideAlreadyAnswered ?? false,
      },
      search: '',
      page: 0,
      sortOpts: sortOpts,
      sortMode,
    };
  },
  methods: {
    changeSortMode(sortMode) {
      this.sortMode = sortMode;
      this.runSearch();
    },
    filterChanged(filter) {
      this.buttonFilter = filter;
      this.runSearch();
    },
    async updateData() {
      this.loading = true;
      try {
        this.items = await this.$api.InitialContact.findAll({
          eager: `[
            customer.[contacts],
            responsibleUser,
            tags
          ]`,
          orderByDesc: 'updatedAt',
        });
        this.runSearch();
      } catch (e) {
        console.error(e);
      } finally {
        this.loading = false;
      }
    },
    runSearch() {
      let items = this.parentItems || this.items;

      if (this.search) {
        const digitsOnlySearch = stripNonDigits(this.search);

        // Se busca começar com X faz match somente pelo número do pedido
        if (this.search.toLowerCase().startsWith('x')) {
          items = items.filter((i) => {
            if (!i.hasOwnProperty('code')) return false;
            return `${i.id}`.includes(digitsOnlySearch);
          });
        } else if (this.search.startsWith('#')) {
          // Se busca começar com # faz match somente pelo code (legacy, vinha do ecommerce) do pedido
          items = items.filter((i) => {
            return `${i.code || ''}`.includes(digitsOnlySearch);
          });
        } else {
          items = items.filter((i) => {
            // Match por código do pedido
            if (
              `${i.id}`.includes(digitsOnlySearch) ||
              `${i.code || ''}`.includes(digitsOnlySearch)
            ) {
              return true;
            }

            // Match pelo numero de telefone
            return i.customer.contacts.some((contact) => {
              const digitsOnly = stripNonDigits(contact.content || '');
              return digitsOnly.includes(digitsOnlySearch);
            });
          });
        }
      }

      if (!_.isEmpty(this.buttonFilter.tags)) {
        items = items.filter((i) => i.tags.some((t) => this.buttonFilter.tags.includes(t.id)));
      }

      if (!_.isEmpty(this.buttonFilter.users)) {
        items = items.filter((i) =>
          this.buttonFilter.users.some((userId) => userId === i.responsibleUserId)
        );
      }

      if (this.buttonFilter.hideAlreadyAnswered) {
        items = items.filter((i) => {
          return OrderUi.buildWhatsMsgStatus(i).needResponse;
        });
      }

      this.filteredItems = this.sortMode.sort(items);
    },
    notifyFilterChange() {
      this.$emit(
        'filterChanged',
        _.cloneDeep({
          ...this.buttonFilter,
          sortMode: this.sortMode.value,
        })
      );
    },
  },
  watch: {
    parentItems(val) {
      if (val) {
        this.runSearch();
      }
    },
    buttonFilter: {
      deep: true,
      handler() {
        this.notifyFilterChange();
      },
    },
    sortMode() {
      this.notifyFilterChange();
    },
  },
  computed: {
    itemsPerPage() {
      return 20;
    },
    numberOfPages() {
      return Math.max(1, Math.ceil(this.filteredItems.length / this.itemsPerPage));
    },
  },
  async created() {
    if (this.parentItems) {
      this.runSearch();
      return;
    }
    this.$bus.$on(EventType.CustomerChanged, this.updateData);
    this.$bus.$on(EventType.OrderChanged, this.updateData);
    this.$bus.$on(EventType.InitialContactChanged, this.updateData);
    this.updateData();
  },
  beforeDestroy() {
    if (this.parentItems) return;
    this.$bus.$off(EventType.CustomerChanged, this.updateData);
    this.$bus.$off(EventType.OrderChanged, this.updateData);
    this.$bus.$off(EventType.InitialContactChanged, this.updateData);
  },
};
</script>

<style lang="scss">
.InitialContactsCard {
  min-width: 325px;
  max-width: 325px;

  .ContainerCard {
    background-color: #3d9970;

    .Cards {
      max-height: calc(100vh - 280px);
      min-height: calc(100vh - 280px);

      overflow: auto;

      > .card {
        position: relative;
        background: rgba(0, 0, 0, 0.3);
      }
    }
  }

  .Whats {
    cursor: pointer;
  }

  .FloatingActions {
    position: absolute;
    bottom: 2px;
    left: 8px;
  }

  .SearchInput {
    .input-group-text,
    .form-control {
      border: none;
      background-color: rgba(0, 0, 0, 0.3);
      color: rgba(255, 255, 255, 0.8);
    }

    .form-control::placeholder {
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
