var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:['ImageUpload', { DragOver: _vm.drag.over, [`size-x${_vm.size}`]: true }],on:{"dragenter":_vm.dragEntered,"dragover":_vm.dragOver,"drop":function($event){_vm.drag.over = false}}},[_c('div',{staticClass:"position-relative"},[_c('span',{staticClass:"btn btn-simple btn-file btn-sm"},[_c('a',{class:[
          'img-circle d-flex align-items-center justify-content-center',
          {
            Upload: !_vm.fileExists,
            Image: _vm.fileExists && _vm.preview,
            File: _vm.fileExists && !_vm.preview,
          },
        ],attrs:{"href":_vm.fileExists && !_vm.preview ? _vm.src : '#',"target":_vm.fileExists && !_vm.preview ? '_blank' : ''}},[(_vm.fileExists && _vm.preview)?_c('ExpandableImage',{attrs:{"src":_vm.image,"alt":"preview","hide-expand":_vm.drag.over}}):(_vm.fileExists && !_vm.preview)?_c('i',{staticClass:"fas fa-download"}):_c('i',{staticClass:"fas fa-file-upload UploadIcon"})],1),_c('input',{attrs:{"type":"hidden","value":"","name":""}}),_c('input',{staticClass:"valid",attrs:{"accept":_vm.accept,"type":"file","name":"...","multiple":false,"aria-invalid":"false"},on:{"change":_vm.handlePreview,"click":_vm.fileInputClicked}})]),(_vm.fileExists)?_c('BaseButton',{staticClass:"RemoveBtn",attrs:{"type":"danger","size":"sm","icon":""},on:{"click":_vm.removeFile}},[_c('i',{staticClass:"fas fa-times"})]):_vm._e()],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }