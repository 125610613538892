<template>
  <CrudDialog
    :title="$t('stock')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
    class="StockEditDialog"
  >
    <div class="row">
      <div class="col-2">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
        />
      </div>
      <div class="col">
        <BaseInput name="date" :label="$t('date')" required>
          <AppDateTimePicker
            type="date"
            name="date"
            v-model="model.date"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="operation" :label="$t('operation')" required>
          <AppSelect
            name="operation"
            v-model="model.operation"
            v-validate="'required'"
            :options="operationOptions"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="notes" :label="$t('notes')">
          <AppTextArea name="notes" v-model="model.notes" rows="3" />
        </BaseInput>
      </div>
    </div>

    <MaterialTable v-model="model.items" :title="$t('items')" />
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import InputMoney from 'components/app/input/InputMoney';
import { mergeObjects } from 'util/utils';
import ImageUpload from 'components/ImageUpload';
import Collapse from 'components/Collapse/Collapse';
import CollapseItem from 'components/Collapse/CollapseItem';
import OrderPaymentsProgressBar from 'pages/funnel/OrderPaymentsProgressBar';
import AppAutoUpload from 'components/app/input/AppAutoUpload';
import BaseAlert from 'components/BaseAlert';
import { StockUi } from 'api/Models';
import AppTextArea from 'components/app/AppTextArea';
import MaterialTable from 'pages/product/attribute/MaterialTable';

export default {
  name: 'StockEditDialog',
  components: {
    MaterialTable,
    AppTextArea,
    BaseAlert,
    AppAutoUpload,
    OrderPaymentsProgressBar,
    CollapseItem,
    Collapse,
    ImageUpload,
    InputMoney,
    AppDateTimePicker,
    AppSelect,
  },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        date: new Date(),
        operation: null,
        notes: '',
        items: [],
        props: {},
      },
      operationOptions: _.sortBy(
        Object.keys(StockUi.Operations).map(key => ({
          value: key,
          label: this.$t(key),
        })),
        ['label']
      ),
      productOptions: [],
    };
  },
  methods: {
    async onSave() {
      await this.$api.Stock.save(this.model);

      this.$bus.$emit(EventType.StockChanged);
    },
    attributeOptions(product) {
      if (!product) return [];
      return product.attributes.map(attribute => {
        return {
          value: attribute,
          label: attribute.name,
          options: _.sortBy(attribute.values, ['value']).map(
            attributeValue => ({
              value: attributeValue,
              label: `${attribute.name}: ${attributeValue.value}`,
            })
          ),
        };
      });
    },
    retainDuplicatedAttributes(attributes) {
      const ids = Object.values(
        attributes.reduce((accumulator, val) => {
          accumulator[val.attributeId] = val.id;
          return accumulator;
        }, {})
      );
      return attributes.filter(a => ids.includes(a.id));
    },
  },
  computed: {},
  async mounted() {
    this.loading = true;
    try {
      this.productOptions = (
        await this.$api.Product.findAll({
          eager: '[attributes.[values]]',
          orderBy: 'name',
        })
      ).map(product => ({ value: product, label: product.name }));

      /*this.attributes = (
          await this.$api.Attribute.findAll({
            eager: 'values',
            orderBy: ['name'],
          })
        ).then(attributes => {
          return attributes.filter(attr => !_.isEmpty(attr.values));
        });*/

      if (this.id) {
        this.model = mergeObjects(
          {},
          this.model,
          await this.$api.Stock.findById(this.id, {
            eager:
              '[items.[product.[attributes.[values]], attributeValues.[attribute]]]',
          })
        );
      }
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss"></style>
