<template>
  <div class="MoveOrderToStatusButtonOptions">
    <div class="title text-center mb-2">
      <b>{{ data.title }}:</b>
      {{ data.funnel.name }}
    </div>

    <ElDropdownItem
      v-for="os in data.visible"
      :key="os.id"
      :disabled="os.id === order.statusId"
      :command="{ status: os }"
    >
      {{ os.description }}
    </ElDropdownItem>
    <template v-if="!_.isEmpty(data.notVisible)">
      <ElDropdownItem v-if="!_.isEmpty(data.visible)" divided />
      <ElDropdownItem
        v-for="os in data.notVisible"
        :key="os.id"
        :disabled="os.id === order.statusId"
        :command="{ order, status: os }"
      >
        {{ os.description }}
      </ElDropdownItem>
    </template>
  </div>
</template>

<script>
export default {
  name: 'MoveOrderToStatusButtonOptions',
  props: {
    data: { required: true },
    order: { required: true },
  },
};
</script>

<style lang="scss">
.MoveOrderToStatusButtonOptions {
}
</style>
