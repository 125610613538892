<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  >
    <template v-slot:headerButtons>
      <BaseButton class="mr-2" @click="openUpdateValuesDialog()">
        {{ $t('updateValues') }}
      </BaseButton>
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import ShippingMethodEditDialog from 'pages/shippingMethod/ShippingMethodEditDialog';
import MoneyCol from 'components/app/table/MoneyCol';
import UpdateValuesDialog from 'pages/shippingMethod/UpdateValuesDialog';

export default {
  name: 'ShippingMethods',
  data() {
    return {
      dialog: ShippingMethodEditDialog,
      eventsToListen: [EventType.ShippingMethodChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'code', attrs: { width: 100 } },
        { prop: 'description' },
        {
          label: this.$t('defaultShippingTime'),
          attrs: { width: 180 },
          render: ({ row }) => {
            return row.props.defaultDays
              ? `${row.props.defaultDays} ${this.$t('days')}`
              : '-';
          },
        },
        {
          prop: 'props.defaultValue',
          label: this.$t('defaultValue'),
          component: MoneyCol,
          attrs: { width: 140 },
        },
        {
          label: this.$t('numberOfBands'),
          attrs: { width: 180 },
          render: ({ row }) => {
            const size = _.get(row, 'props.postalCodeRanges', []).length;
            return size ? size : '-';
          },
        },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|code|description:ilike'] = `%${query}%`;
      }
      return await this.$api.ShippingMethod.findAll({
        orderBy: 'description',
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.ShippingMethod.remove(row.id);
    },
    openUpdateValuesDialog() {
      this.$openModal(UpdateValuesDialog, {});
    },
  },
};
</script>
<style scoped lang="scss"></style>
