<template>
  <CrudPage
    :fetch-data="fetchData"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    dateField="date"
    :table-attrs="tableAttrs"
    ref="crudPage"
    :action-col-width="110"
    :showActionCol="true"
  >
    <template v-slot:headerFilters>
      <div class="col">
        <div class="form-group">
          <AppSelect
            :options="validatedOptions"
            v-model="validatedFilter"
            @input="filterChanged('validated', ...arguments)"
            :placeholder="$t('confirmation')"
          />
        </div>
      </div>
      <div class="col">
        <div class="form-group">
          <AppSelect
            :options="providerFilterOpts"
            v-model="providerFilter"
            multiple
            value-key="id"
            @input="filterChanged('provider', ...arguments)"
            :placeholder="$t('provider')"
            :collapse-tags="true"
          />
        </div>
      </div>
    </template>

    <template v-slot:additionalColumns>
      <ElTableColumn label="">
        <template slot-scope="props">
          <div>
            <b>{{ $t('order') }}</b>
            {{ props.row.orderCode }}
          </div>
          <div>
            <b>{{ $t('customer') }}:</b> {{ props.row.customer.name }}
          </div>

          <div>
            <b>{{ $t('contacts') }}:</b>
            {{ props.row.customer.contacts.map((c) => c.content).join(', ') }}
          </div>
        </template>
      </ElTableColumn>

      <ElTableColumn :label="$t('date')" width="120" sortable="custom" prop="date">
        <template slot-scope="props">
          <DateTimeCol
            :table-props="props"
            :col-info="{ prop: 'date', attrs: { style: 'date' } }"
          />
        </template>
      </ElTableColumn>

      <ElTableColumn :label="$t('provider')" width="180" prop="provider">
        <template slot-scope="props">
          <BaseInput name="provider">
            <AppSelect
              name="provider"
              :value="props.row.provider"
              :options="providerOpts"
              value-key="id"
              :filterable="true"
              :clearable="true"
              @input="updateOrderProvider($event, props.row)"
            />
          </BaseInput>
        </template>
      </ElTableColumn>

      <ElTableColumn
        :label="$t('paymentDate')"
        width="200"
        sortable="custom"
        prop="providerPaymentDate"
      >
        <template slot-scope="props">
          <div class="d-flex align-items-center">
            <BaseInput name="providerPaymentDate">
              <AppDateTimePicker
                name="providerPaymentDate"
                :value="props.row.providerPaymentDate"
                type="date"
                @input="toggleValidated($event, props.row)"
              />
            </BaseInput>

            <BaseButton
              size="sm"
              icon
              simple
              class="btn-behance ml-2"
              @click="markValidatedToday(props.row)"
              :title="$t('confirmPaymentOnCurrentDay')"
            >
              <i class="fas fa-check" />
            </BaseButton>
          </div>
        </template>
      </ElTableColumn>

      <ElTableColumn :label="$t('totalCost')" width="125" prop="totalCost">
        <template slot-scope="props">
          <MoneyCol :table-props="props" :col-info="{ prop: 'totalCost' }" />
        </template>
      </ElTableColumn>
    </template>

    <template v-slot:tableButtons="{ rowProps }">
      <Tooltip :content="$t('seeDetails')">
        <BaseButton
          size="sm"
          icon
          class="btn-link btn-github btn-simple"
          @click="manageOrder(rowProps.row)"
        >
          <i class="fas fa-search-plus" />
        </BaseButton>
      </Tooltip>
      <EditOrderButton :id="rowProps.row.id" />
    </template>
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import DateTimeCol from 'components/app/table/DateTimeCol';
import OrderManagementDialog from 'pages/order/OrderManagementDialog';
import MoneyCol from 'components/app/table/MoneyCol';
import AppFilterDatePicker from 'components/app/input/AppFilterDatePicker';
import AppSelect from 'components/app/input/AppSelect';
import { commonAlerts } from 'util/commonAlerts';
import AppDateTimePicker from '@/components/app/input/AppDateTimePicker';
import { formatDateTime } from '@/util/utils';
import { StatusTypeUi } from '@/api/Models';
import EditOrderButton from '@/pages/order/EditOrderButton';

const WITHOUT_PROVIDER_VALUE = { id: 0 };
export default {
  name: 'ProviderPayments',
  components: {
    EditOrderButton,
    MoneyCol,
    AppDateTimePicker,
    DateTimeCol,
    AppSelect,
    AppFilterDatePicker,
  },
  data() {
    return {
      eventsToListen: [EventType.OrderChanged],
      tableAttrs: {
        defaultSort: { prop: 'date', order: 'descending' },
        showSummary: true,
        summaryMethod: this.computeItemsSummary,
      },
      validatedOptions: [
        { value: true, label: this.$t('confirmed') },
        { value: false, label: this.$t('notConfirmed') },
      ],
      validatedFilter: false,
      tableColumns: [],
      providerOpts: [],
      providerFilterOpts: [],
      providerFilter: [],
    };
  },
  methods: {
    async fetchData({ query, pagination, sort, filters: queryFilters }) {
      const filters = {};

      if (this.validatedFilter === true) {
        filters['providerPaymentDate:notNull'] = '';
      }

      if (this.validatedFilter === false) {
        filters['providerPaymentDate:isNull'] = '';
      }

      if (!_.isEmpty(this.providerFilter)) {
        if (this.providerFilter.some((p) => p === WITHOUT_PROVIDER_VALUE)) {
          filters['providerId:isNull'] = '';
        } else {
          filters['providerId:in'] = this.providerFilter.map((p) => p.id).join(',');
        }
      }

      if (query) {
        filters['id|code|customer.name|customer.contacts.content:ilike'] = `%${query}%`;
      }
      if (queryFilters.dateRange) {
        Object.assign(filters, AppFilterDatePicker.formatAsFilter('date', queryFilters.dateRange));
      }
      return await this.$api.Order.findAll({
        'status.types.name:ne': StatusTypeUi.Type.CANCELLED,
        eager: '[customer.[contacts], provider, items]',
        orderBy: sort && sort.order === 'ascending' ? [sort.prop] : undefined,
        orderByDesc: sort && sort.order === 'descending' ? [sort.prop] : undefined,
        ...filters,
        ...pagination,
      });
    },
    manageOrder(order) {
      this.$openModal(OrderManagementDialog, {
        orderId: order.id,
      });
    },
    async toggleValidated($event, order) {
      const confirmed = await commonAlerts.confirmOperation({
        title: '',
        text: this.$t(
          $event ? 'providerPaymentConfirmationMsg' : 'providerPaymentRemoveConfirmationMsg',
          {
            orderCode: order.orderCode,
            value: this.$filters.money(order.totalCost()),
            name: order.provider?.name || this.$t('withoutProvider'),
            date: formatDateTime($event, 'date'),
          }
        ),
        cancelButtonText: this.$t('no'),
      });

      if (confirmed) {
        await this.$api.Order.save({
          id: order.id,
          providerPaymentDate: $event,
        });
      }
    },
    markValidatedToday(order) {
      return this.toggleValidated(moment(), order);
    },
    async updateOrderProvider($event, order) {
      try {
        await this.$api.Order.save({
          id: order.id,
          provider: $event,
        });
        order.provider = $event;
      } catch (e) {
        console.error('Error on updateOrderProvider()', { $event, order }, e);
      }
    },
    async filterChanged(property, values) {
      if (property === 'provider') {
        if (values.some((v) => v === WITHOUT_PROVIDER_VALUE)) {
          const lastValueIsWithoutProvider = values[values.length - 1] === WITHOUT_PROVIDER_VALUE;
          if (lastValueIsWithoutProvider) {
            this.providerFilter = [WITHOUT_PROVIDER_VALUE];
          } else {
            this.providerFilter = values.filter((v) => v !== WITHOUT_PROVIDER_VALUE);
          }
          await new Promise((res) => this.$nextTick(res));
        }
      }

      this.$refs.crudPage.fetchEntities();
    },
    computeItemsSummary({ columns, data }) {
      const values = columns.map((c) => null);
      const totalCost = data.reduce((acc, item) => acc + (item.totalCost() || 0), 0);
      values[values.length - 2] = this.$filters.money(totalCost);
      return values;
    },
  },
  async mounted() {},
  async created() {
    this.$api.Customer.findAll({
      provider: true,
      orderBy: ['name'],
    }).then((providers) => {
      this.providerOpts = providers.map((value) => ({
        value,
        label: value.name,
      }));

      this.providerFilterOpts = [
        {
          value: WITHOUT_PROVIDER_VALUE,
          label: this.$t('withoutProvider'),
        },
        ...this.providerOpts,
      ];
    });
  },
};
</script>
<style scoped lang="scss"></style>
