<template>
  <CrudDialog
    :title="$t('messageTemplate')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col-2">
        <BaseInput name="id" :label="$t('id')" v-model.number="model.id" :readonly="true" number />
      </div>
      <div class="col">
        <BaseInput
          name="name"
          :label="$t('name')"
          v-model="model.name"
          v-validate="'required|min:2|max:256'"
          required
        />
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="content" :label="$t('content')">
          <AppTextArea
            name="content"
            v-model="model.content"
            :rows="10"
            v-validate="'min:2|max:4096'"
          />
        </BaseInput>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <BaseInput name="image" :label="$t('image')">
          <div>
            <AppAutoUpload v-model="model.imageFileId" :preview="true" />
          </div>
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import AppTextArea from 'components/app/AppTextArea';
import AppAutoUpload from 'components/app/input/AppAutoUpload';

export default {
  name: 'MessageTemplateFormDialog',
  components: { AppAutoUpload, AppSelect, AppTextArea },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: {
        id: undefined,
        name: '',
        content: '',
        imageFileId: undefined,
      },
    };
  },
  methods: {
    async onSave() {
      const messageTemplate = await this.$api.MessageTemplate.save(this.model);
      this.$bus.$emit(EventType.MessageTemplateChanged, messageTemplate);
    },
  },
  async mounted() {
    if (this.id) {
      try {
        this.loading = true;
        this.model = await this.$api.MessageTemplate.findById(this.id);
      } finally {
        this.loading = false;
      }
    }
  },
};
</script>

<style scoped></style>
