<template>
  <Card class="InitialContactCard" body-classes="pl-2 pr-2">
    <div class="OrderCode d-flex" v-if="isOrder">
      <div>
        <Badge type="danger" class="mr-1">
          {{ item.status.description }}
        </Badge>
      </div>

      <Tooltip :content="$t('manageOrder')">
        <div @click="manageOrder" class="ManageOrder">
          <Badge type="white">
            {{ item.orderCode }}
          </Badge>
        </div>
      </Tooltip>
    </div>

    <div :class="['d-flex', { 'mt-3': isOrder }]">
      <div class="mr-2">
        <WhatsAvatar :customer="item.customer" :contact="item.customer.mainContact()" class="sm" />
      </div>
      <div>
        <p>
          <i class="fas fa-user-circle" />
          {{ item.customer.name }}
        </p>
        <div class="Contacts">
          <div
            v-for="contact in item.customer.contacts"
            :key="contact.id"
            v-if="contact.type === 'PHONE'"
            class="Contact"
          >
            <OrderWhatsButton :entity="item">
              <template v-slot="{ openWhats }">
                <div @click="openWhats($event, contact)" class="Whats d-inline-block">
                  <i class="fab fa-whatsapp" />
                  {{ contact.content }}
                  <span v-if="item.customer.contacts.length !== 1">
                    {{ item.customer.mainContact().id === contact.id ? '*' : '' }}
                  </span>
                </div>
              </template>
            </OrderWhatsButton>
          </div>
        </div>
      </div>
    </div>

    <div v-if="isOrder">
      <div class="d-flex align-items-center" v-if="!_.isEmpty(userRoles)">
        <span class="mr-2"> {{ $t('roles') }}: </span>
        <div>
          <Badge
            v-for="(role, idx) in userRoles"
            :key="role.label"
            :type="role.type"
            :class="['m-0', { 'mr-1': idx !== userRoles.length - 1 }]"
          >
            <i :class="['mr-1', role.icon]" />
            {{ role.label }}
          </Badge>
        </div>
      </div>
    </div>

    <div class="text-right mt-2">
      <div>
        <TagArrayInput :value="item.tags" @input="saveTags" />
      </div>

      <ElPopover trigger="hover" :open-delay="500">
        <div>
          <h4 class="mb-2">
            <i class="fab fa-whatsapp-square mr-2" />
            {{ $t('whatsApp') }}
          </h4>
          <p class="text-white">
            <span>{{ $t('lastMessage') }}:</span>
            <small v-if="whatsMsgStatus.msgAt">
              {{ whatsMsgStatus.msgAt | formatDateTime }}
              ({{ whatsMsgStatus.msgAt | fromNow }})
            </small>
          </p>
          <p class="text-white">
            <span>{{ $t('lastResponse') }}:</span>
            <small v-if="whatsMsgStatus.responseAt">
              {{ whatsMsgStatus.responseAt | formatDateTime }}
              ({{ whatsMsgStatus.responseAt | fromNow }})
            </small>
          </p>
          <hr />
          <div class="text-white">
            <span>{{ $t('markedAsNotReadAt') }}:</span>
            <small v-if="whatsMsgStatus.markedNotReadAt">
              {{ whatsMsgStatus.markedNotReadAt | formatDateTime }}
              ({{ whatsMsgStatus.markedNotReadAt | fromNow }})
            </small>
          </div>
          <div class="text-white">
            <span>{{ $t('markedAsReadAt') }}:</span>
            <small v-if="whatsMsgStatus.markedReadAt">
              {{ whatsMsgStatus.markedReadAt | formatDateTime }}
              ({{ whatsMsgStatus.markedReadAt | fromNow }})
            </small>
          </div>
          <div class="text-white" v-if="whatsMsgStatus.arrivedInStatusAt">
            <span>{{ $t('arrivedInStatusAt') }}:</span>
            <small>
              {{ whatsMsgStatus.arrivedInStatusAt | formatDateTime }}
              ({{ whatsMsgStatus.arrivedInStatusAt | fromNow }})
            </small>
          </div>
          <div class="text-white">
            <span>{{ $t('updatedAt') }}:</span>
            <small>
              {{ whatsMsgStatus.updatedAt | formatDateTime }}
              ({{ whatsMsgStatus.updatedAt | fromNow }})
            </small>
          </div>
        </div>

        <small :class="`text-${whatsMsgStatus.type}`" slot="reference" role="button">
          <i :class="whatsMsgStatus.needResponse ? 'fab fa-whatsapp' : 'fas fa-check'" />
          <span v-if="whatsMsgStatus.needResponse">
            {{ whatsMsgStatus.date | fromNow }}
          </span>
        </small>
      </ElPopover>
    </div>

    <div class="FloatingActions">
      <template v-if="!isOrder">
        <Tooltip :content="$t('generateOrder')">
          <TableButton icon="fas fa-archive" @click="$emit('generateOrder')" />
        </Tooltip>
        <Tooltip :content="$t('customer')">
          <TableButton type="info" icon="fas fa-user" @click="$emit('editCustomer')" />
        </Tooltip>

        <TableRemoveButton @click="$emit('remove')" />
      </template>
    </div>
  </Card>
</template>

<script>
import OrderWhatsButton from 'pages/order/whats/OrderWhatsButton';
import TableButton from 'components/app/btn/TableButton';
import WhatsAvatar from 'components/app/WhatsAvatar';
import Badge from 'components/Badge';
import OrderManagementDialog from 'pages/order/OrderManagementDialog';
import { commonAlerts } from 'util/commonAlerts';
import TagArrayInput from 'components/app/input/TagArrayInput';
import { OrderUi } from 'api/Models';

export default {
  name: 'InitialContactCard',
  components: { TagArrayInput, Badge, WhatsAvatar, TableButton, OrderWhatsButton },
  props: {
    item: {},
    user: {},
  },
  data() {
    return {};
  },
  computed: {
    isOrder() {
      return this.item.hasOwnProperty('statusId');
    },
    userRoles() {
      const roles = [];
      if (this.isSeller) {
        roles.push({
          label: this.$t('seller'),
          type: 'success',
          icon: 'fas fa-dollar-sign',
        });
      }
      if (this.isDesigner) {
        roles.push({
          label: this.$t('designer'),
          type: 'info',
          icon: 'fas fa-palette',
        });
      }
      return roles;
    },
    isSeller() {
      return this.item.sellerId === this.user?.id;
    },
    isDesigner() {
      return this.item.designChangeUserId === this.user?.id;
    },
    whatsMsgStatus() {
      return OrderUi.buildWhatsMsgStatus(this.item);
    },
  },
  methods: {
    manageOrder() {
      this.$openModal(OrderManagementDialog, {
        orderId: this.item.id,
      });
    },
    async saveTags(tags) {
      try {
        this.item.tags = tags;
        const service = this.isOrder ? this.$api.Order : this.$api.InitialContact;
        await service.save(_.pick(this.item, ['id', 'tags']));
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      }
    },
  },
};
</script>

<style lang="scss">
.InitialContactCard {
  .OrderCode {
    position: absolute;
    right: 2px;
    top: 2px;
  }

  .ManageOrder {
    cursor: pointer;
    opacity: 0.8;
    transition: opacity ease-in-out 100ms;

    &:hover {
      opacity: 1;
    }
  }
}
</style>
