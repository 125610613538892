import OrderEditDialog from 'pages/order/OrderEditDialog';
import CustomerEditDialog from 'pages/customer/CustomerEditDialog';
import { commonAlerts } from 'util/commonAlerts';

export default {
  methods: {
    generateOrderFromInitialContact(initialContact) {
      this.$openModal(OrderEditDialog, {
        initialCustomerId: initialContact.customer.id,
        initialWhatsDialogMessage: initialContact.props?.whatsDialogMessage || ''
      });
    },
    editCustomer({customer}) {
      this.$openModal(CustomerEditDialog, {
        id: customer.id,
      });
    },
    async removeInitialContact({ id }) {
      return await this.$api.InitialContact.remove(id);
    },
    async confirmRemoveInitialContact(initialContact) {
      if (!(await commonAlerts.confirmOperation())) return false;

      try {
        await this.removeInitialContact(initialContact);
        return true;
      } catch (e) {
        console.error(e);
        commonAlerts.error(e);
      }
      return false;
    },
  },
};
