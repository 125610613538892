import './globals';

import Vue from 'vue';
import VueRouter from 'vue-router';
import RouterPrefetch from 'vue-router-prefetch';

import { EventBusPlugin } from 'plugins/eventBusPlugin';
import { PermissionPlugin } from 'plugins/permissionsPlugin';
import ApiPlugin from './plugins/apiPlugin';
import DashboardPlugin from './plugins/dashboard-plugin';
import App from './App.vue';
import router from './routes/router';
import i18n from './i18n';
//import './registerServiceWorker';
import { appStore } from 'state/Store';
import { WebSocketPlugin } from 'plugins/wsPlugin';
import ModalsPlugin from 'plugins/ModalsPlugin';
import ValidatorPlugin from 'plugins/ValidatorPlugin';

Vue.use({ install: (Vue) => (Vue.prototype._ = _) });
Vue.use(EventBusPlugin);
Vue.use(WebSocketPlugin);
Vue.use(PermissionPlugin);
Vue.use(ApiPlugin);
Vue.use(DashboardPlugin);
Vue.use(ValidatorPlugin);
Vue.use(VueRouter);
Vue.use(RouterPrefetch);
Vue.use(ModalsPlugin);

/* eslint-disable no-new */
new Vue({
  el: '#app',
  render: (h) => h(App),
  store: appStore,
  router,
  i18n,
});
