<template>
  <CrudDialog
    :title="$t(isRequest ? 'layoutRequest' : 'layoutResponse')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col">
        <BaseInput name="user" :label="$t(isRequest ? 'seller' : 'designer')">
          <AppSelect
            name="user"
            value-key="id"
            v-model="model.user"
            v-validate="'required'"
            :options="userOptions"
            :clearable="false"
          />
        </BaseInput>
      </div>
      <div v-if="isRequest" class="col-2">
        <BaseInput name="priority" :label="$t('priority')">
          <AppSelect
            name="priority"
            v-model="model.priority"
            :options="priorityOptions"
            :clearable="false"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
      <div class="col-2">
        <BaseInput name="alert" label=" ">
          <BaseCheckbox
            name="alert"
            v-model="model.alert"
            :checked="model.alert"
          >
            {{ $t(isRequest ? 'alertTheDesigners' : 'alertTheSeller') }}
          </BaseCheckbox>
        </BaseInput>
      </div>
      <div class="col-2" v-if="isRequest">
        <BaseInput name="finished" label=" ">
          <BaseCheckbox
            name="finished"
            v-model="model.finished"
            :checked="model.finished"
            :disabled="!model.response || !model.response.alert"
          >
            {{ $t('finalizeRequest') }}
          </BaseCheckbox>
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <BaseInput name="notes" :label="$t('notes')">
          <AppTextArea
            name="notes"
            v-model="model.notes"
            class="form-control"
            maxLength="8192"
          >
          </AppTextArea>
        </BaseInput>
      </div>
    </div>

    <div class="mt-3">
      <DropzoneUpload v-model="model.uploads" />
    </div>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import AppAutoUpload from 'components/app/input/AppAutoUpload';
import AppTextArea from 'components/app/AppTextArea';
import DropzoneUpload from 'components/app/input/DropzoneUpload';
import { OrderLayoutRequestUi } from 'api/Models';
import { mergeObjects } from 'util/utils';

export default {
  name: 'OrderLayoutReqResEditDialog',
  components: {
    DropzoneUpload,
    AppTextArea,
    AppAutoUpload,
    AppDateTimePicker,
    InputMoney,
    AppSelect,
  },
  mixins: [FormMixin],
  props: {
    onSave: Function,
    entity: Object,
    isResponse: Boolean,
  },
  data() {
    const model = mergeObjects(
      {},
      {
        notes: '',
        priority: OrderLayoutRequestUi.Priority.LOW.value,
        user: this.$store.state.user,
        uploads: [],
        props: {},
        createdAt: new Date(),
        updatedAt: new Date(),
      },
      this.entity || {}
    );
    model.uploads = model.uploads.map((up) =>
      mergeObjects({ props: { notes: '' } }, up)
    );
    if (this.isResponse) {
      delete model.priority;
    }
    return {
      loading: false,
      model,
      priorityOptions: Object.entries(OrderLayoutRequestUi.Priority).map(
        ([key, { value }]) => ({
          value,
          label: this.$t(key),
        })
      ),
      userOptions: [],
    };
  },
  methods: {},
  computed: {
    isRequest() {
      return !this.isResponse;
    },
  },
  async mounted() {
    this.loading = true;
    try {
      this.userOptions = (await this.$api.User.brain.findAll()).map((u) => ({
        value: u,
        label: u.name,
      }));
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style lang="scss"></style>
