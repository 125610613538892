var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"form-group BaseInput",class:{
    'input-group-focus': _vm.focused,
    'has-danger': !_vm.disableValidation && _vm.fieldError,
    'has-success': !_vm.disableValidation && !_vm.fieldError && _vm.touched,
    'has-label': _vm.label,
    inline: _vm.inline,
  }},[_c('div',{staticClass:"mb-0",class:{ 'input-group': _vm.hasIcon }},[_vm._t("label",function(){return [(_vm.label)?_c('label',[_vm._v(" "+_vm._s(_vm.label)+" "+_vm._s(_vm.required ? '*' : '')+" ")]):_vm._e()]}),_vm._t("addonLeft",function(){return [(_vm.addonLeftIcon)?_c('span',{staticClass:"input-group-prepend"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonLeftIcon})])]):_vm._e()]}),_vm._t("default",function(){return [_c('input',_vm._g(_vm._b({staticClass:"form-control",attrs:{"aria-describedby":"addon-right addon-left"},domProps:{"value":_vm.value}},'input',_vm.$attrs,false),_vm.listeners))]},null,{ name: _vm.$attrs.name, label: _vm.label })],2),(_vm.fieldError || _vm.$slots.error)?_vm._t("error",function(){return [_c('label',{staticClass:"error"},[_vm._v(_vm._s(_vm.fieldError))])]}):_vm._e(),_vm._t("addonRight",function(){return [(_vm.addonRightIcon)?_c('span',{staticClass:"input-group-append"},[_c('div',{staticClass:"input-group-text"},[_c('i',{class:_vm.addonRightIcon})])]):_vm._e()]}),_vm._t("helperText")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }