import { axiosInstance } from 'api/AxiosInstance';

const $http = axiosInstance();

export class AuthApi {
  async login(username, password) {
    const response = await $http.post('/api/auth/login', {
      username,
      password,
    });
    return response.data;
  }

  async me() {
    const response = await $http.get('/api/auth/me');
    return response.data;
  }
}
