<template>
  <CrudDialog
    :title="$t('statusTypes')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col" v-for="type in model" :key="type.id">
        <h4 class="text-center">{{ $t(type.name) }}</h4>
        <BaseInput name="orderStatus" :label="$t('orderStatus')">
          <AppSelect
            name="orderStatus"
            value-key="id"
            v-model="type.orderStatus"
            :options="availableStatus"
            multiple
            :clearable="false"
            :grouped="true"
          />
        </BaseInput>
      </div>
    </div>
  </CrudDialog>
</template>

<script>
import { EventType } from 'plugins/eventBusPlugin';
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import { StatusTypeUi } from 'api/Models';

export default {
  name: 'OrderStatusTypeConfigDialog',
  components: { AppSelect },
  mixins: [FormMixin],
  props: {
    id: {},
  },
  data() {
    return {
      loading: false,
      model: [],
      availableStatus: [],
    };
  },
  methods: {
    async onSave() {
      for (const statusType of this.model) {
        await this.$api.StatusType.save(statusType);
      }
      this.$bus.$emit(EventType.StatusTypeChanged);
    },
  },
  async mounted() {
    this.loading = true;

    try {
      const result = await this.$api.StatusType.findAll({
        eager: 'orderStatus',
        orderBy: 'id',
      });
      this.model = Object.keys(StatusTypeUi.Type).map(type => {
        const r = result.find(r => r.name === type);
        return (
          r || {
            name: type,
            orderStatus: [],
          }
        );
      });
      this.availableStatus = (
        await this.$api.OrderStatus.findAll({
          orderBy: ['order', 'description'],
        })
      ).reduce(
        (acc, val) => {
          acc[val.visible ? 0 : 1].options.push({
            value: val,
            label: val.description,
          });
          return acc;
        },
        [
          { label: this.$t('visibles'), options: [] },
          { label: '', options: [] },
        ]
      );
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
