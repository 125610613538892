<template>
  <CrudDialog
    :title="$t('withdrawal')"
    :loading="loading"
    @save="save"
    @close="cancel"
  >
    <BaseInput name="reasons" :label="$t('reasons')" required>
      <AppSelect
        name="reasons"
        v-model="model.props.reasons"
        :options="availableReasons"
        :multiple="true"
        v-validate="'required'"
      />
    </BaseInput>

    <BaseInput name="message" :label="$t('message')">
      <textarea
        name="message"
        v-model="model.props.message"
        class="form-control"
        maxlength="1024"
        v-validate="'max:1024'"
        :placeholder="$t('withdrawalMessagePlaceholder')"
      />
    </BaseInput>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import { commonAlerts } from 'util/commonAlerts';

export default {
  name: 'OrderCancelDialog',
  components: { AppSelect },
  mixins: [FormMixin],
  props: {
    order: {
      required: true,
    },
    resolve: {
      required: true,
    },
  },
  data() {
    return {
      loading: false,
      model: {
        type: 'CANCELLATION',
        orderId: this.order.id,
        userId: this.$store.state.user.id,
        props: {
          message: '',
          reasons: [],
        },
      },
      availableReasons: [
        { value: 'Preço dos produtos' },
        { value: 'Prazo de produção e entrega' },
        { value: 'Não gostei do atendimento' },
        { value: 'Atendimento demorado' },
        { value: 'Não gostei do design/arte dos produtos' },
        { value: 'Optamos por outro tipo de produto' },
        { value: 'Desisti de comprar canecas' },
        { value: 'Queria poucas unidades, abaixo do pedido mínimo' },
        { value: 'Apenas fazendo cotação para compra futura' },
        { value: 'Valor frete' },
        { value: 'Outro' },
      ],
    };
  },
  methods: {
    async onSave() {
      this.loading = true;
      try {
        await this.$api.OrderActivity.save(this.model);
        this.resolve(true);
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
    cancel() {
      this.resolve(false);
      this.$emit('close');
    },
  },
};
</script>

<style scoped></style>
