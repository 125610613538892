var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"wrapper",class:{ 'nav-open': _vm.$sidebar.showSidebar }},[_c('notifications'),_c('sidebar-fixed-toggle-button'),_c('side-bar',{attrs:{"background-color":_vm.sidebarBackground},scopedSlots:_vm._u([{key:"links",fn:function(props){return [_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('sidebar.dashboard'),
          icon: 'tim-icons icon-chart-pie-36',
          path: '/dashboard',
        }}}),_c('sidebar-item',{attrs:{"link":{
          name: _vm.$t('initialContacts'),
          icon: 'fab fa-whatsapp-square',
          path: '/initialContacts',
        },"badge-value":_vm.initialContactsCount}}),(_vm.$perm.hasAccess(_vm.$permType.FinancialManagement))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('financial'), icon: 'fas fa-dollar-sign' }}},[(_vm.$perm.hasAccess(_vm.$permType.AccountingEntryCategoryManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('accountingEntryCategories'),
            path: '/accounting/categories',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.AccountingEntryManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('accountingEntries'),
            path: '/accounting/entries',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderPaymentManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('orderMissingPayments'),
            path: '/orderMissingPayments',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderPaymentManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('payments'),
            path: '/orderPayments',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.ProviderPaymentManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('providerPayments'),
            path: '/providerPayments',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.FinancialReport))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('report'),
            path: '/financial/report',
          }}}):_vm._e()],1):_vm._e(),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('design'), icon: 'fas fa-palette' },"badge-value":_vm.layoutRequestsCount}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('orderLayoutRequests'),
            path: '/orderLayoutRequests',
          },"badge-value":_vm.layoutRequestsCount}})],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('sales'), icon: 'fas fa-shopping-cart' },"badge-value":_vm.layoutResponsesCount}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('orderLayoutResponses'),
            path: '/orderLayoutResponses',
          },"badge-value":_vm.layoutResponsesCount}}),(_vm.$perm.hasAccess(_vm.$permType.CustomerManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('customers'),
            path: '/customers',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.EventTypeManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('eventTypes'),
            path: '/eventTypes',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.EventProspectionManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('eventProspections'),
            path: '/eventProspections',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('orders'),
            path: '/orders',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('afterSaleQuizReport'),
            path: '/afterSaleQuizReport',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('cancelledOrdersReport'),
            path: '/cancelledOrdersReport',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('orderCostsAndSalesReport'),
            path: '#orderCostsAndSalesReport',
          },"action-button":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openOrderCostsAndSalesReportDialog.apply(null, arguments)}}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('productCostsAndSalesReport'),
            path: '#productCostsAndSalesReport',
          },"action-button":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openProductCostsAndSalesReportDialog.apply(null, arguments)}}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sellersReport'),
            path: '#sellersReport',
          },"action-button":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openSellersReportDialog.apply(null, arguments)}}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('production'), icon: 'fas fa-tools' }}},[_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('productionSchedule'),
            path: '/productionSchedule',
          }}}),(_vm.$perm.hasAccess(_vm.$permType.OrderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('orders'),
            path: '/orders',
          }}}):_vm._e()],1),_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('funnels'), icon: 'fas fa-filter' },"badge-value":_vm.funnels.reduce((a, b) => (b.badgeValue ? a + b.badgeValue : a), 0)}},_vm._l((_vm.funnels),function(funnel){return _c('sidebar-item',{key:funnel.id,attrs:{"link":{
            name: funnel.name,
            path: `/funnels/${funnel.id}`,
          },"badge-value":funnel.badgeValue}})}),1),(_vm.$perm.hasAccess(_vm.$permType.SettingsManagement))?_c('sidebar-item',{attrs:{"link":{ name: _vm.$t('settings'), icon: 'fas fa-cog' }}},[(_vm.$perm.hasAccess(_vm.$permType.ApplicationConfigManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('application'),
            path: '#applicationConf',
          },"action-button":""},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.openApplicationConf.apply(null, arguments)}}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.AttributeManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('attributes'),
            path: '/attributes',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.StatusAutomationManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('statusAutomations'),
            path: '/statusAutomations',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.StockManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('stock'),
            path: '/stock',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.ProviderManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('providers'),
            path: '/providers',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.FunnelManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('funnels'),
            path: '/funnels',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.ShippingMethodManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('shippingMethods'),
            path: '/shippingMethods',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.ProductManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('products'),
            path: '/products',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.SectorManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('sectors'),
            path: '/sectors',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.OrderStatusManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('orderStatus'),
            path: '/orderStatus',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.UserManagementAccess))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('users'),
            path: '/users',
          }}}):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.WooManagement))?_c('sidebar-item',{attrs:{"link":{
            name: _vm.$t('wooCommerce'),
            path: '/wooCalls',
          }}}):_vm._e()],1):_vm._e(),(_vm.$perm.hasAccess(_vm.$permType.IntegrationView))?_c('li',[_c('a',{attrs:{"href":"#"},on:{"click":function($event){$event.preventDefault();return _vm.toggleIntegrationsModal.apply(null, arguments)}}},[_c('i',{staticClass:"fas fa-paperclip"}),_c('p',[_vm._v(_vm._s(_vm.$t('integrations')))])])]):_vm._e()]}}])}),_c('div',{staticClass:"main-panel",attrs:{"data":_vm.sidebarBackground}},[_c('dashboard-navbar'),_c('router-view',{attrs:{"name":"header"}}),_c('div',{class:{ content: !_vm.$route.meta.hideContent },on:{"click":_vm.toggleSidebar}},[_c('zoom-center-transition',{attrs:{"duration":200,"mode":"out-in"}},[_c('router-view'),_vm._t("default")],2)],1)],1),_c('ModalRoot')],1)
}
var staticRenderFns = []

export { render, staticRenderFns }