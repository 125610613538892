<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  >
  </CrudPage>
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import Badge from 'components/Badge';
import FunnelEditDialog from 'pages/funnel/FunnelEditDialog';
import BooleanCol from 'components/app/table/BooleanCol';

export default {
  name: 'Funnels',
  components: { Badge },
  data() {
    return {
      dialog: FunnelEditDialog,
      eventsToListen: [EventType.FunnelChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'name' },
        {
          prop: 'props.showInitialContacts',
          label: this.$t('showInitialContacts'),
          component: BooleanCol,
          attrs: { width: 220 },
        },
        { prop: 'order', label: this.$t('position'), attrs: { width: 100 } },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination, filters: queryFilters }) {
      const filters = {};

      if (query) {
        filters['id|name:ilike'] = `%${query}%`;
      }
      const result = await this.$api.Funnel.findAll({
        ...filters,
        ...pagination,
      });
      return result;
    },
    async remove(row) {
      return await this.$api.Funnel.remove(row.id);
    },
  },
};
</script>
<style scoped lang="scss"></style>
