<template>
  <CrudDialog
    :title="$t('design')"
    :loading="loading"
    @save="save"
    @close="$emit('close')"
  >
    <div class="row">
      <div class="col">
        <BaseInput
          name="id"
          :label="$t('id')"
          v-model.number="model.id"
          :readonly="true"
        />
      </div>
      <div class="col">
        <BaseInput name="date" :label="$t('date')">
          <AppDateTimePicker
            name="date"
            v-model="model.date"
            v-validate="'required'"
          />
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="designer" :label="$t('designer')">
          <AppSelect
            value-key="id"
            name="designer"
            v-model="model.designer"
            :options="availableDesigners"
          />
        </BaseInput>
      </div>
    </div>

    <div class="row">
      <div class="col-4">
        <BaseInput name="imageFile" :label="$t('image')" required>
          <div class="text-center">
            <AppAutoUpload
              name="imageFile"
              v-model="model.imageFileId"
              v-validate="'required'"
              :data-vv-as="$t('file')"
              accept="image/*"
              :size="4"
            />
          </div>
        </BaseInput>
      </div>
      <div class="col">
        <BaseInput name="description" :label="$t('description')">
          <AppTextArea
            name="description"
            v-model="model.description"
            :max-length="2048"
            v-validate="'max:2048'"
            :rows="10"
          />
        </BaseInput>
      </div>
    </div>
    <template v-slot:footer>
      <BaseButton
        v-if="!!entity"
        class="pull-left"
        type="danger"
        @click="removeDesign"
      >
        {{ $t('remove') }}
      </BaseButton>
    </template>
  </CrudDialog>
</template>

<script>
import { FormMixin } from 'mixins/FormMixin';
import AppSelect from 'components/app/input/AppSelect';
import InputMoney from 'components/app/input/InputMoney';
import AppDateTimePicker from 'components/app/input/AppDateTimePicker';
import { mergeObjects } from 'util/utils';
import AppAutoUpload from 'components/app/input/AppAutoUpload';
import AppTextArea from 'components/app/AppTextArea';
import { commonAlerts } from 'util/commonAlerts';

export default {
  name: 'OrderItemDesignEditDialog',
  components: {
    AppTextArea,
    AppAutoUpload,
    AppDateTimePicker,
    InputMoney,
    AppSelect,
  },
  mixins: [FormMixin],
  props: {
    entity: {},
    onSave: {},
    onRemove: {},
  },
  data() {
    return {
      loading: false,
      model: mergeObjects(
        {},
        {
          date: new Date(),
          description: '',
          designer: undefined,
          imageFileId: undefined,
        },
        this.entity
      ),
      availableDesigners: [],
    };
  },
  methods: {
    async removeDesign() {
      if (!(await commonAlerts.confirmOperation())) {
        return;
      }
      await this.onRemove();
      this.$emit('close');
    },
  },
  async mounted() {
    this.loading = true;
    try {
      this.availableDesigners = (await this.$api.User.brain.findAll({
        orderBy: 'name',
      })).map(value => ({
        value,
        label: value.name,
      }));
    } finally {
      this.loading = false;
    }
  },
};
</script>

<style scoped></style>
