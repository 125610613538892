import Vue from 'vue';
import Vuex from 'vuex';
import { appCache } from 'state/Cache';
import { routerStorePlugin } from 'routes/routerStorePlugin';
import { fillObjectValuesWithKeyName, generateUniqueId } from 'util/utils';

Vue.use(Vuex);

const authData = appCache.get('authData') || { user: null, token: null };

if (!authData.token) {
  const urlParams = new URLSearchParams(window.location.search);
  authData.token = urlParams.get('uKey');
}

export const Mutation = {
  login: '',
  logout: '',
  openModal: '',
  closeModal: '',
};

fillObjectValuesWithKeyName(Mutation);

export const appStore = new Vuex.Store({
  plugins: [routerStorePlugin],
  state: {
    modals: {
      open: [],
    },
    ...authData,
  },
  mutations: {
    [Mutation.login](state, authData) {
      Object.assign(state, authData);
      appCache.put('authData', authData);
    },
    [Mutation.logout](state) {
      Object.assign(state, { user: null, token: null });
      appCache.remove('authData');
    },
    [Mutation.openModal](state, { component, props }) {
      const id = generateUniqueId();
      state.modals.open.push({
        modalId: id,
        component,
        props: {
          ...props,
        },
      });
    },
    [Mutation.closeModal](state, modalId) {
      state.modals.open = state.modals.open.filter((m) => m.modalId !== modalId);
    },
  },
});
