import Vue from 'vue';
import { fillObjectValuesWithKeyName } from 'util/utils';

export const EventType = fillObjectValuesWithKeyName({
  AccountingEntryChanged: '',
  AccountingEntryCategoryChanged: '',
  AttributeChanged: '',
  ConfChanged: '',
  CustomerChanged: '',
  CustomerContactChanged: '',
  DesignWorkflowChanged: '',
  EventProspectionChanged: '',
  EventsToProspectChanged: '',
  EventTypeChanged: '',
  FunnelChanged: '',
  // Websocket
  InitialContactChanged: '',
  MessageTemplateChanged: '',
  // Websocket
  OrderChanged: '',
  // Websocket
  OrderActivityChanged: '',
  // Websocket
  OrderLayoutRequestChanged: '',
  OrderPaymentChanged: '',
  // Websocket
  OrderStatusChanged: '',
  ProductChanged: '',
  SectorChanged: '',
  ShippingMethodChanged: '',
  StatusAutomationChanged: '',
  StatusTypeChanged: '',
  StockChanged: '',
  UserChanged: '',
  WooCallChanged: '',
  // Websocket
  Whats: '',
});

export const appEventBus = new Vue();

export const EventBusPlugin = {
  install(Vue) {
    Vue.prototype.$bus = appEventBus;
  },
};
