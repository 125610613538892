<template>
  <ul>
    <li class="Item" v-for="(item, idx) in data" :key="idx">
      <span class="ml-2 mr-2">{{ item.product.name }}</span>
      <ul>
        <li
          v-for="(attr, idx) in _.orderBy(item.attrs, 'quantity', 'desc')"
          :key="idx"
        >
          <Badge type="info" class="mr-2 no"> {{ attr.quantity }}x </Badge>

          <Badge v-if="_.isEmpty(attr.attrVals)" type="warning">
            {{ $t('withoutAttributes') }}
          </Badge>
          <AttributeValueBadge
            v-for="(attrVal, idx) in attr.attrVals"
            :key="idx"
            :attribute-value="attrVal"
            type="success"
          />
        </li>
      </ul>
    </li>
  </ul>
</template>

<script>
import Badge from 'components/Badge';
import AttributeValueBadge from 'pages/order/AttributeValueBadge';
export default {
  name: 'TotalProduction',
  components: { AttributeValueBadge, Badge },
  props: {
    data: {},
  },
};
</script>

<style scoped></style>
