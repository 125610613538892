<template>
  <div>
    <el-tag
      v-for="(tag, index) in dynamicTags"
      :key="tag + index"
      size="small"
      :type="tagType"
      :closable="editable"
      :close-transition="false"
      @close="handleClose(tag)"
    >
      {{ tag }}
    </el-tag>

    <Tooltip :content="$t('tagInputTitle')" v-if="editable">
      <input
        type="text"
        :placeholder="placeholder || $t('addNewItem')"
        class="form-control input-new-tag"
        v-model="inputValue"
        ref="saveTagInput"
        size="mini"
        @input="onInput"
        @keydown.enter.prevent="handleInputConfirm"
        @blur="handleInputConfirm"
      />
    </Tooltip>
  </div>
</template>

<script>
import { Tag } from 'element-ui';

export default {
  name: 'TagsInput',
  components: {
    [Tag.name]: Tag,
  },
  props: {
    value: {
      type: Array,
      default: () => [],
      description: 'List of tags',
    },
    tagType: {
      type: String,
      default: 'primary',
      description: 'Tag type (primary|danger etc)',
    },
    allowDuplicates: {
      type: Boolean,
      default: false,
    },
    placeholder: {
      type: String,
      default: '',
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },
  model: {
    prop: 'value',
    event: 'change',
  },
  data() {
    return {
      dynamicTags: [],
      inputVisible: false,
      inputValue: '',
    };
  },
  methods: {
    handleClose(tag) {
      this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
      this.$emit('change', this.dynamicTags);
    },
    showInput() {
      this.inputVisible = true;
      this.$nextTick(() => {
        this.$refs.saveTagInput.$refs.input.focus();
      });
    },

    handleInputConfirm() {
      let inputValue = _.trim(this.inputValue);
      if (inputValue) {
        const valueExists = this.dynamicTags.includes(inputValue);
        if (valueExists && !this.allowDuplicates) {
          return;
        }
        this.dynamicTags.push(inputValue);
        this.$emit('change', this.dynamicTags);
      }
      this.inputVisible = false;
      this.inputValue = '';
    },
    onInput(evt) {
      this.$emit('input', evt.target.value);
    },
  },
  created() {
    this.$watch(
      'value',
      newVal => {
        this.dynamicTags = [...newVal];
      },
      { immediate: true }
    );
  },
};
</script>
