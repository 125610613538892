import { commonAlerts } from 'util/commonAlerts';

export const FormMixin = {
  provide() {
    return { errors: this.errors };
  },
  data() {
    return {
      loading: false,
    };
  },
  methods: {
    async save() {
      this.loading = true;
      try {
        const isValid = await this.$validator.validate();
        if (!isValid) {
          return;
        }
        await this.onSave(this.model);
        this.$emit('close');
      } catch (e) {
        console.error(e);
        commonAlerts.defaultErrorMessage(e);
      } finally {
        this.loading = false;
      }
    },
  },
  computed: {
    isNew() {
      return !_.isNumber(_.get(this, 'model.id'));
    },
  },
};
