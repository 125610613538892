<template>
  <CrudPage
    :fetch-data="fetchData"
    :dialog="dialog"
    :columns="tableColumns"
    :listenToEvents="eventsToListen"
    :on-remove="remove"
  />
</template>
<script>
import { EventType } from 'plugins/eventBusPlugin';
import StatusAutomationEditDialog from 'pages/statusAutomation/StatusAutomationEditDialog';
import TagsCol from 'components/app/table/TagsCol';

export default {
  name: 'StatusAutomations',
  props: {
    providerView: {
      default: false,
    },
  },
  data() {
    return {
      dialog: StatusAutomationEditDialog,
      eventsToListen: [EventType.StatusAutomationChanged],
      tableColumns: [
        { prop: 'id', attrs: { width: 100 } },
        { prop: 'name' },

        {
          prop: 'statuses',
          label: this.$t('statusToBeObserved'),
          component: TagsCol,
          toLabel: (status) => {
            return status.description;
          },
        },
        { prop: 'idleTime', label: this.$t('afterIdleTimeInMinutes') },
        { prop: 'action', render: ({ row }) => this.$t(row.action) },
        {
          prop: 'moveToStatus',
          label: this.$t('moveToStatus'),
          component: TagsCol,
          toLabel: (status) => {
            return status.description;
          },
        },
      ],
    };
  },
  methods: {
    async fetchData({ query, pagination }) {
      const filters = {};
      if (query) {
        filters['id|name:ilike'] = `%${query}%`;
      }
      return await this.$api.StatusAutomation.findAll({
        eager: '[statuses, moveToStatus]',
        orderBy: ['name'],
        ...filters,
        ...pagination,
      });
    },
    async remove(row) {
      return await this.$api.StatusAutomation.remove(row.id);
    },
  },
};
</script>
<style scoped lang="scss"></style>
